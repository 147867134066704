import AcademicBackground from "./AcademicBackground";
import PermanentAndMailingAddress from "./PermanentAndMailingAddress";
import WorkExperience from "./WorkExperience";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from "react";

import {
  Typography,
  Box,
  TextField,
  Grid,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  FormLabel,
  CardContent,
  Card
} from "@mui/material";

const DashboardEnrollmentForm = ({
  formData,
  setFormData,
  setPermanentAddress,
  permanentAddress,
  academicData,
  setAcademicData,
  mailingAddress,
  setMailingAddress
}) => {
  const [dob, setDob] = useState(null);
  const formFields = [
    { label: "First Name", name: "firstName" },
    { label: "Last Name", name: "lastName" }
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid item md={12} lg={12} xs={12} sx={{ paddingTop: "20px" }}>
        <Box sx={{ margin: "0 20px" }}>
          <Card sx={{ borderRadius: 2, boxShadow: 3, overflow: "visible" }}>
            <CardContent>
              <SectionTitle title="INFORMATION" />
              <Grid container spacing={2}>
                {formFields.map((field, index) => (
                  <Grid key={index} item lg={3} xs={6}>
                    <TextField
                      label={field.label}
                      name={field.name}
                      variant="outlined"
                      fullWidth
                      value={formData[field.name] || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [field.name]: e.target.value
                        })
                      }
                    />
                  </Grid>
                ))}
                <Grid item lg={2} xs={6}>
                  <DatePicker
                    label="DOB"
                    value={dob}
                    onChange={(newValue) => {
                      setDob(newValue);
                      setFormData({ ...formData, dob: newValue });
                    }} // Update state on date change
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )} // Render the TextField
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <FormControl sx={{ paddingLeft: "10px" }}>
                    <FormLabel>Gender</FormLabel>
                    <RadioGroup
                      row
                      name="gender"
                      value={formData.gender || ""}
                      onChange={(e) =>
                        setFormData({ ...formData, gender: e.target.value })
                      }
                    >
                      <FormControlLabel
                        value="Male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="others"
                        control={<Radio />}
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <AcademicBackground
                formData={formData.academicData}
                academicData={academicData}
                setAcademicData={setAcademicData}
                sendDataToParent={(academicData) => {
                  setFormData({
                    ...formData,
                    academicBackground: academicData
                  });
                }}
              />
              <WorkExperience
                workData={formData.workExperience || []}
                setWorkData={(workData) => {
                  setFormData({ ...formData, workExperience: workData });
                }}
              />
              <SectionTitle title="STUDENT DETAILS" />
              <Grid container spacing={2}>
                {[
                  { label: "Student EmailID", name: "studentEmail" },
                  { label: "Mobile Number", name: "mobileNumber" },
                  { label: "Guardian Name", name: "guardianName" },
                  { label: "Guardian Mobile Number", name: "guardianMobile" }
                ].map((field, index) => (
                  <Grid key={index} item lg={3} xs={6}>
                    <TextField
                      label={field.label}
                      name={field.name}
                      variant="outlined"
                      type={
                        field.name === "mobileNumber" ||
                          field.name === "guardianMobile"
                          ? "number"
                          : "text"
                      }
                      fullWidth
                      value={formData[field.name] || ""}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [field.name]: e.target.value
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
              <Box sx={{ paddingTop: "14px" }}>
                <PermanentAndMailingAddress
                  mailingAddress={mailingAddress}
                  setMailingAddress={setMailingAddress}
                  permanentAddress={permanentAddress}
                  setPermanentAddress={setPermanentAddress}
                  sendDataToParent={({ permanentAddress, mailingAddress }) => {
                    setFormData({
                      ...formData,
                      permanentAddress: permanentAddress,
                      mailingAddress: mailingAddress
                    });
                  }}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </LocalizationProvider>
  );
};

export default DashboardEnrollmentForm;

const SectionTitle = ({ title }) => (
  <Typography
    variant="h5"
    sx={{ fontSize: { xs: "18px" }, paddingBottom: "10px" }}
  >
    {title}
  </Typography>
);
