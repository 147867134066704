import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Hidden,
  createTheme,
  Paper,
} from "@mui/material";
import React, { useState } from "react";
import service1 from "../../../asset/service1.svg";
import service2 from "../../../asset/service2.svg";
import service3 from "../../../asset/service3.svg";
import service4 from "../../../asset/service4.svg";
import service11 from "../../../asset/service11.svg";
import service22 from "../../../asset/service22.svg";
import service33 from "../../../asset/service33.svg";
import service44 from "../../../asset/service44.svg";
import line from "../../../asset/line.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { viewmoredata } from "./ViewmoreData";
import dottedline from "../../../asset/dottedline.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "../../../globle.css";
import Header from "../../../Component/content-component/Header";
import SecondCard from "./SecondCard";

const Abroad = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [hoveredStep, setHoveredStep] = useState(null);
  const [popup, setPopup] = useState(false);
  const [cate, setCate] = useState(0);

  const handlepopup = () => {
    setPopup(!popup);
  };

  const [selectedCategory, setSelectedCategory] = useState(null);
  const handleButtonClick = (category) => {
    setSelectedCategory((prevCategory) =>
      prevCategory === category ? null : category
    );
  };

  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920,
      },
    },
  });


  const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));
  return (
    <div>
      <Stack
        gap={3}
        direction="column"
        sx={{
          backgroundColor: "#FfffFF",
          textAlign: "center",
          padding: "60px 0",
        }}
      >
        {" "}
        <Header
          color="#00999e"
          textAlign="center"
          content="Track Your Study Abroad Journey"
        />
        <Container maxWidth={isLargeScreen ? "xl" : "lg"}>
          <Box
            rowGap={3}
            paddingTop={6}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
              md: "repeat(4, 1fr)",
            }}
          >
            {/* box-1 */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  color: "#00999e",
                  // position: "absolute",
                  right: { xs: "10%", sm: "20%", md: "10%", lg: "10%" },
                  top: { xs: "-6%", sm: "" - (6 % ""), md: "-5%", lg: "-5%" },
                  zIndex: 9,
                }}
              ></Box>
              <Card
                sx={{
                  width: 200,
                  height: 200,
                  borderTopWidth: "50%",
                  "&:hover": {
                    backgroundColor: "#5cd2d6",
                    color: "white",
                    zIndex: 1,
                    "& .css-1rq26sm-MuiTypography-root": {
                      color: "black",
                    },
                  },
                  position: "relative",
                  zIndex: 1,
                  overflow: "visible",
                }}
                onMouseEnter={() => setHoveredStep(1)}
                onMouseLeave={() => setHoveredStep(null)}
                className="pseudo-partial-border"
              >
                <Box padding={2}>
                  <img
                    alt="Hexagon Image"
                    src={hoveredStep === 1 ? service11 : service1}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 700, padding: 1 }}>
                    PERSONALIZED MENTORING
                  </Typography>
                </Box>
              </Card>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#00999E",
                    mt: 2,
                    "&:hover": {
                      backgroundColor: "#007f80",
                      color: "#fff",
                    },
                  }}
                  endIcon={
                    selectedCategory === "personalized" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  onClick={() => handleButtonClick("personalized")}
                >
                  View More
                </Button>
              </Box>
              <Box
                sx={{
                  height: isSmallScreen ? "auto" : "100px",
                }}
              >
                {selectedCategory === "personalized" && (
                  <div>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        flexDirection: "column",
                        paddingTop: "20px",
                      }}
                    >
                      <img
                        src={dottedline}
                        alt="dotted-line"
                        width={5}
                        height={80}
                        style={{ margin: "auto" }}
                      />
                      <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
                    </Box>
                    {isSmallScreen ? (
                      <Card sx={{ mt: 3, mb: 5 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {viewmoredata[selectedCategory].map(
                              (item, index) => (
                                <>
                                  <Grid
                                    item
                                    lg={4}
                                    display="flex"
                                    flexDirection={
                                      isSmallScreen ? "column" : "row"
                                    }
                                    alignItems="center"
                                    textAlign="center"
                                  >
                                    <Box py={4}>
                                      <Typography sx={{ fontWeight: 700 }}>
                                        {item?.title}
                                      </Typography>
                                      <Box
                                        display="flex"
                                        justifyContent="space-evenly"
                                      >
                                        <img src={line} alt />
                                      </Box>
                                      <Typography
                                        sx={{
                                          height: "100px",
                                          padding: "10px",
                                        }}
                                      >
                                        {item.about}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  {index <
                                  viewmoredata[selectedCategory].length - 1 ? (
                                    <Box
                                      sx={{
                                        color: "#00999E",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      {isSmallScreen ? (
                                        <KeyboardArrowDownIcon />
                                      ) : (
                                        <ArrowForwardIosIcon />
                                      )}
                                    </Box>
                                  ) : null}
                                </>
                              )
                            )}
                          </Grid>
                        </Box>
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Box>
            </Box>

            {/* box-2 */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  color: "#00999e",
                  position: "absolute",
                  right: "10%",
                  top: "-5%",
                  zIndex: 9,
                }}
              ></Box>
              <Card
                sx={{
                  width: 200,
                  height: 200,
                  borderTopWidth: "50%",
                  "&:hover": {
                    backgroundColor: "#5cd2d6",
                    color: "white",
                    zIndex: 1,
                    "& .css-1rq26sm-MuiTypography-root": {
                      color: "black",
                    },
                  },
                  position: "relative",
                  zIndex: 1,
                  overflow: "visible",
                }}
                onMouseEnter={() => setHoveredStep(2)}
                onMouseLeave={() => setHoveredStep(null)}
                className="pseudo-partial-border"
              >
                <Box padding={2}>
                  <img
                    alt="Hexagon Image"
                    src={hoveredStep === 2 ? service22 : service2}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 700, padding: 1 }}>
                    CAREER <br /> MAPPING
                  </Typography>
                </Box>
              </Card>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#00999E",
                    mt: 2,
                    "&:hover": {
                      backgroundColor: "#007f80",
                      color: "#fff",
                    },
                  }}
                  endIcon={
                    selectedCategory === "career" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  onClick={() => handleButtonClick("career")}
                >
                  View More
                </Button>
              </Box>
              <Box
                sx={{
                  height: isSmallScreen ? "auto" : "100px",
                }}
              >
                {selectedCategory === "career" && (
                  <div>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        flexDirection: "column",
                        paddingTop: "20px",
                      }}
                    >
                      <img
                        src={dottedline}
                        alt="dotted-line"
                        width={5}
                        height={80}
                        style={{ margin: "auto" }}
                      />
                      <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
                    </Box>
                    {isSmallScreen ? (
                      <Card sx={{ mt: 3, mb: 5 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {viewmoredata[selectedCategory].map(
                              (item, index) => (
                                <>
                                  <Grid
                                    item
                                    lg={4}
                                    display="flex"
                                    flexDirection={
                                      isSmallScreen ? "column" : "row"
                                    }
                                    alignItems="center"
                                    textAlign="center"
                                  >
                                    <Box py={4}>
                                      {/*  <Box><img src={item?.icon}
                                      alt={item?.title} /></Box> */}
                                      <Typography sx={{ fontWeight: 700 }}>
                                        {item?.title}
                                      </Typography>
                                      <Box
                                        display="flex"
                                        justifyContent="space-evenly"
                                      >
                                        <img src={line} alt />
                                      </Box>
                                      <Typography
                                        sx={{
                                          height: "100px",
                                          padding: "10px",
                                        }}
                                      >
                                        {item.about}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  {index <
                                  viewmoredata[selectedCategory].length - 1 ? (
                                    <Box
                                      sx={{
                                        color: "#00999E",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      {isSmallScreen ? (
                                        <KeyboardArrowDownIcon />
                                      ) : (
                                        <ArrowForwardIosIcon />
                                      )}
                                    </Box>
                                  ) : null}
                                </>
                              )
                            )}
                          </Grid>
                        </Box>
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Box>
            </Box>

            {/* box-3 */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  color: "#00999e",
                  position: "absolute",
                  right: "10%",
                  top: "-5%",
                  zIndex: 9,
                }}
              ></Box>
              <Card
                sx={{
                  width: 200,
                  height: 200,
                  borderTopWidth: "50%",
                  "&:hover": {
                    backgroundColor: "#5cd2d6",
                    color: "white",
                    zIndex: 1,
                    "& .css-1rq26sm-MuiTypography-root": {
                      color: "black",
                    },
                  },
                  position: "relative",
                  zIndex: 1,
                  overflow: "visible",
                }}
                onMouseEnter={() => setHoveredStep(3)}
                onMouseLeave={() => setHoveredStep(null)}
                className="pseudo-partial-border"
              >
                <Box padding={2}>
                  <img
                    alt="Hexagon Image"
                    src={hoveredStep === 3 ? service33 : service3}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 700, padding: 1 }}>
                    ADMISSION <br /> SERVICES
                  </Typography>
                </Box>
              </Card>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#00999E",
                    mt: 2,
                    "&:hover": {
                      backgroundColor: "#007f80",
                      color: "#fff",
                    },
                  }}
                  endIcon={
                    selectedCategory === "admission" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  onClick={() => handleButtonClick("admission")}
                >
                  View More
                </Button>
              </Box>
              <Box
                sx={{
                  height: isSmallScreen ? "auto" : "100px",
                }}
              >
                {selectedCategory === "admission" && (
                  <div>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        flexDirection: "column",
                        paddingTop: "20px",
                      }}
                    >
                      <img
                        src={dottedline}
                        alt="dotted-line"
                        width={5}
                        height={80}
                        style={{ margin: "auto" }}
                      />
                      <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
                    </Box>
                    {isSmallScreen ? (
                      <Card sx={{ mt: 3, mb: 5 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {viewmoredata[selectedCategory].map(
                              (item, index) => (
                                <>
                                  <Grid
                                    item
                                    lg={4}
                                    display="flex"
                                    flexDirection={
                                      isSmallScreen ? "column" : "row"
                                    }
                                    alignItems="center"
                                    textAlign="center"
                                  >
                                    <Box py={4}>
                                      {/*  <Box><img src={item?.icon}
                                      alt={item?.title} /></Box> */}
                                      <Typography sx={{ fontWeight: 700 }}>
                                        {item?.title}
                                      </Typography>
                                      <Box
                                        display="flex"
                                        justifyContent="space-evenly"
                                      >
                                        <img src={line} alt />
                                      </Box>
                                      <Typography
                                        sx={{
                                          height: "100px",
                                          padding: "10px",
                                        }}
                                      >
                                        {item.about}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  {index <
                                  viewmoredata[selectedCategory].length - 1 ? (
                                    <Box
                                      sx={{
                                        color: "#00999E",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      {isSmallScreen ? (
                                        <KeyboardArrowDownIcon />
                                      ) : (
                                        <ArrowForwardIosIcon />
                                      )}
                                    </Box>
                                  ) : null}
                                </>
                              )
                            )}
                          </Grid>
                        </Box>
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Box>
            </Box>

            {/* box-4 */}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  color: "#00999e",
                  position: "absolute",
                  right: "10%",
                  top: "-5%",
                  zIndex: 9,
                }}
              ></Box>
              <Card
                sx={{
                  width: 200,
                  height: 200,
                  borderTopWidth: "50%",
                  "&:hover": {
                    backgroundColor: "#5cd2d6",
                    color: "white",
                    zIndex: 1,
                    "& .css-1rq26sm-MuiTypography-root": {
                      color: "black",
                    },
                  },
                  position: "relative",
                  zIndex: 1,
                  overflow: "visible",
                }}
                onMouseEnter={() => setHoveredStep(4)}
                onMouseLeave={() => setHoveredStep(null)}
                className="pseudo-partial-border"
              >
                <Box padding={2}>
                  <img
                    alt="Hexagon Image"
                    src={hoveredStep === 4 ? service44 : service4}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 700, padding: 1 }}>
                    POST ENROLMENT SUPPORT
                  </Typography>
                </Box>
              </Card>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#00999E",
                    mt: 2,
                    "&:hover": {
                      backgroundColor: "#007f80",
                      color: "#fff",
                    },
                  }}
                  endIcon={
                    selectedCategory === "personalized" ? (
                      <KeyboardArrowUpIcon />
                    ) : (
                      <KeyboardArrowDownIcon />
                    )
                  }
                  onClick={() => handleButtonClick("post")}
                >
                  View More
                </Button>
              </Box>
              <Box
                sx={{
                  height: isSmallScreen ? "auto" : "100px",
                }}
              >
                {selectedCategory === "post" && (
                  <div>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        flexDirection: "column",
                        paddingTop: "20px",
                      }}
                    >
                      <img
                        src={dottedline}
                        alt="dotted-line"
                        width={5}
                        height={80}
                        style={{ margin: "auto" }}
                      />
                      <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
                    </Box>

                    {isSmallScreen ? (
                      <Card sx={{ mt: 3, mb: 5 }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            {viewmoredata[selectedCategory].map(
                              (item, index) => (
                                <>
                                  <Grid
                                    item
                                    lg={4}
                                    display="flex"
                                    flexDirection={
                                      isSmallScreen ? "column" : "row"
                                    }
                                    alignItems="center"
                                    textAlign="center"
                                  >
                                    <Box py={4}>
                                      {/*  <Box><img src={item?.icon}
                                          alt={item?.title} /></Box> */}
                                      <Typography sx={{ fontWeight: 700 }}>
                                        {item?.title}
                                      </Typography>
                                      <Box
                                        display="flex"
                                        justifyContent="space-evenly"
                                      >
                                        <img src={line} alt />
                                      </Box>
                                      <Typography
                                        sx={{
                                          height: "100px",
                                          padding: "10px",
                                        }}
                                      >
                                        {item.about}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  {index <
                                  viewmoredata[selectedCategory].length - 1 ? (
                                    <Box
                                      sx={{
                                        color: "#00999E",
                                        paddingLeft: "20px",
                                      }}
                                    >
                                      {isSmallScreen ? (
                                        <KeyboardArrowDownIcon />
                                      ) : (
                                        <ArrowForwardIosIcon />
                                      )}
                                    </Box>
                                  ) : null}
                                </>
                              )
                            )}
                          </Grid>
                        </Box>
                      </Card>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Box>
            </Box>
          </Box>
          <Hidden smDown>
            {selectedCategory && (
              <SecondCard
                theme={theme}
                popup={popup}
                setPopup={setPopup}
                cate={cate}
                setCate={setCate}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            )}
          </Hidden>
        </Container>
      </Stack>
    </div>
  );
};

export default Abroad;
