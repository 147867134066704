// import React from "react";
// import { Box, Grid, Typography } from "@mui/material";

// const SelectionBox = ({
//   label,
//   options,
//   selectedValue,
//   onSelect,
//   width,
//   gridsizemedium = 6,
//   gridsizesmall = 6
// }) => {
//   return (
//     <Box
//       sx={{
//         margin: "20px 0",
//         // bgcolor: "rgb(239, 253, 255, 0.9)",
//         border: "2px solid #00999E",
//         borderRadius: "20px",
//         background: `linear-gradient(to right, 
//               rgba(167, 214, 215, 0.6) 10%,   
//               rgba(218, 240, 241, 0.5) 60%, 
//               rgba(255, 255, 255, 1) 100%)`,
//         padding: "20px",
//         display: "flex",
//         justifyContent: "space-between",
//         flexDirection: { xs: "column", md: "row" },

//         alignItems: "center"
//       }}
//     >
//       <Box
//         sx={{
//           width: { sm: "600px", xs: "320px" },
//           margin: "auto",
//           textAlign: { xs: "center", md: "start" }
//         }}
//       >
//         <Typography variant="h6" gutterBottom>
//           {label}
//         </Typography>
//       </Box>
//       <Box sx={{ width: { sm: "600px", xs: "320px" } }}>
//         <Grid container spacing={2}>
//           {options.map((option) => (
//             <Grid
//               item
//               key={option}
//               xs={gridsizesmall}
//               md={gridsizemedium}
//               sx={{ display: "flex", justifyContent: "center" }}
//             >
//               <Box
//                 sx={{
//                   width: width ?? "200px",
//                   padding: "8px 16px",
//                   textAlign: "center",
//                   cursor: "pointer",
//                   borderRadius: "4px",
//                   border:
//                     selectedValue === option
//                       ? "2px solid #00999E"
//                       : "2px solid #000",
//                   backgroundColor: "white",
//                   color: selectedValue === option ? "#00999E" : "#000",
//                   "&:hover": {
//                     backgroundColor: "grey.100"
//                   },
//                   boxShadow:
//                     selectedValue === option
//                       ? "0px 4px 6px #00999E"
//                       : "0px 4px 6px rgba(0, 0, 0, 0.5)" // Custom shadow only at the bottom
//                 }}
//                 onClick={() => onSelect(option)}
//               >
//                 {option}
//               </Box>
//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//     </Box>
//   );
// };

// export default SelectionBox;


import React from "react";
import { Box, Grid, Typography } from "@mui/material";

const SelectionBox = ({
  label,
  options,
  selectedValue,
  onSelect,
  width,
  multiple = false, // New prop to toggle between single and multiple selection
  gridsizemedium = 6,
  gridsizesmall = 6
}) => {
  const handleClick = (option) => {
    if (multiple) {
      if (selectedValue.includes(option)) {
        onSelect(selectedValue.filter((item) => item !== option)); // Remove option
      } else {
        onSelect([...selectedValue, option]); // Add option
      }
    } else {
      onSelect(option); // Single selection
    }
  };

  return (
    <Box
      sx={{
        margin: "20px 0",
        border: "2px solid #00999E",
        borderRadius: "20px",
        background: `linear-gradient(to right, 
              rgba(167, 214, 215, 0.6) 10%,   
              rgba(218, 240, 241, 0.5) 60%, 
              rgba(255, 255, 255, 1) 100%)`,
        padding: "20px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center"
      }}
    >
      <Box
        sx={{
          width: { sm: "600px", xs: "200px" },
          margin: "auto",
          textAlign: { xs: "center", md: "start" }
        }}
      >
        <Typography variant="h6" gutterBottom>
          {label}
        </Typography>
      </Box>
      <Box sx={{ width: { sm: "600px", xs: "220px" } }}>
        <Grid container spacing={2}>
          {options.map((option) => (
            <Grid
              item
              key={option}
              xs={gridsizesmall}
              md={gridsizemedium}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Box
                sx={{
                  width: width ?? "200px",
                  padding: "8px 16px",
                  textAlign: "center",
                  cursor: "pointer",
                  borderRadius: "4px",
                  border: selectedValue.includes(option)
                    ? "2px solid #00999E"
                    : "2px solid #000",
                  backgroundColor: "white",
                  color: selectedValue.includes(option) ? "#00999E" : "#000",
                  "&:hover": {
                    backgroundColor: "grey.100"
                  },
                  boxShadow: selectedValue.includes(option)
                    ? "0px 4px 6px #00999E"
                    : "0px 4px 6px rgba(0, 0, 0, 0.5)"
                }}
                onClick={() => handleClick(option)}
              >
                {option}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default SelectionBox;
