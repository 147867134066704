/* eslint-disable no-unused-expressions */
import {
  Box,
  Card,
  CardActions,
  CardMedia,
  CircularProgress,
  Grid,
  Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";

import MainHeader from "src/Component/content-component/MainHeader";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import { Icon } from "@iconify/react";
import Header from "src/Component/content-component/Header";
import Slider from "react-slick";
import axios from "axios";
import BestSellerCard from "src/Component/card-components/BestSellerCard";
import "./slider.css";
const username = sessionStorage.getItem("username");

const DashboardHome = () => {
  const [bestSellers, setBestSellers] = useState([]);
  const [recentlyBroughtProducts, setRecentlyBroughtProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isenrolled, setIsEnrolled] = useState(false);
  const [profileImage, setProfileImage] = useState("");

  const token = sessionStorage.getItem("token");

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return date.toLocaleDateString("en-GB", options).replace(",", "");
  };
  const handleReadMoreClick = () => {
    window.location.href = "/dashboard/home";
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}student/get-student-web`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        const data = response.data.data;
        setIsEnrolled(data.isenrolled);
        setProfileImage(data.image || "path/to/default/profile/image.jpg");
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [token]);

  useEffect(() => {
    const fetchBestSellers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}banner/web`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        setBestSellers(response.data.data);
      } catch (error) {
        console.error("Error fetching Best Sellers:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchBestSellers();
  }, [token]);

  useEffect(() => {
    const fetchRecentlyBroughtProducts = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}student/student-purchase`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        setRecentlyBroughtProducts(response.data.data);
      } catch (error) {
        console.error("Error fetching recently brought products:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchRecentlyBroughtProducts();
  }, [token]);

  var settings = {
    dots: true,
    infinite: recentlyBroughtProducts.length > 1, // Infinite scroll only if more than one item
    arrows: true, // Corrected the spelling of arrows
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: recentlyBroughtProducts.length > 1, // Adjusted for single item
          dots: true,
          arrows: true,
          speed: 2000,
          autoplaySpeed: 2000
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: recentlyBroughtProducts.length > 1,
          dots: true,
          arrows: true,
          speed: 2000,
          autoplaySpeed: 2000
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: recentlyBroughtProducts.length > 1,
          dots: true,
          arrows: true,
          speed: 3000,
          autoplaySpeed: 2000
        }
      }
    ]
  };

  return (
    <Box sx={{ margin: "0px 20px", display: "flex", flexDirection: "column" }}>
      <Box sx={{ height: "auto" }}>
        <Grid container sx={{ height: { xs: "700px", md: "550px" } }}>
          <Grid
            item
            md={6}
            xs={12}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <MainHeader content={"WELCOME"} />
              <MainHeader content={username} color={"#454545"} />
              <Typography
                sx={{
                  py: "20px",
                  color: "#757575",
                  fontWeight: "550",
                  fontSize: "24px"
                }}
              >
                We’re Excited To Help You On Your Journey Towards Academic
                Success
              </Typography>
              {!isenrolled && (
                <>
                  <Typography
                    sx={{
                      pb: "20px",
                      color: "#00999E",
                      fontWeight: "500",
                      fontSize: "24px"
                    }}
                  >
                    Please complete the enrollment form
                  </Typography>
                  <ButtonComponent
                    text={"Enroll Now"}
                    href={"enrollment-form"}
                    icon={
                      <Icon
                        icon="eva:arrow-forward-fill"
                        style={{ color: "#fff" }}
                      />
                    }
                  />
                </>
              )}
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box
              sx={{
                width: { lg: "560px", md: "350px", sm: "450px", xs: "280px" },
                height: { lg: "560px", md: "350px", sm: "450px", xs: "280px" }
              }}
            >
              <Box
                sx={{
                  position: "relative"
                }}
              >
                <Box
                  sx={{
                    position: "absolute",
                    right: "0px",
                    top: "25px",
                    width: { md: "80%", sm: "90%" },
                    height: {
                      xs: "350px",
                      md: "400px"
                    },
                    background: `linear-gradient(to top, 
                rgba(167, 214, 215, 1) 5%,   
                rgba(218, 240, 241, 0.8) 40%, 
                rgba(255, 255, 255, 1) 100%)`,
                    borderRadius: "20%",
                    border: "1px solid #00999E",
                    padding: "20px",
                    display: "flex",
                    boxShadow: 10,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "25%",
                      overflow: "hidden",
                      border: "1px solid #00999E",
                      boxShadow: 10,

                      width: "250px",
                      height: "250px"
                    }}
                  >
                    <img
                      src={profileImage}
                      alt="profile_pic"
                      width={"100%"}
                      height={"100%"}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Recently Brought Products */}
      <Box sx={{ padding: "20px 10px" }}>
        <Header content={"Recently Brought Product"} />
        {loading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", padding: "40px" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Slider {...settings} sx={{ pt: "20px" }}>
              {recentlyBroughtProducts?.map((item, index) => (
                <Box
                  sx={{ textAlign: "-webkit-center", pt: "40px" }}
                  key={index}
                >
                  <Box
                    sx={{
                      width: "300px"
                    }}
                  >
                    <Card
                      sx={{
                        width: { xs: "320px", md: "360px" },
                        borderRadius: "10px",
                        height: "450px",
                        marginBottom: "25px",
                        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px"
                      }}
                    >
                      <Box>
                        <CardMedia
                          component="img"
                          width={"100%"}
                          height={"200px"}
                          image={item.image}
                          alt="product image"
                        />
                        <Box sx={{ padding: "15px", marginTop: "7px" }}>
                          <Typography
                            variant="caption"
                            sx={{
                              color: "#00999E",
                              fontSize: "13px",
                              fontWeight: "500",
                              textAlign: "left"
                            }}
                          >
                            {formatDate(item.date)}
                          </Typography>
                          <MainHeader
                            content={item.courseName}
                            fontSize={"24px"}
                          />

                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "16px",
                              textAlign: "justify",
                              fontWeight: "600",
                              marginBottom: "10px"
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "13px",
                              margin: "5px 0",
                              textAlign: "justify"
                            }}
                          >
                            {item.description || ""}
                          </Typography>
                        </Box>
                      </Box>
                      <CardActions>
                        <Box sx={{ padding: "10px" }}>
                          <ButtonComponent
                            text={"Read More"}
                            onClick={() => {
                              sessionStorage.setItem("courseId", item.courseId);
                              sessionStorage.setItem(
                                "courseName",
                                item.courseName
                              );
                              sessionStorage.setItem("readMoreClicked", true);
                              handleReadMoreClick();
                            }}
                            disabled={!isenrolled}
                          />
                        </Box>
                      </CardActions>
                    </Card>
                  </Box>
                </Box>
              ))}
            </Slider>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          padding: "20px 0"
        }}
      >
        <Header content={"Our Best Sellers"} />
        {loading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", padding: "40px" }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Slider {...settings} sx={{ pt: "20px" }}>
            {bestSellers?.map((item, index) => (
              <Box
                sx={{ textAlign: "-webkit-center", pt: "40px" }}
                key={item._id}
              >
                <BestSellerCard data={item} imageheight={"250"} />
              </Box>
            ))}
          </Slider>
        )}
      </Box>
    </Box>
  );
};

export default DashboardHome;
