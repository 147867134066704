import React from "react";
import { Box, Card, CardContent } from "@mui/material";
import Bar from "./bar";

const ChartCard = ({ progressData }) => {
  const totalClasses = progressData?.total; // Total classes to attend
  const completedClasses = progressData?.completed; // Classes completed so far

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="center">
          <Bar
            totalClasses={totalClasses}
            completedClasses={completedClasses}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ChartCard;
