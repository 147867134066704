import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  Typography,
  ListItem,
  ListItemIcon
} from "@mui/material";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import logo from "../../asset/TIE_LOGO.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
// import SearchIcon from "@mui/icons-material/Search";
import { Icon } from "@iconify/react";
import axios from "axios";

const json = [
  {
    title: "Home",
    icon: <Icon icon={"fluent:home-32-regular"} />,
    path: "enrollment"
  },
  {
    title: "Dashboard",
    icon: <Icon icon={"fluent:home-32-regular"} />,
    path: "home"
  },
  {
    title: "Planner",
    icon: <Icon icon={"streamline:class-lesson"} />,
    path: "planner"
  },
  {
    title: "Learning Resources",
    icon: <Icon icon={"grommet-icons:resources"} />,
    path: "learningresources"
  },
  {
    title: "Mock Test",
    icon: <Icon icon={"heroicons:light-bulb"} />,
    path: "mocktest"
  },
  {
    title: "Performance",
    icon: <Icon icon={"game-icons:progression"} />,
    path: "performance"
  },
  {
    title: "Admission",
    icon: <Icon icon={"game-icons:progression"} />,
    path: "admission"
  },
  {
    title: "Support",
    icon: <Icon icon={"ic:baseline-contact-phone"} />,
    path: "support"
  },
  { title: "Order", icon: <Icon icon={"ion:cart-outline"} />, path: "order" },

  {
    title: "Study Abroad",
    icon: <Icon icon={"ic:baseline-contact-phone"} />,
    path: "studyabroad"
  },

];

const TopBar = () => {
  const navigate = useNavigate();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation();
  const [profileImage, setProfileImage] = useState("");

  const token = sessionStorage.getItem("token");
  const [showAllTabs, setShowAllTabs] = useState(false);

  useEffect(() => {
    const readMoreClicked = sessionStorage.getItem("readMoreClicked");
    if (readMoreClicked) {
      setShowAllTabs(true);
    }
  }, []);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}student/get-student-web`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        const data = response.data.data;
        setProfileImage(data.image);
        sessionStorage.setItem("profileImg", data.image);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [token]);
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };

  const handleLogoClick = () => {
    sessionStorage.removeItem("courseId");
    sessionStorage.removeItem("courseName");
    sessionStorage.removeItem("readMoreClicked");
    setShowAllTabs(false);
    navigate("/dashboard/enrollment");
  };

  const confirmLogout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("courseId");
    sessionStorage.removeItem("courseName");
    sessionStorage.removeItem("readMoreClicked");
    setShowAllTabs(false);
    // Navigate to the home page

    setLogoutDialogOpen(false);
    navigate("/");
    // Perform logout action here
  };

  //   <Typography
  //     variant="h6"
  //     color="inherit"
  //     sx={{
  //       textDecoration: "none",
  //       underline: "none",
  //       border: "2px solid white",
  //       borderRadius: 2,
  //       padding: {
  //         xs: "5px 0px 5px 0px",
  //         sm: "5px 0px 5px 0px",
  //         md: "5px 8px 5px 8px",
  //         lg: "5px 20px 5px 20px"
  //       },
  //       fontSize: { md: "14px", lg: "16px" },
  //       fontStyle: "normal",

  //       fontWeight: 500,
  //       lineHeight: "28px",
  //       letterSpacing: "0.2px",
  //       "&:hover": {
  //         color: "#00999e",
  //         border: isActive ? "2px solid #00999e" : "",
  //         cursor: "pointer",
  //         padding: {
  //           xs: "5px 0px 5px 0px",
  //           sm: "5px 0px 5px 0px",
  //           md: "5px 20px 5px 20px",
  //           lg: "5px 20px 5px 20px"
  //         }
  //       }
  //     }}
  //   >
  //     {children}
  //   </Typography>
  // );

  const username = sessionStorage.getItem("username");

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#00999E",
        padding: "18px 30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box
          sx={{
            display: " flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: "20px"
          }}
        >
          <Box
            sx={{
              height: "45px",
              width: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: "50%",
              backgroundColor: "#fff",
              padding: "10px"
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Icon
              icon="tabler:home-filled"
              style={{ color: "#000000", fontSize: "25px" }}
            />{" "}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Box sx={{ display: { md: "none", xs: "block" } }}>
            <Box sx={{ display: "flex", flexDirection: "row", color: "white" }}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerClose}
              ModalProps={{ keepMounted: true }}
              sx={{
                "& .MuiDrawer-paper": {
                  width: "90%"
                }
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "1rem"
                }}
              >
                <NavLink onClick={handleLogoClick} className="custom-link">
                  <img width="150px" src={logo} alt="Logo" />
                </NavLink>
                <IconButton
                  onClick={handleDrawerClose}
                  sx={{ alignSelf: "flex-start" }}
                >
                  <CloseIcon sx={{ color: "#00999E" }} />
                </IconButton>
              </Box>
              <List
                sx={{
                  marginTop: "20px"
                }}
              >
                {json.map((item, index) => {
                  const isSelected = location.pathname.includes(
                    item?.path?.toLowerCase()
                  );
                  const hideTabs =
                    !showAllTabs &&
                    [
                      "Planner",
                      "Performance",
                      "Dashboard",
                      "Mock Test",
                      "Learning Resources"
                    ].includes(item.title);
                  const hideHome = showAllTabs && item.title === "Home";

                  if (hideTabs || hideHome) return null;

                  return (
                    <ListItem
                      key={index}
                      sx={{
                        margin: "5px 0",
                        padding: "8px 10px",
                        fontWeight: "500",
                        backgroundColor: isSelected ? "#C9EFFB" : "transparent",
                        color: isSelected ? "#000" : "#706C6D",
                        "&:hover": {
                          backgroundColor: "#C9EFFB",
                          color: "#000"
                        },
                        cursor: "pointer",
                        transition: "backgroundColor 0.3s linear",
                        borderRadius: "5px"
                      }}
                      onClick={() => navigate(`${item?.path?.toLowerCase()}`)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: "auto",
                          marginRight: "8px",
                          color: isSelected ? "#000" : "#706C6D"
                        }}
                      >
                        {item.icon}
                      </ListItemIcon>
                      <Typography
                        variant="inherit"
                        sx={{
                          fontSize: "14px",
                          color: isSelected ? "#000" : "#706C6D"
                        }}
                      >
                        {item.title}
                      </Typography>
                    </ListItem>
                  );
                })}
              </List>
            </Drawer>
          </Box>
          {/* <Box
          sx={{
            // borderRadius: "8px",
            backgroundColor: "#ffffff",
            border: "1px solid #ccc",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: "300px",
            minWidth: "250px",
            width: "30%",
            borderRadius: "5px",
            padding: "6px 10px"
          }}
        >
          <input
            style={{
              padding: "5px",
              fontSize: "0.9rem",
              color: "#444",
              outline: "none",
              border: "none",
              backgroundColor: "transparent"
            }}
            type="text"
            placeholder="search"
          />
          <SearchIcon
            sx={{
              color: "#444"
            }}
          />
        </Box> */}
        </Box>
      </Box>
      <Box
        sx={{ display: " flex", flexDirection: "row", alignItems: "center" }}
      >
        {/* <Box
          sx={{
            height: "45px",
            width: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: "50%",
            backgroundColor: "#fff",
            padding: "10px"
          }}
          onClick={() => {
            navigate("/dashboard/notifications");
          }}
        >
          <Icon
            icon="mingcute:notification-fill"
            style={{ color: "#000000", fontSize: "25px" }}
          />{" "}
        </Box> */}
        <Box
          sx={{
            backgroundColor: "#ffffff",
            borderRadius: "40px",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            cursor: "pointer",
            padding: "6px",
            mx: "10px"
          }}
          onClick={() => {
            navigate("/dashboard/profileupdate");
          }}
        >
          <Typography
            sx={{
              color: "#000000",
              fontWeight: "550",
              fontSize: "15px",
              mx: "10px"
            }}
          >
            {" "}
            Hey, {username}
          </Typography>
          {profileImage === null ? (
            <Avatar />
          ) : (
            <Box
              sx={{
                borderRadius: "50%",
                overflow: "hidden",
                border: "1px solid #00999E",
                boxShadow: 10,

                width: "40px",
                height: "40px"
              }}
            >
              <img
                src={profileImage}
                alt="profile_pic"
                width={"100%"}
                height={"100%"}
              />
            </Box>
          )}
        </Box>

        <Box
          sx={{
            height: "45px",
            width: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: "50%",
            backgroundColor: "#fff",
            padding: "10px"
          }}
          onClick={handleLogout}
        >
          <Icon
            icon="ep:switch-button"
            style={{ color: "#000000", fontSize: "25px" }}
          />{" "}
        </Box>
      </Box>

      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            width: { sm: "450px", xs: "300px" }
          }
        }}
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setLogoutDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button variant="contained" color="error" onClick={confirmLogout}>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TopBar;
