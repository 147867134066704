// import React, { useState, useEffect } from "react";
// import { Grid, Typography, Box } from "@mui/material";
// import axios from "axios";
// import back from "../../asset/universityfinder/back.png";
// import front from "../../asset/universityfinder/front.png";

// // Constants
// const ITEMS_PER_PAGE = 12;

// const CountrySelect = ({ setFilters }) => {
//   const [page, setPage] = useState(1); // Start from page 1
//   const [selectedCountry, setSelectedCountry] = useState(null);
//   const [countryData, setCountryData] = useState([]);
//   const [totalItems, setTotalItems] = useState(0);

//   // Fetch country data from the API using axios
//   useEffect(() => {
//     const fetchCountryData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_BASE_URL}/university/countryList`,
//           {
//             params: {
//               page,
//               limit: ITEMS_PER_PAGE
//             }
//           }
//         );

//         if (response.data.success && response.data.status === 200) {
//           setCountryData(response.data.data.formattedData);
//           setTotalItems(response.data.data.count); // Assuming API returns totalItems
//         } else {
//           console.error("Failed to fetch data:", response.data.message);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };

//     fetchCountryData();
//   }, [page]);

//   // Function to handle card selection and update selected country
//   const handleCardClick = (countryId) => {
//     setSelectedCountry(countryId);
//     setFilters((prev) => ({
//       ...prev,
//       country: countryId
//     }));
//   };

//   // Function to handle page navigation
//   const handlePageChange = (direction) => {
//     if (direction === "next" && page * ITEMS_PER_PAGE <= totalItems) {
//       setPage((prevPage) => prevPage + 1);
//     } else if (direction === "prev" && page > 1) {
//       setPage((prevPage) => prevPage - 1);
//     }
//   };

//   return (
//     <Box>
//       {/* Country selection grid */}
//       <Grid
//         container
//         spacing={2}
//         sx={{
//           height: { sm: "400px", xs: "auto" },
//           display: "flex",
//           justifyContent: "space-between"
//         }}
//       >
//         {countryData.map((item) => (
//           <Grid item xs={6} md={3} key={item._id}>
//             <Box
//               onClick={() => handleCardClick(item._id)}
//               sx={{
//                 border: "2px solid #c1c1c1",
//                 borderRadius: "10px",
//                 height: "110px",
//                 width: "120px",
//                 display: "flex",
//                 alignItems: "center",
//                 flexDirection: "column",
//                 overflow: "hidden",
//                 cursor: "pointer",
//                 // margin: "20px",
//                 textAlign: "center",
//                 backgroundColor:
//                   selectedCountry === item._id ? "#00999E" : "#Effdff"
//               }}
//             >
//               <Box sx={{ width: "80px", height: "65px", padding: "8px 0" }}>
//                 <img src={item.icon} alt="" width={"100%"} height={"100%"} />
//               </Box>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   fontSize: "14px",
//                   pt: "2px",
//                   color: selectedCountry === item._id ? "#fff" : "#000"
//                 }}
//               >
//                 {item.name}
//               </Typography>
//             </Box>
//           </Grid>
//         ))}
//       </Grid>

//       {/* Page navigation for countries */}
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "space-between",
//           marginTop: "20px",
//           alignItems: "center"
//         }}
//       >
//         <Box
//           sx={{ width: "80px", opacity: page === 1 ? 0.5 : 1 }}
//           onClick={() => handlePageChange("prev")}
//         >
//           <img src={back} alt="Previous" width={"100%"} />
//         </Box>
//         <Box
//           sx={{
//             width: "80px",
//             opacity: page * ITEMS_PER_PAGE >= totalItems ? 0.5 : 1
//           }}
//           onClick={() => handlePageChange("next")}
//         >
//           <img src={front} alt="Next" width={"100%"} />
//         </Box>
//       </Box>
//     </Box>
//   );
// };

// export default CountrySelect;



import React, { useState, useEffect } from "react";
import { Grid, Typography, Box } from "@mui/material";
import axios from "axios";
import back from "../../asset/universityfinder/back.png";
import front from "../../asset/universityfinder/front.png";

// Constants
const ITEMS_PER_PAGE = 12;

const CountrySelect = ({ setFilters }) => {
  const [page, setPage] = useState(1); // Start from page 1
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [countryData, setCountryData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  // Fetch country data from the API using axios
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/university/countryList`,
          {
            params: {
              page,
              limit: ITEMS_PER_PAGE
            }
          }
        );

        if (response.data.success && response.data.status === 200) {
          setCountryData(response.data.data.formattedData);
          setTotalItems(response.data.data.count); // Assuming API returns totalItems
        } else {
          console.error("Failed to fetch data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCountryData();
  }, [page]);

  // Function to handle card selection and toggle selected countries
  const handleCardClick = (countryId) => {
    setSelectedCountries((prevSelected) => {
      const isSelected = prevSelected.includes(countryId);
      if (isSelected) {
        // Remove country if already selected
        return prevSelected.filter((id) => id !== countryId);
      } else {   
        // Add country if not already selected
        return [...prevSelected, countryId];
      }
    });

    setFilters((prev) => ({
      ...prev,
      country: selectedCountries.includes(countryId)
        ? selectedCountries.filter((id) => id !== countryId)
        : [...selectedCountries, countryId]
    }));
  };

  // Function to handle page navigation
  const handlePageChange = (direction) => {
    if (direction === "next" && page * ITEMS_PER_PAGE <= totalItems) {
      setPage((prevPage) => prevPage + 1);
    } else if (direction === "prev" && page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <Box>
      {/* Country selection grid */}
      <Grid
        container
        spacing={2}
        sx={{
          height: { sm: "400px", xs: "auto" },
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        {countryData.map((item) => (
          <Grid item xs={6} md={3} key={item._id}>
            <Box
              onClick={() => handleCardClick(item._id)}
              sx={{
                border: "2px solid #c1c1c1",
                borderRadius: "10px",
                height: "110px",
                width: "120px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                overflow: "hidden",
                cursor: "pointer",
                textAlign: "center",
                backgroundColor: selectedCountries.includes(item._id)
                  ? "#00999E"
                  : "#Effdff"
              }}
            >
              <Box sx={{ width: "80px", height: "65px", padding: "8px 0" }}>
                <img src={item.icon} alt="" width={"100%"} height={"100%"} />
              </Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  pt: "2px",
                  color: selectedCountries.includes(item._id) ? "#fff" : "#000"
                }}
              >
                {item.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box sx={{display: { md: "flex", sm: 'none', xs: 'none', display: "flex",
                alignItems: "center",
                flexDirection: "column"}}}>
              <Typography
                  sx={{
                    fontSize: { sm: "28px", xs: "20px" },
                    p: "20px 0",
                    fontWeight: "400",
                    color: "#606060"
                  }}
                >
                  Select Your Location In{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { sm: "28px", xs: "20px" },
                    fontWeight: "550",
                    color: "#606060"
                  }}
                >
                  The Selected Country
                </Typography>
              </Box>

      {/* Page navigation for countries */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: {md:"20px",sm:"70%",xs:"20px"},
          alignItems: "center"
        }}
      >
        <Box
          sx={{ width: "80px", opacity: page === 1 ? 0.5 : 1,cursor:'pointer' }}
          onClick={() => handlePageChange("prev")}
        >
          <img src={back} alt="Previous" width={"100%"} />
        </Box>
        <Box
          sx={{
            width: "80px",
            opacity: page * ITEMS_PER_PAGE >= totalItems ? 0.5 : 1,
            cursor:'pointer'
          }}
          onClick={() => handlePageChange("next")}
        >
          <img src={front} alt="Next" width={"100%"} />
        </Box>
      </Box>
    </Box>
  );
};

export default CountrySelect;
