import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import Cta from "src/Component/card-components/Cta";
import Header from "src/Component/content-component/Header";

const WhyChooseUniversity = ({ data }) => {
    return (
        <>
            <Box sx={{ bgcolor: "#effdff", padding: "40px 0" }}>
                <Container
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mb: 5,
                            marginTop: 5
                        }}
                    >
                        <Header content={data?.heading} textAlign={"center"} />
                    </Box>
                    <Grid
                        container
                        spacing={2}
                        sx={{ display: "flex", justifyContent: "center" }}
                    >
                        {
                            data?.items?.map((item) => (
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    sx={{ display: "flex", justifyContent: "center" }}
                                >
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                        <Box
                                            sx={{
                                                width: "150px",
                                                height: "150px",
                                                bgcolor: "#ffffff",
                                                borderRadius: "50%",
                                                border: "3px solid #00999E",
                                                boxShadow: " rgba(100, 100, 111, 0.2) 7px 7px 29px 0px",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Box sx={{ width: "180px", padding: "10px 0" }}>
                                                <Typography
                                                    variant="h6"
                                                    textAlign={"center"}
                                                    sx={{
                                                        fontSize: "20px",
                                                        fontWeight: "500",
                                                        color: "#00999E"
                                                    }}
                                                >
                                                    {item?.count}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ padding: "10px 0", display: "flex", justifyContent: "center", height: "100px", width: "250px" }}>
                                            <Typography
                                                variant="body2"
                                                textAlign={"center"}
                                                sx={{
                                                    fontSize: "15px",
                                                    color: "#000"
                                                }}
                                            >
                                                {item?.title}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>

                        <Cta />
                    </Box>

                </Container>
            </Box>
        </>
    );
};

export default WhyChooseUniversity;
