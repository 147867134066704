import React, { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import registerimg from "../../asset/universityfinder/registerImg.png";
import MainHeader from "src/Component/content-component/MainHeader";
import FormComponent from "src/Component/form-component/Form";
import CountrySelect from "./countrySelect";
import SelectionBox from "./SelectionBox";
// import bgImage from "../../asset/universityfinder/bgImage.png";
import RangeSelectionBox from "./RangeSelectionBox";
import DropdownSelectionBox from "./DropdownSelectionBox";
import StateSelect from "./stateSelect";
import axios from "axios";

export const RegisterYourself = ({ filters, setFilters }) => (
  <Box sx={{ padding: "20px" }}>
    <Container>
      <Grid container>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box sx={{ width: { md: "600px", xs: "320" }, position: "relative" }}>
            <img
              src={registerimg}
              alt="Register"
              width={"100%"}
              style={{ position: "relative" }}
            />
            <Box
              sx={{
                position: "absolute",
                width: { sm: "320px", xs: "220px" },
                top: "0",
                right: "0px",
                textAlign: "center"
              }}
            >
              <MainHeader content={"Welcome"} />
              <Typography
                sx={{
                  fontSize: { sm: "28px", xs: "20px" },
                  fontWeight: "550",
                  color: "#606060"
                }}
              >
                To Study Abroad Journey
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              width: "320px",
              p: "10px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRadius: "10px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.5)"
            }}
          >
            <MainHeader
              content={"Start Your Journey"}
              color={"#000"}
              textAlign={"center"}
              fontSize={"24px"}
            />
            {/* <FormComponent buttonText="Submit" /> */}
            <FormComponent buttonText="Next" filters={filters}
              setFilters={setFilters} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export const Location = ({ filters, setFilters }) => {
  const [selectedValue, setSelectedValue] = useState(
    filters.fieldofInterest || ""
  );

  const [options, setOptions] = useState([])

  const handleSelect = (value) => {
    const selectedCourse = options.filter((option) =>
      value.includes(option.name)
    );
    if (selectedCourse) {
      setSelectedValue(selectedCourse.map((course) => course.name)); // You can keep the name for display purposes
      setFilters((prevFilters) => ({
        ...prevFilters,
        fieldofInterest: selectedCourse.map((course) => course.id) // Update with the course IDs
      }));
    }
  };

  useEffect(() => {
    console.log('filters =>', filters)

  }, [filters])
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}university/courseList`)
      .then((response) => {
        const courseData = response.data.data.formattedData.map((course) => ({
          id: course._id, // Assuming the API provides an id field
          name: course.name
        }));
        setOptions(courseData);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, []);


  return (
    <>
      <div>
        <Container>
          <Grid container>
            {/* <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

              }}
            >
             
              <Box>
                <DropdownSelectionBox
                  sx={{ p: "0 50px" }}
                  label="Choose Your Field Of Interest?"
                  options={options.map((option) => option.name)}
                  selectedValue={selectedValue}
                  onChange={handleSelect}
                />
              </Box>
            </Grid> */}

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >


              <Box sx={{ width: { md: "500px", sm: "320px", xs: "120px" }, position: "relative",
              //  display: { md: "flex", sm: 'none', xs: 'none'}
               }}>
                <Box sx={{display: { md: "flex", sm: 'none', xs: 'none'}}}>
                <img
                  src={registerimg}
                  alt="Register"
                  width={"100%"}
                  style={{ position: "relative" }}
                />
                </Box>
                {/* <Box
                sx={{
                  height: "100%",
                  width: { md: "600px", xs: "320px" },
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              > */}

                <Box
                  sx={{
                    position: "absolute",
                    width: { sm: "320px", xs: "220px" },
                    top: "0",
                    right: { md: "-50px", sm: "-80px" },
                    textAlign: "center",
                    display: { md: "flex", sm: 'none', xs: "none" },
                    flexDirection: "column",
                    
                  }}
                >
                  <MainHeader  content={"Welcome"} />
                  <Typography
                    sx={{
                      fontSize: { sm: "20px", xs: "20px" },
                      p: "5px 0",
                      fontWeight: "400",
                      color: "#606060",

                    }}
                  >
                    To Study Abroad {" "} Journey
                  </Typography>
                  <Typography
                  sx={{
                    fontSize: { sm: "20px", xs: "20px" },
                    fontWeight: "550",
                    color: "#606060",
                    mb:'10px'
                  }}
                >
                  Where Do You Plan To Study?{" "}
                </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              // sx={{
              //   display: "flex",
              //   justifyContent: "center",
              //   alignItems: "center"
              // }}
            >

              <Box sx={{display: { md: "none", sm: 'flex', xs: 'flex'},justifyContent:"center",alignItems:"center",flexDirection: "column",}}>
              <MainHeader  content={"Welcome"} />
                  <Typography
                    sx={{
                      fontSize: { sm: "20px", xs: "20px" },
                      p: "5px 0",
                      fontWeight: "400",
                      color: "#606060",

                    }}
                  >
                    To Study Abroad {" "} Journey
                  </Typography>
                  <Typography
                  sx={{
                    fontSize: { sm: "20px", xs: "20px" },
                    fontWeight: "550",
                    color: "#606060",
                    mb:'10px',
                    textAlign:"center"
                  }}
                >
                  Where Do You Plan To Study?{" "}
                </Typography>
              </Box>

              <Box>
                <DropdownSelectionBox
                  sx={{ p: "0 50px" }}
                  label="Choose Your Field Of Interest?"
                  options={options.map((option) => option.name)}
                  selectedValue={selectedValue}
                  onChange={handleSelect}
                />
              </Box>

              <CountrySelect setFilters={setFilters} />
             
            </Grid>
          </Grid>
          <Grid container>



            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: { md: "600px", xs: "320px" },
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  flexDirection: "column",
                  display: { md: "none", sm: 'flex', xs: 'flex'}
                }}
              >
                {/* <Typography
                  sx={{
                    fontSize: { sm: "28px", xs: "20px" },
                    p: "20px 0",
                    fontWeight: "400",
                    color: "#606060"
                  }}
                >
                  Where Do You Plan To Study?{" "}
                </Typography> */}
                <Typography
                  sx={{
                    fontSize: { sm: "28px", xs: "20px" },
                    p: "20px 0",
                    fontWeight: "400",
                    color: "#606060"
                  }}
                >
                  Select Your Location In{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { sm: "28px", xs: "20px" },
                    fontWeight: "550",
                    color: "#606060"
                  }}
                >
                  The Selected Country
                </Typography>


              </Box>
            </Grid>

          </Grid>
        </Container>
      </div>
    </>
  );
};


// export const ProgramAndPreferredYear = ({ filters, setFilters }) => {
//   const [pursue, setPursue] = useState(filters.pursue || "");
//   const [year, setYear] = useState(filters.year || "");
//   const [intake, setIntake] = useState(filters.intake || "");
//   const [duration, setDuration] = useState(filters.duration || "");

//   const handleSelectPursue = (value) => {
//     setPursue(value);
//     setFilters((prevFilters) => ({ ...prevFilters, pursue: value }));
//   };

//   const handleSelectYear = (value) => {
//     setYear(value);
//     setFilters((prevFilters) => ({ ...prevFilters, year: value }));
//   };

//   const handleSelectIntake = (value) => {
//     setIntake(value);
//     setFilters((prevFilters) => ({ ...prevFilters, intake: value }));
//   };

//   const handleSelectDuration = (value) => {
//     setDuration(value);
//     setFilters((prevFilters) => ({ ...prevFilters, duration: value }));
//   };

//   return (
//     <Box
//       sx={{
//         // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${bgImage}")`,
//         backgroundSize: "cover",
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center"
//       }}
//     >
//       <Container>
//         <Box p={3} borderRadius={2}>
//           <SelectionBox
//             label="What Are You Planning To Pursue?"
//             options={[
//               "Undergraduate",
//               "Graduate",
//               "PHD",
//               "Certificate Program"
//             ]}
//             gridsizesmall={12}
//             selectedValue={pursue}
//             onSelect={handleSelectPursue}
//           />
//           <SelectionBox
//             label="What Is Your Preferred Year?"
//             options={["2024", "2025", "2026", "2027", "2028", "2029"]}
//             selectedValue={year}
//             width={"120px"}
//             gridsizemedium={4}
//             onSelect={handleSelectYear}
//           />
//           <SelectionBox
//             label="What Is Your Preferred Intake?"
//             options={["Fall", "Spring", "Summer", "Winter"]}
//             selectedValue={intake}
//             gridsizesmall={12}
//             onSelect={handleSelectIntake}
//           />
//           <SelectionBox
//             label="What Is Your Preferred Duration?"
//             options={[
//               "less than 1 Year",
//               "1-2 year",
//               "3-4 year",
//               "more than 4 year"
//             ]}
//             selectedValue={duration}
//             gridsizesmall={12}
//             onSelect={handleSelectDuration}
//           />
//         </Box>
//       </Container>
//     </Box>
//   );
// };


export const ProgramAndPreferredYear = ({ filters, setFilters }) => {
  const [pursue, setPursue] = useState(filters.pursue || "");
  const [year, setYear] = useState(filters.year || []);
  const [intake, setIntake] = useState(filters.intake || []);
  const [duration, setDuration] = useState(filters.duration || []);

  const handleSelectPursue = (value) => {
    setPursue(value);
    setFilters((prevFilters) => ({ ...prevFilters, pursue: value }));
  };

  const handleSelectYear = (values) => {
    setYear(values);
    setFilters((prevFilters) => ({ ...prevFilters, year: values }));
  };

  const handleSelectIntake = (values) => {
    setIntake(values);
    setFilters((prevFilters) => ({ ...prevFilters, intake: values }));
  };

  const handleSelectDuration = (values) => {
    setDuration(values);
    setFilters((prevFilters) => ({ ...prevFilters, duration: values }));
  };

  return (
    <Box
      sx={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      <Container>
        <Box p={3} borderRadius={2}>
          {/* Single selection for Pursue */}
          <SelectionBox
            label="What Are You Planning To Pursue?"
            options={["Undergraduate", "Graduate", "PHD", "Certificate Program"]}
            gridsizesmall={12}
            selectedValue={pursue}
            onSelect={handleSelectPursue}
          />

          {/* Multiple selections for Year */}
          <SelectionBox
            label="What Is Your Preferred Year?"
            options={["2024", "2025", "2026", "2027", "2028", "2029"]}
            selectedValue={year}
            multiple
            gridsizemedium={4}
            onSelect={handleSelectYear}
          />

          {/* Multiple selections for Intake */}
          <SelectionBox
            label="What Is Your Preferred Intake?"
            options={["Fall", "Spring", "Summer", "Winter"]}
            selectedValue={intake}
            multiple
            gridsizesmall={12}
            onSelect={handleSelectIntake}
          />

          {/* Multiple selections for Duration */}
          <SelectionBox
            label="What Is Your Preferred Duration?"
            options={[
              "less than 1 Year",
              "1-2 year",
              "3-4 year",
              "more than 4 year"
            ]}
            selectedValue={duration}
            multiple
            gridsizesmall={12}
            onSelect={handleSelectDuration}
          />
        </Box>
      </Container>
    </Box>
  );
};



export const FieldOfStudy = ({ filters, setFilters }) => {
  const [sliderValue, setSliderValue] = useState(filters.tutionfee || 0);
  const [selectedValue, setSelectedValue] = useState(
    filters.fieldofInterest || ""
  );

  const [scholarship, setscholarship] = useState(filters.scholarship || "");
  
  const [options, setOptions] = useState([]);

  const handleSelectScholarship = (value) => {
    setscholarship(value);
    setFilters((prevFilters) => ({ ...prevFilters, scholarship: value }));
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setFilters((prevFilters) => ({ ...prevFilters, tutionfee: newValue }));
  };

  

  return (
    <Box
      sx={{
        // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${bgImage}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      <Container>
        <Box p={3} borderRadius={2}>
          {/* <DropdownSelectionBox
            label="Choose Your Field Of Interest?"
            options={options.map((option) => option.name)}
            selectedValue={selectedValue}
            onSelect={handleSelect}
          /> */}
           <SelectionBox
            label="Scholarship Availability"
            options={[
              "Full Scholarships",
              "Partial Scholarships",
              "No Scholarships"
            ]}
            gridsizesmall={12}
            gridsizemedium={12}
            width={"100%"}
            selectedValue={scholarship}
            onSelect={handleSelectScholarship}
          />
          <RangeSelectionBox
            label="Tuition Fee (Optional)"
            value={sliderValue}
            onChange={handleSliderChange}
          />
        </Box>
      </Container>
    </Box>
  );
};

export const AdmissionRequirements = ({ filters, setFilters }) => {
  const [admission, setadmission] = useState(filters.admission || "");
  const [qualification, setqualification] = useState(
    filters.qualification || ""
  );

  const handleSelectadmission = (value) => {
    setadmission(value);
    setFilters((prevFilters) => ({ ...prevFilters, admission: value }));
  };

  const handleSelectQualification = (value) => {
    setqualification(value);
    setFilters((prevFilters) => ({ ...prevFilters, qualification: value }));
  };

  return (
    <Box
      sx={{
        // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${bgImage}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
    >
      <Container>
        <Box p={3} borderRadius={2}>
          {/* <SelectionBox
            label="Admission Requirement"
            options={["PTE", "IELTS", "TOEFL", "SAT", "GRE", "GMAT", "EXEMPT"]}
            width={"120px"}
            gridsizemedium={4}
            selectedValue={admission}
            onSelect={handleSelectadmission}
          /> */}
          <SelectionBox
            label="Admission Requirement"
            options={["PTE", "IELTS", "TOEFL", "SAT", "GRE"]}
            selectedValue={admission}
            multiple
            gridsizesmall={12}
            onSelect={handleSelectadmission}
          />
          <SelectionBox
            label="Highest Qualification"
            options={[
              "Higher Secondary",
              "Undergraduate",
              "Graduate",
              "Certificate Program"
            ]}
            gridsizesmall={12}
            selectedValue={qualification}
            onSelect={handleSelectQualification}
          />
        </Box>
      </Container>
    </Box>
  );
};

// export const Scholarship = ({ filters, setFilters }) => {
//   const [scholarship, setscholarship] = useState(filters.scholarship || "");
//   const [language, setlanguage] = useState(filters.language || "");

//   const handleSelectScholarship = (value) => {
//     setscholarship(value);
//     setFilters((prevFilters) => ({ ...prevFilters, scholarship: value }));
//   };

//   const handleSelectLanguage = (value) => {
//     setlanguage(value);
//     setFilters((prevFilters) => ({ ...prevFilters, language: value }));
//   };

//   return (
//     <Box
//       sx={{
//         // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${bgImage}")`,
//         backgroundSize: "cover",
//         backgroundRepeat: "no-repeat",
//         backgroundPosition: "center"
//       }}
//     >
//       <Container>
//         <Box p={3} borderRadius={2}>
//           {/* <SelectionBox
//             label="Scholarship Availability"
//             options={[
//               "Full Scholarships",
//               "Partial Scholarships",
//               "No Scholarships"
//             ]}
//             gridsizesmall={12}
//             gridsizemedium={12}
//             width={"100%"}
//             selectedValue={scholarship}
//             onSelect={handleSelectScholarship}
//           /> */}
//           {/* <SelectionBox
//             label="Language Of Instruction"
//             options={["English", "Other"]}
//             width={"120px"}
//             selectedValue={language}
//             onSelect={handleSelectLanguage}
//           /> */}
//         </Box>
//       </Container>
//     </Box>
//   );
// };
