import React from "react";
import { Box, Typography, Button } from "@mui/material";
import rightIcon from "../../../../asset/Right_abroad.svg";

const Right = () => {
  return (
    <>
      <Box
        sx={{
          width: { xs: "100%", sm: "200px" },
          height: "128px",
          opacity: "0.9",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: "12px",
          marginTop: { xs: "0", sm: "157.5px" },
          marginLeft: { xs: "0", sm: "50px" },
          bgcolor: "#00999E",
          borderRadius: "20px",
        }}
      >
        <Typography color="white">Counselling</Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          maxWidth: "662px", // Limit max width for larger screens
          padding: { xs: "16px", sm: "32px" },
          borderRadius: "10px",
          backgroundColor: "#E2FEFF",
          margin: "auto", // Center the box horizontally
          border: "1px solid #ccc",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add subtle shadow
        }}
      >
        {/* Header Section */}
        <Box sx={{ textAlign: "center", mb: 2 }}>
          <Typography
            variant="h4"
            sx={{
              fontSize: { xs: "20px", sm: "24px", md: "32px" },
              fontWeight: "bold",
            }}
          >
            Counselling
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
              color: "gray",
            }}
          >
            Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet
            consectetur.
          </Typography>
        </Box>

        {/* Content Section */}
        <Box
          sx={{
            width: "100%",
            padding: { xs: "16px", sm: "24px" },
            borderRadius: "20px",
            backgroundColor: "#FFFFFF",
            border: "1px solid #ccc",
          }}
        >
          {/* Row 1 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>YES</Typography>
            <img src={rightIcon} alt="Right Icon" style={{ width: "24px" }} />
          </Box>

          {/* Row 2 */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: 1,
              mb: 2,
            }}
          >
            <Typography>Date:</Typography>
            <Typography>23 AUGUST 2024</Typography>
            <Typography>Time:</Typography>
            <Typography>10:00 AM - 12:00 AM</Typography>
          </Box>

          {/* Row 3 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Counselor Name</Typography>
            <Typography>Mr. Abhishek Verma</Typography>
          </Box>

          {/* Row 4 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Counselling Form</Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: 2,
                backgroundColor: "#00999E",
                "&:hover": {
                  backgroundColor: "#007f80",
                  color: "#fff",
                },
                width : "160px",
                fontSize : 13,
                padding : "7px 4px"
              }}
            >
              Download
            </Button>
          </Box>

          {/* Row 5 */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>Counselling Schedule</Typography>
            <Button
              variant="contained"
              sx={{
                borderRadius: 2,
                backgroundColor: "#00999E",
                "&:hover": {
                  backgroundColor: "#007f80",
                  color: "#fff",
                },
                width : "160px",
                fontSize : 13,
                padding : "7px 4px"
              }}
            >
              Schedule
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Right;
