const WisconsinScholarships = [
  {
    title: "International Student Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "Outstanding academic achievements, financial need",
      "Varies",
      "Up to ₹15,00,000 per year",
      "Undergraduate"
    ]
  },
  {
    title: "King-Morgridge Scholars Program",
    topColor: "#10B981",
    listitem: [
      "High-achieving international students with leadership potential and financial need",
      "6-8",
      "Full cost of attendance",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate School Fellowships",
    topColor: "#981dff",
    listitem: [
      "Merit-based for graduate students, including international students",
      "Varies",
      "Varies; typically includes tuition and stipend",
      "Postgraduate"
    ]
  },
  
];
const CaliforniaScholarships = [
  {
    title: "Berkeley International Office Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "International students with outstanding academic achievements and financial need",
      "partial tuition coverage"
    ]
  },
  {
    title: "The Mastercard Foundation Scholars Program",
    topColor: "#10B981",
    listitem: [
      "Students from Sub-Saharan Africa demonstrating academic talent, leadership potential, and financial need",
      "Full cost of attendance, including tuition, housing, and living expenses"
    ]
  },
  {
    title: "Regents' and Chancellor's Scholarship",
    topColor: "#981dff",
    listitem: [
      "High-achieving students, including international students, demonstrating exceptional academic promise",
      "$2,500 per year and additional benefits such as priority registration, research funding, and faculty mentorship"
    ]
  },
];
const YeshivaScholarships = [
  {
    title: "International Student Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "Outstanding academic achievements, financial need",
      "Varies",
      "Up to ₹15,00,000 per year",
      "Undergraduate"
    ]
  },
  {
    title: "King-Morgridge Scholars Program",
    topColor: "#10B981",
    listitem: [
      "High-achieving international students with leadership potential and financial need",
      "6-8",
      "Full cost of attendance",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate School Fellowships",
    topColor: "#981dff",
    listitem: [
      "Merit-based for graduate students, including international students",
      "Varies",
      "Varies; typically includes tuition and stipend",
      "Postgraduate"
    ]
  },
  
];
const UtahScholarships = [
  {
    title: "Transfer Four Semester Renewable Scholarship",
    topColor: "#F59E0B",
    listitem: ["$2,250", "Transfer GPA between 3.0 and 3.69"]
  },
  {
    title: "Western Undergraduate Exchange (WUE)",
    topColor: "#10B981",
    listitem: ["$5,568.50", "Transfer GPA between 2.5 and 3.69"]
  },
  {
    title: "Snow Transfer Resident Fellow's Scholarship",
    topColor: "#981dff",
    listitem: ["$2,500", "Transfer GPA between 3.75 and 4.0"]
  },
  {
    title: "Snow Transfer Resident Founder's Scholarship",
    topColor: "#F59E0B",
    listitem: ["$2,000", "Transfer GPA between 3.5 and 3.74"]
  },
  {
    title: "Snow Transfer Resident Dean's Scholarship",
    topColor: "#10B981",
    listitem: ["$1,250", "Transfer GPA between 3.25 and 3.49"]
  },
  {
    title: "Snow Transfer Resident Centurium Scholarship",
    topColor: "#981dff",
    listitem: ["$750", "Transfer GPA between 3.0 and 3.24"]
  },
  {
    title: "Snow Transfer Non-Resident Founder's Scholarship",
    topColor: "#F59E0B",
    listitem: ["$6,908", "Transfer GPA between 3.65 and 4.0"]
  },
  {
    title: "Snow Transfer Non-Resident Achievement (non-WUE) Scholarship",
    topColor: "#10B981",
    listitem: ["$2,250", "Transfer GPA between 3.0 and 3.64"]
  },
  {
    title: "Snow Transfer Non-Resident WUE Scholarship",
    topColor: "#981dff",
    listitem: ["$5,568.50", "Transfer GPA between 2.0 and 3.64"]
  }
];
const ColoradoScholarships = [
  {
    title: "Green and Gold Scholarships",
    topColor: "#F59E0B",
    listitem: ["Colorado residents", "$2,500 - $4,000 per year"]
  },
  {
    title: "Alliance High School Partnership Award",
    topColor: "#10B981",
    listitem: [
      "Graduates of Alliance high schools, enrolled in a Colorado community/junior college",
      "$4,000 per year (up to 5 years)"
    ]
  },
  {
    title: "Student Support Services Partnership Award",
    topColor: "#981dff",
    listitem: [
      "Colorado residents transferring from a Colorado two-year/community college",
      "$2,500 per year (up to 5 years)"
    ]
  },
  
];

const IllinoisScholarships = [
  {
    title: "Illinois Commitment Scholarship",
    topColor: "#f59e0b", // You can change this color as needed
    listitem: [
      "Illinois residents, high GPA",
      "Varies",
      "₹2,50,000 - ₹5,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "International Student Scholarship",
    topColor: "#10B981", // You can change this color as needed
    listitem: [
      "International students, strong academics",
      "Varies",
      "₹1,50,000 - ₹3,00,000",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "Graduate College Fellowship",
    topColor: "#981dff", // You can change this color as needed
    listitem: [
      "Graduate students, excellent academic record",
      "Varies",
      "₹5,00,000 - ₹10,00,000",
      "Postgraduate"
    ]
  },
  
];

const StanfordScholarships = [
  {
    title: "Stanford Scholarship",
    topColor: "#f59e0b",
    listitem: [
      "Based on financial need and merit",
      "Varies",
      "Up to ₹70,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "Knight-Hennessy Scholars",
    topColor: "#10B981",
    listitem: [
      "Strong academic record, leadership qualities",
      "Approximately 100",
      "Up to ₹70,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Graduate Fellowships",
    topColor: "#981dff",
    listitem: [
      "Excellent academic record",
      "Varies",
      "₹10,00,000 - ₹20,00,000",
      "Postgraduate"
    ]
  },
   
];

const PennsylvaniaScholarships = [
  {
    title: "Penn Grant",
    topColor: "#f59e0b",
    listitem: [
      "Demonstrated financial need",
      "Varies",
      "Up to ₹50,00,000+",
      "Undergraduate"
    ]
  },
  {
    title: "Dean’s Scholarship",
    topColor: "#10B981",
    listitem: [
      "Outstanding academic achievement",
      "Varies",
      "Up to ₹20,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "Wharton Fellowship",
    topColor: "#981dff",
    listitem: [
      "Admission to Wharton MBA",
      "Limited",
      "Up to ₹70,00,000",
      "Postgraduate"
    ]
  },
  
];


const ChicagoScholarships = [
  {
    title: "University of Chicago Scholarships",
    topColor: "#2563eb",
    listitem: [
      "Based on financial need and merit",
      "Varies",
      "Up to ₹80,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "The Chicago Commitment",
    topColor: "#ec4899",
    listitem: [
      "High-achieving students from low-income backgrounds",
      "Varies",
      "Full tuition and fees",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate Fellowships",
    topColor: "#a855f7",
    listitem: [
      "Excellent academic record",
      "Varies",
      "₹10,00,000 - ₹20,00,000",
      "Postgraduate"
    ]
  },
  
];

const MITScholarships = [
  {
    title: "MIT Scholarships",
    listitem: [
      "Based on financial need and merit",
      "Varies",
      "Up to ₹80,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Graduate Fellowships",
    listitem: [
      "Excellent academic record",
      "Varies",
      "₹10,00,000 - ₹20,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Dean’s Fellowships",
    listitem: [
      "Graduate students with outstanding credentials",
      "Varies",
      "₹5,00,000 - ₹15,00,000",
      "Postgraduate",
    ],
  },
 
];




//UK

const BristolScholarships = [
  {
    title: "Think Big Undergraduate Scholarships",
    topColor: "#f59e0b",
    listitem: [
      "International students applying for undergraduate programs",
      "₹500,000 - ₹1,000,000 per year"
    ]
  },
  {
    title: "Think Big Postgraduate Scholarships",
    topColor: "#10B981",
    listitem: [
      "International students applying for postgraduate programs",
      "₹500,000 - ₹2,000,000 per year"
    ]
  },
  {
    title: "Global Accounting and Finance Undergraduate Scholarship",
    topColor: "#981dff",

    listitem: [
      "International students applying for Accounting and Finance programs",
      "₹500,000"
    ]
  },
  {
    title: "Michael Wong Pakshong Bursary",
    topColor: "#f59e0b",

    listitem: [
      "International students in financial need, applying for any program",
      "₹300,000"
    ]
  },
  {
    title: "Chevening Scholarships",
    topColor: "#10B981",

    listitem: [
      "International students with leadership potential, applying for any postgraduate program",
      "Full tuition fees + stipend"
    ]
  },
  {
    topColor: "#981dff",

    title: "GREAT Scholarships",
    listitem: [
      "International students from specific countries, applying for postgraduate programs",
      "₹1,000,000"
    ]
  },
  
];
const LeedsScholarships = [
  {
    title: "Dean's Excellence Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "Outstanding academic achievement, typically GPA 3.8+",
      "£5,000 - £20,000 per year"
    ]
  },
  {
    title: "International Excellence Scholarship",
    topColor: "#10B981",

    listitem: [
      "Exceptional academic performance, typically GPA 3.5+",
      "£2,000 - £5,000 (one-time award)"
    ]
  },
  {
    title: "India Excellence Scholarship",
    topColor: "#981dff",

    listitem: [
      "Indian national, outstanding academic performance",
      "£2,500 (one-time award)"
    ]
  },
  
];

const GlasgowScholarships = [
  {
    title: "Global Excellence Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "Outstanding academic record, offer of admission to a full-time program",
      "₹5,00,000 - ₹10,00,000"
    ]
  },
  {
    title: "International Merit Scholarship",
    topColor: "#10B981",

    listitem: [
      "High academic achievement, strong leadership qualities, and extracurricular involvement",
      "₹2,00,000 - ₹7,00,000"
    ]
  },
  {
    topColor: "#981dff",

    title: "Vice-Chancellor’s International Scholarship",
    listitem: [
      "Exceptional academic performance, leadership potential, and community service",
      "₹7,00,000 - ₹15,00,000"
    ]
  },
  
];
const BirminghamScholarships = [
  {
    title: "Birmingham International Excellence Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "International students with high academic achievement",
      "Varies",
      "Up to ₹5,00,000",
      "Undergraduate"
    ]
  },
  {
    topColor: "#10B981",

    title: "Birmingham Postgraduate Scholarships",
    listitem: [
      "International and UK students, academic merit",
      "Varies",
      "Up to ₹4,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Great Scholarship",
    topColor: "#981dff",

    listitem: [
      "Students from specific countries (varies)",
      "Limited",
      "₹10,00,000",
      "Postgraduate"
    ]
  },
  
];

const ImperialScholarships = [
  {
    title: "President’s Scholarship for Frontier Research",
    topColor: "#981dff",

    listitem: [
      "High academic achievement",
      "Approximately 50",
      "Varies (Equivalent to full tuition and stipend)",
      "Postgraduate"
    ]
  },
  {
    title: "Imperial College London Global Undergraduate Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "Financial need and academic excellence",
      "Around 20",
      "Up to 20,00,000 INR",
      "Undergraduate"
    ]
  }
];


const QueenMaryScholarships = [
  {
    "title": "Chevening Scholarships",
    "topColor": "#4caf50",
    "listitem": [
      "Academic excellence, leadership potential, 2+ years of work experience",
      "Varies (competitive)",
      "Full tuition + living costs",
      "Postgraduate (Master's)"
    ]
  },
  {
    "title": "Commonwealth Scholarships",
    "topColor": "#ff9800",
    "listitem": [
      "From a Commonwealth country, high academic performance, and financial need",
      "Limited",
      "Full tuition + living costs",
      "Postgraduate (Master's, PhD)"
    ]
  },
  {
    "title": "Global Excellence Scholarships",
    "topColor": "#f44336",
    "listitem": [
      "High academic achievers, based on merit (for specific countries)",
      "Limited",
      "₹7-10 Lakhs",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    "title": "Queen Mary Global Talent Scholarships",
    "topColor": "#3f51b5",
    "listitem": [
      "High academic achievers with leadership or research potential",
      "Limited",
      "₹7-14 Lakhs",
      "Postgraduate (Master's)"
    ]
  },
  {
    "title": "International Science and Engineering Excellence Award",
    "topColor": "#9c27b0",
    "listitem": [
      "High-performing students applying to science and engineering courses",
      "Limited",
      "₹3-7 Lakhs",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    "title": "DeepMind Scholarship (AI and ML)",
    "topColor": "#e91e63",
    "listitem": [
      "Female/underrepresented students in AI and Machine Learning; academic excellence",
      "4-5",
      "Full tuition + ₹18 Lakhs stipend",
      "Postgraduate (Master's)"
    ]
  }
];



const LiverpoolScholarships = [
  {
    "title": "University of Liverpool International Scholarships",
    "topColor": "#4caf50",
    "listitem": [
      "Non-UK students with high academic achievements",
      "Varies (approximately 100)",
      "Up to 1,000,000 INR",
      "Undergraduate"
    ]
  },
  {
    "title": "Liverpool Chancellor's International Scholarship",
    "topColor": "#ff9800",
    "listitem": [
      "High-achieving international students",
      "Approximately 50",
      "1,000,000 INR per year",
      "Postgraduate"
    ]
  },
  {
    "title": "Sports Scholarships",
    "topColor": "#f44336",
    "listitem": [
      "Talented athletes meeting specific criteria",
      "Varies",
      "Varies (up to 500,000 INR)",
      "Undergraduate & Postgraduate"
    ]
  },
  
];

const EdinburghScholarships = [
  {
    "title": "International Student Scholarship",
    "topColor": "#4caf50",
    "listitem": [
      "International students with strong academic records",
      "Varies (Approx. 50)",
      "Up to ₹5,00,000 each",
      "Both"
    ]
  },
  {
    "title": "Entrance Scholarship",
    "topColor": "#2196f3",
    "listitem": [
      "High school graduates with exceptional grades",
      "Varies (Approx. 100)",
      "Up to ₹2,00,000 each",
      "Undergraduate"
    ]
  },
  {
    "title": "Graduate Research Scholarship",
    "topColor": "#ff5722",
    "listitem": [
      "Master's or PhD applicants with research proposals",
      "Varies (Approx. 30)",
      "Up to ₹7,00,000 each",
      "Postgraduate"
    ]
  },
  
];


const SouthamptonScholarships = [
  {
    "title": "Edinburgh Global Research Scholarship",
    "topColor": "#4caf50",
    "listitem": [
      "International students for research programs",
      "Varies",
      "Varies",
      "Postgraduate"
    ]
  },
  {
    "title": "Dean's International Scholarship",
    "topColor": "#2196f3",
    "listitem": [
      "International undergraduate students",
      "Varies",
      "Varies",
      "Undergraduate"
    ]
  },
  {
    "title": "Edinburgh College of Art Scholarships",
    "topColor": "#ff5722",
    "listitem": [
      "Art and design students",
      "Varies",
      "Varies",
      "Undergraduate/Postgraduate"
    ]
  },
  
];

const DurhamScholarships = [
  {
    title: "Durham International Scholarships",
    topColor: "#4caf50",
    listitem: [
      "International students",
      "Varies",
      "Up to ₹12,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "Vice-Chancellor’s Scholarship",
    topColor: "#2196f3",
    listitem: [
      "Academic excellence in undergraduate studies",
      "Varies",
      "Up to ₹10,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "MBA Scholarships",
    topColor: "#ff5722",
    listitem: [
      "Applicants to the MBA program",
      "Varies",
      "Up to ₹15,00,000",
      "Postgraduate"
    ]
  },
  
];







const UNSWScholarships = [
  {
    title: "UNSW International Scholarships",
    topColor: "#10B981",

    listitem: [
      "Academic merit; international students",
      "Hundreds",
      "100,000 - 1,000,000 INR",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "UNSW Global Scholarships",
    topColor: "#981dff",

    listitem: [
      "Academic achievement; financial need",
      "Varies",
      "200,000 - 500,000 INR",
      "Undergraduate"
    ]
  },
  {
    title: "Faculty Scholarships (various faculties)",
    topColor: "#f59e0b",

    listitem: [
      "Specific to faculty; academic merit",
      "Varies",
      "100,000 - 500,000 INR",
      "Undergraduate & Postgraduate"
    ]
  },
  
];
const MonashScholarships = [
  {
    title: "Monash Scholarship for Excellence",
    topColor: "#f59e0b",

    listitem: [
      "High-achieving students",
      "100",
      "100,000 - 500,000 INR",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Monash International Merit Scholarship",
    topColor: "#10B981",

    listitem: [
      "International students with academic excellence",
      "31",
      "300,000 INR",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Faculty-specific Scholarships",
    topColor: "#981dff",

    listitem: [
      "Varies by faculty",
      "Varies",
      "200,000 - 1,000,000 INR",
      "Undergraduate & Postgraduate"
    ]
  },
  
];
const QueenslandScholarships = [
  {
    title: "UQ Academic Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "High-achieving students",
      "Varies",
      "Up to 100,000 INR",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "UQ International Scholarship",
    topColor: "#10B981",

    listitem: [
      "International students with strong academic records",
      "Varies",
      "Up to 50,000 INR",
      "Undergraduate"
    ]
  },
  {
    title: "UQ Excellence Scholarships",
    topColor: "#981dff",

    listitem: [
      "Academic excellence and leadership",
      "Approximately 30",
      "Up to 150,000 INR",
      "Undergraduate & Postgraduate"
    ]
  },
  
];

const UWAScholarships = [
  {
    title: "UWA Academic Excellence Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "High-achieving students",
      "Varies",
      "Up to 100,000 INR",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "UWA International Scholarship",
    topColor: "#10B981",

    listitem: [
      "International students with strong academic records",
      "Varies",
      "Up to 50,000 INR",
      "Undergraduate"
    ]
  },
  {
    title: "UWA Equity Scholarships",
    topColor: "#981dff",

    listitem: [
      "Students facing financial hardship",
      "Varies",
      "Up to 30,000 INR",
      "Undergraduate & Postgraduate"
    ]
  },
  
];
const AdelaideScholarships = [
  {
    title: "Adelaide International Undergraduate Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "International students with strong academic records",
      "Varies",
      "Up to 8,00,000 INR per year",
      "Undergraduate"
    ]
  },
  {
    title: "Global Academic Excellence Scholarship",
    topColor: "#10B981",

    listitem: [
      "High-achieving international students",
      "Varies",
      "15,00,000 INR over 3 years",
      "Undergraduate"
    ]
  },
  {
    title: "South Australian Government Scholarships",
    topColor: "#981dff",

    listitem: [
      "International students with a study offer",
      "Varies",
      "Up to 20,00,000 INR total",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Adelaide International Masters Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "International students for master's programs",
      "Varies",
      "Up to 50% of tuition fees",
      "Postgraduate"
    ]
  },
  {
    title: "Research Training Program (RTP) Scholarship",
    topColor: "#10B981",

    listitem: [
      "For high-achieving research candidates",
      "Varies",
      "35,00,000 INR over 3 years",
      "Postgraduate"
    ]
  },
  {
    title: "Women in STEM Scholarship",
    topColor: "#981dff",

    listitem: [
      "Female students pursuing STEM fields",
      "Varies",
      "10,00,000 INR over 3 years",
      "Undergraduate & Postgraduate"
    ]
  }
];
const MelbourneScholarships = [
  {
    title: "University of Melbourne Scholarships",
    topColor: "#f59e0b",

    listitem: [
      "Varies by program, academic merit",
      "Varies",
      "Up to ₹1,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Graduate Research Scholarships",
    topColor: "#10B981",

    listitem: [
      "For research-focused postgraduate study",
      "Approximately 200",
      "₹28,000/month + tuition",
      "Postgraduate"
    ]
  },
  {
    title: "International Undergraduate Scholarships",
    topColor: "#981dff",

    listitem: [
      "International students with high grades",
      "Limited",
      "Up to ₹50,000",
      "Undergraduate"
    ]
  },
  
];
const SydneyScholarships = [
  {
    title: "Sydney Scholars Award",
    topColor: "#981dff",

    listitem: [
      "High-achieving students",
      "Approximately 50",
      "Up to ₹10,000 per year",
      "Undergraduate"
    ]
  },
  {
    title: "International Undergraduate Scholarships",
    topColor: "#f59e0b",

    listitem: [
      "International students with high grades",
      "Limited",
      "Up to ₹50,000",
      "Undergraduate"
    ]
  },
  {
    title: "Sydney Talent Scholarships",
    topColor: "#10B981",

    listitem: [
      "Academic excellence in various fields",
      "Limited",
      "Up to ₹20,000",
      "Undergraduate & Postgraduate"
    ]
  },
  
];
const ANUScholarships = [
  {
    title: "ANU Excellence Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "High academic achievers",
      "Varies",
      "Up to 18,50,000",
      "Undergraduate"
    ]
  },
  {
    title: "ANU International Scholarships",
    topColor: "#10B981",

    listitem: [
      "International students",
      "Varies",
      "Up to 11,25,000",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "ANU Research Scholarships",
    topColor: "#981dff",

    listitem: [
      "Research candidates",
      "Varies",
      "Research candidates",
      "Postgraduate"
    ]
  },
];
const UTSScholarships = [
  {
    title: "Academic Excellence Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "High academic performance in previous studies",
      "100",
      "500,000",
      "Undergraduate"
    ]
  },
  {
    title: "International Student Scholarship",
    topColor: "#10B981",

    listitem: [
      "International students with excellent grades",
      "50",
      "1,000,000",
      "Postgraduate"
    ]
  },
  {
    title: "Merit-based Scholarship",
    topColor: "#981dff",

    listitem: ["Based on academic merit", "75", "750,000", "Undergraduate"]
  },
  {
    title: "Diversity Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "Promoting diversity in student population",
      "30",
      "300,000",
      "Postgraduate"
    ]
  },
  {
    title: "Research Grant",
    topColor: "#10B981",

    listitem: ["For research-based programs", "20", "2,000,000", "Postgraduate"]
  },
  {
    title: "Community Service Scholarship",
    topColor: "#981dff",

    listitem: [
      "Involvement in community service projects",
      "40",
      "400,000",
      "Undergraduate"
    ]
  },
  
];
const MacquarieScholarships = [
  {
    title: "Academic Excellence Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "High academic performance in previous studies",
      "100",
      "500,000",
      "Undergraduate"
    ]
  },
  {
    title: "International Student Scholarship",
    topColor: "#10B981",

    listitem: [
      "International students with excellent grades",
      "50",
      "1,000,000",
      "Postgraduate"
    ]
  },
  {
    title: "Merit-based Scholarship",
    topColor: "#981dff",

    listitem: ["Based on academic merit", "75", "750,000", "Undergraduate"]
  },
  {
    title: "Diversity Scholarship",
    topColor: "#f59e0b",

    listitem: [
      "Promoting diversity in student population",
      "30",
      "300,000",
      "Postgraduate"
    ]
  },
  {
    title: "Research Grant",
    topColor: "#10B981",

    listitem: ["For research-based programs", "20", "2,000,000", "Postgraduate"]
  },
  {
    title: "Community Service Scholarship",
    topColor: "#981dff",

    listitem: [
      "Involvement in community service projects",
      "40",
      "400,000",
      "Undergraduate"
    ]
  },
  
];

const TrentScholarships = [
  {
    title: "Waterloo International Funding",
    topColor: "#f59e0b",
    listitem: [
      "High academic achievers from international backgrounds",
      "Limited",
      "Up to ₹5 Lakhs",
      "Undergraduate"
    ]
  },
  {
    title: "Entrance Scholarships",
    topColor: "#10B981",
    listitem: [
      "Based on high academic performance (minimum 80% average)",
      "Varies",
      "₹1 - 3 Lakhs",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate Entrance Scholarships",
    topColor: "#981dff",
    listitem: [
      "Based on academic merit for graduate programs",
      "Limited",
      "Up to ₹5 Lakhs",
      "Postgraduate"
    ]
  },
  
];

const TrinityScholarships = [
  {
    title: "TWU Entrance Scholarship",
    topColor: "#f59e0b",
    listitem: [
      "Based on academic merit, minimum 80% in previous education",
      "Limited",
      "₹3 - 7 Lakhs",
      "Undergraduate"
    ]
  },
  {
    title: "Dean’s Scholarship",
    topColor: "#10B981",
    listitem: [
      "Outstanding academic performance, leadership, and extracurricular involvement",
      "Limited",
      "₹2 - 5 Lakhs",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "TWU Leadership Scholarship",
    topColor: "#981dff",
    listitem: [
      "Students with strong leadership skills and community involvement",
      "Limited",
      "₹2 - 4 Lakhs",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "International Student Scholarship",
    topColor: "#f59e0b",
    listitem: [
      "Merit-based, for high-performing international students",
      "Limited",
      "₹2 - 6 Lakhs",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "Graduate Scholarship",
    topColor: "#10B981",
    listitem: [
      "Based on academic excellence for Master’s and PhD students",
      "Limited",
      "₹3 - 6 Lakhs",
      "Postgraduate"
    ]
  },
  {
    title: "Canada Graduate Scholarships (External)",
    topColor: "#981dff",
    listitem: [
      "For outstanding graduate students pursuing a Master’s or PhD degree in Canada",
      "Limited (government-funded)",
      "Full tuition + living expenses",
      "Postgraduate"
    ]
  }
];

const RoyalScholarships = [
  {
    title: "Royal Roads Entrance Scholarships",
    topColor: "#f59e0b",
    listitem: [
      "High academic achievers, leadership, and community involvement",
      "Limited",
      "₹1 - 3 Lakhs",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "Royal Roads Graduate Scholarships",
    topColor: "#10B981",
    listitem: [
      "Based on academic merit and research potential",
      "Limited",
      "₹3 - 5 Lakhs",
      "Postgraduate"
    ]
  },
  {
    title: "Commonwealth Scholarships",
    topColor: "#981dff",
    listitem: [
      "For students from Commonwealth countries with high academic achievement",
      "Limited",
      "Full or partial tuition",
      "Postgraduate"
    ]
  },
];

const WaterlooScholarships = [
  {
    title: "Waterloo International Funding",
    topColor: "#f59e0b",
    listitem: [
      "High academic achievers from international backgrounds",
      "Limited",
      "Up to ₹5 Lakhs",
      "Undergraduate"
    ]
  },
  {
    title: "Entrance Scholarships",
    topColor: "#10B981",
    listitem: [
      "Based on high academic performance (minimum 80% average)",
      "Varies",
      "₹1 - 3 Lakhs",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate Entrance Scholarships",
    topColor: "#981dff",
    listitem: [
      "Based on academic merit for graduate programs",
      "Limited",
      "Up to ₹5 Lakhs",
      "Postgraduate"
    ]
  },
  
];

const CalgeryScholarships = [
  {
    title: "President’s Admission Scholarship",
    topColor: "#f59e0b",
    listitem: [
      "High academic achievers with a minimum average of 95% or higher",
      "Limited",
      "₹1 - 3 Lakhs",
      "Undergraduate"
    ]
  },
  {
    title: "International Entrance Scholarship",
    topColor: "#10B981",
    listitem: [
      "High academic performance, extracurricular involvement",
      "Limited",
      "₹2 - 4 Lakhs",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate Award Program",
    topColor: "#981dff",
    listitem: [
      "Based on academic merit and research potential",
      "Limited",
      "₹3 - 7 Lakhs",
      "Postgraduate"
    ]
  },
  
];

const DalhousieScholarships = [
  {
    title: "Dalhousie Entrance Scholarship",
    topColor: "#f59e0b",
    listitem: [
      "High academic achievers entering undergraduate programs",
      "Limited",
      "₹1 - 3 Lakhs",
      "Undergraduate"
    ]
  },
  {
    title: "In-Course Scholarships",
    topColor: "#10B981",
    listitem: [
      "Based on academic performance during undergraduate studies",
      "Limited",
      "₹50,000 - ₹2 Lakhs",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate Funding",
    topColor: "#981dff",
    listitem: [
      "Merit-based, program-specific scholarships for graduate students",
      "Limited",
      "₹3 - 7 Lakhs",
      "Postgraduate"
    ]
  },
  
];

const BrockScholarships = [
  {
    title: "Brock University Entrance Scholarship",
    topColor: "#f59e0b",
    listitem: [
      "High school graduates with strong academic records",
      "Varies (Approximately 100)",
      "Up to ₹2,00,000 per year",
      "Undergraduate"
    ]
  },
  {
    title: "International Student Scholarship",
    topColor: "#10B981",
    listitem: [
      "International students with high academic achievement",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate Scholarships",
    topColor: "#981dff",
    listitem: [
      "Students enrolled in graduate programs",
      "Varies",
      "₹2,00,000 - ₹5,00,000",
      "Postgraduate"
    ]
  },
  
];

const WindsorScholarships = [
  {
    title: "Windsor Entrance Scholarship",
    topColor: "#f59e0b",
    listitem: [
      "High school graduates with strong academic records",
      "Varies (approximately 1,000)",
      "Up to ₹2,00,000 per year",
      "Undergraduate"
    ]
  },
  {
    title: "International Student Scholarship",
    topColor: "#10B981",
    listitem: [
      "International students with high academic achievement",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate Scholarships",
    topColor: "#981dff",
    listitem: [
      "Students enrolled in graduate programs",
      "Varies",
      "₹2,00,000 - ₹5,00,000",
      "Postgraduate"
    ]
  },
  
];

const VCCScholarships = [
  {
    title: "International Student Scholarship",
    topColor: "#10B981",
    listitem: [
      "International students with strong academic records",
      "Varies (Approx. 50)",
      "Up to ₹5,00,000 each",
      "Both"
    ]
  },
  {
    title: "Entrance Scholarship",
    topColor: "#f59e0b",
    listitem: [
      "High school graduates with exceptional grades",
      "Varies (Approx. 100)",
      "Up to ₹2,00,000 each",
      "Undergraduate"
    ]
  },
  {
    title: "Graduate Research Scholarship",
    topColor: "#981dff",
    listitem: [
      "Master's or PhD applicants with research proposals",
      "Varies (Approx. 30)",
      "Up to ₹7,00,000 each",
      "Postgraduate"
    ]
  },
  
];


const TorontoScholarships = [
  {
    title: "University of Toronto Entrance Scholarship",
    topColor: "#10B981",
    listitem: [
      "High academic achievement in secondary school",
      "Varies (Hundreds)",
      "₹1,00,000 - ₹2,00,000 per year",
      "Undergraduate"
    ]
  },
  {
    title: "President’s Scholarship",
    topColor: "#f59e0b",
    listitem: [
      "Outstanding academic performance and leadership",
      "Approximately 50",
      "₹30,000 - ₹60,000 per year",
      "Undergraduate"
    ]
  },
  {
    title: "Ontario Graduate Scholarship",
    topColor: "#981dff",
    listitem: [
      "Full-time graduate students",
      "Approximately 500",
      "₹15,000 per year",
      "Postgraduate"
    ]
  },
  
];

// Germany

const LMUScholarships = [
  {
    title: "LMU Study Scholarships",
    topColor: "#ff5733",
    listitem: [
      "Academic excellence and financial need",
      "Varies",
      "Up to ₹1,50,000",
      "Undergraduate"
    ]
  },
  {
    title: "DAAD Scholarships",
    topColor: "#33c4ff",
    listitem: [
      "International students",
      "Varies",
      "Up to ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#9e33ff",
    listitem: [
      "Participation in Erasmus program",
      "Varies",
      "Varies based on program",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Germany Scholarship",
    topColor: "#ffd700",
    listitem: [
      "Outstanding academic performance",
      "Varies",
      "₹1,00,000 - ₹2,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "University Merit Scholarships",
    topColor: "#10B981",
    listitem: [
      "Based on academic performance",
      "Varies",
      "Up to ₹2,00,000",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "Research Grants",
    topColor: "#f59e0b",
    listitem: [
      "For research projects",
      "Varies",
      "Based on project",
      "Postgraduate"
    ]
  },
  // {
  //   title: "External Scholarships",
  //   topColor: "#ff6f61",
  //   listitem: [
  //     "Various organizations",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate/Postgraduate"
  //   ]
  // }
];

const TUMScholarships = [
  {
    title: "TUM Study Scholarships",
    topColor: "#ff5733",
    listitem: [
      "Academic excellence and financial need",
      "Varies",
      "Up to ₹1,50,000",
      "Undergraduate"
    ]
  },
  {
    title: "DAAD Scholarships",
    topColor: "#33c4ff",
    listitem: [
      "International students",
      "Varies",
      "Up to ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#9e33ff",
    listitem: [
      "Participation in Erasmus program",
      "Varies",
      "Varies based on program",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Germany Scholarship",
    topColor: "#ffd700",
    listitem: [
      "Outstanding academic performance",
      "Varies",
      "₹1,00,000 - ₹2,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "TUM Excellence Scholarships",
    topColor: "#10B981",
    listitem: [
      "High-achieving students",
      "Varies",
      "Up to ₹2,50,000",
      "Postgraduate"
    ]
  },
  {
    title: "Research Grants",
    topColor: "#f59e0b",
    listitem: [
      "For research projects",
      "Varies",
      "Based on project",
      "Postgraduate"
    ]
  },
  // {
  //   title: "External Scholarships",
  //   topColor: "#ff6f61",
  //   listitem: [
  //     "Various organizations",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate/Postgraduate"
  //   ]
  // }
];

const HeidelbergUniversityScholarships = [
  {
    title: "Heidelberg University Scholarships",
    topColor: "#1e90ff",
    listitem: [
      "Academic excellence and financial need",
      "Varies",
      "Up to ₹1,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "DAAD Scholarships",
    topColor: "#33c4ff",
    listitem: [
      "International students",
      "Varies",
      "Up to ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Deutschlandstipendium",
    topColor: "#ffd700",
    listitem: [
      "Outstanding academic performance",
      "Varies",
      "₹1,00,000 - ₹2,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  // {
  //   title: "Research Grants",
  //   topColor: "#f59e0b",
  //   listitem: [
  //     "For research projects",
  //     "Varies",
  //     "Based on project",
  //     "Postgraduate"
  //   ]
  // },
  // {
  //   title: "External Scholarships",
  //   topColor: "#ff6f61",
  //   listitem: [
  //     "Various organizations",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate/Postgraduate"
  //   ]
  // }
];

const HumboldtUniversityScholarships = [
  {
    title: "Humboldt University Scholarships",
    topColor: "#007BFF",
    listitem: [
      "Academic excellence and financial need",
      "Varies",
      "Up to ₹1,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "DAAD Scholarships",
    topColor: "#33c4ff",
    listitem: [
      "International students",
      "Varies",
      "Up to ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Deutschlandstipendium",
    topColor: "#ffd700",
    listitem: [
      "Outstanding academic performance",
      "Varies",
      "₹1,00,000 - ₹2,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  // {
  //   title: "Research Grants",
  //   topColor: "#f59e0b",
  //   listitem: [
  //     "For research projects",
  //     "Varies",
  //     "Based on project",
  //     "Postgraduate"
  //   ]
  // },
  // {
  //   title: "External Scholarships",
  //   topColor: "#ff6f61",
  //   listitem: [
  //     "Various organizations",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate/Postgraduate"
  //   ]
  // }
];

const RWTHScholarships = [
  {
    title: "RWTH Scholarships",
    topColor: "#0056b3",
    listitem: [
      "Academic excellence and financial need",
      "Varies",
      "Up to ₹1,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "DAAD Scholarships",
    topColor: "#33c4ff",
    listitem: [
      "International students",
      "Varies",
      "Up to ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Deutschlandstipendium",
    topColor: "#ffd700",
    listitem: [
      "Outstanding academic performance",
      "Varies",
      "₹1,00,000 - ₹2,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  // {
  //   title: "Research Grants",
  //   topColor: "#f59e0b",
  //   listitem: [
  //     "For specific research projects",
  //     "Varies",
  //     "Based on project",
  //     "Postgraduate"
  //   ]
  // },
  // {
  //   title: "External Scholarships",
  //   topColor: "#ff6f61",
  //   listitem: [
  //     "Various organizations",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate/Postgraduate"
  //   ]
  // }
];

const FUBScholarships = [
  {
    title: "DAAD Scholarships",
    topColor: "#1e90ff",
    listitem: [
      "Varies by program",
      "Varies",
      "₹2,00,000 - ₹10,00,000",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "FU Berlin International Scholarships",
    topColor: "#ff7f50",
    listitem: [
      "International students",
      "Varies",
      "Varies",
      "Undergraduate"
    ]
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#ff6347",
    listitem: [
      "EU exchange students",
      "Varies",
      "Varies",
      "Undergraduate"
    ]
  },
  {
    title: "Heinrich Böll Foundation Scholarships",
    topColor: "#228b22",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Konrad Adenauer Foundation Scholarships",
    topColor: "#8a2be2",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Friedrich Ebert Foundation Scholarships",
    topColor: "#4682b4",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Postgraduate"
    ]
  },
  // {
  //   title: "Deutschlandstipendium",
  //   topColor: "#ffd700",
  //   listitem: [
  //     "High-achieving students",
  //     "Varies",
  //     "₹1,00,000 - ₹3,00,000",
  //     "Undergraduate/Postgraduate"
  //   ]
  // }
];

const UOFScholarships = [
  {
    title: "DAAD Scholarships",
    topColor: "#1e90ff",
    listitem: [
      "Varies by program",
      "Varies",
      "₹2,00,000 - ₹10,00,000",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "University of Freiburg Scholarships",
    topColor: "#32cd32",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#ff6347",
    listitem: [
      "EU exchange students",
      "Varies",
      "Varies",
      "Undergraduate"
    ]
  },
  {
    title: "Heinrich Böll Foundation Scholarships",
    topColor: "#228b22",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Konrad Adenauer Foundation Scholarships",
    topColor: "#8a2be2",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Friedrich Ebert Foundation Scholarships",
    topColor: "#4682b4",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Postgraduate"
    ]
  },
  // {
  //   title: "Deutschlandstipendium",
  //   topColor: "#ffd700",
  //   listitem: [
  //     "High-achieving students",
  //     "Varies",
  //     "₹1,00,000 - ₹3,00,000",
  //     "Undergraduate/Postgraduate"
  //   ]
  // }
];

const UOTScholarships = [
  {
    title: "DAAD Scholarships",
    topColor: "#1e90ff",
    listitem: [
      "Varies by program",
      "Varies",
      "₹2,00,000 - ₹10,00,000",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "University of Tübingen Scholarships",
    topColor: "#2e8b57",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#ff6347",
    listitem: [
      "EU exchange students",
      "Varies",
      "Varies",
      "Undergraduate"
    ]
  },
  {
    title: "Heinrich Böll Foundation Scholarships",
    topColor: "#228b22",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Konrad Adenauer Foundation Scholarships",
    topColor: "#8a2be2",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Friedrich Ebert Foundation Scholarships",
    topColor: "#4682b4",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Deutschlandstipendium",
    topColor: "#ffd700",
    listitem: [
      "High-achieving students",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate/Postgraduate"
    ]
  }
];

const UOMScholarships = [
  {
    title: "DAAD Scholarships",
    topColor: "#1e90ff",
    listitem: [
      "Varies by program",
      "Varies",
      "₹2,00,000 - ₹10,00,000",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "University of Mannheim Scholarships",
    topColor: "#2e8b57",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#ff6347",
    listitem: [
      "EU exchange students",
      "Varies",
      "Varies",
      "Undergraduate"
    ]
  },
  {
    title: "Heinrich Böll Foundation Scholarships",
    topColor: "#228b22",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Konrad Adenauer Foundation Scholarships",
    topColor: "#8a2be2",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Friedrich Ebert Foundation Scholarships",
    topColor: "#4682b4",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Postgraduate"
    ]
  },
  // {
  //   title: "Deutschlandstipendium",
  //   topColor: "#ffd700",
  //   listitem: [
  //     "High-achieving students",
  //     "Varies",
  //     "₹1,00,000 - ₹3,00,000",
  //     "Undergraduate/Postgraduate"
  //   ]
  // }
];

const UOGScholarships = [
  {
    title: "DAAD Scholarships",
    topColor: "#1e90ff",
    listitem: [
      "Varies by program",
      "Varies",
      "₹2,00,000 - ₹10,00,000",
      "Undergraduate/Postgraduate"
    ]
  },
  {
    title: "University of Göttingen Scholarships",
    topColor: "#2e8b57",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate"
    ]
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#ff6347",
    listitem: [
      "EU exchange students",
      "Varies",
      "Varies",
      "Undergraduate"
    ]
  },
  {
    title: "Heinrich Böll Foundation Scholarships",
    topColor: "#228b22",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate"
    ]
  },
  {
    title: "Konrad Adenauer Foundation Scholarships",
    topColor: "#8a2be2",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Postgraduate"
    ]
  },
  {
    title: "Friedrich Ebert Foundation Scholarships",
    topColor: "#4682b4",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Postgraduate"
    ]
  },
  // {
  //   title: "Deutschlandstipendium",
  //   topColor: "#ffd700",
  //   listitem: [
  //     "High-achieving students",
  //     "Varies",
  //     "₹1,00,000 - ₹3,00,000",
  //     "Undergraduate/Postgraduate"
  //   ]
  // }
];


// France

const UPSLScholarships = [
  {
    title: "PSL Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Academic merit, financial need",
      "200+",
      "Up to ₹5,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#10B981",
    listitem: [
      "Residency in specific regions",
      "50+",
      "Up to ₹2,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#981dff",
    listitem: [
      "Exchange students from partner universities",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Master's Scholarships for International Students",
    topColor: "#3B82F6",
    listitem: [
      "Non-EU students applying for master’s programs",
      "30+",
      "Up to ₹10,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "PhD Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Admission to PhD programs",
      "20+",
      "Up to ₹15,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Diversity Scholarships",
    topColor: "#F472B6",
    listitem: [
      "Underrepresented groups in higher education",
      "10+",
      "Varies",
      "Undergraduate/Postgraduate",
    ],
  },
  // {
  //   title: "Merit-Based Scholarships",
  //   topColor: "#34D399",
  //   listitem: [
  //     "High academic performance",
  //     "Varies",
  //     "Up to ₹3,00,000",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const UPSScholarships = [
  {
    title: "Université Paris-Saclay Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Academic merit and financial need",
      "200+",
      "Up to ₹5,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#10B981",
    listitem: [
      "Residency in specific regions",
      "50+",
      "Up to ₹2,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#981dff",
    listitem: [
      "Exchange students from partner universities",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Master's Scholarships for International Students",
    topColor: "#3B82F6",
    listitem: [
      "Non-EU students applying for master’s programs",
      "30+",
      "Up to ₹10,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "PhD Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Admission to PhD programs",
      "20+",
      "Up to ₹15,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Diversity Scholarships",
    topColor: "#F472B6",
    listitem: [
      "Underrepresented groups in higher education",
      "10+",
      "Varies",
      "Undergraduate/Postgraduate",
    ],
  },
  // {
  //   title: "Merit-Based Scholarships",
  //   topColor: "#34D399",
  //   listitem: [
  //     "High academic performance",
  //     "Varies",
  //     "Up to ₹3,00,000",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const SUScholarships = [
  {
    title: "Sorbonne University Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Academic merit and financial need",
      "200+",
      "Up to ₹5,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
  {
    title: "International Scholarships",
    topColor: "#10B981",
    listitem: [
      "Non-EU international students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#981dff",
    listitem: [
      "Exchange students from partner universities",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Master’s Scholarships for International Students",
    topColor: "#3B82F6",
    listitem: [
      "Non-EU students applying for master’s programs",
      "30+",
      "Up to ₹10,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "PhD Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Admission to PhD programs",
      "20+",
      "Up to ₹15,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Diversity Scholarships",
    topColor: "#F472B6",
    listitem: [
      "Underrepresented groups in higher education",
      "10+",
      "Varies",
      "Undergraduate/Postgraduate",
    ],
  },
  // {
  //   title: "Merit-Based Scholarships",
  //   topColor: "#34D399",
  //   listitem: [
  //     "High academic performance",
  //     "Varies",
  //     "Up to ₹3,00,000",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const UGAScholarship = [
  {
    title: "Eiffel Excellence Scholarship Program",
    topColor: "#F59E0B",
    listitem: [
      "For outstanding international students (academic excellence)",
      "Varies",
      "INR 12,00,000 – 15,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Université Grenoble Alpes International Scholarship",
    topColor: "#10B981",
    listitem: [
      "International students from outside the EU with strong academic records",
      "Varies",
      "INR 2,50,000 – 5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "French Government Scholarships",
    topColor: "#981dff",
    listitem: [
      "Awarded by the French Government for international students in specific fields",
      "Varies",
      "INR 6,00,000 – 10,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Regional Scholarships (Ile-de-France)",
    topColor: "#3B82F6",
    listitem: [
      "Available to international students studying in the Île-de-France region",
      "Varies",
      "INR 2,00,000 – 3,50,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "EU-funded exchange program for students from partner institutions",
      "Varies",
      "EUR 400-500/month (INR 35,000 – 45,000/month)",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Master’s Scholarships for Non-EU Students",
    topColor: "#F472B6",
    listitem: [
      "For non-EU students applying for specific master’s programs",
      "Varies",
      "INR 3,00,000 – 7,00,000",
      "Postgraduate",
    ],
  },
  // {
  //   title: "SFR (Scholarships for Research)",
  //   topColor: "#34D399",
  //   listitem: [
  //     "For students conducting research projects or PhD students",
  //     "Varies",
  //     "INR 5,00,000 – 10,00,000",
  //     "Postgraduate",
  //   ],
  // },
];

const UDSScholarships = [
  {
    title: "Eiffel Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "For international students with excellent academic records.",
      "Varies",
      "INR 12,00,000 – 15,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Université de Strasbourg International Scholarships",
    topColor: "#10B981",
    listitem: [
      "For international students with strong academic backgrounds.",
      "Varies",
      "INR 2,50,000 – 5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "French Government Scholarships",
    topColor: "#981dff",
    listitem: [
      "For outstanding international students in specific fields.",
      "Varies",
      "INR 6,00,000 – 12,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "EU-funded scholarships for exchange students from partner universities.",
      "Varies",
      "EUR 400-500/month (INR 35,000 – 45,000/month)",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Master’s Scholarships for Non-EU Students",
    topColor: "#9B5DE5",
    listitem: [
      "For non-EU students applying for specific master's programs.",
      "Varies",
      "INR 3,00,000 – 7,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Regional Scholarships (Alsace)",
    topColor: "#F472B6",
    listitem: [
      "For international students studying in the Alsace region.",
      "Varies",
      "INR 2,00,000 – 4,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
  // {
  //   title: "Research Scholarships (PhD)",
  //   topColor: "#34D399",
  //   listitem: [
  //     "For PhD students conducting research in relevant fields.",
  //     "Varies",
  //     "INR 5,00,000 – 10,00,000",
  //     "Postgraduate",
  //   ],
  // },
];

const UDMScholarships = [
  {
    title: "Université de Montpellier Merit Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Excellent academic performance, good English proficiency",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Eiffel Excellence Scholarship Program",
    topColor: "#10B981",
    listitem: [
      "For international students with strong academic background",
      "Varies",
      "₹10,00,000 - ₹15,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "French Government Scholarships",
    topColor: "#981dff",
    listitem: [
      "Based on academic performance and financial need",
      "Varies",
      "₹4,00,000 - ₹7,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Montpellier Institute of Genetics (IGMM) Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Strong academic background in genetics, biology, or related field",
      "Varies",
      "₹5,00,000 - ₹8,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Master’s Scholarships in Environmental Science",
    topColor: "#9B5DE5",
    listitem: [
      "For students pursuing a Master's in environmental studies",
      "Varies",
      "₹6,00,000 - ₹9,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#F472B6",
    listitem: [
      "Exchange students from EU or partner universities",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  // {
  //   title: "L’Oréal-Université de Montpellier Women in Science Scholarship",
  //   topColor: "#34D399",
  //   listitem: [
  //     "Female students pursuing science-related degrees",
  //     "Varies",
  //     "₹4,00,000 - ₹6,00,000",
  //     "Undergraduate & Postgraduate",
  //   ],
  // },
];

const UDLScholarships = [
  {
    title: "Université de Lille Merit Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "Excellent academic performance, high school or bachelor’s GPA",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Eiffel Excellence Scholarship",
    topColor: "#10B981",
    listitem: [
      "International students with strong academic background",
      "Varies",
      "₹8,00,000 - ₹12,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "French Government Scholarship",
    topColor: "#981dff",
    listitem: [
      "Based on academic excellence and financial need",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Lille University International Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "High-achieving international students applying to selected programs",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "For exchange students from EU partner universities",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "L'Oréal-Université de Lille Women in Science Scholarship",
    topColor: "#F472B6",
    listitem: [
      "Female students pursuing science, engineering, or related fields",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  // {
  //   title: "Master’s Scholarship in Environmental Science",
  //   topColor: "#34D399",
  //   listitem: [
  //     "For students pursuing a Master's in Environmental Science",
  //     "Varies",
  //     "₹5,00,000 - ₹7,00,000",
  //     "Postgraduate",
  //   ],
  // },
];

const UDNScholarships = [
  {
    title: "Université de Nantes Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "Excellent academic performance, high school or bachelor’s GPA",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Eiffel Excellence Scholarship",
    topColor: "#10B981",
    listitem: [
      "International students with strong academic background",
      "Varies",
      "₹8,00,000 - ₹12,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "French Government Scholarship",
    topColor: "#981dff",
    listitem: [
      "Based on academic excellence and financial need",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "For exchange students from EU partner universities",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "L'Oréal-Université de Nantes Women in Science Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "Female students pursuing science or engineering degrees",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Master’s Scholarship in Environmental Science",
    topColor: "#F472B6",
    listitem: [
      "For students pursuing a Master's in Environmental Science",
      "Varies",
      "₹5,00,000 - ₹7,00,000",
      "Postgraduate",
    ],
  },
  // {
  //   title: "International Student Scholarship",
  //   topColor: "#34D399",
  //   listitem: [
  //     "High-achieving international students applying to selected programs",
  //     "Varies",
  //     "₹3,00,000 - ₹5,00,000",
  //     "Undergraduate & Postgraduate",
  //   ],
  // },
];

const UDBScholarships = [
  {
    title: "Université de Bordeaux Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "High academic performance (based on GPA)",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Eiffel Excellence Scholarship",
    topColor: "#10B981",
    listitem: [
      "For international students with outstanding academic records",
      "Varies",
      "₹8,00,000 - ₹12,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "French Government Scholarship",
    topColor: "#981dff",
    listitem: [
      "For international students based on academic excellence and financial need",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "For exchange students participating in Erasmus programs",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Master’s Scholarship in Environmental Science",
    topColor: "#9B5DE5",
    listitem: [
      "For students pursuing a Master’s in Environmental Science",
      "Varies",
      "₹5,00,000 - ₹7,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "L'Oréal-Université de Bordeaux Women in Science Scholarship",
    topColor: "#F472B6",
    listitem: [
      "For female students pursuing science or engineering fields",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  // {
  //   title: "International Student Scholarship",
  //   topColor: "#34D399",
  //   listitem: [
  //     "For high-achieving international students applying to selected programs",
  //     "Varies",
  //     "₹3,00,000 - ₹5,00,000",
  //     "Undergraduate & Postgraduate",
  //   ],
  // },
];

const UPPSScholarships = [
  {
    title: "Université Paris 1 Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "High academic performance (based on GPA)",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Eiffel Excellence Scholarship",
    topColor: "#10B981",
    listitem: [
      "For international students with exceptional academic records",
      "Varies",
      "₹8,00,000 - ₹12,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "French Government Scholarship",
    topColor: "#981dff",
    listitem: [
      "For international students based on academic excellence and financial need",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "For exchange students participating in Erasmus programs",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Master’s Scholarship in Humanities and Social Sciences",
    topColor: "#9B5DE5",
    listitem: [
      "For students pursuing a Master’s in relevant fields",
      "Varies",
      "₹5,00,000 - ₹7,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "L'Oréal-Université Paris 1 Women in Science Scholarship",
    topColor: "#F472B6",
    listitem: [
      "Female students pursuing science, technology, or engineering fields",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  // {
  //   title: "International Student Scholarship",
  //   topColor: "#34D399",
  //   listitem: [
  //     "For high-achieving international students applying to selected programs",
  //     "Varies",
  //     "₹3,00,000 - ₹5,00,000",
  //     "Undergraduate & Postgraduate",
  //   ],
  // },
];    

//Italy

const PDMilanoScholarships = [
  {
    title: "Politecnico di Milano Merit Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "For students with exceptional academic performance",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Regional Financial Aid Scholarship",
    topColor: "#10B981",
    listitem: [
      "For students with demonstrated financial need",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Erasmus Mundus Joint Master's Scholarship",
    topColor: "#981dff",
    listitem: [
      "For students enrolled in select Erasmus Mundus programs",
      "Varies",
      "₹5,00,000 - ₹8,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Italian Government Fellowship",
    topColor: "#3B82F6",
    listitem: [
      "For international students demonstrating academic merit",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Humanities & Social Sciences Excellence Grant",
    topColor: "#9B5DE5",
    listitem: [
      "For Master's students in Humanities or Social Sciences",
      "Varies",
      "₹3,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Women in STEM Scholarship",
    topColor: "#F472B6",
    listitem: [
      "Female students pursuing STEM-related fields",
      "Varies",
      "₹6,00,000 - ₹8,00,000",
      "Postgraduate",
    ],
  },
];


const UOMilanScholarships = [
  {
    title: "University Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Awarded based on academic merit",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#10B981",
    listitem: [
      "For students with demonstrated financial need",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "Erasmus Mundus Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "For students in specific Master's programs",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "Tuition Fee Waivers",
    topColor: "#981dff",
    listitem: [
      "Awarded to students with academic excellence",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Italian Government Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Available for international students",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "Institutional Scholarships",
    topColor: "#F472B6",
    listitem: [
      "Granted for outstanding academic achievement",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
];

const UOBolognaScholarships = [
  {
    title: "University Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Academic merit",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#10B981",
    listitem: [
      "Financial need",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "Erasmus Mundus Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Specific Master's programs",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "Tuition Fee Waivers",
    topColor: "#981dff",
    listitem: [
      "Academic excellence",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Italian Government Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "International students",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "Institutional Scholarships",
    topColor: "#F472B6",
    listitem: [
      "Academic achievement",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
];

const UOFlorenceScholarships = [
  {
    title: "University Merit Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Academic excellence",
      "Varies",
      "₹2,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#10B981",
    listitem: [
      "Residents of specific regions",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "International Student Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Non-Italian citizens",
      "Varies",
      "₹2,50,000 - ₹6,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Research Grants",
    topColor: "#981dff",
    listitem: [
      "For research proposals",
      "Varies",
      "₹3,00,000 - ₹8,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Participating in exchange programs",
      "Varies",
      "₹1,50,000 - ₹4,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Government Scholarships",
    topColor: "#F472B6",
    listitem: [
      "Based on country of origin",
      "Varies",
      "₹2,00,000 - ₹5,00,000",
      "Postgraduate",
    ],
  },
];

const UOPaduaScholarships = [
  {
    title: "University Merit Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Academic excellence",
      "Varies",
      "₹2,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#10B981",
    listitem: [
      "Residents of specific regions",
      "Varies",
      "₹1,00,000 - ₹3,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "International Student Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Non-Italian citizens",
      "Varies",
      "₹2,50,000 - ₹6,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Research Grants",
    topColor: "#981dff",
    listitem: [
      "For research proposals",
      "Varies",
      "₹3,00,000 - ₹8,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Participating in exchange programs",
      "Varies",
      "₹1,50,000 - ₹4,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Government Scholarships",
    topColor: "#F472B6",
    listitem: [
      "Based on country of origin",
      "Varies",
      "₹2,00,000 - ₹5,00,000",
      "Postgraduate",
    ],
  },
];

const UOTurinScholarships = [
  {
    title: "Turin University Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "International students",
      "Varies",
      "₹1,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus Mundus Scholarships",
    topColor: "#10B981",
    listitem: [
      "Selected master's programs",
      "Varies",
      "₹10,00,000 - ₹15,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Italian Government Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Non-EU international students",
      "Varies",
      "₹3,00,000 - ₹8,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "University of Turin Merit Scholarships",
    topColor: "#981dff",
    listitem: [
      "Outstanding academic performance",
      "Varies",
      "₹2,00,000 - ₹6,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Residents of specific regions",
      "Varies",
      "₹50,000 - ₹2,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Research Grants",
    topColor: "#F472B6",
    listitem: [
      "PhD candidates and researchers",
      "Varies",
      "₹5,00,000 - ₹12,00,000",
      "Postgraduate",
    ],
  },
];

const RLSScholarships = [
  {
    title: "Merit-Based Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement",
      "Varies",
      "Varies (up to ₹1,00,000)",
      "Undergraduate",
    ],
  },
  {
    title: "International Student Grants",
    topColor: "#10B981",
    listitem: [
      "Non-EU international students",
      "Varies",
      "Varies (up to ₹1,50,000)",
      "Undergraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Specific to certain regions",
      "Varies",
      "Varies (up to ₹2,00,000)",
      "Undergraduate",
    ],
  },
  {
    title: "Research Grants",
    topColor: "#981dff",
    listitem: [
      "For research projects",
      "Varies",
      "Varies (based on project)",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Participation in Erasmus program",
      "Varies",
      "Varies (up to ₹3,00,000)",
      "Postgraduate",
    ],
  },
  {
    title: "Fulbright Program",
    topColor: "#F472B6",
    listitem: [
      "U.S. citizens for study in Italy",
      "Varies",
      "Varies (up to ₹5,00,000)",
      "Postgraduate",
    ],
  },
];

const UONaplesScholarships = [
  {
    title: "Merit-Based Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement",
      "Varies",
      "Up to ₹1,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "International Student Grants",
    topColor: "#10B981",
    listitem: [
      "Non-EU international students",
      "Varies",
      "Up to ₹1,50,000",
      "Undergraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Specific to certain regions",
      "Varies",
      "Up to ₹2,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Research Grants",
    topColor: "#981dff",
    listitem: [
      "For research projects",
      "Varies",
      "Based on project",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Participation in Erasmus program",
      "Varies",
      "Up to ₹3,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Italian Government Scholarships",
    topColor: "#F472B6",
    listitem: [
      "For international students",
      "Varies",
      "Up to ₹4,00,000",
      "Postgraduate",
    ],
  },
];

const UOPisaScholarships = [
  {
    title: "Merit-Based Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement",
      "Varies",
      "Up to ₹1,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "International Student Grants",
    topColor: "#10B981",
    listitem: [
      "Non-EU international students",
      "Varies",
      "Up to ₹1,50,000",
      "Undergraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Specific to certain regions",
      "Varies",
      "Up to ₹2,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Research Grants",
    topColor: "#981dff",
    listitem: [
      "For research projects",
      "Varies",
      "Based on project",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Participation in Erasmus program",
      "Varies",
      "Up to ₹3,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Italian Government Scholarships",
    topColor: "#F472B6",
    listitem: [
      "For international students",
      "Varies",
      "Up to ₹4,00,000",
      "Postgraduate",
    ],
  },
];

const IEDScholarships = [
  {
    title: "Merit-Based Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement",
      "Varies",
      "Up to ₹1,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "International Student Grants",
    topColor: "#10B981",
    listitem: [
      "Non-EU international students",
      "Varies",
      "Up to ₹1,50,000",
      "Undergraduate",
    ],
  },
  {
    title: "Regional Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Specific to certain regions",
      "Varies",
      "Up to ₹2,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Research Grants",
    topColor: "#981dff",
    listitem: [
      "For research projects",
      "Varies",
      "Based on project",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Erasmus+ Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Participation in Erasmus program",
      "Varies",
      "Up to ₹3,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "IED Scholarships",
    topColor: "#F472B6",
    listitem: [
      "Based on artistic portfolios",
      "Varies",
      "Up to ₹2,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
];

//Ireland

const TCDublinScholarships = [
  {
    title: "Trinity College Dublin Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement, financial need",
      "Varies",
      "₹5,00,000 - ₹15,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Global Excellence Scholarships",
    topColor: "#10B981",
    listitem: [
      "International students with outstanding academic records",
      "10-20",
      "₹6,00,000 - ₹18,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Science Foundation Ireland Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Strong academic background in Science, Engineering, or Technology",
      "Varies",
      "₹7,00,000 - ₹25,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Provost’s Scholarships",
    topColor: "#981dff",
    listitem: [
      "High-achieving students from both undergraduate and postgraduate levels",
      "5-10",
      "₹8,00,000 - ₹20,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Postgraduate Research Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "For students pursuing advanced research in various fields, with excellent academic performance",
      "Varies",
      "₹10,00,000 - ₹25,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Irish Research Council Scholarships",
    topColor: "#F472B6",
    listitem: [
      "For postgraduate students pursuing a research-based program in any academic field",
      "50-100",
      "₹10,00,000 - ₹30,00,000",
      "Postgraduate",
    ],
  },
  // {
  //   title: "Trinity College Graduate Scholarships",
  //   topColor: "#F472B6",
  //   listitem: [
  //     "Exceptional graduate students in a variety of disciplines",
  //     "Varies",
  //     "₹5,00,000 - ₹15,00,000",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const UCDublinScholarships = [
  {
    title: "UCD Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Academic excellence and financial need",
      "Varies",
      "₹2,00,000 - ₹10,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Global Excellence Scholarships",
    topColor: "#10B981",
    listitem: [
      "For international students with exceptional academic records",
      "Varies",
      "₹5,00,000 - ₹15,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "UCD Entrance Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Based on high academic performance in the secondary school exams",
      "Varies",
      "₹3,00,000 - ₹7,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "UCD Postgraduate Scholarships",
    topColor: "#981dff",
    listitem: [
      "For postgraduate students with strong academic credentials and research focus",
      "Varies",
      "₹5,00,000 - ₹15,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Irish Research Council Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "For postgraduate students pursuing research-based programs",
      "50-100",
      "₹10,00,000 - ₹25,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "UCD Graduate Scholarships",
    topColor: "#F472B6",
    listitem: [
      "For outstanding graduate students in various disciplines",
      "Varies",
      "₹6,00,000 - ₹12,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
];

const UOCorkScholarships = [
  {
    title: "UCC Merit-Based Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Academic excellence, international students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "UCC International Scholarships",
    topColor: "#10B981",
    listitem: [
      "Non-EU international students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "UCC Science Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "For Science students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "UCC Graduate Scholarships",
    topColor: "#981dff",
    listitem: [
      "Academic excellence, postgraduate students",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "UCC MBA Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "For MBA applicants",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "UCC Research Scholarships",
    topColor: "#F472B6",
    listitem: [
      "For research-based programs",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  // {
  //   title: "UCC Postgraduate Merit Scholarships",
  //   topColor: "#22C55E",
  //   listitem: [
  //     "Academic excellence, postgraduate students",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate & Postgraduate",
  //   ],
  // },
];

const UOGalwayScholarships = [
  {
    title: "University of Galway Merit-Based Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "Academic excellence, international students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "University of Galway International Scholarships",
    topColor: "#10B981",
    listitem: [
      "Non-EU international students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "University of Galway Science Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "For Science students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "University of Galway Graduate Scholarships",
    topColor: "#981dff",
    listitem: [
      "Academic excellence, postgraduate students",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "University of Galway MBA Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "For MBA applicants",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "University of Galway Research Scholarships",
    topColor: "#F472B6",
    listitem: [
      "For research-based programs",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  // {
  //   title: "University of Galway Postgraduate Merit Scholarships",
  //   topColor: "#22C55E",
  //   listitem: [
  //     "Academic excellence, postgraduate students",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate & Postgraduate",
  //   ],
  // },
];

const DCUScholarships = [
  {
    title: "DCU International Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "International students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "DCU Merit-Based Scholarships",
    topColor: "#10B981",
    listitem: [
      "Academic excellence",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "DCU Engineering Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "For Engineering students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "DCU Postgraduate Scholarships",
    topColor: "#981dff",
    listitem: [
      "Academic excellence, postgraduate students",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "DCU MBA Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "For MBA applicants",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "DCU Research Scholarships",
    topColor: "#F472B6",
    listitem: [
      "For research-based programs",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  // {
  //   title: "DCU Student Support Scholarships",
  //   topColor: "#22C55E",
  //   listitem: [
  //     "Financial need-based",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const UOLimerickScholarships = [
  {
    title: "UL International Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Non-EU International students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "UL Merit-Based Scholarships",
    topColor: "#10B981",
    listitem: [
      "Academic excellence",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "UL Sports Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Outstanding athletes",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "UL Postgraduate Scholarships",
    topColor: "#981dff",
    listitem: [
      "Academic excellence, postgraduate students",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "UL Research Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "For research-based programs",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "UL STEM Scholarships",
    topColor: "#F472B6",
    listitem: [
      "For STEM students",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  // {
  //   title: "UL Financial Aid Scholarships",
  //   topColor: "#22C55E",
  //   listitem: [
  //     "Financial need-based",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const MUScholarships = [
  {
    title: "Maynooth University International Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Non-EU International students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "Maynooth University Merit-Based Scholarships",
    topColor: "#10B981",
    listitem: [
      "Academic excellence",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "Maynooth University Sports Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Outstanding athletes",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Maynooth University Postgraduate Scholarships",
    topColor: "#981dff",
    listitem: [
      "Academic excellence, postgraduate students",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "Maynooth University Research Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "For research-based programs",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "Maynooth University Financial Aid Scholarships",
    topColor: "#22C55E",
    listitem: [
      "Financial need-based",
      "Varies",
      "Varies",
      "Undergraduate/Postgraduate",
    ],
  },
];

const TUDublinScholarships = [
  {
    title: "TU Dublin International Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "Non-EU international students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "TU Dublin Merit-Based Scholarships",
    topColor: "#10B981",
    listitem: [
      "High academic achievement",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "TU Dublin Sports Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Outstanding athletes",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "TU Dublin Postgraduate Scholarships",
    topColor: "#981dff",
    listitem: [
      "Academic excellence, postgraduate students",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "TU Dublin Research Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "For research-based programs",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "TU Dublin Financial Aid Scholarships",
    topColor: "#22C55E",
    listitem: [
      "Financial need-based",
      "Varies",
      "Varies",
      "Undergraduate/Postgraduate",
    ],
  },
];

const DBSScholarships = [
  {
    title: "Academic Merit Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "Outstanding academic performance",
      "Varies",
      "₹1,00,000 – ₹2,50,000",
      "Undergraduate",
    ],
  },
  {
    title: "International Student Scholarship",
    topColor: "#10B981",
    listitem: [
      "Open to all international applicants",
      "Varies",
      "₹1,50,000 – ₹3,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Need-Based Financial Aid",
    topColor: "#3B82F6",
    listitem: [
      "Demonstrated financial need",
      "Varies",
      "₹50,000 – ₹2,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Excellence in Leadership Award",
    topColor: "#981dff",
    listitem: [
      "Proven leadership in extracurricular activities",
      "Varies",
      "₹2,00,000 – ₹5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Postgraduate Progression Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "High-performing undergraduate degree holders",
      "Varies",
      "₹1,50,000 – ₹3,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Women in STEM Scholarship",
    topColor: "#22C55E",
    listitem: [
      "Female students in STEM fields",
      "Varies",
      "₹2,00,000 – ₹5,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Diversity and Inclusion Scholarship",
    topColor: "#F472B6",
    listitem: [
      "Promoting cultural and social diversity",
      "Varies",
      "₹1,00,000 – ₹2,50,000",
      "Undergraduate/Postgraduate",
    ],
  },
];




const RCSScholarships = [
  {
    title: "Academic Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "Outstanding academic performance",
      "Varies",
      "₹1,00,000 – ₹3,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "International Student Scholarship",
    topColor: "#10B981",
    listitem: [
      "Open to international students",
      "Varies",
      "₹1,50,000 – ₹3,50,000",
      "Undergraduate",
    ],
  },
  {
    title: "Need-Based Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "Financial need-based applicants",
      "Varies",
      "₹50,000 – ₹2,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Leadership Excellence Award",
    topColor: "#981dff",
    listitem: [
      "Leadership in extracurricular activities",
      "Varies",
      "₹2,00,000 – ₹5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Postgraduate Merit Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "High-performing undergraduate degree holders",
      "Varies",
      "₹1,50,000 – ₹3,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Women in STEM Scholarship",
    topColor: "#22C55E",
    listitem: [
      "Female students in STEM-related programs",
      "Varies",
      "₹2,00,000 – ₹5,00,000",
      "Postgraduate",
    ],
  },
];


// SchoolarShip 


// New Zealand

const AucklandScholarships = [
  {
    title: "Auckland International Student Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement, international students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "Auckland University of Technology (AUT) Scholarship",
    topColor: "#10B981",
    listitem: [
      "High academic achievement, international students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "University of Auckland International Student Merit Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "Merit-based scholarship for international students",
      "Varies",
      "Varies",
      "Undergraduate",
    ],
  },
  {
    title: "University of Auckland International Masters Scholarship",
    topColor: "#981dff",
    listitem: [
      "High academic achievement, postgraduate international students",
      "Varies",
      "Varies",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Auckland Doctoral Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "PhD applicants, strong research proposal",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  {
    title: "The Sir Edmund Hillary Scholarship",
    topColor: "#22C55E",
    listitem: [
      "Exceptional students, leadership qualities",
      "Varies",
      "Varies",
      "Postgraduate",
    ],
  },
  // {
  //   title: "International Excellence Scholarship",
  //   topColor: "#E11D48",
  //   listitem: [
  //     "High academic performance and leadership potential",
  //     "Varies",
  //     "Varies",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const MasseyScholarships = [
  {
    title: "Massey University Vice-Chancellor's High Achiever Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "Outstanding academic performance, leadership qualities",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Massey University Undergraduate Scholarship for International Students",
    topColor: "#10B981",
    listitem: [
      "High academic achievement, international student status",
      "Varies",
      "₹2,50,000 - ₹5,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Massey Business School International Student Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "Academic excellence in Business or related fields",
      "Varies",
      "₹3,00,000 - ₹6,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Massey University Doctoral Scholarship",
    topColor: "#981dff",
    listitem: [
      "Strong academic and research potential in a PhD program",
      "Varies",
      "₹10,00,000 - ₹12,00,000 (per year)",
      "Postgraduate (PhD)",
    ],
  },
  {
    title: "College of Humanities and Social Sciences International Excellence Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "Outstanding academic performance in Humanities/Social Sciences",
      "Varies",
      "₹2,50,000 - ₹5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Creative Arts International Excellence Scholarship",
    topColor: "#22C55E",
    listitem: [
      "Academic excellence in Design or Creative Arts programs",
      "Varies",
      "₹3,00,000 - ₹7,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  // {
  //   title: "International Student Excellence Scholarship",
  //   topColor: "#E11D48",
  //   listitem: [
  //     "Broad academic excellence across disciplines",
  //     "Varies",
  //     "₹2,50,000 - ₹5,00,000",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const WaikatoScholarships = [
  {
    title: "Waikato Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement, international student status",
      "Varies",
      "₹2,50,000 - ₹5,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Waikato International Merit Scholarship",
    topColor: "#10B981",
    listitem: [
      "Academic excellence in selected undergraduate programs",
      "Varies",
      "₹3,00,000 - ₹6,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Waikato MBA Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "Excellent academic record, work experience for MBA",
      "Varies",
      "₹5,00,000 - ₹7,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Waikato PhD Scholarship",
    topColor: "#981dff",
    listitem: [
      "Strong academic and research potential, relevant qualifications",
      "Varies",
      "₹8,00,000 - ₹12,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Waikato Creative Arts Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "Outstanding achievement in creative arts programs",
      "Varies",
      "₹3,00,000 - ₹6,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Waikato Postgraduate Scholarship",
    topColor: "#22C55E",
    listitem: [
      "Excellent academic performance, post-graduate program focus",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  // {
  //   title: "Waikato International Student Scholarship",
  //   topColor: "#E11D48",
  //   listitem: [
  //     "Academic excellence, good extracurricular profile",
  //     "Varies",
  //     "₹2,00,000 - ₹4,00,000",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const VictoriaScholarships = [
  {
    title: "Victoria University of Wellington Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement, international student status",
      "Varies",
      "₹3,00,000 - ₹6,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Victoria University of Wellington International Scholarship",
    topColor: "#10B981",
    listitem: [
      "Outstanding academic performance, international student",
      "Varies",
      "₹2,50,000 - ₹5,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Victoria MBA Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "Excellent academic record, work experience for MBA",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Victoria University of Wellington Doctoral Scholarship",
    topColor: "#981dff",
    listitem: [
      "Strong academic and research potential",
      "Varies",
      "₹7,00,000 - ₹10,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Victoria International Research Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "High academic standing in research programs",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Victoria University of Wellington Graduate Scholarship",
    topColor: "#22C55E",
    listitem: [
      "Strong academic performance, relevant undergraduate degree",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  // {
  //   title: "Victoria University of Wellington International Student Scholarship",
  //   topColor: "#E11D48",
  //   listitem: [
  //     "Good academic and extracurricular achievements",
  //     "Varies",
  //     "₹2,50,000 - ₹4,00,000",
  //     "Undergraduate/Postgraduate",
  //   ],
  // },
];

const CanterburyScholarships = [
  {
    title: "UC International First Year Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "International students with excellent academic records",
      "Varies",
      "₹2,50,000 - ₹4,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "UC Master’s Scholarship",
    topColor: "#10B981",
    listitem: [
      "Outstanding academic performance in undergraduate studies",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "UC Doctoral Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "High academic and research potential",
      "Varies",
      "₹6,00,000 - ₹8,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "UC International Student Scholarship",
    topColor: "#981dff",
    listitem: [
      "International students with strong academic background",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
  {
    title: "UC Māori and Pacific Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "Māori or Pacific students with excellent academic achievement",
      "Varies",
      "₹2,50,000 - ₹4,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
  {
    title: "UC Excellence Scholarship",
    topColor: "#22C55E",
    listitem: [
      "Excellent academic records, involvement in extracurriculars",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
];

const OtagoScholarships = [
  {
    title: "University of Otago International Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement and international student status",
      "Varies",
      "₹3,00,000 - ₹6,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "University of Otago International Master’s Research Scholarship",
    topColor: "#10B981",
    listitem: [
      "Strong academic performance, research proposal required",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "University of Otago Graduate Research Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "Exceptional academic and research potential",
      "Varies",
      "₹5,00,000 - ₹8,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "University of Otago International Undergraduate Scholarship",
    topColor: "#981dff",
    listitem: [
      "Excellent academic record and extracurricular involvement",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "University of Otago Māori and Pacific Island Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "Māori or Pacific Island students with strong academic performance",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
  {
    title: "University of Otago PhD Scholarships",
    topColor: "#22C55E",
    listitem: [
      "Excellent academic records, research focus",
      "Varies",
      "₹7,00,000 - ₹10,00,000",
      "Postgraduate",
    ],
  },
];

const AUTScholarships = [
  {
    title: "AUT International Excellence Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "High academic achievement and international student status",
      "Varies",
      "₹2,00,000 - ₹5,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "AUT Māori and Pacific Island Scholarships",
    topColor: "#10B981",
    listitem: [
      "Māori or Pacific Island descent with strong academic record",
      "Varies",
      "₹2,50,000 - ₹4,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "AUT Postgraduate Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "Strong academic background, apply for postgraduate studies",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "AUT Business School Scholarships",
    topColor: "#981dff",
    listitem: [
      "Excellent academic performance in business-related subjects",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "AUT Postgraduate Research Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "Research-focused postgraduate students with high academic potential",
      "Varies",
      "₹5,00,000 - ₹7,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "AUT Indian International Scholarship",
    topColor: "#22C55E",
    listitem: [
      "Indian students with strong academic credentials",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
];

const LincolnScholarships = [
  {
    title: "Lincoln University International Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "International students with strong academic records",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Lincoln University Māori Scholarships",
    topColor: "#10B981",
    listitem: [
      "Māori students with good academic performance",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Lincoln University Postgraduate Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "For high-performing postgraduate students",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Lincoln University Study Abroad Scholarships",
    topColor: "#981dff",
    listitem: [
      "Open to all students wishing to study abroad",
      "Varies",
      "₹2,00,000 - ₹3,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Lincoln University Research Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "For students undertaking research-focused programs",
      "Varies",
      "₹5,00,000 - ₹7,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Lincoln University Excellence Scholarship",
    topColor: "#22C55E",
    listitem: [
      "For international students with excellent academic records",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
];

const EITScholarships = [
  {
    title: "International Student Scholarship",
    topColor: "#F59E0B",
    listitem: [
      "International students with high academic performance",
      "Varies",
      "₹3,00,000 - ₹5,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "EIT Maori Scholarships",
    topColor: "#10B981",
    listitem: [
      "Māori students with strong academic records",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Postgraduate Research Scholarship",
    topColor: "#3B82F6",
    listitem: [
      "Postgraduate students undertaking research programs",
      "Varies",
      "₹4,00,000 - ₹6,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Study Abroad Scholarships",
    topColor: "#981dff",
    listitem: [
      "Available to students wishing to study abroad",
      "Varies",
      "₹2,00,000 - ₹3,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Postgraduate Excellence Scholarship",
    topColor: "#9B5DE5",
    listitem: [
      "For high-achieving postgraduate students",
      "Varies",
      "₹5,00,000 - ₹7,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "EIT Merit Scholarship",
    topColor: "#22C55E",
    listitem: [
      "For exceptional students based on academic achievements",
      "Varies",
      "₹2,00,000 - ₹4,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
];

const UCScholarships = [
  {
    title: "UC International College Scholarships",
    topColor: "#F59E0B",
    listitem: [
      "International students with high academic performance",
      "Varies",
      "₹2,00,000 - ₹5,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Excellence Scholarship",
    topColor: "#10B981",
    listitem: [
      "High-achieving students based on academic performance",
      "Varies",
      "₹3,00,000 - ₹6,00,000",
      "Undergraduate",
    ],
  },
  {
    title: "Māori & Pacific Scholarships",
    topColor: "#3B82F6",
    listitem: [
      "Māori and Pacific students with academic merit",
      "Varies",
      "₹1,50,000 - ₹3,50,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Postgraduate Research Scholarships",
    topColor: "#981dff",
    listitem: [
      "Available to postgraduate research students",
      "Varies",
      "₹4,00,000 - ₹8,00,000",
      "Postgraduate",
    ],
  },
  {
    title: "Equity Scholarships",
    topColor: "#9B5DE5",
    listitem: [
      "Students who are experiencing financial hardship",
      "Varies",
      "₹1,00,000 - ₹2,00,000",
      "Undergraduate & Postgraduate",
    ],
  },
  {
    title: "Study Abroad Scholarships",
    topColor: "#22C55E",
    listitem: [
      "For students wishing to study abroad",
      "Varies",
      "₹1,50,000 - ₹4,00,000",
      "Undergraduate/Postgraduate",
    ],
  },
];










export {
  MelbourneScholarships,
  CaliforniaScholarships,
  WisconsinScholarships,
  YeshivaScholarships,
  UtahScholarships,
  ColoradoScholarships,
  IllinoisScholarships,
  StanfordScholarships,
  PennsylvaniaScholarships,
  ChicagoScholarships,
  MITScholarships,

  BristolScholarships,
  LeedsScholarships,
  GlasgowScholarships,
  BirminghamScholarships,
  ImperialScholarships,
  QueenMaryScholarships,
  LiverpoolScholarships,
  EdinburghScholarships,
  SouthamptonScholarships,
  DurhamScholarships,

  ANUScholarships,
  UNSWScholarships,
  UTSScholarships,
  MonashScholarships,
  MacquarieScholarships,
  TrentScholarships,
  TrinityScholarships,
  RoyalScholarships,
  WaterlooScholarships,
  CalgeryScholarships,
  DalhousieScholarships,
  BrockScholarships,
  WindsorScholarships,
  VCCScholarships,
  TorontoScholarships,
  QueenslandScholarships,
  UWAScholarships,
  AdelaideScholarships,
  SydneyScholarships,

  //Germany

  LMUScholarships,
  TUMScholarships,
  HeidelbergUniversityScholarships,
  HumboldtUniversityScholarships,
  FUBScholarships,
  RWTHScholarships,
  UOFScholarships,
  UOTScholarships,
  UOMScholarships,
  UOGScholarships,

  //France

  UPSLScholarships,
  UPSScholarships,
  SUScholarships,
  UGAScholarship,
  UDSScholarships,
  UDMScholarships,
  UDLScholarships,
  UDNScholarships,
  UDBScholarships,
  UPPSScholarships,

  //Italy

  PDMilanoScholarships,
  UOMilanScholarships,
  UOBolognaScholarships,
  UOFlorenceScholarships,
  UOPaduaScholarships,
  UOTurinScholarships,
  RLSScholarships,
  UONaplesScholarships,
  UOPisaScholarships,
  IEDScholarships,

  //Ireland

  TCDublinScholarships,
  UCDublinScholarships,
  UOCorkScholarships,
  UOGalwayScholarships,
  DCUScholarships,
  UOLimerickScholarships,
  MUScholarships,
  TUDublinScholarships,
  DBSScholarships,
  RCSScholarships,

  // NewZealand
  AucklandScholarships,
  MasseyScholarships,
  WaikatoScholarships,
  VictoriaScholarships,
  CanterburyScholarships,
  OtagoScholarships,
  AUTScholarships,
  LincolnScholarships,
  EITScholarships,
  UCScholarships


};


