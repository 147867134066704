import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, Grid, Typography } from "@mui/material";

const CalendarComponent = ({
  events,
  fetchData,
  onDateClick,
  onEventClick
}) => {
  const handleDateChange = (dateInfo) => {
    // Get the first date of the current visible month (ignores padding from other months)
    const firstDateOfMonth = dateInfo.view.currentStart;

    const year = firstDateOfMonth.getFullYear();
    const month = firstDateOfMonth.getMonth() + 1; // Adding +1 since getMonth() is zero-indexed

    // console.log(`Fetching data for year: ${year}, month: ${month}`);
    fetchData(year, month); // Fetch data with the correct year and month
  };

  return (
    <Box
      sx={{
        p: { lg: "20px", sm: "5px", xs: "0px" },
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { md: "row", xs: "column" },
        backgroundColor: "#effdff",
        borderRadius: "10px",
        border: "2px solid #00999E",
        margin: "auto"
      }}
    >
      {/* Calendar Section */}
      <Box
        sx={{
          bgcolor: "#fff",
          width: { lg: "80%", md: "70%", sm: "95%", xs: "100%" },
          m: { sm: "0px 20px", xs: "0px" },
          padding: { sm: "20px", xs: "0px" },
          mb: { xs: "20px", md: "0px" },
          borderRadius: "20px",
          boxShadow: 3,
          border: "2px solid #c1c1c1"
        }}
      >
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          dateClick={onDateClick}
          eventClick={onEventClick}
          eventContent={renderEventContent}
          headerToolbar={{
            left: "title",
            right: "prev,next" // The navigation buttons for changing months
          }}
          height="auto"
          contentHeight="auto"
          dayCellClassNames="custom-day-cell"
          datesSet={handleDateChange} // Listen for date changes including navigation
        />
      </Box>

      <Box
        sx={{
          bgcolor: "#fff",
          width: { lg: "20%", md: "30%", sm: "100%" },
          padding: "20px",
          height: "300px",
          borderRadius: "20px",
          boxShadow: 3,
          border: "2px solid #c1c1c1"
        }}
      >
        <Legend />
      </Box>
    </Box>
  );
};

// Custom rendering for events in the calendar
function renderEventContent(eventInfo) {
  const eventTypeToColor = {
    class: "#FFC107", // yellow
    mock: "#E91E63", // pink
    attended: "#2196F3", // blue
    notAttended: "#9E9E9E" // gray
  };

  const event = eventInfo.event;
  const color = eventTypeToColor[event.extendedProps.type];

  return (
    <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
      <span
        style={{
          backgroundColor: color,
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          marginRight: "4px"
        }}
      ></span>
      <span
        style={{
          fontSize: "12px",
          color: "#000",
          display: { md: "block", xs: "none" }
        }}
      >
        {event.title}
      </span>
    </div>
  );
}

// Legend Component
const Legend = () => (
  <Box
    sx={{
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      p: 2,
      borderRadius: "20px"
    }}
  >
    <Grid container spacing={2}>
      <LegendItem color="#FFC107" label="Class" />
      <LegendItem color="#E91E63" label="Mock Test" />
      <LegendItem color="#2196F3" label="Attended Class" />
      <LegendItem color="#9E9E9E" label="Not Attended" />
    </Grid>
  </Box>
);

// Reusable Legend Item component
const LegendItem = ({ color, label }) => (
  <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
    <Box
      sx={{
        border: "2px solid ",
        borderColor: color,
        borderRadius: "50%",
        height: "22px",
        padding: "2px",
        mr: "10px",
        width: "22px"
      }}
    >
      <Box
        sx={{
          bgcolor: color,
          width: "15px",
          height: "15px",
          mr: 2,
          borderRadius: "50%"
        }}
      />
    </Box>
    <Typography>{label}</Typography>
  </Grid>
);

const styles = `
  .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;
    align-items: center;   
    justify-content: center;
    padding: 0px 0;
    backgroundColor: white;
    border: 1px solid #E0E0E0;
    border-radius: 10px;
    box-sizing: border-box;
  }
  .fc-day-today {
    background-color: #008080 !important;
    border-radius: 10px;
    color: white;
  }
    .fc-theme-standard td, .fc-theme-standard th {
    border: none;
 border:none; 
}
  .fc .fc-daygrid-day-number {
    font-weight: 500;
    
    font-size: 14px;
    margin: 0;
    line-height: 1.5;
  }
  .fc-day:hover {
    background-color: #00999E;
       border-radius: 10px;
    color: white;
  }
  .fc-event {
    background: transparent !important;
    border: none !important;
    margin: 0 !important;
  }
  .fc-prev-button, .fc-next-button {
    background-color: transparent !important;
    border: none !important;
    color: black !important;
    font-size: 18px;
  }
  .fc-prev-button:hover, .fc-next-button:hover {
    background-color: transparent !important;
    color: #00999E !important;
  }
.fc-toolbar-title {
  font-weight: 500 !important; 
  font-size: 20px !important;  
  color: #333; 
}.fc-theme-standard .fc-scrollgrid {
    border: 1px solid #ddd;
    border: 1px solid var(--fc-border-color, #c1c1c1);
    border-radius: 10px;
    padding-top:10px;
}
`;

const CalendarPage = ({ events, fetchData, onDateClick, onEventClick }) => (
  <>
    <style>{styles}</style>
    <CalendarComponent
      events={events}
      fetchData={fetchData}
      onDateClick={onDateClick}
      onEventClick={onEventClick}
    />
  </>
);

export default CalendarPage;
