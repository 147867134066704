import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import bannerimg1 from "../../../../asset/TestPrepDashboard/homebanner1.png";
import bannerimg2 from "../../../../asset/TestPrepDashboard/homebanner2.png";
import bannericon from "../../../../asset/TestPrepDashboard/bannericon.png";
import { useNavigate } from "react-router";

const FacultyConnectBanner = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        m: "20px",
        display: "flex",
        width: "fullWidth",
        backgroundColor: "center",
        background: "linear-gradient(to right, #D9FBFE, #D9FBFE)",
        border: "2px solid #00999E",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
        flexDirection: "column"
      }}
    >
      <Box
        sx={{
          mb: "20px",
          border: "2px solid white",
          padding: "10px 30px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: { xs: "320px", sm: "500px" },
          borderRadius: "30px",
          background: "linear-gradient(to right, #dff6fb, #dae8ee)"
        }}
      >
        <img src={bannericon} alt="" />
        <Typography
          sx={{ padding: "0 15px", fontSize: "24px", color: "#4d4d4d" }}
        >
          Connect With Our Faculty
        </Typography>
      </Box>
      <Typography
        sx={{
          padding: "0 15px",
          fontSize: "24px",
          color: "#4d4d4d",
          textAlign: "center",
          mb: "20px"
        }}
      >
        <Typography
          sx={{
            padding: "0 15px",
            fontSize: "28px",
            fontWeight: "500",
            color: "#000"
          }}
        >
          Schedule Your Initial Orientation & Doubt Clearance <br />
          sessions with our faculty
        </Typography>
      </Typography>
      <Grid container>
        <Grid
          Item
          md={4}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            <img src={bannerimg1} alt={""} />
          </Box>
        </Grid>
        <Grid
          Item
          md={4}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <ButtonComponent
            text={"SCHEDULE"}
            onClick={() => {
              navigate("/dashboard/scheduleForm");
            }}
            fontWeight={"550"}
          />
        </Grid>
        <Grid
          Item
          md={4}
          xs={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Box>
            <img src={bannerimg2} alt={""} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FacultyConnectBanner;
