import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import learning from "../../asset/TestPrepDashboard/learning.svg";
import MainHeader from "src/Component/content-component/MainHeader";
import { Icon } from "@iconify/react";
import axios from "axios";

const LearningResources = () => {
  const [bookletsData, setBookletsData] = useState([]);
  const token = sessionStorage.getItem("token");
  const planId = sessionStorage.getItem("courseId");

  useEffect(() => {
    const fetchLearningResources = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}learningResource/userLearingResource`,
          {
            headers: {
              Authorization: token,
              "Content-Type": "application/json"
            },
            params: {
              planId: planId // Correctly passing planId as a query parameter
            }
          }
        );

        if (response.status === 200 && response.data.success) {
          setBookletsData(response.data.data); // Extract the data array from response
        } else {
          console.error("Failed to fetch learning resources");
        }
      } catch (error) {
        console.error("Error fetching learning resources:", error);
      }
    };

    fetchLearningResources();
  }, [planId, token]);
  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "row", mb: "30px" }}>
        <img src={learning} alt="" style={{ fontSize: "48px" }} />
        <Box sx={{ pl: "20px" }}>
          <MainHeader
            content={"Learning Resources"}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>
      <Grid container spacing={3}>
        {bookletsData?.map((item, index) => (
          <Grid
            item
            md={4}
            xs={12}
            key={item._id}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                width: "340px",
                border: "1px solid #00999E",
                borderRadius: "20px",
                padding: "10px"
              }}
            >
              <Typography
                sx={{ color: "#00999E", fontWeight: "500", mb: "8px" }}
              >
                {new Date(item.date).toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric"
                })}
              </Typography>
              <MainHeader
                content={item.name}
                color={"#1a1a1a"}
                fontWeight={"550"}
                fontSize={"20px"}
              />
              {item?.pdf?.map((pdfItem, i) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    m: "10px 0"
                  }}
                  key={i}
                >
                  <Icon icon="ri:booklet-fill" style={{ color: "#667085" }} />
                  <Typography
                    sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                  >
                    {pdfItem.name}
                  </Typography>
                  <Box
                    sx={{
                      height: "25px",
                      ml: "auto",
                      border: "1px solid #667085",
                      borderRadius: "5px",
                      padding: "0 10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      cursor: "pointer"
                    }}
                    onClick={() => window.open(pdfItem.pdf, "_blank")}
                  >
                    <Icon
                      icon={"carbon:view-filled"}
                      style={{ color: "#667085" }}
                    />
                    <Typography
                      sx={{ color: "#667085", fontWeight: "500", pl: "10px" }}
                    >
                      View
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default LearningResources;
