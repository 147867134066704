import React, { useState } from "react";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery
} from "@mui/material";
import Navbar from "src/Component/Navbar";
import UniversityFinderBanner from "./components/UniversityFinderBanner";
import LetsGetStarted from "src/Component/LetsGetStarted";
import Footer from "src/Component/Footer";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Icon } from "@iconify/react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import { useLocation, useNavigate } from "react-router-dom";
import UniversityItem from "./components/universityItem";
import axios from "axios";
import FiltersComponent from "./components/FiltersComponent";
import MainHeader from "src/Component/content-component/MainHeader";
import BackRouteContainer from "src/Component/grid-component/BackButtonComponent";
import logo from "./../asset/backuniversity.png";

const UniversityView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [universityData, setUniversityData] = useState(
    location.state?.data || []
  );
  const [filterData, setFilterData] = useState(location.state?.data2 || {});
  const [showFilters, setShowFilters] = useState(false); // For toggling filter visibility on small screens
  const [shortlisted, setShortlisted] = useState([]);
  const [compared, setCompared] = useState([]);
  const [allShortlisted, setAllShortlisted] = useState(false);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleFilterChange = (newUniversityData, newFilterData) => {
    setUniversityData(newUniversityData);
    setFilterData(newFilterData);
    window.scrollTo({
      top: 0,
      behavior: "smooth" // Optional: This gives a smooth scrolling effect
    });
    // Update the location state as well if needed
    navigate("/study-abroad/universities/view", {
      state: { data: newUniversityData, data2: newFilterData }
    });
  };

  const handleShortlist = (id) => {
    setShortlisted((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleShortlistAll = () => {
    if (allShortlisted) {
      // Deselect all
      setShortlisted([]);
    } else {
      // Select all
      setShortlisted(universityData.map((university) => university._id));
    }
    setAllShortlisted(!allShortlisted);
  };

  const handleDownload = (id) => {
    const url = `${process.env.REACT_APP_BASE_URL}university/csv?ids=${id}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create a link element and use it to trigger the download
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "university.csv"; // Set a default file name
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => console.error("Error downloading the file:", error));
  };

  const handleDownloadall = () => {
    if (shortlisted.length === 0) {
      alert("No universities shortlisted for download.");
      return;
    }

    // Construct the query string with shortlisted IDs
    const ids = shortlisted.join(",");
    const url = `${process.env.REACT_APP_BASE_URL}university/csv?ids=${ids}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create a link element and use it to trigger the download
        const downloadUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "shortlisted_universities.csv"; // Set a default file name
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => console.error("Error downloading the file:", error));
  };

  const handleCompare = (name) => {
    setCompared((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name]
    );
  };

  const handleCompareButtonClick = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}university/compareUniveristy`,
        {
          ids: compared // Pass the compared university IDs in the request
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      );

      if (response.status === 200) {
        // Navigate to comparison page and pass the university IDs as URL parameters
        const ids = compared.join(",");
        navigate(`/study-abroad/universities/view/Compare?ids=${ids}`);
      } else {
        alert("Failed to compare universities. Please try again.");
      }
    } catch (error) {
      console.error("Error comparing universities:", error);
      alert(
        "An error occurred while comparing universities. Please try again."
      );
    }
  };

  return (
    <Box>
      <Navbar />
      <UniversityFinderBanner />
      <Box
        sx={{
          height: "80px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          bgcolor: "#00999E"
        }}
      >
        <Container>
          <Typography
            sx={{
              fontSize: { xs: "18px" },
              p: "10px 0",
              fontWeight: "550",
              color: "#fff"
            }}
          >
            {universityData.length} University Found
          </Typography>
        </Container>
      </Box>

      <BackRouteContainer
        logo={logo}
        path={"/study-abroad"}
        title={"Study Abroad Page"}
      />

      {compared.length > 0 && (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            width: "100%",
            maxWidth: "600px",
            p: 2
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
              bgcolor: "#effdff",
              borderRadius: "20px",
              boxShadow: 2,
              border: "2px solid #00999E"
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                pr: "20px",
                fontWeight: 500,
                marginRight: 2
              }}
            >
              {compared.length}{" "}
              {compared.length > 1 ? "universities" : "university"} added to
              compare
            </Typography>
            <ButtonComponent
              text={"Compare"}
              fontWeight={"550"}
              onClick={handleCompareButtonClick}
            />
          </Box>
        </Box>
      )}

      <Container>
        <Box sx={{ display: "flex", justifyContent: "end", mt: "20px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allShortlisted}
                onChange={handleShortlistAll}
                icon={<RadioButtonUncheckedIcon />}
                checkedIcon={
                  <Icon
                    icon="lets-icons:check-fill"
                    style={{ color: "#00999E", fontSize: "24px" }}
                  />
                }
              />
            }
            label="Shortlist All"
          />
          <ButtonComponent
            text={"Download"}
            onClick={handleDownloadall}
            backgroundColor={!allShortlisted ? "#c1c1c1" : "#00999E"}
            disabled={!allShortlisted}
          />
        </Box>

        <Grid container spacing={2}>
          {/* Filter Section */}
          <Grid item xs={12} md={4}>
            {isSmallScreen ? (
              <Box
                sx={{
                  mt: "10px",
                  paddingLeft: "50px"
                }}
              >
                <ButtonComponent
                  text={showFilters ? "Hide Filters" : "Show Filters"}
                  onClick={() => setShowFilters((prev) => !prev)}
                />
              </Box>
            ) : (
              <FiltersComponent
                filterData={filterData}
                onFilterChange={handleFilterChange}
              />
            )}
            {isSmallScreen && showFilters && (
              <FiltersComponent
                filterData={filterData}
                onFilterChange={handleFilterChange}
              />
            )}
          </Grid>

          {/* University List Section */}
          <Grid item xs={12} md={8}>
            <Box sx={{ margin: "20px 0" }}>
              {universityData.length === 0 ? (
                <Box sx={{ margin: "40px 10px" }}>
                  <MainHeader
                    textAlign={"center"}
                    content={
                      "There are no universities present for the selected filters."
                    }
                  />
                </Box>
              ) : (
                universityData.map((university) => (
                  <UniversityItem
                    key={university._id}
                    university={university}
                    onCompare={() => handleCompare(university._id)}
                    isCompared={compared.includes(university._id)}
                    onShortlist={() => handleShortlist(university._id)}
                    onDownload={() => handleDownload(university._id)}
                    isShortlisted={shortlisted.includes(university._id)}
                  />
                ))
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <BackRouteContainer
        logo={logo}
        path={"/study-abroad"}
        title={"Study Abroad Page"}
      />

      <LetsGetStarted marginTop={"50px"} />
      <Footer />
    </Box>
  );
};

export default UniversityView;
