import {
  Box,
  Typography,
  IconButton,
  List,
  FormControlLabel,
  Checkbox,
  CardContent,
  Card,
  CardHeader,
  Avatar,
  CircularProgress,
  TextareaAutosize,
  Grid
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import MainHeader from "src/Component/content-component/MainHeader";
import { useParams } from "react-router";

const AnswerTable = () => {
  const [data, setData] = useState(null); // For storing API data
  const [loading, setLoading] = useState(true);
  const [testType, setTestType] = useState(null);

  const { id, type } = useParams();
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}test/user-checked-answer`,
          {
            method: "POST",
            headers: {
              Authorization: token,
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              testId: id,
              type: type
            })
          }
        );
        const result = await response.json();
        setData(result);
        setTestType(result.type); // Set the API result into state
        setLoading(false); // Set loading to false after data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Even in error, stop loading
      }
    };

    fetchData();
  }, [id, token, type]);

  if (loading) {
    return <CircularProgress />; // Show loading spinner while data is being fetched
  }

  if (!data) {
    return <Typography>No data available</Typography>; // Handle case when no data is fetched
  }
  const questionData =
    data.type === "reading"
      ? data.reading.data
      : data.type === "writing"
      ? data.writing.data
      : data.type === "listening"
      ? data.listening.data
      : data.type === "aptitude"
      ? data.aptitude.data
      : data.type === "speaking"
      ? data.speaking.data
      : data.reading.data;

  const totalCorrect =
    data.type === "reading"
      ? data.reading.totalCorrect
      : data.type === "writing"
      ? data.writing.totalCorrect
      : data.type === "listening"
      ? data.listening.totalCorrect
      : data.type === "aptitude"
      ? data.aptitude.totalCorrect
      : data.type === "speaking"
      ? data.speaking.totalCorrect
      : 0;

  const totalIncorrect =
    data.type === "reading"
      ? data.reading.totalIncorrect
      : data.type === "writing"
      ? data.writing.totalIncorrect
      : data.type === "listening"
      ? data.listening.totalIncorrect
      : data.type === "aptitude"
      ? data.aptitude.totalIncorrect
      : data.type === "speaking"
      ? data.speaking.totalIncorrect
      : 0;

  const obtainMarks =
    data.type === "reading"
      ? data.reading.obtainMarks
      : data.type === "writing"
      ? data.writing.obtainMarks
      : data.type === "listening"
      ? data.listening.obtainMarks
      : data.type === "aptitude"
      ? data.aptitude.obtainMarks
      : data.type === "speaking"
      ? data.speaking.obtainMarks
      : 0;

  const totalMarks =
    data.type === "reading"
      ? data.reading.totalMarks
      : data.type === "writing"
      ? data.writing.totalMarks
      : data.type === "listening"
      ? data.listening.totalMarks
      : data.type === "aptitude"
      ? data.aptitude.totalMarks
      : data.type === "speaking"
      ? data.speaking.totalMarks
      : 0;
  const totalUnanswered =
    data.type === "reading"
      ? data.reading.totalUnanswered
      : data.type === "writing"
      ? data.writing.totalUnanswered
      : data.type === "listening"
      ? data.listening.totalUnanswered
      : data.type === "aptitude"
      ? data.aptitude.totalUnanswered
      : data.type === "speaking"
      ? data.speaking.totalUnanswered
      : 0;

  const checkerName =
    data.type === "reading"
      ? data.reading.checkerName
      : data.type === "writing"
      ? data.writing.checkerName
      : data.type === "listening"
      ? data.listening.checkerName
      : data.type === "aptitude"
      ? data.aptitude.checkerName
      : data.type === "speaking"
      ? data.speaking.checkerName
      : "";

  const facultyFeedback =
    data.type === "reading"
      ? data.reading.facultyFeedback
      : data.type === "writing"
      ? data.writing.facultyFeedback
      : data.type === "listening"
      ? data.listening.facultyFeedback
      : data.type === "aptitude"
      ? data.aptitude.facultyFeedback
      : data.type === "speaking"
      ? data.speaking.facultyFeedback
      : "";

  return (
    <Box>
      {testType !== "writing" && (
        <List
          sx={{
            overflowY: "auto",
            padding: 0,
            width: "100%"
          }}
        >
          {questionData?.map((item, index) => (
            <Box
              key={index}
              sx={{
                border: "1px solid #80869A",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: index % 2 === 0 ? "#e8f5fe" : "#FFFFFF",
                padding: "10px",
                borderRadius: "5px"
              }}
            >
              {/* Display question number */}
              <Box>
                <Typography variant="body2">
                  Question {item.questionNumber}: ({item.questionType})
                </Typography>

                {/* Conditionally render checkboxes or typography based on question type */}
                {item.questionType === "text field" ? (
                  <Typography>{item.answer[0]}</Typography>
                ) : (
                  ["A", "B", "C", "D", "E", "F"].map((option) => (
                    <FormControlLabel
                      key={option}
                      control={
                        <Checkbox
                          checked={item.answer.includes(option)}
                          sx={{ color: "#00999E" }}
                          checkedIcon={
                            <Icon
                              icon="material-symbols:check-box"
                              style={{ color: "#00999E", fontSize: "24px" }}
                            />
                          }
                        />
                      }
                      label={option}
                    />
                  ))
                )}
              </Box>

              {/* Status Buttons */}
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{
                  backgroundColor: index % 2 === 0 ? "#e8f5fe" : "#FFFFFF",
                  flexDirection: { xs: "column", sm: "row" },
                  padding: { xs: 2, sm: 0 }
                }}
              >
                {["pending", "rejected", "approved"].map((status) => (
                  <IconButton
                    key={status}
                    sx={{
                      backgroundColor:
                        item.correct && status === "approved"
                          ? "#009A3D"
                          : item.incorrect && status === "rejected"
                          ? "#AD0000"
                          : !item.correct &&
                            !item.incorrect &&
                            status === "pending"
                          ? "#F9C45B"
                          : index % 2 === 0
                          ? "#e8f5fe"
                          : "#FFFFFF",
                      border: `1px solid ${
                        item.correct && status === "approved"
                          ? "JadeGreen"
                          : item.incorrect && status === "rejected"
                          ? "DarkRed"
                          : !item.correct &&
                            !item.incorrect &&
                            status === "pending"
                          ? "Goldenrod"
                          : "black"
                      }`,
                      borderRadius: "8px",
                      width: "33px",
                      height: "33px"
                    }}
                  >
                    <Icon
                      icon={
                        status === "pending"
                          ? "carbon:radio-button"
                          : status === "rejected"
                          ? "maki:cross"
                          : "mingcute:check-2-line"
                      }
                      style={{
                        color:
                          (item.correct && status === "approved") ||
                          (item.incorrect && status === "rejected") ||
                          (!item.correct &&
                            !item.incorrect &&
                            status === "pending")
                            ? "#FFFFFF"
                            : "#000000",
                        fontSize: "18px"
                      }}
                    />
                  </IconButton>
                ))}
              </Box>
            </Box>
          ))}
        </List>
      )}
      {testType === "writing" && (
        <Grid container spacing={2}>
          {questionData?.map((item, index) => (
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  border: "2px solid #666666",
                  padding: "20px",
                  minHeight: "500px"
                }}
              >
                <Typography sx={{ color: "#00999E", mb: "10px" }}>
                  Passage {item.passageNumber}
                </Typography>
                <TextareaAutosize
                  minRows={10}
                  disabled
                  style={{
                    width: "100%",
                    border: "none",
                    outline: "none",
                    resize: "none",
                    fontFamily: "monospace",
                    lineHeight: "2rem",
                    overflowY: "auto",
                    background: `repeating-linear-gradient(white, white 30px, #ccc 31px, white 32px)`
                  }}
                  placeholder={`Start typing here... (Max ${100} words)`}
                  value={item.answer || ""} // Show the answer here
                />
              </Box>
            </Grid>
          ))}{" "}
        </Grid>
      )}

      {/* Overall Score */}

      <Box sx={{ padding: "10px", mt: "20px" }}>
        <MainHeader
          content="Overall Marks Obtained"
          fontWeight="550"
          fontSize="28px"
        />
        <Box
          display="flex"
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{
            mt: "10px",

            border: "1px solid #666660",
            padding: "20px",
            borderRadius: "8px",
            "@media (max-width: 600px)": {
              flexDirection: "column",
              alignItems: "stretch"
            }
          }}
        >
          {testType !== "writing" && (
            <ScoreCard
              title="Total Correct"
              score={`${totalCorrect} Points`}
              color="#00ACC1"
            />
          )}
          {testType !== "writing" && (
            <ScoreCard
              title="Unanswered"
              score={`${totalUnanswered} Points`}
              color="#FFB300"
            />
          )}

          {testType !== "writing" && (
            <ScoreCard
              title="Incorrect"
              score={`${totalIncorrect} Points`}
              color="#E53935"
            />
          )}

          <ScoreCard
            title="Total Score Marks"
            score={`${obtainMarks}/${totalMarks} Points`}
            color="#000"
          />
        </Box>
      </Box>

      {/* Faculty Feedback */}
      <Box sx={{ padding: "10px", mt: "20px" }}>
        <MainHeader
          content="Faculty Feedback"
          fontWeight="550"
          fontSize="28px"
        />
        <Box
          sx={{
            mt: "10px",
            border: "1px solid #666660",
            padding: "10px",
            borderRadius: "8px"
          }}
        >
          <Card sx={{}} elevation={0}>
            <CardHeader
              avatar={<Avatar sx={{ bgcolor: "#00999E" }}>T</Avatar>}
              title={checkerName}
            />
            <CardContent>
              <Typography variant="body2" color="#404040">
                {facultyFeedback}
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
};

export default AnswerTable;

const ScoreCard = ({ title, score, color }) => (
  <Box>
    <MainHeader
      content={title}
      fontWeight="550"
      fontSize="15px"
      color="#545454"
      textAlign="center"
    />
    <Card
      variant="outlined"
      sx={{
        paddingTop: "10px",
        borderColor: color,
        borderWidth: 2,
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <CardContent>
        <Typography variant="h7" sx={{ color }}>
          {score}
        </Typography>
      </CardContent>
    </Card>
  </Box>
);
