// import { Box, Card, CardActions, CardMedia, Typography } from "@mui/material";
// import React, { useState } from "react";
// import ButtonComponent from "../content-component/ButtonComponent";
// import { Icon } from "@iconify/react";

// const NewsCard = ({ data, imageheight, imagewidth }) => {
//   const [expanded, setExpanded] = useState(false);

//   // const handleReadMoreClick = () => {
//   //   if (data?.url) {
//   //     window.open(data.url, "_blank");
//   //   } else {
//   //     setExpanded(true);
//   //   }
//   // };


//   const handleReadMoreClick = () => {
//     if (data?.url) {
//       window.open(data.url, "_blank");
//     } else if (data?.description) {
//       setOpenModal(true);
//     } else {
//       setExpanded(true);
//     }
//   };

//   const handleShowLessClick = () => {
//     setExpanded(false);
//   };

//   return (
//     <Card
//       sx={{
//         width: { xs: "320px", md: "360px" },
//         padding: "0 !important",
//         height: expanded ? "auto" : "400px",

//         marginBottom: "25px"
//       }}
//     >
//       <Box>
//         <CardMedia
//           component="img"
//           width={imagewidth ?? "100%"}
//           height={imageheight ?? "200px"}
//           image={data.image}
//           alt="news image"
//         />
//         <Box
//           sx={{
//             padding: "15px",
//             marginTop: "7px"
//           }}
//         >
//           <Typography
//             variant="caption"
//             sx={{
//               color: "#00999E",
//               fontSize: "13px",
//               fontWeight: "500",
//               textAlign: "left"
//             }}
//           >
//             {data.date}
//           </Typography>
//           <Typography
//             variant="h5"
//             sx={{
//               fontSize: "16px",
//               textAlign: "justify",
//               fontWeight: "600",
//               marginBottom: "10px"
//             }}
//           >
//             {data.title}
//           </Typography>
//           <Typography
//             variant="body2"
//             sx={{
//               fontSize: "13px",
//               margin: "5px 0",
//               textAlign: "justify"
//             }}
//           >
//             {data.subtitle}
//           </Typography>
//           {!expanded && (
//             <ButtonComponent
//               fontSize="14px"
//               padding="10px 20px"
//               onClick={handleReadMoreClick}
//               icon={<Icon icon="fluent:arrow-right-28-regular" />}
//               text="Read More"
//             />
//           )}
//           {expanded && !data.url && (
//             <Typography
//               variant="body2"
//               sx={{
//                 fontSize: "13px",
//                 margin: "5px 0",
//                 textAlign: "justify"
//               }}
//             >
//               {data.content}
//             </Typography>
//           )}
//         </Box>
//       </Box>

//       <CardActions>
//         <Box sx={{ padding: "10px" }}>
//           {expanded && !data.url && (
//             <ButtonComponent
//               fontSize="14px"
//               padding="0px 20px"
//               onClick={handleShowLessClick}
//               icon={<Icon icon="fluent:arrow-right-28-regular" />}
//               text="Show Less"
//             />
//           )}
//         </Box>
//       </CardActions>

//        {/* Modal for Description */}
//        <Modal
//         open={openModal}
//         onClose={handleCloseModal}
//         aria-labelledby="news-description-modal"
//         aria-describedby="news-description-details"
//       >
//         <Box
//           sx={{
//             position: "absolute",
//             top: "50%",
//             left: "50%",
//             transform: "translate(-50%, -50%)",
//             width: { xs: 300, md: 400 },
//             bgcolor: "background.paper",
//             boxShadow: 24,
//             p: 4,
//             borderRadius: 2,
//           }}
//         >
//           <Typography
//             id="news-description-modal"
//             variant="h6"
//             component="h2"
//             sx={{ mb: 2 }}
//           >
//             {data.title}
//           </Typography>
//           <Typography
//             id="news-description-details"
//             variant="body2"
//             sx={{ textAlign: "justify" }}
//           >
//             {data.description}
//           </Typography>
//         </Box>
//       </Modal>

//     </Card>
//   );
// };

// export default NewsCard;


// // import { Box, Card, CardActions, CardMedia, Typography, Modal } from "@mui/material";
// // import React, { useState } from "react";
// // import ButtonComponent from "../content-component/ButtonComponent";
// // import { Icon } from "@iconify/react";

// // const NewsCard = ({ data, imageheight, imagewidth }) => {
// //   const [expanded, setExpanded] = useState(false);
// //   const [openModal, setOpenModal] = useState(false);

// //   const handleReadMoreClick = () => {
// //     if (data?.url) {
// //       window.open(data.url, "_blank");
// //     } else if (data?.description) {
// //       setOpenModal(true);
// //     } else {
// //       setExpanded(true);
// //     }
// //   };

// //   const handleCloseModal = () => {
// //     setOpenModal(false);
// //   };

// //   const handleShowLessClick = () => {
// //     setExpanded(false);
// //   };

// //   return (
// //     <>
// //       <Card
// //         sx={{
// //           width: { xs: "320px", md: "360px" },
// //           padding: "0 !important",
// //           height: expanded ? "auto" : "400px",
// //           marginBottom: "25px",
// //         }}
// //       >
// //         <Box>
// //           <CardMedia
// //             component="img"
// //             width={imagewidth ?? "100%"}
// //             height={imageheight ?? "200px"}
// //             image={data.image}
// //             alt="news image"
// //           />
// //           <Box
// //             sx={{
// //               padding: "15px",
// //               marginTop: "7px",
// //             }}
// //           >
// //             <Typography
// //               variant="caption"
// //               sx={{
// //                 color: "#00999E",
// //                 fontSize: "13px",
// //                 fontWeight: "500",
// //                 textAlign: "left",
// //               }}
// //             >
// //               {data.date}
// //             </Typography>
// //             <Typography
// //               variant="h5"
// //               sx={{
// //                 fontSize: "16px",
// //                 textAlign: "justify",
// //                 fontWeight: "600",
// //                 marginBottom: "10px",
// //               }}
// //             >
// //               {data.title}
// //             </Typography>
// //             <Typography
// //               variant="body2"
// //               sx={{
// //                 fontSize: "13px",
// //                 margin: "5px 0",
// //                 textAlign: "justify",
// //               }}
// //             >
// //               {data.subtitle}
// //             </Typography>
// //             {!expanded && (
// //               <ButtonComponent
// //                 fontSize="14px"
// //                 padding="10px 20px"
// //                 onClick={handleReadMoreClick}
// //                 icon={<Icon icon="fluent:arrow-right-28-regular" />}
// //                 text="Read More"
// //               />
// //             )}
// //             {expanded && !data.url && (
// //               <Typography
// //                 variant="body2"
// //                 sx={{
// //                   fontSize: "13px",
// //                   margin: "5px 0",
// //                   textAlign: "justify",
// //                 }}
// //               >
// //                 {data.content}
// //               </Typography>
// //             )}
// //           </Box>
// //         </Box>

// //         <CardActions>
// //           <Box sx={{ padding: "10px" }}>
// //             {expanded && !data.url && (
// //               <ButtonComponent
// //                 fontSize="14px"
// //                 padding="0px 20px"
// //                 onClick={handleShowLessClick}
// //                 icon={<Icon icon="fluent:arrow-right-28-regular" />}
// //                 text="Show Less"
// //               />
// //             )}
// //           </Box>
// //         </CardActions>
// //       </Card>

// //       {/* Modal for Description */}
// //       <Modal
// //         open={openModal}
// //         onClose={handleCloseModal}
// //         aria-labelledby="news-description-modal"
// //         aria-describedby="news-description-details"
// //       >
// //         <Box
// //           sx={{
// //             position: "absolute",
// //             top: "50%",
// //             left: "50%",
// //             transform: "translate(-50%, -50%)",
// //             width: { xs: 300, md: 400 },
// //             bgcolor: "background.paper",
// //             boxShadow: 24,
// //             p: 4,
// //             borderRadius: 2,
// //           }}
// //         >
// //           <Typography
// //             id="news-description-modal"
// //             variant="h6"
// //             component="h2"
// //             sx={{ mb: 2 }}
// //           >
// //             {data.title}
// //           </Typography>
// //           <Typography
// //             id="news-description-details"
// //             variant="body2"
// //             sx={{ textAlign: "justify" }}
// //           >
// //             {data.description}
// //           </Typography>
// //         </Box>
// //       </Modal>
// //     </>
// //   );
// // };

// // export default NewsCard;



import {
  Box,
  Card,
  CardActions,
  CardMedia,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import ButtonComponent from "../content-component/ButtonComponent";
import { Icon } from "@iconify/react";

const NewsCard = ({ data, imageheight, imagewidth }) => {
  const [expanded, setExpanded] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleReadMoreClick = () => {
    if (data?.url) {
      window.open(data.url, "_blank");
    } else if (data?.description) {
      setOpenDialog(true);
    } else {
      setExpanded(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleShowLessClick = () => {
    setExpanded(false);
  };

  return (
    <>
      <Card
        sx={{
          width: { xs: "320px", md: "360px" },
          padding: "0 !important",
          height: expanded ? "auto" : "400px",
          marginBottom: "25px",
        }}
      >
        <Box>
          <CardMedia
            component="img"
            width={imagewidth ?? "100%"}
            height={imageheight ?? "200px"}
            image={data.image}
            alt="news image"
          />
          <Box
            sx={{
              padding: "15px",
              marginTop: "7px",
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "#00999E",
                fontSize: "13px",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {data.date}
            </Typography>
            <Typography
              variant="h5"
              sx={{
                fontSize: "16px",
                textAlign: "justify",
                fontWeight: "600",
                marginBottom: "10px",
              }}
            >
              {data.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: "13px",
                margin: "5px 0",
                textAlign: "justify",
              }}
            >
              {data.subtitle}
            </Typography>
            {!expanded && (
              <ButtonComponent
                fontSize="14px"
                padding="10px 20px"
                onClick={handleReadMoreClick}
                icon={<Icon icon="fluent:arrow-right-28-regular" />}
                text="Read More"
              />
            )}
            {expanded && !data.url && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "13px",
                  margin: "5px 0",
                  textAlign: "justify",
                }}
              >
                {data.content}
              </Typography>
            )}
          </Box>
        </Box>

        <CardActions>
          <Box sx={{ padding: "10px" }}>
            {expanded && !data.url && (
              <ButtonComponent
                fontSize="14px"
                padding="0px 20px"
                onClick={handleShowLessClick}
                icon={<Icon icon="fluent:arrow-right-28-regular" />}
                text="Show Less"
              />
            )}
          </Box>
        </CardActions>
      </Card>

      {/* Dialog for Description */}
      <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle sx={{fontWeight:"800"}}>
          {data.title}
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icon icon="eva:close-fill" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body2"
            sx={{
              textAlign: "justify",
              fontSize: "14px",
            }}
          >
            {data.description}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NewsCard;
