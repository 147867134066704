import us1 from "../../../asset/CountryRecruiter/us/google.png";
import us2 from "../../../asset/CountryRecruiter/us/amazon.png";
import us3 from "../../../asset/CountryRecruiter/us/microspft.png";
import us4 from "../../../asset/CountryRecruiter/us/apple.png";
import us5 from "../../../asset/CountryRecruiter/us/facebook.png";
import us6 from "../../../asset/CountryRecruiter/us/ibm.png";
import us7 from "../../../asset/CountryRecruiter/us/goldman.png";
import us8 from "../../../asset/CountryRecruiter/us/jp.png";
import us9 from "../../../asset/CountryRecruiter/us/deloitte.png";
import us10 from "../../../asset/CountryRecruiter/us/pwc.png";
import uk1 from "../../../asset/CountryRecruiter/uk/bp.png";
import uk2 from "../../../asset/CountryRecruiter/uk/unilever.png";
import uk3 from "../../../asset/CountryRecruiter/uk/pwc.png";
import uk4 from "../../../asset/CountryRecruiter/uk/deloitte.png";
import uk5 from "../../../asset/CountryRecruiter/uk/kpmg.png";
import uk6 from "../../../asset/CountryRecruiter/uk/ey.png";
import uk7 from "../../../asset/CountryRecruiter/uk/barclays.png";
import uk8 from "../../../asset/CountryRecruiter/uk/hsbc.png";
import uk9 from "../../../asset/CountryRecruiter/uk/gsk.png";
import uk10 from "../../../asset/CountryRecruiter/uk/rr.png";
import ire1 from "../../../asset/CountryRecruiter/ireland/accenture.png";
import ire2 from "../../../asset/CountryRecruiter/ireland/google.png";
import ire3 from "../../../asset/CountryRecruiter/ireland/facebook.png";
import ire4 from "../../../asset/CountryRecruiter/ireland/deloitte.png";
import ire5 from "../../../asset/CountryRecruiter/ireland/kpmg.png";
import ire6 from "../../../asset/CountryRecruiter/ireland/pwc.png";
import ire7 from "../../../asset/CountryRecruiter/ireland/ey.png";
import ire8 from "../../../asset/CountryRecruiter/ireland/Ellipse 30.png";
import ire9 from "../../../asset/CountryRecruiter/ireland/intel.png";
import ire10 from "../../../asset/CountryRecruiter/ireland/amazon.png";
import can1 from "../../../asset/CountryRecruiter/canada/shopify.png";
import can2 from "../../../asset/CountryRecruiter/canada/google.png";
import can3 from "../../../asset/CountryRecruiter/canada/microspft.png";
import can4 from "../../../asset/CountryRecruiter/canada/ibm.png";
import can5 from "../../../asset/CountryRecruiter/canada/amazon.png";
import can6 from "../../../asset/CountryRecruiter/canada/deloitte.png";
import can7 from "../../../asset/CountryRecruiter/canada/pwc.png";
import can8 from "../../../asset/CountryRecruiter/canada/ey.png";
import can9 from "../../../asset/CountryRecruiter/canada/kpmg.png";
import can10 from "../../../asset/CountryRecruiter/canada/rbc.png";
import ger1 from "../../../asset/CountryRecruiter/germany/wv.png";
import ger2 from "../../../asset/CountryRecruiter/germany/bmw.png";
import ger3 from "../../../asset/CountryRecruiter/germany/simens.png";
import ger4 from "../../../asset/CountryRecruiter/germany/daimler.png";
import ger5 from "../../../asset/CountryRecruiter/germany/bosch.png";
import ger6 from "../../../asset/CountryRecruiter/germany/sap.png";
import ger7 from "../../../asset/CountryRecruiter/germany/deutschetelekom.png";
import ger8 from "../../../asset/CountryRecruiter/germany/bayer.png";
import ger9 from "../../../asset/CountryRecruiter/germany/deutschebank.png";
import ger10 from "../../../asset/CountryRecruiter/germany/basf.png";
import aus1 from "../../../asset/CountryRecruiter/australia/commbank.png";
import aus2 from "../../../asset/CountryRecruiter/australia/deloitte.png";
import aus3 from "../../../asset/CountryRecruiter/australia/pwc.png";
import aus4 from "../../../asset/CountryRecruiter/australia/kpmg.png";
import aus5 from "../../../asset/CountryRecruiter/australia/ey.png";
import aus6 from "../../../asset/CountryRecruiter/australia/westpac.png";
import aus7 from "../../../asset/CountryRecruiter/australia/anz.png";
import aus8 from "../../../asset/CountryRecruiter/australia/telstra.png";
import aus9 from "../../../asset/CountryRecruiter/australia/nab.png";
import aus10 from "../../../asset/CountryRecruiter/australia/bhp.png";
import sin1 from "../../../asset/CountryRecruiter/singapore/dbs.png";
import sin2 from "../../../asset/CountryRecruiter/singapore/ocbc.png";
import sin3 from "../../../asset/CountryRecruiter/singapore/uob.png";
import sin4 from "../../../asset/CountryRecruiter/singapore/singtel.png";
import sin5 from "../../../asset/CountryRecruiter/singapore/Deloitte.png";
import sin6 from "../../../asset/CountryRecruiter/singapore/pwc.png";
import sin7 from "../../../asset/CountryRecruiter/singapore/ey.png";
import sin8 from "../../../asset/CountryRecruiter/singapore/kpmg.png";
import sin9 from "../../../asset/CountryRecruiter/singapore/Gic.png";
import sin10 from "../../../asset/CountryRecruiter/singapore/capitaland.png";
import ity1 from "../../../asset/CountryRecruiter/italy/eni.png";
import ity2 from "../../../asset/CountryRecruiter/italy/unicredit.png";
import ity3 from "../../../asset/CountryRecruiter/italy/intesa.png";
import ity4 from "../../../asset/CountryRecruiter/italy/enel.png";
import ity5 from "../../../asset/CountryRecruiter/italy/fca.png";
import ity6 from "../../../asset/CountryRecruiter/italy/telecom.png";
import ity7 from "../../../asset/CountryRecruiter/italy/pt.png";
import ity8 from "../../../asset/CountryRecruiter/italy/luxottica.png";
import ity9 from "../../../asset/CountryRecruiter/italy/generali.png";
import ity10 from "../../../asset/CountryRecruiter/italy/leonardo.png";
import frn1 from "../../../asset/CountryRecruiter/france/loreal.png";
import frn2 from "../../../asset/CountryRecruiter/france/te.png";
import frn3 from "../../../asset/CountryRecruiter/france/axa.png";
import frn4 from "../../../asset/CountryRecruiter/france/bnp.png";
import frn5 from "../../../asset/CountryRecruiter/france/sg.png";
import frn6 from "../../../asset/CountryRecruiter/france/airbus.png";
import frn7 from "../../../asset/CountryRecruiter/france/ft.png";
import frn8 from "../../../asset/CountryRecruiter/france/renault.png";
import frn9 from "../../../asset/CountryRecruiter/france/danone.png";
import frn10 from "../../../asset/CountryRecruiter/france/capgemini.png";
import dub1 from "../../../asset/CountryRecruiter/dubai/emirates.png";
import dub2 from "../../../asset/CountryRecruiter/dubai/emaar.png";
import dub3 from "../../../asset/CountryRecruiter/dubai/dubai.png";
import dub4 from "../../../asset/CountryRecruiter/dubai/dpworld.png";
import dub5 from "../../../asset/CountryRecruiter/dubai/jumeirah.png";
import dub6 from "../../../asset/CountryRecruiter/dubai/dubaielectricity.png";
import dub7 from "../../../asset/CountryRecruiter/dubai/etisalat.png";
import dub8 from "../../../asset/CountryRecruiter/dubai/alfuttaim.png";
import dub9 from "../../../asset/CountryRecruiter/dubai/futtaim.png";
import dub10 from "../../../asset/CountryRecruiter/dubai/emiratesnbd.png";

const USARecruiter = [
    {
        id: 1,
        Image: us1,
        title: ["Google"],

        // title: "Mountain View"
    },
    {
        id: 2,
        Image: us2,
        title: ["Amazon"],

        // title: "Seattle"
    },
    {
        id: 3,
        Image: us3,
        title: ["Microsoft"],

        // title: "Redmond"
    },
    {
        id: 4,
        Image: us4,
        title: ["Apple"],

        // title: "Cupertino"
    },
    {
        id: 5,
        Image: us5,
        title: ["Facebook"],

        // title: "Menlo Park"
    },
    {
        id: 6,
        Image: us6,
        title: ["IBM"],

        // title: "Armonk"
    },
    {
        id: 7,
        Image: us7,
        title: ["Goldman Sachs"],

        // title: "New York"
    },
    {
        id: 8,
        Image: us8,
        title: ["JPMorgan Chase"],

        // title: "New York"
    },
    {
        id: 9,
        Image: us9,
        title: ["Deloitte"],

        // title: "New York"
    },
    {
        id: 10,
        Image: us10,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "London"
    }
];

const UKRecruiter = [
    {
        id: 1,
        Image: uk1,
        title: ["BP (British Petroleum)"],

        // title: "London"
    },
    {
        id: 2,
        Image: uk2,
        title: ["Unilever"],

        // title: "London"
    },
    {
        id: 3,
        Image: uk3,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "London"
    },
    {
        id: 4,
        Image: uk4,
        title: ["Deloitte"],

        // title: "London"
    },
    {
        id: 5,
        Image: uk5,
        title: ["KPMG"],

        // title: "London"
    },
    {
        id: 6,
        Image: uk6,
        title: ["Ernst & Young (EY)"],

        // title: "London"
    },
    {
        id: 7,
        Image: uk7,
        title: ["Barclays"],

        // title: "London"
    },
    {
        id: 8,
        Image: uk8,
        title: ["HSBC"],

        // title: "London"
    },
    {
        id: 9,
        Image: uk9,
        title: ["GlaxoSmithKline (GSK)"],

        // title: "Brentford"
    },
    {
        id: 10,
        Image: uk10,
        title: ["Rolls-Royce Holdings"],

        // title: "London"
    }
];
const IrelandRecruiter = [
    {
        id: 1,
        Image: ire1,
        title: ["Accenture"],

        // title: "Dublin"
    },
    {
        id: 2,
        Image: ire2,
        title: ["Google"],

        // title: "Dublin"
    },
    {
        id: 3,
        Image: ire3,
        title: ["Facebook"],

        // title: "Dublin"
    },
    {
        id: 4,
        Image: ire4,
        title: ["Deloitte"],

        // title: "Dublin"
    },
    {
        id: 5,
        Image: ire5,
        title: ["KPMG"],

        // title: "Dublin"
    },
    {
        id: 6,
        Image: ire6,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "Dublin"
    },
    {
        id: 7,
        Image: ire7,
        title: ["EY Ireland (Ernst & Young)"],

        // title: "Dublin"
    },
    {
        id: 8,
        Image: ire8,
        title: ["Microsoft"],

        // title: "Dublin"
    },
    {
        id: 9,
        Image: ire9,
        title: ["Intel"],

        // title: "Leixlip"
    },
    {
        id: 10,
        Image: ire10,
        title: ["Amazon"],

        // title: "Dublin"
    }
];
const CanadaRecruiter = [
    {
        id: 1,
        Image: can1,
        title: ["Shopify"],

        // title: "Ottawa"
    },
    {
        id: 2,
        Image: can2,
        title: ["Google"],

        // title: "Toronto"
    },
    {
        id: 3,
        Image: can3,
        title: ["Microsoft"],

        // title: "Mississauga"
    },
    {
        id: 4,
        Image: can4,
        title: ["IBM"],

        // title: "Markham"
    },
    {
        id: 5,
        Image: can5,
        title: ["Amazon"],

        // title: "Toronto"
    },
    {
        id: 6,
        Image: can6,
        title: ["Deloitte"],

        // title: "Toronto"
    },
    {
        id: 7,
        Image: can7,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "Toronto"
    },
    {
        id: 8,
        Image: can8,
        title: ["Ernst & Young (EY)"],

        // title: "Toronto"
    },
    {
        id: 9,
        Image: can9,
        title: ["KPMG"],

        // title: "Toronto"
    },
    {
        id: 10,
        Image: can10,
        title: ["RBC (Royal Bank of Canada)"],

        // title: "Toronto"
    }
];
const GermanyRecruiter = [
    {
        id: 1,
        Image: ger1,
        title: ["Volkswagen Group"],
        // title: "Wolfsburg"
    },
    {
        id: 2,
        Image: ger2,
        title: ["BMW Group"],

        // title: "Munich"
    },
    {
        id: 3,
        Image: ger3,
        title: ["Siemens"],

        // title: "Munich"
    },
    {
        id: 4,
        Image: ger4,
        title: ["Daimler AG"],

        // title: "Stuttgart"
    },
    {
        id: 5,
        Image: ger5,
        title: ["Bosch"],

        // title: "Gerlingen"
    },
    {
        id: 6,
        Image: ger6,
        title: ["SAP SE"],

        // title: "Walldorf"
    },
    {
        id: 7,
        Image: ger7,
        title: ["Deutsche Telekom AG"],

        // title: "Bonn"
    },
    {
        id: 8,
        Image: ger8,
        title: ["Bayer AG"],

        // title: "Leverkusen"
    },
    {
        id: 9,
        Image: ger9,
        title: ["Deutsche Bank AG"],

        // title: "Frankfurt"
    },
    {
        id: 10,
        Image: ger10,
        title: ["BASF SE"],

        // title: "Ludwigshafen"
    }
];
const AustraliaRecruiter = [
    {
        id: 1,
        Image: aus1,
        title: ["Commonwealth Bank"],

        // title: "Sydney"
    },
    {
        id: 2,
        Image: aus2,
        title: ["Deloitte"],

        // title: "Sydney"
    },
    {
        id: 3,
        Image: aus3,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "Sydney"
    },
    {
        id: 4,
        Image: aus4,
        title: ["KPMG Australia"],

        // title: "Sydney"
    },
    {
        id: 5,
        Image: aus5,
        title: ["Ernst & Young (EY)"],

        // title: "Sydney"
    },
    {
        id: 6,
        Image: aus6,
        title: ["Westpac Banking Corporation"],

        // title: "Sydney"
    },
    {
        id: 7,
        Image: aus7,
        title: ["ANZ Banking Group"],

        // title: "Melbourne"
    },
    {
        id: 8,
        Image: aus8,
        title: ["Telstra Corporation"],

        // title: "Melbourne"
    },
    {
        id: 9,
        Image: aus9,
        title: ["National Australia Bank (NAB)"],

        // title: "Melbourne"
    },
    {
        id: 10,
        Image: aus10,
        title: ["BHP (BHP Group Limited)"],

        // title: "Melbourne"
    }
];
const SingaporeRecruiter = [
    {
        id: 1,
        Image: sin1,
        title: ["DBS Bank"],

        // title: "Singapore"
    },
    {
        id: 2,
        Image: sin2,
        title: ["OCBC Bank"],

        // title: "Singapore"
    },
    {
        id: 3,
        Image: sin3,
        title: ["United Overseas Bank (UOB)"],

        // title: "Singapore"
    },
    {
        id: 4,
        Image: sin4,
        title: ["Singtel"],

        // title: "Singapore"
    },
    {
        id: 5,
        Image: sin5,
        title: ["Deloitte"],

        // title: "Singapore"
    },
    {
        id: 6,
        Image: sin6,
        title: ["Pricewaterhouse Coopers (PwC)"],

        // title: "Singapore"
    },
    {
        id: 7,
        Image: sin7,
        title: ["Ernst & Young (EY)"],

        // title: "Singapore"
    },
    {
        id: 8,

        Image: sin8,
        title: ["KPMG"],

        // title: "Singapore"
    },
    {
        id: 9,
        Image: sin9,
        title: ["GIC Private Limited"],

        // title: "Singapore"
    },
    {
        id: 10,
        Image: sin10,
        title: ["CapitaLand"],

        // title: "Singapore"
    }
];
const ItalyRecruiter = [
    {
        id: 1,
        Image: ity1,
        title: ["Eni"],

        // title: "Rome"
    },
    {
        id: 2,
        Image: ity2,
        title: ["UniCredit"],

        // title: "Milan"
    },
    {
        id: 3,
        Image: ity3,
        title: ["Intesa Sanpaolo"],

        // title: "Turin"
    },
    {
        id: 4,
        Image: ity4,
        title: ["Enel"],

        // title: "Rome"
    },
    {
        id: 5,
        Image: ity5,
        title: ["Fiat Chrysler Automobiles (FCA)"],

        // title: "Turin"
    },
    {
        id: 6,
        Image: ity6,
        title: ["Telecom Italia"],

        // title: "Milan"
    },
    {
        id: 7,
        Image: ity7,
        title: ["Poste Italiane"],

        // title: "Rome"
    },
    {
        id: 8,
        Image: ity8,
        title: ["Luxottica Group"],

        // title: "Milan"
    },
    {
        id: 9,
        Image: ity9,
        title: ["Generali Group"],

        // title: "Trieste"
    },
    {
        id: 10,
        Image: ity10,
        title: ["Leonardo S.p.A."],

        // title: "Rome"
    }
];
const FranceRecruiter = [
    {
        id: 1,
        Image: frn1,
        title: ["L'Oréal"],

        // title: "Clichy"
    },
    {
        id: 2,
        Image: frn2,
        title: ["TotalEnergies"],

        // title: "Paris"
    },
    {
        id: 3,
        Image: frn3,
        title: ["AXA Group"],

        // title: "Paris"
    },
    {
        id: 4,
        Image: frn4,
        title: ["BNP Paribas"],

        // title: "Paris"
    },
    {
        id: 5,
        title: ["Société Générale"],

        Image: frn5,
        // title: "Paris"
    },
    {
        id: 6,
        Image: frn6,
        title: ["Airbus"],

        // title: "Toulouse"
    },
    {
        id: 7,
        Image: frn7,
        title: ["Orange S.A. (formerly France Télécom)"],

        // title: "Paris"
    },
    {
        id: 8,
        Image: frn8,
        title: ["Renault Group"],

        // title: "Boulogne-Billancourt"
    },
    {
        id: 9,
        Image: frn9,
        title: ["Danone"],

        // title: "Paris"
    },
    {
        id: 10,
        Image: frn10,
        title: ["Capgemini"],

        // title: "Paris"
    }
];
const DubaiRecruiter = [
    {
        id: 1,
        Image: dub1,
        title: ["Emirates Group"],
        // title: "Dubai"
    },
    {
        id: 2,
        Image: dub2,
        title: ["Emaar Properties"],

        // title: "Dubai"
    },
    {
        id: 3,
        Image: dub3,
        title: ["Dubai Holding"],

        // title: "Dubai"
    },
    {
        id: 4,
        Image: dub4,
        title: ["DP World"],

        // title: "Dubai"
    },
    {
        id: 5,
        Image: dub5,
        title: ["Jumeirah Group"],

        // title: "Dubai"
    },
    {
        id: 6,
        Image: dub6,
        title: ["Dubai Electricity and Water Authority (DEWA)"],

        // title: "Dubai"
    },
    {
        id: 7,
        Image: dub7,
        title: ["Etisalat"],

        // title: "Abu Dhabi"
    },
    {
        id: 8,
        Image: dub8,
        title: ["Majid Al Futtaim Group"],

        // title: "Dubai"
    },
    {
        id: 9,
        Image: dub9,
        title: ["Al-Futtaim Group"],

        // title: "Dubai"
    },
    {
        id: 10,
        Image: dub10,
        title: ["Emirates NBD"],

        // title: "Dubai"
    }
];

const NewZealandRecruiter = [
    {
        id: 1,
        Image: dub1,
        title: ["Emirates Group"],
        // title: "Dubai"
    },
    {
        id: 2,
        Image: dub2,
        title: ["Emaar Properties"],

        // title: "Dubai"
    },
    {
        id: 3,
        Image: dub3,
        title: ["Dubai Holding"],

        // title: "Dubai"
    },
    {
        id: 4,
        Image: dub4,
        title: ["DP World"],

        // title: "Dubai"
    },
    {
        id: 5,
        Image: dub5,
        title: ["Jumeirah Group"],

        // title: "Dubai"
    },
    {
        id: 6,
        Image: dub6,
        title: ["Dubai Electricity and Water Authority (DEWA)"],

        // title: "Dubai"
    },
    {
        id: 7,
        Image: dub7,
        title: ["Etisalat"],

        // title: "Abu Dhabi"
    },
    {
        id: 8,
        Image: dub8,
        title: ["Majid Al Futtaim Group"],

        // title: "Dubai"
    },
    {
        id: 9,
        Image: dub9,
        title: ["Al-Futtaim Group"],

        // title: "Dubai"
    },
    {
        id: 10,
        Image: dub10,
        title: ["Emirates NBD"],

        // title: "Dubai"
    }
];

export {
    USARecruiter,
    UKRecruiter,
    IrelandRecruiter,
    CanadaRecruiter,
    GermanyRecruiter,
    AustraliaRecruiter,
    SingaporeRecruiter,
    ItalyRecruiter,
    FranceRecruiter,
    DubaiRecruiter,
    NewZealandRecruiter,
};
