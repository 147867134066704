import React, { useState } from "react";
// import { useDropzone } from "react-dropzone";
import {
  Grid,
  Button,
  Typography,
  Link,
  Box,
} from "@mui/material";
import { Icon } from "@iconify/react";
import ButtonComponent from "src/Component/content-component/ButtonComponent";

const ImageUpload = () => {
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   accept: "image/jpeg, image/png, application/pdf",
  //   maxSize: 10485760, // 10MB
  // });

  const [uploadedFiles, setUploadedFiles] = useState([]);

  // Handle document upload
  const handleFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setUploadedFiles(filesArray);
  };

  const handleAddFileChange = (event) => {
    const filesArray = Array.from(event.target.files);
    setUploadedFiles((prevFiles) => {
      // Combine new files with existing files
      return [...prevFiles, ...filesArray];
    });
  };
  return (
    <Box sx={{ paddingX: "10px" }}>
      <Typography
        variant="body1"
        sx={{ fontWeight: "bold", marginBottom: "5px", paddingX: "10px" }}
      >
        Upload Document
      </Typography>
      {/* <Typography sx={{ mb: "10px ", mt: "20px", color: "#000000" }}>
        {"Upload Document"}
      </Typography>{" "} */}
      <Box
        sx={{
          border: "1px dashed grey",
          borderRadius: "5px",
          bgcolor: "#FFFFFF",
          textAlign: "center",
          padding: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          m: "10px",
        }}
      >
        <Icon
          icon="flowbite:upload-solid"
          style={{ color: "#80869A", fontSize: "30px" }}
        />
        <Typography variant="h6" color={"#606060"}>
          Drag and Drop Or Select to upload your Documents
        </Typography>
        <Typography variant="caption" color={"#9BA1B2"}>
          JPG, PNG or PDF, file size no more than 10MB
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "Row",
            paddingRight: "20px",
            gap: "5px",
            mb: "20px",
          }}
        >
          <Button
            variant="contained"
            component="label"
            sx={{
              bgcolor: "#fff",
              border: "2px solid #00999E",
              borderRadius: "10px",
              color: "#00999E",
              "&:hover": {
                bgcolor: "#fff",
                border: "1px solid #00999E",
                color: "#00999E",
              },
            }}
          >
            Select File
            <input
              type="file"
              hidden
              multiple
              onChange={handleFileChange}
              accept=".pdf,.doc,.docx,.png,.jpg"
            />
          </Button>

          <Button
            variant="contained"
            component="label"
            sx={{
              bgcolor: "#00999E",
              border: "2px solid #00999E",
              borderRadius: "10px",
              color: "#00999E",
              "&:hover": {
                bgcolor: "#00999E",
                border: "1px solid #00999E",
                color: "#00999E",
              },
            }}
          >
            <Icon
              icon={"gridicons:add-outline"}
              style={{ fontSize: "25px", color: "#fff" }}
            />
            <input
              type="file"
              hidden
              multiple
              onChange={handleAddFileChange}
              accept=".pdf,.doc,.docx,.png,.jpg"
            />
          </Button>
        </Box>
      </Box>
      {/* Display uploaded file names */}
      {uploadedFiles.length > 0 && (
        <Grid container spacing={1} style={{ marginTop: 10 }}>
          {uploadedFiles.map((file, index) => (
            <Grid item xs={12} key={index}>
              <Link
                href={URL.createObjectURL(file)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.name}
              </Link>
            </Grid>
          ))}
        </Grid>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "Row",
            paddingRight: "20px",
            gap: "5px",
            mb: "20px",
          }}
        >
          <ButtonComponent
            text={"Post"}
            href={"createcommunitypost"}
            width={"150px"}
          />

          <ButtonComponent
            text={"Cencel"}
            href={"createcommunitypost"}
            backgroundColor={"#FFFFFF"}
            color={"#00999E"}
            borderColor={"#00999E"}
            width={"150px"}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ImageUpload;
