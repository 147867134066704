/* eslint-disable no-unused-vars */
/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from "react";
import {
  AppBar,
  IconButton,
  Typography,
  Drawer,
  List,
  Stack,
  Container,
  Box,
  createTheme,
  styled,
  Badge,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../asset/TIE_LOGO.png";
import phone from "../asset/phone.svg";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid } from "@material-ui/core";
import "../../src/index.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Icon } from "@iconify/react";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

const Navbar = ({ setItemUpdate, itemupdate }) => {
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: { md: 13, xs: 5 },
      border: `2px solid ${theme.palette.background.paper}`,
      padding: "0 4px"
    }
  }));

  const [cartList, setCartList] = useState();
  const [iftoken, setIfToken] = useState(false);
  const location = useLocation();
  const profileImg = sessionStorage.getItem("profileImg");

  useEffect(() => {
    const fetchCartData = async () => {
      const token = sessionStorage.getItem("token");
      if (token) {
        setIfToken(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}order/get-cart`,
            {
              headers: {
                Authorization: token
              }
            }
          );
          const data = response.data;
          if (data.success) {
            const apiCartList = data.data.flatMap((order) =>
              order.items.map((item) => ({
                id: item._id,
                title: item.planName,
                packagePrice: item.amount,
                planId: item.planId
              }))
            );
            setCartList(apiCartList);
          }
        } catch (error) {
          console.error("Error fetching cart data:", error);
        }
      } else {
        const localCart = JSON.parse(localStorage.getItem("cart")) || [];
        setCartList(localCart);
      }
    };

    fetchCartData();
  }, [itemupdate]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const largeScreen = createTheme({
    breakpoints: {
      values: {
        xxl: 1920
      }
    }
  });

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handlePhoneIconClick = () => {
    window.open(`tel:${+919831241212}`);
  };

  const HoverTypography = ({ children, fontSize, fontWeight, isActive }) => (
    <Typography
      variant="h6"
      color="inherit"
      sx={{
        textDecoration: "none",
        underline: "none",
        border: "2px solid white",
        borderRadius: 2,
        padding: {
          xs: "5px 0px 5px 0px",
          sm: "5px 0px 5px 0px",
          md: "5px 8px 5px 8px",
          lg: "5px 20px 5px 20px"
        },
        fontSize: { md: "14px", lg: "16px" },
        fontStyle: "normal",

        fontWeight: 500,
        lineHeight: "28px",
        letterSpacing: "0.2px",
        "&:hover": {
          color: "#00999e",
          border: isActive ? "2px solid #00999e" : "",
          cursor: "pointer",
          padding: {
            xs: "5px 0px 5px 0px",
            sm: "5px 0px 5px 0px",
            md: "5px 20px 5px 20px",
            lg: "5px 20px 5px 20px"
          }
        }
      }}
    >
      {children}
    </Typography>
  );

  const NavLinkCss = ({ isActive }) => {
    return {
      color: isActive ? "#00999e" : "",
      border: isActive ? "2px solid #00999e" : "",
      borderRadius: isActive ? 8 : ""
    };
  };

  // Dropdown menu state and handlers

  const navigate = useNavigate();

  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);

  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };

  const confirmLogout = () => {
    sessionStorage.removeItem("token");

    // Navigate to the home page
    setLogoutDialogOpen(false);
    navigate("/");
    window.location.reload();
    // Perform logout action here
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        sx={{ backgroundColor: "white" }}
      >
        <Container maxWidth={"xxl"}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "80px"
              // padding: "10px 0 10px 0",
            }}
          >
            {isSmScreen || isMediumScreen ? (
              <Grid
                container
                spacing={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <NavLink to="/" className="custom-link">
                    <img width="150px" src={logo} alt="Logo" />
                  </NavLink>
                </Grid>

                <Grid Grid item>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <IconButton aria-label="cart" sx={{ pr: "40px" }}>
                      <StyledBadge
                        badgeContent={cartList?.length}
                        color={"primary"}
                      >
                        <ShoppingCartIcon />
                      </StyledBadge>
                    </IconButton>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={handleDrawerOpen}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <NavLink to="/" className="custom-link">
                <img width="150px" src={logo} alt="Logo" />
              </NavLink>
            )}

            {isSmScreen || isMediumScreen ? (
              // Drawer for small screens
              <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
                ModalProps={{ keepMounted: true }}
                sx={{
                  "& .MuiDrawer-paper": {
                    width: isSmScreen ? "90%" : 300
                  }
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1rem"
                  }}
                >
                  {(isSmScreen || isMediumScreen) && (
                    <NavLink to="/" className="custom-link">
                      <img width="150px" src={logo} alt="Logo" />
                    </NavLink>
                  )}
                  <IconButton
                    onClick={handleDrawerClose}
                    sx={{ alignSelf: "flex-start" }}
                  >
                    <CloseIcon sx={{ color: "#00999E" }} />
                  </IconButton>
                </Box>
                <List sx={{ padding: "0px 20px" }}>
                  <NavLink to="/" className="custom-link">
                    <HoverTypography
                      fontSize={{
                        xs: "14px",
                        sm: "16px",
                        md: "16px",
                        lg: "20px"
                      }}
                      fontWeight={500}
                      sx={{ width: "80px" }}
                    >
                      Home
                    </HoverTypography>
                  </NavLink>
                  <NavLink to="/aboutus" className="custom-link">
                    <HoverTypography
                      fontSize={{
                        xs: "14px",
                        sm: "16px",
                        md: "16px",
                        lg: "17px"
                      }}
                      fontWeight={500}
                      sx={{ width: "60px" }}
                    >
                      Our Story
                    </HoverTypography>
                  </NavLink>
                  <NavLink to="/study-abroad" className="custom-link">
                    <HoverTypography
                      fontSize={{
                        xs: "14px",
                        sm: "16px",
                        md: "16px",
                        lg: "17px"
                      }}
                      fontWeight={500}
                      sx={{ width: "60px" }}
                    >
                      Study Abroad
                    </HoverTypography>
                  </NavLink>
                  <NavLink to="/immigration" className="custom-link">
                    <HoverTypography
                      fontSize={{
                        xs: "14px",
                        sm: "16px",
                        md: "16px",
                        lg: "17px"
                      }}
                      fontWeight={500}
                      sx={{ width: "60px" }}
                    >
                      Immigration
                    </HoverTypography>
                  </NavLink>

                  <NavLink to="/mbbs" className="custom-link">
                    <HoverTypography
                      fontSize={{
                        xs: "14px",
                        sm: "16px",
                        md: "16px",
                        lg: "17px"
                      }}
                      fontWeight={500}
                      sx={{ width: "60px" }}
                    >
                      MBBS
                    </HoverTypography>
                  </NavLink>
                  <NavLink to="/test" className="custom-link">
                    <HoverTypography
                      fontSize={{
                        xs: "14px",
                        sm: "16px",
                        md: "16px",
                        lg: "17px"
                      }}
                      fontWeight={500}
                      sx={{ width: "60px" }}
                    >
                      Test Prep
                    </HoverTypography>
                  </NavLink>
                  <NavLink to="/contact" className="custom-link">
                    <HoverTypography
                      fontSize={{
                        xs: "14px",
                        sm: "16px",
                        md: "16px",
                        lg: "17px"
                      }}
                      fontWeight={500}
                      sx={{ width: "60px" }}
                    >
                      Contact Us
                    </HoverTypography>
                  </NavLink>
                  {iftoken === true ? (
                    <Box>
                      <NavLink
                        to="/dashboard/enrollment"
                        // to="/coming-soon"
                        className="custom-link"
                      >
                        <HoverTypography
                          fontSize={{
                            xs: "14px",
                            sm: "16px",
                            md: "16px",
                            lg: "17px"
                          }}
                          fontWeight={500}
                          sx={{ width: "60px" }}
                        >
                          Dashboard
                        </HoverTypography>
                      </NavLink>
                      <Box className="custom-link" onClick={handleLogout}>
                        <HoverTypography
                          fontSize={{
                            xs: "14px",
                            sm: "16px",
                            md: "16px",
                            lg: "17px"
                          }}
                          fontWeight={500}
                          sx={{ width: "60px" }}
                        >
                          Log Out
                        </HoverTypography>
                      </Box>{" "}
                    </Box>
                  ) : (
                    <NavLink to="/login" className="custom-link">
                      {" "}
                      <HoverTypography
                        fontSize={{
                          xs: "14px",
                          sm: "16px",
                          md: "16px",
                          lg: "17px"
                        }}
                        fontWeight={500}
                        sx={{ width: "60px" }}
                      >
                        Login
                      </HoverTypography>
                    </NavLink>
                  )}
                </List>
              </Drawer>
            ) : (
              <Box
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 2, md: 4, sm: 4 }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
                paddingTop={1}
                paddingBottom={1}
              >
                <NavLink to="/" className="custom-link" style={NavLinkCss}>
                  <HoverTypography
                    fontSize={{
                      xs: "10px",
                      sm: "10px",
                      md: "13px",
                      lg: "20px"
                    }}
                    fontWeight={500}
                    border="4px solid white"
                  >
                    Home
                  </HoverTypography>
                </NavLink>
                {/* <NavLink
                  to="/aboutus"
                  className="custom-link"
                  style={NavLinkCss}
                >
                  <HoverTypography
                    fontSize={{
                      xs: "10px",
                      sm: "10px",
                      md: "13px",
                      lg: "15px",
                    }}
                    border="4px solid white"
                    fontWeight={500}
                  >
                    Our Story
                  </HoverTypography>
                </NavLink> */}
                <NavLink
                  to="/study-abroad"
                  className="custom-link"
                  style={NavLinkCss}
                >
                  <HoverTypography
                    fontSize={{
                      xs: "10px",
                      sm: "10px",
                      md: "13px",
                      lg: "15px"
                    }}
                    border="4px solid white"
                    fontWeight={500}
                  >
                    Study Abroad
                  </HoverTypography>
                </NavLink>
                <NavLink
                  to="/immigration"
                  className="custom-link"
                  style={NavLinkCss}
                >
                  <HoverTypography
                    fontSize={{
                      xs: "10px",
                      sm: "10px",
                      md: "13px",
                      lg: "20px"
                    }}
                    border="4px solid white"
                    fontWeight={500}
                  >
                    Immigration
                  </HoverTypography>
                </NavLink>

                <NavLink to="/mbbs" className="custom-link" style={NavLinkCss}>
                  <HoverTypography
                    fontSize={{
                      xs: "10px",
                      sm: "10px",
                      md: "13px",
                      lg: "20px"
                    }}
                    border="4px solid white"
                    fontWeight={500}
                  >
                    MBBS
                  </HoverTypography>
                </NavLink>
                <NavLink to="/test" className="custom-link" style={NavLinkCss}>
                  <HoverTypography
                    fontSize={{
                      xs: "10px",
                      sm: "10px",
                      md: "13px",
                      lg: "20px"
                    }}
                    border="4px solid white"
                    fontWeight={500}
                  >
                    Test Prep
                  </HoverTypography>
                </NavLink>
                <NavLink
                  to="/contact"
                  className="custom-link"
                  style={NavLinkCss}
                >
                  <HoverTypography
                    fontSize={{
                      xs: "10px",
                      sm: "10px",
                      md: "13px",
                      lg: "20px"
                    }}
                    border="4px solid white"
                    fontWeight={500}
                  >
                    Contact Us
                  </HoverTypography>
                </NavLink>
                {iftoken === true ? (
                  <Box
                    sx={{
                      padding: {
                        xs: "5px 0px 5px 0px",
                        sm: "5px 0px 5px 0px",
                        md: "5px 8px 5px 8px",
                        lg: "5px 20px 5px 20px"
                      }
                    }}
                  >
                    <PopupState variant="popover" popupId="demo-popup-popover">
                      {(popupState) => (
                        <div>
                          <Button {...bindTrigger(popupState)}>
                            {profileImg === null ? (
                              <Icon
                                icon="carbon:user-avatar-filled"
                                fontSize={"36px"}
                                color="#00999E"
                              />
                            ) : (
                              <Box
                                sx={{
                                  borderRadius: "50%",
                                  overflow: "hidden",
                                  border: "1px solid #00999E",
                                  boxShadow: 10,

                                  width: "40px",
                                  height: "40px"
                                }}
                              >
                                <img
                                  src={profileImg}
                                  alt="profile_pic"
                                  width={"100%"}
                                  height={"100%"}
                                />
                              </Box>
                            )}
                          </Button>
                          <Popover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center"
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center"
                            }}
                          >
                            <NavLink
                              to="/dashboard/enrollment"
                              // to="/coming-soon"
                              className="custom-link"
                            >
                              {" "}
                              <HoverTypography
                                fontSize={{
                                  xs: "10px",
                                  sm: "10px",
                                  md: "13px",
                                  lg: "20px"
                                }}
                                border="4px solid white"
                                fontWeight={500}
                              >
                                Dashboard
                              </HoverTypography>
                            </NavLink>
                            <Box className="custom-link" onClick={handleLogout}>
                              <HoverTypography
                                fontSize={{
                                  xs: "10px",
                                  sm: "10px",
                                  md: "13px",
                                  lg: "20px"
                                }}
                                border="4px solid white"
                                fontWeight={500}
                              >
                                Log Out
                              </HoverTypography>
                            </Box>
                          </Popover>
                        </div>
                      )}
                    </PopupState>
                  </Box>
                ) : (
                  <NavLink
                    to="/login"
                    className="custom-link"
                    style={NavLinkCss}
                  >
                    {" "}
                    <HoverTypography
                      fontSize={{
                        xs: "10px",
                        sm: "10px",
                        md: "13px",
                        lg: "20px"
                      }}
                      border="4px solid white"
                      fontWeight={500}
                    >
                      Login
                    </HoverTypography>
                  </NavLink>
                )}

                {/* <Button
                  id="fade-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{
                    border: "2px solid white",
                    borderRadius: 2,

                    color: "#000000",

                    fontWeight: 500,
                    "&:hover": {
                      color: "#00999e",
                      border: "2px solid #00999e",
                    },
                  }}
                >
                  <Typography
                    fontSize={{
                      xs: "14px",
                      sm: "16px",
                      md: "16px",
                      lg: "17px",

                    }}
                    textTransform={"none"}
                    fontWeight={500}
                    sx={{ width: "60px" }}
                  >
                    Others
                  </Typography>
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <NavLink to="/contact" className="custom-link">
                    <MenuItem onClick={handleClose}>
                      Contact Us
                    </MenuItem>
                  </NavLink>
                  <NavLink to="/login" className="custom-link">
                    <MenuItem onClick={handleClose}>
                      Login
                    </MenuItem>
                  </NavLink>
                  <NavLink to="/signup" className="custom-link">
                    <MenuItem onClick={handleClose}>
                      Sign Up
                    </MenuItem>
                  </NavLink>
                </Menu> */}
              </Box>
            )}
            {!isSmScreen && !isMediumScreen && (
              <Box
                direction={{ xs: "column", sm: "row" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
                pr={"20px"}
              >
                <Stack direction="row">
                  <NavLink to="/addCart" className="custom-link">
                    <Badge badgeContent={cartList?.length} color="secondary">
                      <ShoppingCartIcon color="action" />
                    </Badge>
                  </NavLink>
                </Stack>
              </Box>
            )}

            {!isSmScreen && !isMediumScreen && (
              <Box>
                <HoverTypography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Stack
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src={phone}
                        style={{ width: "2.5rem" }}
                        alt="Phone"
                      />
                    </Stack>
                    <Stack ml={1} onClick={handlePhoneIconClick}>
                      <Typography sx={{ color: "grey" }}>
                        Say Hello !
                      </Typography>
                      <Typography>+91 9831241212</Typography>
                    </Stack>
                  </Box>
                </HoverTypography>
              </Box>
            )}
          </Box>
        </Container>
      </AppBar>
      <Dialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            width: { sm: "450px", xs: "300px" } // You can set your custom width here
          }
        }}
      >
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setLogoutDialogOpen(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={confirmLogout} color="error">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Navbar;
