import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Container,
  useMediaQuery,
  useTheme,
  Snackbar,
  Alert
} from "@mui/material";
import Footer from "src/Component/Footer";
import Navbar from "src/Component/Navbar";
import LetsGetStarted from "src/Component/LetsGetStarted";
import { styled } from "@mui/system";
import {
  AdmissionRequirements,
  FieldOfStudy,
  Location,
  ProgramAndPreferredYear,
  RegisterYourself,
  Scholarship
} from "./components/StepComponents";
import { useNavigate } from "react-router-dom";

import forwardarrow from "../asset/universityfinder/forwardArrow.png";
import backwardArrow from "../asset/universityfinder/backwardArrow.png";
import "react-toastify/dist/ReactToastify.css";

// Define steps
const steps = [
  "Register Yourself",
  "Location",
  "Program and Preferred Year",
  "Field of Study",
  "Admission Requirements",
  // "Scholarship"
];

// Custom styles for the Step and StepLabel
const CustomStepLabel = styled(StepLabel)(({ theme, active }) => ({
  "& .MuiStepIcon-root": {
    color: active ? "#00999E !important " : "#fff",
    borderRadius: "50%",
    border: "2px solid",
    borderColor: active ? "#00999E !important" : "#000",
    backgroundColor: active ? "#00999E !important" : "transparent",
    width: "35px",
    height: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  "& .MuiStepIcon-text": {
    fill: active ? "#fff" : "#000",
    fontWeight: "550"
  },

  "& .MuiStepLabel-label": {
    marginTop: theme.spacing(1),
    color: active ? "#000" : "transparent",
    textAlign: "center"
  }
}));

export default function UniversityFinder() {
  const [filters, setFilters] = useState({
    country: "",
    state: "",
    pursue: "",
    year: "",
    intake: "",
    duration: "",
    tutionfee: "",
    fieldofInterest: "",
    admission: "",
    qualification: "",
    scholarship: "",
    language: "",
    name:'',
    phone:'',
    email:'',
  });
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const handleNext = () => {
  //   if (!validateStep()) {
  //     // If validation fails, show a toast and do not proceed
  //     return; // Ensure this return prevents moving to the next step
  //   }

  //   if (activeStep < steps.length - 1) {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //     scrollToTop();
  //   }
  // };


  const handleNext = async () => {
    if (!validateStep()) {
      // If validation fails, show a toast and do not proceed
      return; // Ensure this return prevents moving to the next step
    }
  
    if (activeStep === 0) {
      // API call for "Register Yourself" step
      try {
        const payload = {
          name: filters.name || "", // Ensure these fields exist in the `filters` object
          phone: filters.phone || "",
          email: filters.email || ""
        };

        console.log('payload',payload)
  
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/university/form`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        });
  
        if (response.ok) {
          const data = await response.json();
          console.log("Registration successful:", data);
          // Proceed to the next step after successful API call
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          scrollToTop();
        } else {
          const errorData = await response.json();
          showSnackbar(errorData.message || "Failed to register. Please try again.");
        }
      } catch (error) {
        console.error("Error during registration:", error);
        showSnackbar("An error occurred. Please try again.");
      }
    } else if (activeStep < steps.length - 1) {
      // For other steps, just proceed to the next step
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      scrollToTop();
    }
  };
  


  const validateStep = () => {
    switch (activeStep) {
      case 1: // Location
        if (!filters.country) {
          showSnackbar("Please select a country");
          return false;
        }
        // if (!filters.state) {
        //   showSnackbar("Please select a state");
        //   return false;
        // }
        if (!filters.fieldofInterest) {
          showSnackbar("Please select a field of interest");
          return false;
        }
        return true;
      case 2: // Program and Preferred Year
        if (!filters.pursue) {
          showSnackbar("Please select a pursue option");
          return false;
        }
        if (!filters.year) {
          showSnackbar("Please select a preferred year");
          return false;
        }
        if (!filters.intake) {
          showSnackbar("Please select an intake");
          return false;
        }
        if (!filters.duration) {
          showSnackbar("Please select a duration");
          return false;
        }
        return true;
      case 3: // Field of Study
        // if (!filters.fieldofInterest) {
        //   showSnackbar("Please select a field of interest");
        //   return false;
        // }
         if (!filters.scholarship) {
          showSnackbar("Please select a scholarship option");
          return false;
        }
        return true;
      case 4: // Admission Requirements
        if (!filters.admission) {
          showSnackbar("Please select an admission requirement");
          return false;
        }
        if (!filters.qualification) {
          showSnackbar("Please select a qualification");
          return false;
        }
        return true;
      // case 5: // Scholarship
      //   if (!filters.scholarship) {
      //     showSnackbar("Please select a scholarship option");
      //     return false;
      //   }
      //   if (!filters.language) {
      //     showSnackbar("Please select a language");
      //     return false;
      //   }
      //   return true;
      default:
        return true;
    }
  };

  const validateSubmit = () => {
    let isValid = true;
    // if (!filters.language) {
    //   showSnackbar("Please select a language");
    //   isValid = false;
    // }
    if (!filters.scholarship) {
      showSnackbar("Please select a scholarship option");
      isValid = false;
    }
    return isValid;
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      scrollToTop();
    }
  };

  const handleReset = () => {
    setActiveStep(0);
    scrollToTop();
  };

  const handleSubmit = async () => {
    if (!validateSubmit()) {
      return;
    }

    try {
      const payload = {
        countryId: filters.country || "",
        courseId: filters.fieldofInterest || "",
        // stateId: filters.state || "",
        pursue: filters.pursue || "",
        year: filters.year || "",
        tutionFee: filters.tutionfee || "",
        duration: filters.duration || "",
        intake: filters.intake || "",
        admissionRequirement: filters.admission || "",
        scholarAvailability: filters.scholarship || "",
        language: filters.language || "",
        highestQualification: filters.qualification || ""
      };

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}university/universityFilter`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
        }
      );

      if (response.ok) {
        const data = await response.json();
        navigate("/study-abroad/universities/view", {
          state: { data: data.data.formattedData, data2: data.data.filterData }
        });
        scrollToTop();
      } else {
        console.error("Failed to submit filters:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting filters:", error);
    }
  };

  const showSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const renderStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <RegisterYourself filters={filters} setFilters={setFilters} />;
      case 1:
        return <Location filters={filters} setFilters={setFilters} />;
      case 2:
        return (
          <ProgramAndPreferredYear filters={filters} setFilters={setFilters} />
        );
      case 3:
        return <FieldOfStudy filters={filters} setFilters={setFilters} />;
      case 4:
        return (
          <AdmissionRequirements filters={filters} setFilters={setFilters} />
        );
      // case 5:
      //   return <Scholarship filters={filters} setFilters={setFilters} />;
      default:
        return <Typography>Unknown Step</Typography>;
    }
  };

  return (
    <Box>
      <Navbar />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={4000}
        // sx={{ bgcolor: "#ffffff", color: "#000", border: "2px solid red" }}
      >
        <Alert
          // onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          width: "100%",
          height: "100px",
          padding: "20px 0",
          bgcolor: "#effdff"
        }}
      >
        {!isXs ? (
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} completed={false}>
                <CustomStepLabel icon={index + 1} active={activeStep === index}>
                  {label}
                </CustomStepLabel>
              </Step>
            ))}
          </Stepper>
        ) : (
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <Box
              sx={{
                height: "40px",
                width: "40px",
                border: "2px solid #00999E",
                borderRadius: "50%",
                padding: "3px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Box
                sx={{
                  width: "34px",
                  height: "34px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "50%",
                  bgcolor: "#00999E"
                }}
              >
                <Typography variant="h6" sx={{ color: "#fff" }}>
                  {activeStep + 1}
                </Typography>
              </Box>
            </Box>

            <Typography variant="h6">{steps[activeStep]}</Typography>
          </Box>
        )}
      </Box>

      <Box>
        <Box sx={{ mt: 2 }}>{renderStepContent(activeStep)}</Box>
        <Container>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              variant="contained"
              disabled={activeStep === 0}
              onClick={handleBack}
              startIcon={
                <img
                  src={backwardArrow}
                  alt="prefix"
                  style={{ width: "50px", height: 15 }}
                />
              }
              sx={{
                backgroundColor: activeStep === 0 ? "#e0e0e0" : "#00999E",
                color: activeStep === 0 ? "#a0a0a0" : "#fff",
                "&:hover": {
                  backgroundColor: activeStep === 0 ? "#e0e0e0" : "#007b8f"
                }
              }}
            >
              Previous
            </Button>

            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  backgroundColor: "#00999E",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#007b8f"
                  }
                }}
              >
                Submit
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                endIcon={
                  <img
                    src={forwardarrow}
                    alt="Suffix"
                    style={{ width: "50px", height: 15 }}
                  />
                }
                sx={{
                  backgroundColor: "#00999E",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: " #007b8f"
                  }
                }}
              >
                Next
              </Button>
            )}
          </Box>
        </Container>

        {activeStep === steps.length && (
          <Box sx={{ mt: 4 }}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        )}
      </Box>

      <LetsGetStarted marginTop={"50px"} />
      <Footer />
    </Box>
  );
}
