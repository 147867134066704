// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Box
// } from "@mui/material";

// const DropdownComponent = ({
//   label,
//   options = [],
//   value = "",
//   onChange = () => {}
// }) => {
//   // Check if options are objects or strings
//   const isObjectOptions = options.length > 0 && typeof options[0] === "object";

//   return (
//     <Box>
//       <Typography sx={{ mt: "10px" }}>{label}</Typography>
//       <FormControl
//         fullWidth
//         variant="outlined"
//         margin="normal"
//         sx={{
//           border: "none",
//           backgroundColor: "white",
//           "& .MuiOutlinedInput-root": {
//             borderRadius: "4px"
//           }
//         }}
//       >
//         <InputLabel>Select {label}</InputLabel>
//         <Select
//           value={value} // Controlled component
//           onChange={(event) => onChange(event.target.value)} // Safely call onChange
//           sx={{
//             backgroundColor: "white",
//             "& .MuiSelect-select": {
//               padding: "14px 14px"
//             },
//             "& .MuiOutlinedInput-notchedOutline": {
//               border: "none"
//             }
//           }}
//         >
//           <MenuItem value="" disabled>
//             {`Select ${label}`}
//           </MenuItem>
//           {options.map((option, index) => (
//             <MenuItem
//               key={index}
//               value={isObjectOptions ? option.value : option}
//             >
//               {isObjectOptions ? option.label : option}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Box>
//   );
// };

// export default DropdownComponent;


// import {
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Typography,
//   Box,
//   Checkbox,
//   ListItemText
// } from "@mui/material";

// const DropdownComponent = ({
//   label,
//   options = [],
//   value = "",
//   onChange = () => {},
//   multiSelect = false
// }) => {
//   const isObjectOptions = options.length > 0 && typeof options[0] === "object";

//   const handleChange = (event) => {
//     if (multiSelect) {
//       onChange(event.target.value); // Handles array of selected values
//     } else {
//       onChange(event.target.value); // Handles single value
//     }
//   };

//   return (
//     <Box>
//       <Typography sx={{ mt: "10px" }}>{label}</Typography>
//       <FormControl
//         fullWidth
//         variant="outlined"
//         margin="normal"
//         sx={{
//           border: "none",
//           backgroundColor: "white",
//           "& .MuiOutlinedInput-root": {
//             borderRadius: "4px"
//           }
//         }}
//       >
//         <InputLabel>{`Select ${label}`}</InputLabel>
//         <Select
//           multiple={multiSelect}
//           value={value} // Controlled component
//           onChange={handleChange} // Safely call onChange
//           renderValue={(selected) =>
//             multiSelect
//               ? selected
//                   .map((val) =>
//                     isObjectOptions
//                       ? options.find((option) => option.value === val)?.label
//                       : val
//                   )
//                   .join(", ")
//               : selected
//           }
//           sx={{
//             backgroundColor: "white",
//             "& .MuiSelect-select": {
//               padding: "14px 14px"
//             },
//             "& .MuiOutlinedInput-notchedOutline": {
//               border: "none"
//             }
//           }}
//         >
//           <MenuItem value="" disabled>
//             {`Select ${label}`}
//           </MenuItem>
//           {options.map((option, index) => (
//             <MenuItem
//               key={index}
//               value={isObjectOptions ? option.value : option}
//             >
//               {multiSelect && <Checkbox checked={value.includes(option.value)} />}
//               {multiSelect ? (
//                 <ListItemText
//                   primary={isObjectOptions ? option.label : option}
//                 />
//               ) : (
//                 isObjectOptions ? option.label : option
//               )}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Box>
//   );
// };

// export default DropdownComponent;


import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Box,
  Checkbox,
  ListItemText,
} from "@mui/material";

const DropdownComponent = ({
  label,
  options = [],
  value = [],
  onChange = () => {},
  multiSelect = false,
}) => {
  const isObjectOptions = options.length > 0 && typeof options[0] === "object";

  const handleChange = (event) => {
    if (multiSelect) {
      const selectedValues = event.target.value;
      onChange(selectedValues); // Update the selected values
    } else {
      onChange(event.target.value); // Handle single value
    }
  };

  return (
    <Box>
      <Typography sx={{ mt: "10px" }}>{label}</Typography>
      <FormControl
        fullWidth
        variant="outlined"
        margin="normal"
        sx={{
          border: "none",
          backgroundColor: "white",
          "& .MuiOutlinedInput-root": {
            borderRadius: "4px",
          },
        }}
      >
        <InputLabel>{`Select ${label}`}</InputLabel>
        <Select
          multiple={multiSelect}
          value={value} // Controlled component
          onChange={handleChange} // Safely call onChange
          renderValue={(selected) =>
            multiSelect
              ? selected
                  .map((val) =>
                    isObjectOptions
                      ? options.find((option) => option.value === val)?.label
                      : val
                  )
                  .join(", ")
              : selected
          }
          sx={{
            backgroundColor: "white",
            "& .MuiSelect-select": {
              padding: "14px 14px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          <MenuItem value="" disabled>
            {`Select ${label}`}
          </MenuItem>
          {options.map((option, index) => {
            const optionValue = isObjectOptions ? option.value : option;
            return (
              <MenuItem key={index} value={optionValue}>
                {multiSelect && (
                  <Checkbox checked={value.includes(optionValue)} />
                )}
                <ListItemText
                  primary={isObjectOptions ? option.label : option}
                />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default DropdownComponent;
