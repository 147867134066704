/* eslint-disable no-unused-vars */

import { Grid } from '@material-ui/core'
import { Typography,Container, useMediaQuery, useTheme, createTheme } from '@mui/material'
import React from 'react'
import ValueCard from './ValueCard'
import icon1 from '../../asset/value1.svg';
import icon2 from '../../asset/value2.svg';
import icon3 from '../../asset/value3.svg';
import icon4 from '../../asset/value4.svg';
import Slider from "react-slick";
import Header from '../content-component/Header';


const OurValue = () => {
    const theme = useTheme();
    const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const largeScreen = createTheme({
      breakpoints: {
        values: {
          xxl: 1920,
        },
      },
    });
    const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: isSmScreen ? 1 : 4,
        slidesToScroll: 3,
        arrows: isMediumScreen ?  true : isSmScreen ? true : false ,
        autoPlay: true,
        autoplaySpeed: 500,
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
        
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
         
            },
          },
        ],
      };

    const valueCardData = [
        {
          id: 1,
          icon: icon1,
          title: "Excellence",
          about: "Dedicated to providing excellence in all our services, ensuring students receive top-quality education and support."
        },
        {
          id: 2,
          icon: icon2,
          title: "Integrity",
          about: "To Uphold the highest standards of honesty, transparency, and ethical conduct, our institution places a paramount emphasis on integrity."
        },
        {
          id: 3,
          icon: icon3,
          title: "Student-Centric Approach",
          about : "Prioritizing students' needs, aspirations, and well-being, we offer personalized guidance to help achieve academic and personal goals."
        },
        {
          id: 4,
          icon: icon4,
          title: "Global Citizenship",
          about: "We foster the growth of global citizens—culturally competent, socially responsible, and positively contributing to a globalized society."
        },
      ];
    return (
        <Container 
        sx={{
          // marginTop:'5vh',
          padding:"50px 0",
          height : "auto",
          // height: {xs:'auto',md:'100vh',lg:'90vh',xl:'80vh', xxl: '80vh'},
          // width: {xs:'80%',lg:'auto'},
          width : "100%"
        }}
        maxWidth={isLargeScreen?"xl":"lg"}
        >
        <Grid container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            direction="column"
        > <Header
                        color="#00999e"
                        textAlign="center"
                        content="Our Values"
                    />
        {/* <Typography
            variant="h1"
            gutterBottom
            sx={{
                color: "#00999E",
                fontSize: "36px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                textTransform: "capitalize",
                textAlign: "center",
                mb:4
            }}
        >
            ~Our Values~
        </Typography> */}
       {/*  <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
                color: "#1E1E1E",
                fontSize: { xs: "14px", sm: "18px", md: "20px", lg: "19px" },
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "1.5",
                textTransform: "capitalize",
                textAlign: "center",
                width: { xs: "100%", sm: "80%", md: "80%", lg: "50%" },
                margin: "auto"
            }}
        >
            Install our top-rated dropshipping app to your e-commerce site and get access to US Suppliers, AliExpress vendors, and the best.
          </Typography> */}

        <Container
        style={{ 
            height: "100%",
            // padding: "60px 0",
            justifyContent: isSmScreen?"center":isMediumScreen?"center":"space-between",
            alignItems:"center",
        }}
        maxWidth={isLargeScreen?"xl":"auto"}
        ><div
        style={{
            gap: "2rem",
            justifyContent: "center",
            margin:"auto",
        }}
    > <Slider {...settings} >
            {
                valueCardData.map((card)=>(
                    <ValueCard 
                    key={card.id}
                    id={card.id}  
                    icon={card.icon}
                    title={card.title}
                    about={card.about}
                    />
                ))
            }
            </Slider>
            </div>
        </Container>
    </Grid>
        </Container>
    )
}

export default OurValue