import {
    Box,
    Card,
    Grid,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import line from "../../../asset/line.svg";
  import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { viewMoreDataForAbroad } from "./ViewMoreDataForAbroad";
  import dottedline from "../../../asset/dottedline.svg";
  import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
  import "../../../globle.css";
import ThirdCard from "./ThirdCard";



const SecondCard = ({
  theme,
  popup,
  setPopup,
  setCate,
  selectedCategory,
  setSelectedCategory,
  cate
}) => {
  
  const [show,setShow] = useState(false)
  const [titleThirdCard,setTitleThirdCard] = useState('')

    const handleChangeProcess = (e,about) => {
     
      if(about === "In Progress")setCate(1)
      else if(about === "Not Toke")setCate(2)
      else if(about === "completed")setCate(3)
      else setCate(0)

      setTitleThirdCard(about)
     
    }
    
    

  return (
    <div>
    <Card sx={{ mt: 3, mb: 5 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          padding: "3% 7%",
        }}
        onClick={()=> setShow(true)}
      >
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          {viewMoreDataForAbroad[selectedCategory]?.map(
            (item, index) => (
              <>
                <Grid
                  item
                  xs={12}
                  sm={5}
                  md={4}
                  lg={3}
                  display="flex"
                  alignItems="center"
                  textAlign="center"
                >
                  <Box sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    gap: 5
                  }}>
                    <Box
                      sx={{
                        width: "180px",
                        height: "125px",
                        padding: "16px 0px 0px 0px",
                        gap: "20px",
                        borderRadius: "12px",
                        backgroundColor: "#00999E",
                        color: "white",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e)=>handleChangeProcess(e,item?.about)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "150px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.title}
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: "#B1FCFF",
                            width: "fit-content",
                            borderRadius: 5,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mt: 1,
                            padding: "3px 8px",
                          }}
                        >
                          {item?.icon === "orange" &&  (
                            <Box
                              sx={{
                                height: "12px",
                                width: "12px",
                                border: "4px solid #FFE8E8",
                                borderRadius: "50%",
                                backgroundColor: "#F67A07",
                                mr: 1,
                              }}
                            />
                          )}
                          {item?.icon === "black" && (
                            <Box
                              sx={{
                                height: "12px",
                                width: "12px",
                                border: "4px solid #828282",
                                borderRadius: "50%",
                                backgroundColor: "#1C1C1C",
                                mr: 1,
                              }}
                            />
                          )}

                          {item?.icon === "green" && (
                            <Box
                              sx={{
                                height: "12px",
                                width: "12px",
                                border: "4px solid #20CF10",
                                borderRadius: "50%",
                                backgroundColor: "white",
                                mr: 1,
                              }}
                            />
                          )}
                          <Typography
                            sx={{
                              color: "black",
                              fontSize: "12px",
                            }}
                          >
                            {item?.about}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {index <
                viewMoreDataForAbroad[selectedCategory].length > 0 ? (
                  <Box
                    sx={{
                      color: "#00999E",
                    }}
                  >
                    <ArrowForwardIosIcon />
                  </Box>
                ) : null}
                  </Box>
                </Grid>
              </>
            )
          )}
        </Grid>
      </Box>
    </Card>
    {
      show && <div>
           <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItem: "center",
                        flexDirection: "column",
                        paddingTop: "20px",
                      }}
                    >
                      <img
                        src={dottedline}
                        alt="dotted-line"
                        width={5}
                        height={80}
                        style={{ margin: "auto" }}
                      />
                      <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
                    </Box>
      <ThirdCard 
      theme={theme}
      popup={popup}
      setPopup={setPopup}
      setCate={setCate}
      selectedCategory={selectedCategory}
      setSelectedCategory={setSelectedCategory}
      cate={cate}
      />
        </div>
    }
  </div>  )
}

export default SecondCard