// // import React from "react";
// // import { Box, Typography, Grid, Card, CardContent, CircularProgress } from "@mui/material";

// // // const ProgressCard = ({ title, items }) => {
// // //   return (
// // //     <Card elevation={3} sx={{ borderRadius: 4, padding: 2 }}>
// // //       <CardContent>
// // //         <Typography variant="h6" fontWeight="bold" gutterBottom>
// // //           {title}
// // //         </Typography>
// // //         <Typography variant="body2" color="textSecondary" gutterBottom>
// // //           Information and scopes of your current plan
// // //         </Typography>
// // //         {items.map((item, index) => (
// // //           <Box
// // //             key={index}
// // //             display="flex"
// // //             alignItems="center"
// // //             justifyContent="space-between"
// // //             my={1}
// // //           >
// // //             <Typography variant="body2">{item.label}</Typography>
// // //             <Box display="flex" alignItems="center">
// // //               <Typography
// // //                 variant="body2"
// // //                 sx={{
// // //                   color:
// // //                     item.status === "Completed"
// // //                       ? "green"
// // //                       : item.status === "In Progress"
// // //                       ? "#1D5EF4"
// // //                       : "gray",
// // //                   fontWeight: "bold",
// // //                 }}
// // //               >
// // //                 {item.status}
// // //               </Typography>
// // //             </Box>
// // //           </Box>
// // //         ))}
// // //       </CardContent>
// // //     </Card>
// // //   );
// // // };

// // const ProgressCard = () => {
// //   const progressData = [
// //     {
// //       title: "Career Mapping",
// //       items: [
// //         { label: "Countries", status: "In Progress" },
// //         { label: "Courses", status: "Not Taken" },
// //         { label: "Universities", status: "Completed" },
// //       ],
// //     },
// //     {
// //       title: "Personalized Mentoring",
// //       items: [
// //         { label: "Counseling", status: "In Progress" },
// //         { label: "Psychometric Test", status: "Completed" },
// //         { label: "Profile Building", status: "Not Taken" },
// //       ],
// //     },
// //     {
// //       title: "Admission Service",
// //       items: [
// //         { label: "Test Preparation", status: "In Progress" },
// //         { label: "SOP/Essays", status: "Not Taken" },
// //         { label: "Scholarship", status: "Completed" },
// //       ],
// //     },
// //     {
// //       title: "Post Enrollment Support",
// //       items: [
// //         { label: "Orientation Session", status: "In Progress" },
// //         { label: "Visa/Immigration/Travel & Fees", status: "Not Taken" },
// //         { label: "Accommodation", status: "Completed" },
// //       ],
// //     },
// //   ];

// //   return (
// //     <Box sx={{ padding: 4 }}>
// //       {/* Graph Section */}
// //       <Box
// //         sx={{
// //           backgroundColor: "#E0F7FA",
// //           borderRadius: 4,
// //           padding: 4,
// //           textAlign: "center",
// //           marginBottom: 4,
// //         }}
// //       >
// //         <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 2 }}>
// //           ~My Progress~
// //         </Typography>
// //         <Box
// //           sx={{
// //             height: 200,
// //             background: "linear-gradient(to top, #00BCD4, transparent)",
// //             borderRadius: 4,
// //           }}
// //         />
// //         <Typography variant="body1" color="textSecondary">
// //           ~Track Your Study Abroad Journey~
// //         </Typography>
// //       </Box>

// //       {/* Cards Section */}
// //       {/* <Grid container spacing={4}>
// //         {progressData.map((data, index) => (
// //           <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
// //             <ProgressCard title={data.title} items={data.items} />
// //           </Grid>
// //         ))}
// //       </Grid> */}
// //     </Box>
// //   );
// // };

// // export default ProgressCard;


// import React from "react";
// import { Box, Typography, Grid, Card, CircularProgress } from "@mui/material";

// const ProgressCard = () => {
//   const progressData = [
//     {
//       title: "Career Mapping",
//       progress: 70, // Representing 70% progress
//     },
//     {
//       title: "Personalized Mentoring",
//       progress: 50,
//     },
//     {
//       title: "Admission Service",
//       progress: 80,
//     },
//     {
//       title: "Post Enrollment Support",
//       progress: 40,
//     },
//   ];

//   return (
//     <Box sx={{ padding: 4 }}>
//       {/* Graph Section */}
//       <Box
//         sx={{
//           backgroundColor: "#E0F7FA",
//           borderRadius: 4,
//           padding: 4,
//           textAlign: "center",
//           marginBottom: 4,
//         }}
//       >
//         <Typography variant="h5" sx={{ fontWeight: "bold", marginBottom: 2 }}>
//           ~My Progress~
//         </Typography>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             position: "relative",
//             height: 200,
//             marginBottom: 2,
//           }}
//         >
//           <CircularProgress
//             variant="determinate"
//             value={75} // Adjust this value dynamically for overall progress
//             size={150}
//             thickness={5}
//             sx={{ color: "#1D5EF4" }}
//           />
//           <Box
//             sx={{
//               position: "absolute",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//             }}
//           >
//             <Typography
//               variant="h4"
//               fontWeight="bold"
//               color="#1D5EF4"
//             >{`75%`}</Typography>
//             <Typography variant="body2" color="textSecondary">
//               Overall Progress
//             </Typography>
//           </Box>
//         </Box>
//         <Typography variant="body1" color="textSecondary">
//           ~Track Your Study Abroad Journey~
//         </Typography>
//       </Box>

//       {/* Cards Section */}
//       <Grid container spacing={4}>
//         {progressData.map((data, index) => (
//           <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
//             <Card
//               elevation={3}
//               sx={{
//                 borderRadius: 4,
//                 padding: 2,
//                 height: "100%",
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "space-between",
//                 alignItems: "center",
//               }}
//             >
//               <Typography variant="h6" fontWeight="bold" gutterBottom>
//                 {data.title}
//               </Typography>
//               <CircularProgress
//                 variant="determinate"
//                 value={data.progress}
//                 size={100}
//                 thickness={5}
//                 sx={{ color: "#1D5EF4" }}
//               />
//               <Typography
//                 variant="h6"
//                 sx={{ marginTop: 2, fontWeight: "bold", color: "#1D5EF4" }}
//               >
//                 {`${data.progress}%`}
//               </Typography>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>
//     </Box>
//   );
// };

// export default ProgressCard;



// import React from "react";
// import { Box, Typography } from "@mui/material";

// const ProgressUI = () => {
//   const progressSteps = [
//     { label: "10%", left: "5%" },
//     { label: "20%", left: "20%" },
//     { label: "30%", left: "35%" },
//     { label: "40%", left: "50%" },
//     { label: "50%", left: "65%" },
//     { label: "60%", left: "80%" },
//   ];

//   return (
//     <Box
//     mt={5}
//       sx={{
//         backgroundColor: "#E0F7FA",
//         padding: "20px",
//         textAlign: "center",
//         borderRadius: "10px",
//         width: "100%", // Adjusted width
//         maxWidth: "1000px", // Limit the maximum width
//       }}
//     >
//       {/* Title */}
//       <Typography
//         variant="h4"
//         gutterBottom
//         sx={{ fontWeight: "bold", color: "#00838F" }}
//       >
//         ~My Progress~
//       </Typography>

//       {/* Progress Area */}
//       <Box
//         sx={{
//           position: "relative",
//           height: "200px",
//           backgroundColor: "#ffffff",
//           borderRadius: "10px",
//           overflow: "hidden",
//           margin: "20px auto",
//           width: "90%",
//           maxWidth: "800px",
//           boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
//         }}
//       >
//         {/* Gradient Effect */}
//         <Box
//           sx={{
//             position: "absolute",
//             bottom: 0,
//             left: 0,
//             right: 0,
//             height: "100%",
//             background: "linear-gradient(to top, #00ACC1, transparent)",
//             zIndex: 1,
//           }}
//         />

//         {/* Progress Line */}
//         <Box
//           sx={{
//             position: "absolute",
//             bottom: "50px",
//             left: "5%",
//             width: "90%",
//             height: "4px",
//             backgroundColor: "#00ACC1",
//             zIndex: 2,
//           }}
//         />

//         {/* Progress Circles */}
//         {progressSteps.map((step, index) => (
//           <Box
//             key={index}
//             sx={{
//               position: "absolute",
//               bottom: "40px",
//               left: step.left,
//               transform: "translateX(-50%)",
//               zIndex: 3,
//               textAlign: "center",
//             }}
//           >
//             {/* Circle */}
//             <Box
//               sx={{
//                 width: "50px",
//                 height: "50px",
//                 borderRadius: "50%",
//                 backgroundColor: "#fff",
//                 border: "4px solid #00ACC1",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
//               }}
//             >
//               <Typography
//                 variant="body2"
//                 sx={{ fontWeight: "bold", color: "#00838F" }}
//               >
//                 {step.label}
//               </Typography>
//             </Box>
//           </Box>
//         ))}
//       </Box>

//       {/* Subtitle */}
//       <Typography
//         variant="h6"
//         sx={{ fontWeight: "bold", color: "#00838F", marginTop: "10px" }}
//       >
//         ~Track Your Study Abroad Journey~
//       </Typography>
//     </Box>
//   );
// };

// export default ProgressUI;


import React from 'react'
import Abroad from '../MyProgress.js/FirstCard'

function ProgressUI() {
  return (
 <Abroad/>
  )
}

export default ProgressUI