import { Box, Container, Typography } from "@mui/material";
import React from "react";
import MainHeader from "src/Component/content-component/MainHeader";
import bgImage from "src/asset/universityfinder/universityViewBanner.png";

const UniversityFinderBanner = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "600px",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${bgImage}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        position: "relative" // Add this line
      }}
    >
      <Box
        sx={{
          position: "absolute", // Add this line
          bottom: 0,

          height: "auto",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          bgcolor: "#a6d5d5"
        }}
      >
        <Container>
          <Box sx={{ textAlign: "center", padding: "20px" }}>
            <MainHeader content={"Explore University"} color={"#454545"} />
            <Typography
              sx={{
                fontSize: { xs: "18px" },
                p: "10px 0",
                fontWeight: "550",
                color: "#656565"
              }}
            >
              Unlock your future with global opportunities. Explore top universities and diverse cultures worldwide. Embark on a life changing journey - study abroad with us!{" "}
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default UniversityFinderBanner;
