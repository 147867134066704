// import React from "react";
// import { Box, Typography, Select, MenuItem } from "@mui/material";

// const DropdownSelectionBox = ({
//   label,
//   options,
//   selectedValue,
//   onSelect,
//   width
// }) => (
//   <Box
//     sx={{
//       margin: "20px 0",
//       bgcolor: "rgb(239, 253, 255, 0.9)",
//       padding: "20px",
//       borderRadius: "20px",
//       border: "2px solid #00999E",
//       background: `linear-gradient(to right, 
//               rgba(167, 214, 215, 0.6) 10%,   
//               rgba(218, 240, 241, 0.5) 60%, 
//               rgba(255, 255, 255, 1) 100%)`,
//       display: "flex",
//       justifyContent: "space-between",
//       flexDirection: { xs: "column", md: "row" },
//       alignItems: "center"
//     }}
//   >
//     <Box
//       sx={{
//         width: { sm: "600px", xs: "320px" },
//         margin: "auto",
//         textAlign: { xs: "center", md: "start" }
//       }}
//     >
//       <Typography variant="h6" gutterBottom>
//         {label}
//       </Typography>
//     </Box>

//     <Box sx={{ width: { sm: "600px", xs: "320px" } }}>
//       <Select
//         value={selectedValue}
//         onChange={(event) => onSelect(event.target.value)}
//         displayEmpty
//         sx={{
//           width: width ?? "100%",
//           padding: "8px 16px",
//           borderRadius: "4px",
//           backgroundColor: "white",
//           "& .MuiSelect-outlined": {
//             // border: "2px solid #000",
//             color: "#000"
//           },
//           "&:hover .MuiOutlinedInput-notchedOutline": {
//             borderColor: "#00999E"
//           },
//           "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
//             borderColor: "#00999E"
//           }
//         }}
//         inputProps={{
//           sx: {
//             padding: "10px",
//             fontSize: "16px",
//             color: selectedValue ? "#000" : "rgba(0, 0, 0, 0.54)"
//           }
//         }}
//       >
//         {options.map((option) => (
//           <MenuItem key={option} value={option}>
//             {option}
//           </MenuItem>
//         ))}
//       </Select>
//     </Box>
//   </Box>
// );

// export default DropdownSelectionBox;


import React from "react";
import { Box, Typography, Autocomplete, TextField, Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const DropdownSelectionBoxMulti = ({
  label,
  options,
  selectedValues,
  onChange,
  width
}) => (
  <Box
    sx={{
      margin: "20px 0",
      bgcolor: "rgb(239, 253, 255, 0.9)",
      padding: "20px",
      borderRadius: "20px",
      border: "2px solid #00999E",
      background: `linear-gradient(to right, 
              rgba(167, 214, 215, 0.6) 10%,   
              rgba(218, 240, 241, 0.5) 60%, 
              rgba(255, 255, 255, 1) 100%)`,
      display: "flex",
      justifyContent: "space-between",
      flexDirection: { xs: "column", md: "row" },
      alignItems: "center"
    }}
  >
    <Box
      sx={{
        width: { sm: "600px", xs: "220px" },
        margin: "auto",
        textAlign: { xs: "center", md: "start" }
      }}
    >
      <Typography variant="h6" gutterBottom>
        {label}
      </Typography>
    </Box>

    <Box sx={{ width: { sm: "600px", xs: "200px" } }}>
      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={options}
        value={selectedValues}
        onChange={(event, newValue) => onChange(newValue)}
        disableCloseOnSelect
        getOptionLabel={(option) => option.title || option}
        renderOption={(props, option, { selected }) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title || option}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Options"
            placeholder="Favorites"
            sx={{
              width: width ?? "100%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px",
                backgroundColor: "white"
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00999E"
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#007B7E"
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#00999E"
              }
            }}
          />
        )}
      />
    </Box>
  </Box>
);

export default DropdownSelectionBoxMulti;
