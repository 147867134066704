import u2 from "../../asset/universitybanner/u2.png";
import u3 from "../../asset/universitybanner/u3.png";
import u4 from "../../asset/universitybanner/u4.png";
import u5 from "../../asset/universitybanner/u5.png";
import u6 from "../../asset/universitybanner/u6.png";
import bristol from "../../asset/universitybanner/bristol.png";
import birmingham from "../../asset/universitybanner/birmingham.png";
import glasgow from "../../asset/universitybanner/glasgow.png";
import imperial from "../../asset/universitybanner/imperial.png";
import queenmary from "../../asset/universitybanner/queenmary.png";
import liverpool from "../../asset/universitybanner/liverpool.png";
import edinburgh from "../../asset/universitybanner/edinburgh.png";
import southampton from "../../asset/universitybanner/southampton.png";
import durham from "../../asset/universitybanner/durham.png";

import leeds from "../../asset/universitybanner/leeds.png";
import adelaide from "../../asset/universitybanner/adelaide.png";
import anu from "../../asset/universitybanner/anu.png";
import macquarie from "../../asset/universitybanner/macquarie.png";
import melbourne from "../../asset/universitybanner/melbourne.png";
import monash from "../../asset/universitybanner/monashuniversity.png";
import queensland from "../../asset/universitybanner/queensland.png";
import sydney from "../../asset/universitybanner/sydney.png";
import technologysydney from "../../asset/universitybanner/technologysydney.png";
import unsw from "../../asset/universitybanner/unsw.png";
import westernaustralia from "../../asset/universitybanner/westernaustralia.png";
import brock from "../../asset/universitybanner/Brock.png";
import calgary from "../../asset/universitybanner/Calgary.png";
import dalhousie from "../../asset/universitybanner/Dalhousie.png";
import rru from "../../asset/universitybanner/RRU.png";
import toronto from "../../asset/universitybanner/Toronto.png";
import trent from "../../asset/universitybanner/Trinity.png";
import trinity from "../../asset/universitybanner/Trinity.png";
import vcc from "../../asset/universitybanner/VCC.png";
import waterloo from "../../asset/universitybanner/Waterloo.png";
import windsor from "../../asset/universitybanner/windsor.png";
import illinois from "../../asset/universitybanner/illinois.png";
import stanford from "../../asset/universitybanner/stanford.png";
import pennsylvania from "../../asset/universitybanner/pennsylvania.png";
import chicago from "../../asset/universitybanner/chicago.png";
import mit from "../../asset/universitybanner/mit.png";
import LMU from "../../asset/universitybanner/LMU.png";
import TMU from "../../asset/universitybanner/TMU.png";
import HBU from "../../asset/universitybanner/HBU.png";
import HU from "../../asset/universitybanner/HU.png";
import RWTH from "../../asset/universitybanner/RWTH.png";
import FUB from "../../asset/universitybanner/FUB.png";
import UOF from "../../asset/universitybanner/UOF.png";
import UOT from "../../asset/universitybanner/UOT.png";
import UOM from "../../asset/universitybanner/UOM.png";
import UOG from "../../asset/universitybanner/UOG.png";

import UPSL from "../../asset/universitybanner/UPSL.png";
import UPS from "../../asset/universitybanner/UPS.png";
import SU from "../../asset/universitybanner/SU.png";
import UGA from "../../asset/universitybanner/UGA.png";
import UDS from "../../asset/universitybanner/UDS.png";
import UDM from "../../asset/universitybanner/UDM.png";
import UDL from "../../asset/universitybanner/UDL.png";
import UDN from "../../asset/universitybanner/UDN.png";
import UDB from "../../asset/universitybanner/UDB.png";
import UPPS from "../../asset/universitybanner/UPPS.png";
import PDMilano from "../../asset/universitybanner/PDMilano.png";
import UOMilan from "../../asset/universitybanner/UOMilan.png";
import UOBologna from "../../asset/universitybanner/UOBologna.png";
import UOFlorence from "../../asset/universitybanner/UOFlorence.png";
import UOPadua from "../../asset/universitybanner/UOPadua.png";
import UOTurin from "../../asset/universitybanner/UOTurin.png";
import RLS from "../../asset/universitybanner/RLS.png";
import UONaples from "../../asset/universitybanner/UONaples.png";
import UOPisa from "../../asset/universitybanner/UOPisa.png";
import IED from "../../asset/universitybanner/IED.png";
import TDublin from "../../asset/universitybanner/TDublin.png";
import UCDublin from "../../asset/universitybanner/UCDublin.png";
import UOCork from "../../asset/universitybanner/UOCork.png";
import UOGalway from "../../asset/universitybanner/UOGalway.png";
import DCU from "../../asset/universitybanner/DCU.png";
import UOLimerick from "../../asset/universitybanner/UOLimerick.png";
import MU from "../../asset/universitybanner/MU.png";
import TUDublin from "../../asset/universitybanner/TUDublin.png";
import DBS from "../../asset/universitybanner/DBS.png";
import RCS from "../../asset/universitybanner/RCS.png";
import UOA from "../../asset/universitybanner/UOA.png";
import MUniversity from "../../asset/universitybanner/MUniversity.png";
import UOW from "../../asset/universitybanner/UOW.png";
import VUOW from "../../asset/universitybanner/VUOW.png";
import UCanterbury from "../../asset/universitybanner/UCanterbury.png";
import UOtago from "../../asset/universitybanner/UOtago.png";
import AUOT from "../../asset/universitybanner/AUOT.jpg";
import LUniversity from "../../asset/universitybanner/LUniversity.jpg";
import EIOT from "../../asset/universitybanner/EIOT.png";
import UCIC from "../../asset/universitybanner/UCIC.png";

const CaliforniaBanner = {
  title: "University of California",
  image: u2
};
const WisconsinBanner = {
  title: "University of Wisconsin-Madison",
  image: u3
};
const YeshivaBanner = {
  title: "Yeshiva University",
  image: u4
};
const UtahBanner = {
  title: "Southern Utah University",
  image: u5
};
const ColoradoBanner = {
  title: "Colorado State University",
  image: u6
};
const IllinoisBanner = {
  title: "The University of Illinois at Urbana-Champaign",
  image: illinois
};
const StanfordBanner = {
  title: "Stanford University",
  image: stanford
};
const PennsylvaniaBanner = {
  title: "University of Pennsylvania",
  image: pennsylvania
};
const ChicagoBanner = {
  title: "The University of Chicago",
  image: chicago
};
const MITBanner = {
  title: "The Massachusetts Institute of Technology (MIT)",
  image: mit
};




const BristolBanner = {
  title: "University of Bristol",
  image: bristol
};
const BirminghamBanner = {
  title: "The University of Birmingham",
  image: birmingham
};
const GlasgowBanner = {
  title: "The University of Glasgow",
  image: glasgow
};
const ImperialBanner = {
  title: "Imperial College London",
  image: imperial
};
const QueenMaryBanner = {
  title: "Queen Mary University of London",
  image: queenmary
};
const LiverpoolBanner = {
  title: "University of Liverpool",
  image: liverpool
};
const EdinburghBanner = {
  title: " University of Edinburgh",
  image: edinburgh
};
const SouthamptonBanner = {
  title: " University of Southampton",
  image: southampton
};
const DurhamBanner = {
  title: " Durham University",
  image: durham
};
const LeedsBanner = {
  title: "University of Leeds",
  image: leeds
};
// Australia University
const UNSWBanner = {
  title: "The University of New South Australia",
  image: unsw
};

const MonashBanner = {
  title: "Monash University",
  image: monash
};

const QueenslandBanner = {
  title: "University of Queensland",
  image: queensland
};

const WesternaustraliaBanner = {
  title: "University of Western Australia",
  image: westernaustralia
};
const AdelaideBanner = {
  title: "University of Adelaide",
  image: adelaide
};
const MelbourneBanner = {
  title: "University of Melbourne",
  image: melbourne
};
const SydneyBanner = {
  title: "University of Sydney",
  image: sydney
};
const AnuBanner = {
  title: "The Australian National University",
  image: anu
};
const TechnologySydneyBanner = {
  title: "The University of Technology Sydney",
  image: technologysydney
};
const MacquarieBanner = {
  title: "Macquarie University",
  image: macquarie
};
const TrentBanner = {
  title: "Trent University",
  image: trent
};
const TrinityBanner = {
  title: "Trinity Western University",
  image: trinity
};
const RRUBanner = {
  title: "Royal Roads University (RRU)",
  image: rru
};
const WaterlooBanner = {
  title: "The University of Waterloo",
  image: waterloo
};
const CalgeryBanner = {
  title: "University of Calgary Continuing Education",
  image: calgary
};
const DalhousieBanner = {
  title: "Dalhousie University",
  image: dalhousie
};
const BrockBanner = {
  title: "Brock University",
  image: brock
};
const WindsorBanner = {
  title: "The University of Windsor",
  image: windsor
};
const VCCBanner = {
  title: "Vancouver Community College, Vancouver, British Columbia",
  image: vcc
};
const TorontoBanner = {
  title: "University of Toronto",
  image: toronto
};

//Germany

const LMUBanner = {
  title: "Ludwig Maximilian University of Munich (LMU)",
  image: LMU
};

const TMUBanner = {
  title: "Technical University of Munich (TUM)",
  image: TMU
};

const HeidelbergUniversityBanner = {
  title: "Heidelberg University",
  image: HBU
};

const HumboldtUniversityBanner = {
  title: "Humboldt University of Berlin",
  image: HU
};

const RWTHBanner = {
  title: "RWTH Aachen University",
  image: RWTH
};

const FUBBanner = {
  title: "Freie Universität Berlin",
  image: FUB
};

const UOFBanner = {
  title: "University of Freiburg",
  image: UOF
};

const UOTBanner = {
  title: "University of Tübingen",
  image: UOT
};

const UOMBanner = {
  title: "University of Mannheim",
  image: UOM
};

const UOGBanner = {
  title: "University of Göttingen",
  image: UOG
};

//France

const UPSLBanner = {
  title: "Université PSL (Paris Sciences et Lettres)",
  image: UPSL
};

const UPSBanner = {
  title: "Université Paris-Saclay",
  image: UPS
};

const SUBanner = {
  title: "Sorbonne University",
  image: SU
};

const UGABanner = {
  title: "Université Grenoble Alpes",
  image: UGA
};
const UDSBanner = {
  title: "Université de Strasbourg",
  image: UDS
};
const UDMBanner = {
  title: "Université de Montpellier",
  image: UDM
};
const UDLBanner = {
  title: "Université de Lille",
  image: UDL
};
const UDNBanner = {
  title: "Université de Nantes",
  image: UDN
};
const UDBBanner = {
  title: "Université de Bordeaux",
  image: UDB
};
const UPPSBanner = {
  title: "Université Paris 1 Panthéon-Sorbonne",
  image: UPPS
};

//italy

const PDMilanoBanner = {
  title: "Politecnico di Milano",
  image: PDMilano
};

const UOMilanBanner = {
  title: "University of Milan",
  image: UOMilan
};

const UOBolognaBanner = {
  title: "University of Bologna",
  image: UOBologna
};

const UOFlorenceBanner = {
  title: "University of Florence",
  image: UOFlorence
};
const UOPaduaBanner = {
  title: "University of Padua",
  image: UOPadua
};
const UOTurinBanner = {
  title: "University of Turin",
  image: UOTurin
};
const RLSBanner = {
  title: "University of Rome La Sapienza",
  image: RLS
};
const UONaplesBanner = {
  title: "University of Naples Federico II",
  image: UONaples
};
const UOPisaBanner = {
  title: "The University of Pisa",
  image: UOPisa
};
const IEDBanner = {
  title: "Instituto Europeo di Design (IED), Milan",
  image: IED
};

//Ireland

const TCDublinBanner = {
  title: "Trinity College Dublin",
  image: TDublin
};
const UCDublinBanner = {
  title: "University College Dublin",
  image: UCDublin
};
const UOCorkBanner = {
  title: " University of Cork",
  image: UOCork
};
const UOGalwayBanner = {
  title: "University of Galway",
  image: UOGalway
};
const DCUBanner = {
  title: "Dublin City University (DCU)",
  image: DCU
};
const UOLimerickBanner = {
  title: "University of Limerick",
  image: UOLimerick
};
const MUBanner = {
  title: "Maynooth University",
  image: MU
};
const TUDublinBanner = {
  title: "Technological University Dublin",
  image: TUDublin
};
const DBSBanner = {
  title: "Dublin Business School",
  image: DBS
};
const RCSIBanner = {
  title: "Royal College of Surgeons in Ireland (RCSI)",
  image: RCS
};

//New-Zealand

const UOABanner = {
  title: "University of Auckland",
  image: UOA
};
const MUniversityBanner = {
  title: "Massey University",
  image: MUniversity
};
const UOWBanner = {
  title: "University of Waikato",
  image: UOW
};
const VUOWBanner = {
  title: "Victoria University of Wellington",
  image: VUOW
};
const UCanterburyBanner = {
  title: "University of Canterbury",
  image: UCanterbury
};
const UOtagoBanner = {
  title: "University of Otago",
  image: UOtago
};
const AUOTBanner = {
  title: "Auckland University of Technology",
  image: AUOT
};
const LUniversityBanner = {
  title: "Lincoln University",
  image: LUniversity
};
const EIOTBanner = {
  title: "Eastern Institute of Technology, Te Pūkenga",
  image: EIOT
};
const UCICBanner = {
  title: "UC International College",
  image: UCIC
};






export {
  CaliforniaBanner,
  WisconsinBanner,
  YeshivaBanner,
  UtahBanner,
  ColoradoBanner,
  IllinoisBanner,
  StanfordBanner,
  PennsylvaniaBanner,
  ChicagoBanner,
  MITBanner,
  BristolBanner,
  BirminghamBanner,
  ImperialBanner,
  QueenMaryBanner,
  LiverpoolBanner,
  EdinburghBanner,
  SouthamptonBanner,
  DurhamBanner,
  GlasgowBanner,
  LeedsBanner,
  UNSWBanner,
  MonashBanner,
  QueenslandBanner,
  WesternaustraliaBanner,
  AdelaideBanner,
  MelbourneBanner,
  SydneyBanner,
  AnuBanner,
  TechnologySydneyBanner,
  MacquarieBanner,
  TrentBanner,
  TrinityBanner,
  RRUBanner,
  WaterlooBanner,
  CalgeryBanner,
  DalhousieBanner,
  BrockBanner,
  WindsorBanner,
  VCCBanner,
  TorontoBanner,

  //Germany
  LMUBanner,
  TMUBanner,
  HeidelbergUniversityBanner,
  HumboldtUniversityBanner,
  FUBBanner,
  RWTHBanner,
  UOFBanner,
  UOTBanner,
  UOMBanner,
  UOGBanner,

  //France

  UPSLBanner,
  UPSBanner,
  SUBanner,
  UGABanner,
  UDSBanner,
  UDMBanner,
  UDLBanner,
  UDNBanner,
  UDBBanner,
  UPPSBanner,

  //Italy

  PDMilanoBanner,
  UOMilanBanner,
  UOBolognaBanner,
  UOFlorenceBanner,
  UOPaduaBanner,
  UOTurinBanner,
  RLSBanner,
  UONaplesBanner,
  UOPisaBanner,
  IEDBanner,

  //Ireland
  TCDublinBanner,
  UCDublinBanner,
  UOCorkBanner,
  UOGalwayBanner,
  DCUBanner,
  UOLimerickBanner,
  MUBanner,
  TUDublinBanner,
  DBSBanner,
  RCSIBanner,

  //New-Zealand
  UOABanner,
  MUniversityBanner,
  UOWBanner,
  VUOWBanner,
  UCanterburyBanner,
  UOtagoBanner,
  AUOTBanner,
  LUniversityBanner,
  EIOTBanner,
  UCICBanner,

};
