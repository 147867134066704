import React, { useState, useEffect } from "react";
import { Box, Checkbox, Grid, TextField, Typography } from "@mui/material";

const PermanentAndMailingAddress = ({
  sendDataToParent,
  setPermanentAddress,
  permanentAddress,
  mailingAddress,
  setMailingAddress
}) => {
  // const [mailingAddress, setMailingAddress] = useState({
  //   address: "",
  //   state: "",
  //   city: "",
  //   pincode: ""
  // });
  const [isChecked, setIsChecked] = useState(false);

  // Use effect to send data when either address changes
  useEffect(() => {
    // console.log("permanentAddress :>> ", permanentAddress);
    sendDataToParent({
      permanentAddress: permanentAddress, // Correct key should be 'permanentaddress'
      mailingAddress: mailingAddress
    });
  }, [permanentAddress, mailingAddress, sendDataToParent]);

  const handlePermanentAddressChange = (e) => {
    const { name, value } = e.target;
    setPermanentAddress((prev) => ({ ...prev, [name]: value }));

    // If checkbox is checked, update mailing address to match permanent address
    if (isChecked) {
      setMailingAddress((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleMailingAddressChange = (e) => {
    const { name, value } = e.target;
    setMailingAddress((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked);

    // If checked, set mailing address to be the same as permanent address
    if (checked) {
      setMailingAddress(permanentAddress);
    } else {
      setMailingAddress({
        address: "",
        state: "",
        city: "",
        pincode: ""
      }); // Reset mailing address when unchecked
    }
  };

  return (
    <>
      <Section title="PERMANENT ADDRESS" />
      <AddressFields
        address={permanentAddress}
        onChange={handlePermanentAddressChange}
      />
      <Box sx={{ display: "flex", alignItems: "center", paddingTop: "30px" }}>
        <Section title="Mailing ADDRESS" />
        <CheckboxLabel checked={isChecked} onChange={handleCheckboxChange} />
      </Box>
      <AddressFields
        address={mailingAddress}
        onChange={handleMailingAddressChange}
        disabled={isChecked} // Disable input fields if checkbox is checked
      />
    </>
  );
};

const Section = ({ title }) => (
  <Typography
    variant="h5"
    sx={{ fontSize: { xs: "18px" }, paddingBottom: "10px" }}
  >
    {title}
  </Typography>
);

const AddressFields = ({ address, onChange, disabled = false }) => (
  <Grid container spacing={2}>
    {["address", "state", "city", "pincode"].map((field, index) => (
      <Grid key={index} item lg={3} xs={12}>
        <TextField
          label={capitalize(field)}
          name={field}
          value={address[field] || ""}
          onChange={onChange}
          type={field === "pincode" ? "number" : "text"}
          variant="outlined"
          fullWidth
          disabled={disabled}
        />
      </Grid>
    ))}
  </Grid>
);

const CheckboxLabel = ({ checked, onChange }) => (
  <Typography sx={{ fontSize: "18px", paddingLeft: "30px", color: "grey" }}>
    <Checkbox checked={checked} onChange={onChange} />
    (same as permanent address)
  </Typography>
);

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export default PermanentAndMailingAddress;
