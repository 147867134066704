// import {
//   Box,
//   Button,
//   Card,
//   Container,
//   Grid,
//   Stack,
//   Typography,
//   useMediaQuery,
//   useTheme,
//   Hidden,
//   createTheme,
//   Paper,
// } from "@mui/material";
// import React, { useEffect, useState } from "react";
// import service1 from "../../asset/service1.svg";
// import service2 from "../../asset/service2.svg";
// import service3 from "../../asset/service3.svg";
// import service4 from "../../asset/service4.svg";
// import service11 from "../../asset/service11.svg";
// import service22 from "../../asset/service22.svg";
// import service33 from "../../asset/service33.svg";
// import service44 from "../../asset/service44.svg";
// import line from "../../asset/line.svg";
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import { viewmoredata } from "./ViewmoreData";
// import { viewMoreDataForAbroad } from "./ViewMoreDataForAbroad";
// import dottedline from "../../asset/dottedline.svg";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import "../../globle.css";
// import Header from "../content-component/Header";
// import Right from "./AboradFolder/Right";
// import Wrong from "./AboradFolder/Wrong";
// import Builder from "./AboradFolder/Builder";


// const Abroad = () => {
//   const theme = useTheme();
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
//   const [hoveredStep, setHoveredStep] = useState(null);
//   const [popup, setPopup] = useState(false);
//   const [cate,setCate] = useState(3); 

  

//   const handlepopup = () => {
//     setPopup(!popup);
//   };

//   const [selectedCategory, setSelectedCategory] = useState(null);
//   const handleButtonClick = (category) => {
//     setSelectedCategory((prevCategory) =>
//       prevCategory === category ? null : category
//     );
//   };


//   const largeScreen = createTheme({
//     breakpoints: {
//       values: {
//         xxl: 1920,
//       },
//     },
//   });

//   useEffect(() => {
//     if (selectedCategory && viewMoreDataForAbroad[selectedCategory].length > 0) {
//       const firstItemIcon = viewMoreDataForAbroad[selectedCategory][0]?.icon;
//       if (firstItemIcon === "orange") setCate(1);
//       else if (firstItemIcon === "black") setCate(2);
//       else if (firstItemIcon === "green") setCate(3);
//       else setCate(0); 
//     }
//   }, [selectedCategory, viewMoreDataForAbroad]);


//   const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));
//   return (
//     <div>
//       <Stack
//         gap={3}
//         direction="column"
//         sx={{
//           backgroundColor: "#FfffFF",
//           textAlign: "center",
//           padding: "60px 0",
//         }}
//       >
//         {" "}
//         <Header
//           color="#00999e"
//           textAlign="center"
//           content="Track Your Study Abroad Journey"
//         />
//         <Container maxWidth={isLargeScreen ? "xl" : "lg"}>
//           <Box
//             rowGap={3}
//             paddingTop={6}
//             columnGap={2}
//             display="grid"
//             gridTemplateColumns={{
//               xs: "repeat(1, 1fr)",
//               sm: "repeat(2, 1fr)",
//               md: "repeat(4, 1fr)",
//             }}
//           >
//             {/* box-1 */}
//             <Box
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               flexDirection="column"
//               sx={{
//                 position: "relative",
//                 zIndex: 2,
//               }}
//             >
//               <Box
//                 sx={{
//                   borderRadius: "50%",
//                   color: "#00999e",
//                   // position: "absolute",
//                   right: { xs: "10%", sm: "20%", md: "10%", lg: "10%" },
//                   top: { xs: "-6%", sm: "" - (6 % ""), md: "-5%", lg: "-5%" },
//                   zIndex: 9,
//                 }}
//               ></Box>
//               <Card
//                 sx={{
//                   width: 200,
//                   height: 200,
//                   borderTopWidth: "50%",
//                   "&:hover": {
//                     backgroundColor: "#5cd2d6",
//                     color: "white",
//                     zIndex: 1,
//                     "& .css-1rq26sm-MuiTypography-root": {
//                       color: "black",
//                     },
//                   },
//                   position: "relative",
//                   zIndex: 1,
//                   overflow: "visible",
//                 }}
//                 onMouseEnter={() => setHoveredStep(1)}
//                 onMouseLeave={() => setHoveredStep(null)}
//                 className="pseudo-partial-border"
//               >
//                 <Box padding={2}>
//                   <img
//                     alt="Hexagon Image"
//                     src={hoveredStep === 1 ? service11 : service1}
//                   />
                
//                 </Box>
//                 <Box>
//                   <Typography sx={{ fontWeight: 700, padding: 1 }}>
//                     PERSONALIZED MENTORING
//                   </Typography>
//                 </Box>
//               </Card>
//               <Box>
//                 <Button
//                   variant="contained"
//                   sx={{
//                     backgroundColor: "#00999E",
//                     mt: 2,
//                     "&:hover": {
//                       backgroundColor: "#007f80",
//                       color: "#fff",
//                     },
//                   }}
//                   endIcon={
//                     selectedCategory === "personalized" ? (
//                       <KeyboardArrowUpIcon />
//                     ) : (
//                       <KeyboardArrowDownIcon />
//                     )
//                   }
//                   onClick={() => handleButtonClick("personalized")}
//                 >
//                   View More
//                 </Button>
//               </Box>
//               <Box
//                 sx={{
//                   height: isSmallScreen ? "auto" : "100px",
//                 }}
//               >
//                 {selectedCategory === "personalized" && (
//                   <div>
//                     <Box
//                       display="flex"
//                       justifyContent="center"
//                       alignItems="center"
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItem: "center",
//                         flexDirection: "column",
//                         paddingTop: "20px",
//                       }}
//                     >
//                       <img
//                         src={dottedline}
//                         alt="dotted-line"
//                         width={5}
//                         height={80}
//                         style={{ margin: "auto" }}
//                       />
//                       <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
//                     </Box>
//                     {isSmallScreen ? (
//                       <Card sx={{ mt: 3, mb: 5 }}>
//                         <Box
//                           sx={{
//                             display: "flex",
//                             justifyContent: "center",
//                           }}
//                         >
//                           <Grid
//                             container
//                             display="flex"
//                             justifyContent="center"
//                             alignItems="center"
//                           >
//                             {viewmoredata[selectedCategory].map(
//                               (item, index) => (
//                                 <>
//                                   <Grid
//                                     item
//                                     lg={4}
//                                     display="flex"
//                                     flexDirection={
//                                       isSmallScreen ? "column" : "row"
//                                     }
//                                     alignItems="center"
//                                     textAlign="center"
//                                   >
//                                     <Box py={4}>
//                                       <Typography sx={{ fontWeight: 700 }}>
//                                         {item?.title}
//                                       </Typography>
//                                       <Box
//                                         display="flex"
//                                         justifyContent="space-evenly"
//                                       >
//                                         <img src={line} alt />
//                                       </Box>
//                                       <Typography
//                                         sx={{
//                                           height: "100px",
//                                           padding: "10px",
//                                         }}
//                                       >
//                                         {item.about}
//                                       </Typography>
//                                     </Box>
//                                   </Grid>
//                                   {index <
//                                   viewmoredata[selectedCategory].length - 1 ? (
//                                     <Box
//                                       sx={{
//                                         color: "#00999E",
//                                         paddingLeft: "20px",
//                                       }}
//                                     >
//                                       {isSmallScreen ? (
//                                         <KeyboardArrowDownIcon />
//                                       ) : (
//                                         <ArrowForwardIosIcon />
//                                       )}
//                                     </Box>
//                                   ) : null}
//                                 </>
//                               )
//                             )}
//                           </Grid>
//                         </Box>
//                       </Card>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 )}
//               </Box>
//             </Box>

//             {/* box-2 */}
//             <Box
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               flexDirection="column"
//               sx={{
//                 position: "relative",
//                 zIndex: 2,
//               }}
//             >
//               <Box
//                 sx={{
//                   borderRadius: "50%",
//                   color: "#00999e",
//                   position: "absolute",
//                   right: "10%",
//                   top: "-5%",
//                   zIndex: 9,
//                 }}
//               ></Box>
//               <Card
//                 sx={{
//                   width: 200,
//                   height: 200,
//                   borderTopWidth: "50%",
//                   "&:hover": {
//                     backgroundColor: "#5cd2d6",
//                     color: "white",
//                     zIndex: 1,
//                     "& .css-1rq26sm-MuiTypography-root": {
//                       color: "black",
//                     },
//                   },
//                   position: "relative",
//                   zIndex: 1,
//                   overflow: "visible",
//                 }}
//                 onMouseEnter={() => setHoveredStep(2)}
//                 onMouseLeave={() => setHoveredStep(null)}
//                 className="pseudo-partial-border"
//               >
//                 <Box padding={2}>
//                   <img
//                     alt="Hexagon Image"
//                     src={hoveredStep === 2 ? service22 : service2}
//                   />
//                 </Box>
//                 <Box>
//                   <Typography sx={{ fontWeight: 700, padding: 1 }}>
//                     CAREER <br /> MAPPING
//                   </Typography>
//                 </Box>
//               </Card>
//               <Box>
//                 <Button
//                   variant="contained"
//                   sx={{
//                     backgroundColor: "#00999E",
//                     mt: 2,
//                     "&:hover": {
//                       backgroundColor: "#007f80",
//                       color: "#fff",
//                     },
//                   }}
//                   endIcon={
//                     selectedCategory === "career" ? (
//                       <KeyboardArrowUpIcon />
//                     ) : (
//                       <KeyboardArrowDownIcon />
//                     )
//                   }
//                   onClick={() => handleButtonClick("career")}
//                 >
//                   View More
//                 </Button>
//               </Box>
//               <Box
//                 sx={{
//                   height: isSmallScreen ? "auto" : "100px",
//                 }}
//               >
//                 {selectedCategory === "career" && (
//                   <div>
//                     <Box
//                       display="flex"
//                       justifyContent="center"
//                       alignItems="center"
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItem: "center",
//                         flexDirection: "column",
//                         paddingTop: "20px",
//                       }}
//                     >
//                       <img
//                         src={dottedline}
//                         alt="dotted-line"
//                         width={5}
//                         height={80}
//                         style={{ margin: "auto" }}
//                       />
//                       <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
//                     </Box>
//                     {isSmallScreen ? (
//                       <Card sx={{ mt: 3, mb: 5 }}>
//                         <Box
//                           sx={{
//                             display: "flex",
//                             justifyContent: "center",
//                           }}
//                         >
//                           <Grid
//                             container
//                             display="flex"
//                             justifyContent="center"
//                             alignItems="center"
//                           >
//                             {viewmoredata[selectedCategory].map(
//                               (item, index) => (
//                                 <>
//                                   <Grid
//                                     item
//                                     lg={4}
//                                     display="flex"
//                                     flexDirection={
//                                       isSmallScreen ? "column" : "row"
//                                     }
//                                     alignItems="center"
//                                     textAlign="center"
//                                   >
//                                     <Box py={4}>
//                                       {/*  <Box><img src={item?.icon}
//                                       alt={item?.title} /></Box> */}
//                                       <Typography sx={{ fontWeight: 700 }}>
//                                         {item?.title}
//                                       </Typography>
//                                       <Box
//                                         display="flex"
//                                         justifyContent="space-evenly"
//                                       >
//                                         <img src={line} alt />
//                                       </Box>
//                                       <Typography
//                                         sx={{
//                                           height: "100px",
//                                           padding: "10px",
//                                         }}
//                                       >
//                                         {item.about}
//                                       </Typography>
//                                     </Box>
//                                   </Grid>
//                                   {index <
//                                   viewmoredata[selectedCategory].length - 1 ? (
//                                     <Box
//                                       sx={{
//                                         color: "#00999E",
//                                         paddingLeft: "20px",
//                                       }}
//                                     >
//                                       {isSmallScreen ? (
//                                         <KeyboardArrowDownIcon />
//                                       ) : (
//                                         <ArrowForwardIosIcon />
//                                       )}
//                                     </Box>
//                                   ) : null}
//                                 </>
//                               )
//                             )}
//                           </Grid>
//                         </Box>
//                       </Card>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 )}
//               </Box>
//             </Box>

//             {/* box-3 */}
//             <Box
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               flexDirection="column"
//               sx={{
//                 position: "relative",
//                 zIndex: 2,
//               }}
//             >
//               <Box
//                 sx={{
//                   borderRadius: "50%",
//                   color: "#00999e",
//                   position: "absolute",
//                   right: "10%",
//                   top: "-5%",
//                   zIndex: 9,
//                 }}
//               ></Box>
//               <Card
//                 sx={{
//                   width: 200,
//                   height: 200,
//                   borderTopWidth: "50%",
//                   "&:hover": {
//                     backgroundColor: "#5cd2d6",
//                     color: "white",
//                     zIndex: 1,
//                     "& .css-1rq26sm-MuiTypography-root": {
//                       color: "black",
//                     },
//                   },
//                   position: "relative",
//                   zIndex: 1,
//                   overflow: "visible",
//                 }}
//                 onMouseEnter={() => setHoveredStep(3)}
//                 onMouseLeave={() => setHoveredStep(null)}
//                 className="pseudo-partial-border"
//               >
//                 <Box padding={2}>
//                   <img
//                     alt="Hexagon Image"
//                     src={hoveredStep === 3 ? service33 : service3}
//                   />
//                 </Box>
//                 <Box>
//                   <Typography sx={{ fontWeight: 700, padding: 1 }}>
//                     ADMISSION <br /> SERVICES
//                   </Typography>
//                 </Box>
//               </Card>
//               <Box>
//                 <Button
//                   variant="contained"
//                   sx={{
//                     backgroundColor: "#00999E",
//                     mt: 2,
//                     "&:hover": {
//                       backgroundColor: "#007f80",
//                       color: "#fff",
//                     },
//                   }}
//                   endIcon={
//                     selectedCategory === "admission" ? (
//                       <KeyboardArrowUpIcon />
//                     ) : (
//                       <KeyboardArrowDownIcon />
//                     )
//                   }
//                   onClick={() => handleButtonClick("admission")}
//                 >
//                   View More
//                 </Button>
//               </Box>
//               <Box
//                 sx={{
//                   height: isSmallScreen ? "auto" : "100px",
//                 }}
//               >
//                 {selectedCategory === "admission" && (
//                   <div>
//                     <Box
//                       display="flex"
//                       justifyContent="center"
//                       alignItems="center"
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItem: "center",
//                         flexDirection: "column",
//                         paddingTop: "20px",
//                       }}
//                     >
//                       <img
//                         src={dottedline}
//                         alt="dotted-line"
//                         width={5}
//                         height={80}
//                         style={{ margin: "auto" }}
//                       />
//                       <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
//                     </Box>
//                     {isSmallScreen ? (
//                       <Card sx={{ mt: 3, mb: 5 }}>
//                         <Box
//                           sx={{
//                             display: "flex",
//                             justifyContent: "center",
//                           }}
//                         >
//                           <Grid
//                             container
//                             display="flex"
//                             justifyContent="center"
//                             alignItems="center"
//                           >
//                             {viewmoredata[selectedCategory].map(
//                               (item, index) => (
//                                 <>

//                                   <Grid
//                                     item
//                                     lg={4}
//                                     display="flex"
//                                     flexDirection={
//                                       isSmallScreen ? "column" : "row"
//                                     }
//                                     alignItems="center"
//                                     textAlign="center"
//                                   >
//                                     <Box py={4}>
//                                       {/*  <Box><img src={item?.icon}
//                                       alt={item?.title} /></Box> */}
//                                       <Typography sx={{ fontWeight: 700 }}>
//                                         {item?.title}
//                                       </Typography>
//                                       <Box
//                                         display="flex"
//                                         justifyContent="space-evenly"
//                                       >
//                                         <img src={line} alt />
//                                       </Box>
//                                       <Typography
//                                         sx={{
//                                           height: "100px",
//                                           padding: "10px",
//                                         }}
//                                       >
//                                         {item.about}
//                                       </Typography>
//                                     </Box>
//                                   </Grid>
//                                   {index <
//                                   viewmoredata[selectedCategory].length - 1 ? (
//                                     <Box
//                                       sx={{
//                                         color: "#00999E",
//                                         paddingLeft: "20px",
//                                       }}
//                                     >
//                                       {isSmallScreen ? (
//                                         <KeyboardArrowDownIcon />
//                                       ) : (
//                                         <ArrowForwardIosIcon />
//                                       )}
//                                     </Box>
//                                   ) : null}
//                                 </>
//                               )
//                             )}
//                           </Grid>
//                         </Box>
//                       </Card>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 )}
//               </Box>
//             </Box>

//             {/* box-4 */}
//             <Box
//               display="flex"
//               justifyContent="center"
//               alignItems="center"
//               flexDirection="column"
//               sx={{
//                 position: "relative",
//                 zIndex: 2,
//               }}
//             >
//               <Box
//                 sx={{
//                   borderRadius: "50%",
//                   color: "#00999e",
//                   position: "absolute",
//                   right: "10%",
//                   top: "-5%",
//                   zIndex: 9,
//                 }}
//               ></Box>
//               <Card
//                 sx={{
//                   width: 200,
//                   height: 200,
//                   borderTopWidth: "50%",
//                   "&:hover": {
//                     backgroundColor: "#5cd2d6",
//                     color: "white",
//                     zIndex: 1,
//                     "& .css-1rq26sm-MuiTypography-root": {
//                       color: "black",
//                     },
//                   },
//                   position: "relative",
//                   zIndex: 1,
//                   overflow: "visible",
//                 }}
//                 onMouseEnter={() => setHoveredStep(4)}
//                 onMouseLeave={() => setHoveredStep(null)}
//                 className="pseudo-partial-border"
//               >
//                 <Box padding={2}>
//                   <img
//                     alt="Hexagon Image"
//                     src={hoveredStep === 4 ? service44 : service4}
//                   />
//                 </Box>
//                 <Box>
//                   <Typography sx={{ fontWeight: 700, padding: 1 }}>
//                     POST ENROLMENT SUPPORT
//                   </Typography>
//                 </Box>
//               </Card>
//               <Box>
//                 <Button
//                   variant="contained"
//                   sx={{
//                     backgroundColor: "#00999E",
//                     mt: 2,
//                     "&:hover": {
//                       backgroundColor: "#007f80",
//                       color: "#fff",
//                     },
//                   }}
//                   endIcon={
//                     selectedCategory === "personalized" ? (
//                       <KeyboardArrowUpIcon />
//                     ) : (
//                       <KeyboardArrowDownIcon />
//                     )
//                   }
//                   onClick={() => handleButtonClick("post")}
//                 >
//                   View More
//                 </Button>
//               </Box>
//               <Box
//                 sx={{
//                   height: isSmallScreen ? "auto" : "100px",
//                 }}
//               >
//                 {selectedCategory === "post" && (
//                   <div>
//                     <Box
//                       display="flex"
//                       justifyContent="center"
//                       alignItems="center"
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItem: "center",
//                         flexDirection: "column",
//                         paddingTop: "20px",
//                       }}
//                     >
//                       <img
//                         src={dottedline}
//                         alt="dotted-line"
//                         width={5}
//                         height={80}
//                         style={{ margin: "auto" }}
//                       />
//                       <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
//                     </Box>

//                     {isSmallScreen ? (
//                       <Card sx={{ mt: 3, mb: 5 }}>
//                         <Box
//                           sx={{
//                             display: "flex",
//                             justifyContent: "center",
//                           }}
//                         >
//                           <Grid
//                             container
//                             display="flex"
//                             justifyContent="center"
//                             alignItems="center"
//                           >
//                             {viewmoredata[selectedCategory].map(
//                               (item, index) => (
//                                 <>
//                                   <Grid
//                                     item
//                                     lg={4}
//                                     display="flex"
//                                     flexDirection={
//                                       isSmallScreen ? "column" : "row"
//                                     }
//                                     alignItems="center"
//                                     textAlign="center"
//                                   >
//                                     <Box py={4}>
//                                       {/*  <Box><img src={item?.icon}
//                                           alt={item?.title} /></Box> */}
//                                       <Typography sx={{ fontWeight: 700 }}>
//                                         {item?.title}
//                                       </Typography>
//                                       <Box
//                                         display="flex"
//                                         justifyContent="space-evenly"
//                                       >
//                                         <img src={line} alt />
//                                       </Box>
//                                       <Typography
//                                         sx={{
//                                           height: "100px",
//                                           padding: "10px",
//                                         }}
//                                       >
//                                         {item.about}
//                                       </Typography>
//                                     </Box>
//                                   </Grid>
//                                   {index <
//                                   viewmoredata[selectedCategory].length - 1 ? (
//                                     <Box
//                                       sx={{
//                                         color: "#00999E",
//                                         paddingLeft: "20px",
//                                       }}
//                                     >
//                                       {isSmallScreen ? (
//                                         <KeyboardArrowDownIcon />
//                                       ) : (
//                                         <ArrowForwardIosIcon />
//                                       )}
//                                     </Box>
//                                   ) : null}
//                                 </>
//                               )
//                             )}
//                           </Grid>
//                         </Box>
//                       </Card>
//                     ) : (
//                       ""
//                     )}
//                   </div>
//                 )}
//               </Box>
//             </Box>
//           </Box>

//           <Hidden smDown>
//             {selectedCategory && (
//               <div>
//                 <Card sx={{ mt: 3, mb: 5 }}>
//                   <Box
//                     sx={{
//                       display: "flex",
//                       justifyContent: "center",
//                       padding: "3% 7%",
//                     }}
//                   >
//                     <Grid
//                       container
//                       display="flex"
//                       justifyContent="space-between"
//                       alignItems="center"
//                     >
//                       {viewMoreDataForAbroad[selectedCategory].map(
//                         (item, index) => (
//                           <>
//                             <Grid
//                               item
//                               xs={12}
//                               sm={5}
//                               md={4}
//                               lg={3}
//                               display="flex"
//                               alignItems="center"
//                               textAlign="center"
//                             >
//                               <Box sx={{
//                                 display:'flex',
//                                 justifyContent:'center',
//                                 alignItems:'center',
//                                 gap: 5
//                               }}>
//                                 <Box
//                                   sx={{
//                                     width: "180px",
//                                     height: "125px",
//                                     padding: "16px 0px 0px 0px",
//                                     gap: "20px",
//                                     borderRadius: "12px",
//                                     backgroundColor: "#00999E",
//                                     color: "white",
//                                     display: "flex",
//                                     justifyContent: "center",
//                                     alignItems: "center",
//                                     textAlign: "center",
//                                     cursor: "pointer",
//                                   }}
//                                 >
//                                   <Box
//                                     sx={{
//                                       display: "flex",
//                                       flexDirection: "column",
//                                       alignItems: "center",
//                                       width: "150px",
//                                     }}
//                                   >
//                                     <Typography
//                                       sx={{
//                                         fontSize: "14px",
//                                         fontWeight: "bold",
//                                       }}
//                                     >
//                                       {item?.title}
//                                     </Typography>
//                                     <Box
//                                       sx={{
//                                         bgcolor: "#B1FCFF",
//                                         width: "fit-content",
//                                         borderRadius: 5,
//                                         display: "flex",
//                                         alignItems: "center",
//                                         justifyContent: "center",
//                                         mt: 1,
//                                         padding: "3px 8px",
//                                       }}
//                                     >
//                                       {item?.icon === "orange" &&  (
//                                         <Box
//                                           sx={{
//                                             height: "12px",
//                                             width: "12px",
//                                             border: "4px solid #FFE8E8",
//                                             borderRadius: "50%",
//                                             backgroundColor: "#F67A07",
//                                             mr: 1,
//                                           }}
//                                         />
//                                       )}
//                                       {item?.icon === "black" && (
//                                         <Box
//                                           sx={{
//                                             height: "12px",
//                                             width: "12px",
//                                             border: "4px solid #828282",
//                                             borderRadius: "50%",
//                                             backgroundColor: "#1C1C1C",
//                                             mr: 1,
//                                           }}
//                                         />
//                                       )}

//                                       {item?.icon === "green" && (
//                                         <Box
//                                           sx={{
//                                             height: "12px",
//                                             width: "12px",
//                                             border: "4px solid #20CF10",
//                                             borderRadius: "50%",
//                                             backgroundColor: "white",
//                                             mr: 1,
//                                           }}
//                                         />
//                                       )}
//                                       <Typography
//                                         sx={{
//                                           color: "black",
//                                           fontSize: "12px",
//                                         }}
//                                       >
//                                         {item?.about}
//                                       </Typography>
//                                     </Box>
//                                   </Box>
//                                 </Box>
//                                 {index <
//                             viewMoreDataForAbroad[selectedCategory].length > 0 ? (
//                               <Box
//                                 sx={{
//                                   color: "#00999E",
//                                 }}
//                               >
//                                 <ArrowForwardIosIcon />
//                               </Box>
//                             ) : null}
//                               </Box>
//                             </Grid>


//                           </>
//                         )
//                       )}
//                     </Grid>
//                   </Box>
//                 </Card>
//               </div>
//             )}
//           </Hidden>
// {
//     cate !== 0 &&   <Paper
//     sx={{
//       marginTop: "20px",
//       height: "auto", // Remove fixed height
//       display: "flex",
//       flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens, horizontally on larger ones
//       justifyContent: "space-around",
//       padding: { xs: "16px", sm: "20px" }, // Adjust padding for small screens
//     }}
//   >
//     <Box
//       sx={{
//         width: { xs: "100%", sm: "200px" }, // Full width on small screens, fixed width on large screens
//         height: "128px",
//         opacity: "0.9", // Fixed opacity
//         display: "flex",
//         flexDirection: "row", // Keep items in a row
//         justifyContent: "center",
//         alignItems: "center",
//         gap: "12px",
//         marginTop: { xs: "0", sm: "157.5px" }, // Adjust margin for small screens
//         marginLeft: { xs: "0", sm: "50px" }, // Adjust margin for small screens
//         bgcolor: "#00999E",
//         borderRadius: "20px",
//       }}
//     >
//       <Typography color="white">Counselling</Typography>
//     </Box>

//     {/* <Box
//                       display="flex"
//                       justifyContent="center"
//                       alignItems="center"
//                       sx={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItem: "center",
//                         flexDirection: "column",
//                         paddingTop: "20px",
//                       }}
//                     >
//                       <img
//                         src={dottedline}
//                         alt="dotted-line"
//                         width={5}
//                         height={80}
//                         style={{ margin: "auto" }}
//                       />
//                       <KeyboardArrowDownIcon sx={{ color: "#00999E" }} />
//                     </Box> */}

//       {
//             cate !== 0 && cate===1 ? <Right/>: cate===2 ? <Wrong/> : cate===3 ? <Builder/> : ''   }
  
//   </Paper>
// }


//         </Container>
//       </Stack>
//     </div>
//   );
// };

// export default Abroad;

import React from 'react'

function Abroad() {
  return (
    <div>Abroad</div>
  )
}

export default Abroad