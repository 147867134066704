import React from 'react'
import { Box, Typography, Button } from "@mui/material";
import Wrong1 from '../../../../asset/Wrong_abroad.svg'

const Wrong = () => {
  return (
    <>
          <Box
        sx={{
          width: { xs: "100%", sm: "200px" }, // Full width on small screens, fixed width on large screens
          height: "128px",
          opacity: "0.9", // Fixed opacity
          display: "flex",
          flexDirection: "row", // Keep items in a row
          justifyContent: "center",
          alignItems: "center",
          gap: "12px",
          marginTop: { xs: "0", sm: "157.5px" }, // Adjust margin for small screens
          marginLeft: { xs: "0", sm: "50px" }, // Adjust margin for small screens
          bgcolor: "#00999E",
          borderRadius: "20px",
        }}
      >
        <Typography color="white">
        PYSCHOMETRIC TEST
        </Typography>
      </Box>
    <Box
    sx={{
      width: { xs: "100%", sm: "662px" }, // Full width on small screens, fixed width on large screens
      height: { xs: "auto", sm: "370px" }, // Adjust height for smaller screens
      padding: "21px 32.04px 13px 33.96px",
      borderRadius: "10px",
      border: "1px",
      opacity: "1", // Set opacity to 1
      backgroundColor: "#E2FEFF",
      margin: "12px",
      marginLeft: { sm: "34px" },
      border: "1px solid #ccc",
    }}
  >
    <Box
      sx={{
        width: { xs: "100%", sm: "570px" }, // Full width on small screens, fixed width on larger screens
        height: "265px",
        padding: "16px",
        borderRadius: "20px",
        border: "1px solid #ccc",
        backgroundColor: "#FFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        gap: "8px",
        marginLeft: { xs: "0", sm: "7px" }, // Margin adjustment for small and larger screens
        mt: 5,
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "32px",
          fontWeight: "bold",
          color: "#FF0000",
        }}
      >
        <img src={Wrong1} alt="wrong" />
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontSize: "20px",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        Sorry! You are not Eligible
      </Typography>
      <Typography
        variant="body2"
        sx={{
          fontSize: "14px",
          color: "#666",
        }}
      >
        Lorem ipsum dolor sit amet consectetur. Leo morbi risus morbi ullamcorper volutpat amet lectus vulputate pharetra. Lectus sit ultricies cras curabitur.
      </Typography>
    </Box>
    </Box>
    </>
  );
};

export default Wrong;
