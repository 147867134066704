/* eslint-disable no-unused-vars */
import { Grid } from "@material-ui/core";
import {
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  Hidden
} from "@mui/material";
import React from "react";
import mission from "../../asset/mission.png";
import "../../index.css";
import "../../globle.css";
import ellipse from "../../asset/mission-ellipse.png";
import Header from "../content-component/Header";

const Mission = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      direction="row-reverse"
      style={{
        height: isMediumScreen ? "auto" : "280px",
        // padding: { sm: "40px ", xs: "20px 0" },
        position: "relative",
        mt: "10px"
        // marginBottom: '20px'
        // border: "1px solid black",
      }}
      spacing={1}
    >
      <Grid item xs={12} sm={6} md={5} lg={4}>
        <Stack
          style={{
            display: "flex",
            justifyContent: isSmScreen ? "none" : "center",
            alignItems: "end",
            flexDirection: "row",
            position: "relative",
            width: "100%",
            padding: { xs: "40px" },

            height: "auto"
            // border: "1px solid black",
          }}
        >
          <Hidden mdDown>
            <img
              src={ellipse}
              alt="mission"
              loading="lazy"
              style={{
                zIndex: 1,
                position: "absolute",
                bottom: isSmScreen ? "10px" : "-70px",
                right: isSmScreen ? "10px" : "-50px",
                width: "150px",
                height: "150px",
                //   bottom: isSmScreen ? "170px" : "-60px",
                display: isMediumScreen ? "none" : "block"
                //   marginBottom: "15px",
                //   marginRight: "30px",
              }}
            />
          </Hidden>
          <img
            src={mission}
            alt="heroImg1"
            loading="lazy"
            style={{
              width: isSmScreen ? "100%" : isMediumScreen ? "85%" : "80%",
              height: "auto",
              zIndex: 2,
              top: "20px",
              left: "0"
            }}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6} md={7} lg={8}>
        <Box
          sx={{ paddingTop: { xs: "40px", sm: "0px", md: "0px", lg: "0px" } }}
        >
          {" "}
          <Header
            color="#00999e"
            // textAlign="center"
            content="Mission"
          />
          {/* <Typography
            variant="h2"
            gutterBottom
            sx={{
              color: "#00999E",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: { xs: "40px", sm: "42px", md: "48px", lg: "55px" },
              textTransform: "capitalize",
              paddingTop: { xs: "40px", sm: "0px", md: "0px", lg: "0px" },
            }}
          >
            ~Mission~
          </Typography> */}
          <Typography
            variant="p"
            sx={{
              color: "Black",
              fontSize: { xs: "14px", sm: "14px", md: "18px", lg: "18px" },
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: { xs: "24px", sm: "20px", md: "32px", lg: "32px" },
              textTransform: "capitalize"
            }}
            className="text-justify"
          >
            At TIE, our mission is unlocking each student's potential, providing
            comprehensive support, personalized guidance, and pathways to
            top-notch global educational opportunities. Connecting students with
            enriching, borderless study abroad experiences.
          </Typography>
        </Box>
        {/*  <Stack sx={{
                    width: { xs: "50%", sm: "50%", md: "30%", lg: "30%" },
                    paddingTop: "50px",

                }}>
                    <Button variant="contained"
                        style={{ backgroundColor: "#00999E" }}
                    >
                        Read More
                    </Button>
                </Stack> */}
      </Grid>
    </Grid>
  );
};

export default Mission;
