import {
    Paper,
  } from "@mui/material";
  import React from "react";
  import "../../../globle.css";
import Builder from "./AboradFolder/Builder";
import Wrong from "./AboradFolder/Wrong";
import Right from "./AboradFolder/Right";

function ThirdCard({
  cate
}) {
    
  
  return (
<Paper
      sx={{
        marginTop: "20px",
        height: "auto", // Remove fixed height
        display: "flex",
        flexDirection: { xs: "column", sm: "row" }, // Stack vertically on small screens, horizontally on larger ones
        justifyContent: "space-around",
        padding: { xs: "16px", sm: "20px" }, // Adjust padding for small screens
      }}
    >

{
  cate === 1 && <Right/>
}
{
  cate === 2 && <Wrong/>
}

{cate === 3 &&  <Builder/> }
 </Paper>
)
}

export default ThirdCard