/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { Box, Container, Stack, Grid, useMediaQuery, createTheme } from '@mui/material';
import brochureVideo from '../asset/BrochureVideo.mp4';
import MuiCard from 'src/Component/Card';
import coverImage from '../asset/cover_image.png'
import Header from 'src/Component/content-component/Header';

const BrochureVideo = () => {
    const [showVideo, setShowVideo] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        if (showVideo && videoRef.current) {
            videoRef.current.play();
        }
    }, [showVideo]);

    const handleButtonClick = () => {
        setShowVideo(true);
    };


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const largeScreen = createTheme({
        breakpoints: {
            values: {
                xxl: 1920,
            },
        },
    });
    const isLargeScreen = useMediaQuery(largeScreen.breakpoints.up("xxl"));

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const aboutuscard = [
        // {
        //     id: 1,
        //     // heading: "Platinum Consultancy",
        //     subheading:
        //         "Join us as we invite you to accompany Rahul on his journey with Taksheela towards his aspiration of attending an Ivy League college.",
        // },
        {
            id: 2,
            // heading: "Technology Integration",
            subheading:
                "Join us as we take you on Rahul's journey with Taksheela to his dream university.",
        },

    ];

    return (
        <Box sx={{ backgroundColor: "#fff", width: '100%' }}>


            <Container

            >
                <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    sx={{ padding: "20px", display: "flex", justifyContent: "center", alignItems: "center" }}
                >  <Grid item xs={12} sm={12} md={12} lg={6} sx={{ padding: "20px", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>

                        <Stack
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "row",
                                gap: ".5rem",
                            }}
                        >
                            <Header
                                color="#00999e"
                                textAlign="center"
                                content="Taksheela Insights "
                            />

                        </Stack>
                        <Stack
                            sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                flexDirection: "row",
                                justifyContent: "center",
                                marginRight: "6px",

                            }}
                        >
                            <Box
                                rowGap={isMediumScreen ? 1 : 3}
                                columnGap={isMediumScreen ? 1 : 2}
                                display="flex"
                                justifyContent={"center"}
                                width={"250px"}
                                gridTemplateColumns={{
                                    xs: "repeat(1, 1fr)",
                                    sm: "repeat(2, 1fr)",
                                }}
                            //  padding={isLargeScreen ? '50px' : 'none'}
                            >
                                {aboutuscard.map((item, index) => (
                                    <MuiCard heading={item.heading} subHeading={item.subheading} id={index} />
                                ))}
                            </Box>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box sx={{ width: "100%", borderRadius: "20px", overflow: "hidden" }}>
                            <video
                                ref={videoRef}
                                controls
                                poster={coverImage}
                                style={{

                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <source src={brochureVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </Box>
                    </Grid>

                </Grid>
            </Container>
        </Box>
    );
};

export default BrochureVideo;
