import React, { useState, memo } from "react";
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  Snackbar,
  Alert
} from "@mui/material";

import EnrollmentSuccessModal from "./EnrollmentSuccessfullModel";

import DashboardEnrollmentForm from "./EnrollmentForm1";
import DashboardEnrollmentForm2 from "./EnrollmentForm2";

// Main Component
const DashboardEnrollmentStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: null,
    gender: "",
    friends: [
      { name: "", address: "", mobileNumber: "", email: "" },
      { name: "", address: "", mobileNumber: "", email: "" }
    ],
    isAgreed: false // Initialize as unchecked
  });
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // Snackbar state
  const [snackbarMessage, setSnackbarMessage] = useState(""); // Snackbar message

  const [permanentAddress, setPermanentAddress] = useState({
    address: "",
    state: "",
    city: "",
    pincode: ""
  });
  const [mailingAddress, setMailingAddress] = useState({
    address: "",
    state: "",
    city: "",
    pincode: ""
  });
  const [academicData, setAcademicData] = useState([]);

  const [courseRows, setCourseRows] = useState([
    { name: "GRE", option1: false, option2: false, amount: "" },
    { name: "GMAT", option1: false, option2: false, amount: "" },
    { name: "SAT", option1: false, option2: false, amount: "" },
    { name: "IELTS", option1: false, option2: false, amount: "" },
    { name: "TOEFL", option1: false, option2: false, amount: "" },
    { name: "OTHERS", option1: false, option2: false, amount: "" }
  ]);

  const steps = ["Enrollment Form 1", "Enrollment Form 2"];

  const validateForm = () => {
    const requiredFields = [
      "firstName",
      "lastName",
      "dob",
      "gender",
      "studentEmail",
      "mobileNumber",
      "guardianName",
      "guardianMobile"
    ];

    // Validate required fields
    for (let field of requiredFields) {
      if (!formData[field]) {
        setSnackbarMessage(`${field} is required`);
        setSnackbarOpen(true);
        return false;
      }
    }

    // Validate academic background
    if (academicData.length === 0) {
      setSnackbarMessage("At least one academic background entry is required");
      setSnackbarOpen(true);
      return false;
    }

    // Validate permanent address
    const permanentAddressFields = ["address", "state", "city", "pincode"];
    for (let field of permanentAddressFields) {
      if (!formData?.permanentAddress[field]) {
        setSnackbarMessage(`Permanent Address ${field} is required`);
        setSnackbarOpen(true);
        return false;
      }
    }

    // Validate mailing address
    const mailingAddressFields = ["address", "state", "city", "pincode"];
    for (let field of mailingAddressFields) {
      if (!formData.mailingAddress[field]) {
        setSnackbarMessage(`Mailing Address ${field} is required`);
        setSnackbarOpen(true);
        return false;
      }
    }

    return true;
  };

  const validateForm2 = () => {
    // Check if the user selected how they know about TAKSHEELA
    if (!formData.taksheelaKnowAbout) {
      setSnackbarMessage("Please select how you know about TAKSHEELA");
      setSnackbarOpen(true);
      return false;
    }

    // If they selected Newspaper, ensure newspaper field is filled
    if (formData.taksheelaKnowAbout === "Newspaper" && !formData.newsPaper) {
      setSnackbarMessage("Please mention the newspaper/magazine you read");
      setSnackbarOpen(true);
      return false;
    }

    // Ensure internet access option is selected
    if (!formData.internetAt) {
      setSnackbarMessage("Please select where you can access the internet");
      setSnackbarOpen(true);
      return false;
    }

    // Validate courses table (must have at least one course)
    if (!formData?.courses || formData?.courses.length === 0) {
      setSnackbarMessage("Please select at least one course");
      setSnackbarOpen(true);
      return false;
    }
    if (!formData.isAgreed) {
      setSnackbarMessage("You must agree to the terms and conditions");
      setSnackbarOpen(true);
      return false;
    }
    return true;
  };

  const handleNext = () => {
    // Only validate on the first step
    if (activeStep === 0) {
      if (!validateForm()) {
        return; // Prevent navigation to the next step if validation fails
      }
    }

    if (activeStep === 1) {
      if (!validateForm2()) {
        return; // Prevent navigation to the next step if validation fails
      }
    }

    if (activeStep === steps.length - 1) {
      submitFormData(formData); // Submit the form
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const token = sessionStorage.getItem("token");

  // Form Submission API Handling
  const submitFormData = (data) => {
    // Format the academic background and work experience
    const formattedAcademicBackground = data.academicBackground || [];
    const formattedWorkExperience = data.workExperience || [];
    const formattedFriends = data.friends.map((friend) => ({
      name: friend.name || "",
      address: friend.address || "",
      mobileNumber: friend.mobileNumber || "",
      email: friend.email || ""
    }));

    // Prepare final payload
    const finalPayload = {
      information: {
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob, // Format date as required by API
        gender: data.gender
      },
      academicBackground: formattedAcademicBackground,
      workExperience: formattedWorkExperience,
      studentDetail: {
        studentEmail: data.studentEmail,
        mobileNumber: data.mobileNumber,
        guardianName: data.guardianName,
        guardianMobileNumber: data.guardianMobile
      },
      permanentAddress: data.permanentAddress,
      mailingAddress: data.mailingAddress,
      taksheelaKnowAbout: data.taksheelaKnowAbout,
      newsPaper: data.newsPaper,
      internetAt: data.internetAt,
      course: data.courses,
      friendsName: formattedFriends,
      iAgree: true
    };


    fetch(
      `${process.env.REACT_APP_BASE_URL}enrollementForm/add-enrollement-form`,
      {
        method: "POST",
        headers: {
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalPayload)
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Success:", data);
        setSuccessModalOpen(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setSnackbarMessage("Failed to submit form. Please try again."); // Set snackbar message
        setSnackbarOpen(true); // Show snackbar
      });
  };

  return (
    <Box sx={{ width: "100%", padding: "40px 20px" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === 0 ? (
        <DashboardEnrollmentForm
          formData={formData}
          setFormData={setFormData}
          permanentAddress={permanentAddress}
          mailingAddress={mailingAddress}
          setMailingAddress={setMailingAddress}
          setPermanentAddress={setPermanentAddress}
          academicData={academicData}
          setAcademicData={setAcademicData}
          onNext={handleNext}
        />
      ) : (
        <DashboardEnrollmentForm2
          courseRows={courseRows}
          setCourseRows={setCourseRows}
          formData={formData}
          setFormData={setFormData}
        />
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px"
        }}
      >
        <Button disabled={activeStep === 0} onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleNext}
          sx={{
            bgcolor: "#00999E",
            color: "#ffffff",
            "&:hover": {
              bgcolor: "#00999E",
              color: "#ffffff"
            }
          }}
        >
          {activeStep === steps.length - 1 ? "Submit" : "Next"}
        </Button>
      </Box>
      <EnrollmentSuccessModal
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          // onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default memo(DashboardEnrollmentStepper);
