import minicounrty from "../../asset/minicountry.svg";
import minicourse from "../../asset/minicourse.svg";
import miniuniversity from "../../asset/miniuniversity.svg";
import country from "../../asset/hexcountry.svg";
import course from "../../asset/hexcourse.svg";
import university from "../../asset/hexuniversity.svg";

const globalCombinationData = [
  {
    Image: country,
    icon: minicounrty,
    buttontext: "Country",
    path: "/study-abroad/country"
  },
  {
    Image: course,
    icon: minicourse,
    buttontext: "Courses",
    path: "/study-abroad/courses"
  },
  {
    Image: university,
    icon: miniuniversity,
    buttontext: "University",
    // "path": "/study-abroad/universities"
    path: "/study-abroad/universityFinder"
  }
];
export default globalCombinationData;
