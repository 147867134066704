/* eslint-disable no-unused-vars */
  import React, { useState } from 'react';
import {  Button, Container, Grid,InputLabel,useMediaQuery, useTheme  } from '@mui/material';
import TextField from '@mui/material/TextField';
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ContactForm = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isFullScreen = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        phone:'',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
          from_name:'TIE'
        });
      };
    
      const handleSubmit = (e) => {
        
        e.preventDefault();
        // Add your logic to handle form submission
        console.log('Form submitted:', formData);
        // Reset form after submission
        const serviceId="service_i9rs2hj"
        const templateId ="template_roqvyei"
        const publicKey="ncDIHkoEOeITGgzJI"

    emailjs
    .send(serviceId, templateId, formData, publicKey)
    .then((res) => {
      toast.success('Message sent successfully')
      setFormData({
        name: '',
        email: '',
        message: '',
        phone:'',
      });
    })
    .catch((error) => {
      console.log("Error", error);
    });
        
      };
  return (
    <Container 
    style={{
      paddingTop: isSmScreen ? "40px" : 0,
    }}
    >
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
        <InputLabel htmlFor="name">Name</InputLabel>
          <TextField
            fullWidth
            variant="standard"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="email">Email</InputLabel>
          <TextField
            fullWidth
            variant="standard"
            name="email"
            type='email'
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
        <InputLabel htmlFor="phone">Phone Number</InputLabel>
          <TextField
            fullWidth
            variant="standard"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Write Your Message"
            variant="standard"
            multiline
            rows={4}
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}
        sx={{
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
        }}
        >
          <Button type="submit" variant="contained"
          sx={{
            backgroundColor: '#00999E',
            border: '1px solid #00999E',
            '&:hover': {
              backgroundColor: '#fff',
              color: '#00999E',
              border: '1px solid #00999E',
            },
          }}
          >
          Send Message
          </Button>
        </Grid>
      </Grid>
    </form>
    <ToastContainer/>
  </Container>
  )
}

export default ContactForm