const CaliforniaChoose = {
  heading: "Why Choose The University of California",
  items: [
    {
      title: "Public university worldwide: U.S. News & World Report",
      count: "#1",
    },
    {
      title: "All-time top producer of Peace Corps volunteers",
      count: "#1",
    },
    {
      title: "Nobel Prizes held by alumni",
      count: "35",
    },
    {
      title:
        "Number of community organizations with Berkeley student involvement",
      count: "300+",
    },
  ],
};
const WisconsinChoose = {
  heading: "Why Choose The University of Wisconsin-Madison",
  items: [
    {
      title: "Best public universitY",
      count: "#12",
    },
    {
      title: "Peace corps volunteers",
      count: "#1",
    },
    {
      title: "Noble prize winners",
      count: "20",
    },
  ],
};
const YeshivaChoose = {
  heading: "Why Choose The Yeshiva University",
  items: [
    {
      title: "YU's medical school applicants were accepted in the last 3 years",
      count: "92%",
    },
    {
      title: "YEARS of educating industry leaders",
      count: "135",
    },
    {
      title: "MILLION in financial aid awarded annually",
      count: "$45",
    },
    {
      title: "NCAA athletic teams",
      count: "15",
    },
  ],
};
const UtahChoose = {
  heading: "Why Choose The Sothern Utah University",
  items: [
    {
      title: "REGIONAL UNIVERSITY IN UTAH",
      count: "#2",
    },
    {
      title: "2023-2024 COLLEGE OF DISTINCTION",
      count: "#1",
    },
    {
      title: "CAREER FOCUSED LEARNING",
      count: "#1",
    },
    {
      title: "BEST IN WEST",
      count: "#1",
    },
  ],
};
const ColoradoChoose = {
  heading: "Why Choose The Colorado State University",
  items: [
    {
      title: "FOUR-YEAR COLLEGE IN COLORADO",
      count: "#1",
    },
    {
      title: "Graduate Programmes",
      count: "100+",
    },
    {
      title: "Undergraduate Programmes",
      count: "300+",
    },
    {
      title: "TOP COLLEGES WITH THE BEST ACADEMICS",
      count: "21%",
    },
  ],
};

const IllinoisChoose = {
  heading: "Why Choose  UIUC",
  items: [
    {
      title: "QS World University Rankings",
      count: "40 - 50",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "40 - 50",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "20 - 30",
    },
    {
      title: "U.S. News & World Report",
      count: "40 - 50",
    },
  ],
};

const StanfordChoose = {
  heading: "Why Choose Standford University",
  items: [
    {
      title: "QS World University Rankings",
      count: "1 - 5", // Updated overall rank
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "2 - 5", // Updated overall rank
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "2 - 3", // Updated overall rank
    },
    {
      title: "U.S. News & World Report",
      count: "Not Specified", // Keeping it generic as no specific rank provided
    },
  ],
};

const PennsylvaniaChoose = {
  heading: "Why Choose University of Pennsylvania",
  items: [
    {
      title: "U.S. News & World Report",
      count: "10", // Numerical rank
    },
    {
      title: "QS World University Rankings",
      count: "15", // Numerical rank
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "20", // Numerical rank
    },
    {
      title: "Forbes",
      count: "10", // Numerical rank
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "20", // Numerical rank
    },
  ],
};

const ChicagoChoose = {
  heading: "Why Choose The University of Chicago",
  items: [
    {
      title: " QS World University Rankings",
      count: "10-15", // Numerical rank
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "10-15", // Numerical rank
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "10-15", // Numerical rank
    },
    {
      title: "U.S. News & World Report",
      count: "Not ranked", // Numerical rank
    },
  ],
};

const MITChoose = {
  heading: "Why Choose MIT",
  items: [
    {
      title: "QS World University Rankings",
      count: "1-3", // Updated numerical rank
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "1-2", // Updated numerical rank
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "1-3", // Updated numerical rank
    },
    {
      title: "U.S. News & World Report",
      count: "1-2", // Updated numerical rank
    },
  ],
};


//UK

const BristolChoose = {
  heading: "Why Choose The University of Bristol",
  items: [
    {
      title: "Times Higher Education World University Rankings",
      count: "#9",
    },
    {
      title: "QS World University Rankings",
      count: "#54",
    },
    {
      title: "Academic Ranking of World Universities",
      count: "#8",
    },
    {
      title: "The Guardian University Guide",
      count: "#17",
    },
  ],
};
const LeedsRankings = {
  heading: "Why Choose University of Leeds",
  items: [
    {
      title: "QS World University Rankings",
      count: "#80-100",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "#150-200",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "#100-150",
    },
    {
      title: "Complete University Guide (UK)",
      count: "#20-30",
    },
  ],
};
const GlasgowRankings = {
  heading: "Why Choose The University of Glasgow",
  items: [
    {
      title: "QS World University Rankings",
      count: "#80-100",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "#100-150",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "#100-150",
    },
    {
      title: "Complete University Guide (UK)",
      count: "#20-30",
    },
  ],
};
const BirminghamRankings = {
  heading: "Why Choose The University of Birmingham",
  items: [
    {
      title: "QS World University Rankings",
      count: "#90-100",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "#100-150",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "#100-150",
    },
    {
      title: "Complete University Guide (UK)",
      count: "#15-20",
    },
  ],
};
const ImperialRankings = {
  heading: "Why Choose Imperial College London",
  items: [
    {
      title: "QS World University Rankings",
      count: "#5-10",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "#10-12",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "#10-15",
    },
    {
      title: "Complete University Guide (UK)",
      count: "#3-5",
    },
  ],
};

const QueenMaryRankings = {
  heading: "Why Choose Queen Mary University of London",
  items: [
    {
      title: "QS World University Rankings",
      count: "110-120",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "120-150",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "150-200",
    },
    {
      title: "Complete University Guide (UK)",
      count: "30-40",
    },
  ],
};

const LiverpoolRankings = {
  heading: "Why Choose The University of Liverpool ",
  items: [
    {
      title: "QS World University Rankings",
      count: "130-150",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "150-200",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "150-200",
    },
    {
      title: "Complete University Guide (UK)",
      count: "30-40",
    },
  ],
};

const EdinburghRankings = {
  heading: "Why Choose The University of Edinburgh ",
  items: [
    {
      title: "QS World University Rankings",
      count: "15-25",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "20-30",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "30-40",
    },
    {
      title: "Complete University Guide (UK)",
      count: "10-15",
    },
  ],
};

const SouthamptonRankings = {
  heading: "Why Choose the University of Southampton",
  items: [
    {
      title: "QS World University Rankings",
      count: "80-100",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "100-150",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "150-200",
    },
    {
      title: "Complete University Guide (UK)",
      count: "15-20",
    },
  ],
};

const DurhamRankings = {
  heading: "Why Choose the University of Durham",
  items: [
    {
      title: "QS World University Rankings",
      count: "80-90",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "120-150",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "150-200",
    },
    {
      title: "Complete University Guide (UK)",
      count: "6-10",
    },
  ],
};

const UNSWRankings = {
  heading: "Why Choose UNSW",
  items: [
    {
      title: "QS World University Rankings",
      count: "#40-50",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "#50-60",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "#50-70",
    },
    {
      title: "Complete University Guide (Australia)",
      count: "#5-10",
    },
  ],
};
const MonashRankings = {
  heading: "Why Choose UNSW",
  items: [
    {
      title: "QS World University Rankings",
      count: "40-50",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "50-60",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "50-70",
    },
    {
      title: "Complete University Guide (Australia)",
      count: "5-10",
    },
  ],
};
const QueenslandRankings = {
  heading: "Why Choose the University of Queensland",
  items: [
    {
      title: "QS World University Rankings",
      count: "40-50",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "50-60",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "50-70",
    },
    {
      title: "Complete University Guide (Australia)",
      count: "5-10",
    },
  ],
};
const WesternAustraliaRankings = {
  heading: "Why Choose the University of Western Australia",
  items: [
    {
      title: "QS World University Rankings",
      count: "200-250",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "200-250",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "300-400",
    },
    {
      title: "Complete University Guide (UK)",
      count: "25-30",
    },
  ],
};
const AdelaideRankings = {
  heading: "Why Choose the University of Adelaide",
  items: [
    {
      title: "QS World University Rankings",
      count: "100-120",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "120-150",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "100-150",
    },
    {
      title: "Complete University Guide (Australia)",
      count: "8-12",
    },
  ],
};
const MelbourneRankings = {
  heading: "Why Choose the University of Melbourne",
  items: [
    {
      title: "QS World University Rankings",
      count: "30-40",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "30-40",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "30-40",
    },
    {
      title: "Complete University Guide (Australia)",
      count: "1-2",
    },
  ],
};
const SydneyRankings = {
  heading: "Why Choose the University of Sydney",
  items: [
    {
      title: "QS World University Rankings",
      count: "40-50",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "40-50",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "40-60",
    },
    {
      title: "Complete University Guide (Australia)",
      count: "1-2",
    },
  ],
};
const ANURankings = {
  heading: "Why Choose the Australian National University",
  items: [
    {
      title: "QS World University Rankings",
      count: "30-40",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "40-50",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "40-50",
    },
    {
      title: "Complete University Guide (Australia)",
      count: "1-2",
    },
  ],
};
const UTSRankings = {
  heading: "Why Choose the University of Technology Sydney",
  items: [
    {
      title: "QS World University Rankings",
      count: "130-150",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "150-200",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "200-300",
    },
    {
      title: "Complete University Guide (Australia)",
      count: "10-15",
    },
  ],
};
const MacquarieRankings = {
  heading: "Why Choose Macquarie University",
  items: [
    {
      title: "QS World University Rankings",
      count: "200-250",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "200-250",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "200-300",
    },
    {
      title: "Complete University Guide (Australia)",
      count: "15-20",
    },
  ],
};

const trentRankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "Canadian 'Primarily Undergraduate' Rankings",
      count: "10-15",
    },
    {
      title: "QS World University Rankings",
      count: "300-400",
    },
    {
      title: "Maclean's Rankings (Canada)",
      count: "10",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "500",
    },
  ],
};

const TrinityRankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "QS World University Rankings",
      count: "600-800",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "601-800",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "500",
    },
    {
      title: "Maclean's Rankings (Canada)",
      count: "10-15",
    },
  ],
};

const RRURankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "QS World University Rankings",
      count: "500",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "601-800",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "500",
    },
    {
      title: "Maclean's Rankings (Canada)",
      count: "Not Ranked",
    },
  ],
};

const WaterlooRankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "QS World University Rankings",
      count: "150-200",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "150-200",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "150-200",
    },
    {
      title: "Maclean's Rankings (Canada)",
      count: "5",
    },
  ],
};

const CalgeryRankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "QS World University Rankings",
      count: "150-200",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "150-200",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "200-300",
    },
    {
      title: "Maclean's Rankings (Canada)",
      count: "10",
    },
  ],
};

const DalhousieRankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "QS World University Rankings",
      count: "300-400",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "300-400",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "200-300",
    },
    {
      title: "Maclean's Rankings (Canada)",
      count: "10",
    },
  ],
};

const BrockRankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "QS World University Rankings",
      count: "601-650",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "501-600",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "500",
    },
    {
      title: "Maclean's Rankings (Canada)",
      count: "15",
    },
  ],
};

const WindsorRankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "QS World University Rankings",
      count: "601-650",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "601-800",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "500",
    },
    {
      title: "Maclean's Rankings (Canada)",
      count: "15-20",
    },
  ],
};

const VCCProfile = {
  heading: "College Overview",
  items: [
    {
      title: "Approximate Enrollment",
      count: "20000",
    },
    {
      title: "Total Programs Offered",
      count: "140",
    },
    {
      title: "Approximate Graduation Rate",
      count: "70-80% ", // Using the average of the range (70-80)
    },
    {
      title: "Percentage of International Students",
      count: "15-20%", // Using the average of the range (15-20)
    },
  ],
};

const TorontoRankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "QS World University Rankings",
      count: "20-30",
    },
    {
      title: "Times Higher Education World University Rankings",
      count: "18-20",
    },
    {
      title: "Academic Ranking of World Universities (ARWU)",
      count: "20-30",
    },
    {
      title: "Maclean's Rankings (Canada)",
      count: "3",
    },
  ],
};

//Germany

const LMURankings = {
  heading: "Why Choose This University",
  items: [
    {
      title: "Global Ranking",
      count: 50, // Numeric value for count
    },
    {
      title: "Research Excellence",
      count: 1, // Numeric value for count
    },
    {
      title: "International Students",
      count: 10000, // Numeric value for students
    },
    {
      title: "Programs Offered",
      count: 150, // Numeric value for programs
    },
    {
      title: "Employment Rate",
      count: 90, // Numeric value for percentage
    },
  ],
};


const TUMRankings = {
  heading: "Why Choose Us",
  items: [
    {
      title: "Global Ranking",
      count: 50, // Numeric value for count
    },
    {
      title: "Engineering Excellence",
      count: 1, // Numeric value for count in engineering
    },
    {
      title: "Research Output",
      count: 1, // Numeric value for research count
    },
    {
      title: "International Students",
      count: 35, // Numeric percentage of international students
    },
    {
      title: "Employment Rate",
      count: 92, // Numeric value for employment rate
    },
  ],
};


const HeidelbergUniversityRankings = {
  heading: "Why Choose Us",
  items: [
    {
      title: "Global Ranking",
      count: 100, // Numeric value for count
    },
    {
      title: "Research Excellence",
      count: 10, // Numeric value for research count
    },
    {
      title: "International Students",
      count: 20, // Numeric percentage of international students
    },
    {
      title: "Programs Offered",
      count: 100, // Numeric value for degree programs
    },
    {
      title: "Alumni Success",
      count: 56, // Numeric value for Nobel laureates
    },
  ],
};


const HumboldtUniversityRankings = {
  heading: "Why Choose Us",
  items: [
    {
      title: "Global Ranking",
      count: 100, // Numeric value for global count
    },
    {
      title: "Research Excellence",
      count: 1, // Numeric value for count in humanities and social sciences
    },
    {
      title: "International Community",
      count: 30, // Numeric percentage of international students
    },
    {
      title: "Nobel Laureates",
      count: 55, // Numeric value for Nobel Prize winners
    },
    {
      title: "Degree Programs Offered",
      count: 180, // Numeric value for degree programs
    },
  ],
};


const RWTHRankings = {
  heading: "Why Choose Us",
  items: [
    {
      title: "Global Ranking",
      count: 150, // Numeric value for global count
    },
    {
      title: "Engineering Ranking",
      count: 5, // Numeric value for engineering count in Europe
    },
    {
      title: "Research Funding",
      count: 300, // Numeric value for research funding in millions (€)
    },
    {
      title: "International Students",
      count: 20, // Numeric percentage of international students
    },
    {
      title: "Industry Partnerships",
      count: 200, // Numeric value for partnerships
    },
  ],
};


const FUBRankings = {
  heading: "Why Choose Us",
  items: [
    {
      title: "Global Ranking",
      count: 150, // Numeric value for global count
    },
    {
      title: "Research Output",
      count: 4, // Numeric count for research publications
    },
    {
      title: "International Students",
      count: 20, // Numeric percentage of international students
    },
    {
      title: "Academic Programs",
      count: 100, // Numeric value for academic programs offered
    },
    {
      title: "Campus Size",
      count: 120, // Numeric value for campus size in acres
    },
  ],
};


const UOFRankings = {
  heading: "Why Choose Us",
  items: [
    {
      title: "Top 100 University",
      count: 100, // Numeric value for global count
    },
    {
      title: "Research Funding",
      count: 10, // Numeric count for attracting research funding
    },
    {
      title: "International Students",
      count: 25, // Numeric percentage of international students
    },
    {
      title: "Sustainability",
      count: 5, // Numeric count for sustainability initiatives
    },
    {
      title: "Graduate Employability",
      count: 20, // Numeric count for graduate employability
    },
  ],
};


const UOTRankings = {
  heading: "Why Choose",
  items: [
    {
      title: "Overall QS Ranking",
      count: 200, // Numeric value for the global count (upper bound)
    },
    {
      title: "Subject Ranking in Medicine",
      count: 100, // Numeric count for Medicine
    },
    {
      title: "Subject Ranking in Life Sciences",
      count: 150, // Numeric count for Life Sciences
    },
    {
      title: "Research Quality",
      count: 10, // Numeric count for research output in Germany
    },
    {
      title: "Internationalization",
      count: 20, // Numeric percentage of international students
    },
  ],
};


const UOMRankings = {
  heading: "Why Choose",
  items: [
    {
      title: "Top 200 University",
      count: 200, // Numeric value for global count (upper bound)
    },
    {
      title: "Top 10 in Germany",
      count: 10, // Numeric count for business and economics
    },
    {
      title: "Research Excellence",
      count: 5, // Numeric count for research output in social sciences and economics
    },
    {
      title: "Internationalization",
      count: 30, // Numeric percentage of international students
    },
    {
      title: "Graduate Employability",
      count: 15, // Numeric count for graduate employability in Germany
    },
  ],
};

const UOGRankings = {
  heading: "Why Choose",
  items: [
    {
      title: "Top 150 University Globally",
      count: 150, // Numeric value for global count (upper bound)
    },
    {
      title: "Top 10 in Germany",
      count: 10, // Numeric count for universities in Germany
    },
    {
      title: "Nobel Laureates",
      count: 40, // Numeric value for Nobel Prize winners
    },
    {
      title: "Research Output",
      count: 5, // Numeric count for research output in natural sciences
    },
    {
      title: "Internationalization",
      count: 30, // Numeric percentage of international students
    },
  ],
};


//France

const UPSLRankings = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "QS World University Rankings (Global Ranking)",
      count: 36, // Extracted the numeric rank
    },
    {
      title: "Times Higher Education World University Rankings (Global Ranking)",
      count: 15, // Extracted the numeric rank
    },
    {
      title: "Best University in France",
      count: 1, // Extracted the numeric rank from "1st for research output"
    },
    {
      title: "Arts and Humanities",
      count: 100, // Extracted the upper bound from "Top 100 in QS World Rankings"
    },
    {
      title: "International Students",
      count: 40, // Extracted the percentage from "40% of students"
    },
  ],
};


const UPSRankings = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "QS World University Rankings (Global Ranking)",
      count: 36, // Extracted from "36th"
    },
    {
      title: "Times Higher Education World University Rankings (Global Ranking)",
      count: 15, // Extracted from "15th"
    },
    {
      title: "Best University in France",
      detail: "1st for research output in sciences and humanities",
    },
    {
      title: "Global Ranking in Arts and Humanities",
      count: 100, // Extracted from "Top 100 by QS World Rankings"
    },
    {
      title: "International Students",
      count: "40%", // Retained as is
    },
  ],
};



const SURankings = {
  heading: "Why Choose?",
  items: [
    {
      title: "QS World University Rankings (Global Ranking)",
      count: 40, // Extracted from "40th"
    },
    {
      title: "Times Higher Education World University Rankings (Global Ranking)",
      count: 70, // Extracted from "70th"
    },
    {
      title: "Best University in France",
      detail: "1st for humanities and social sciences",
    },
    {
      title: "Global Ranking in Life and Physical Sciences",
      count: 100, // Extracted from "Top 100 by QS World Rankings"
    },
    {
      title: "International Students",
      count: 50, // Extracted from "50%"
    },
  ],
};


const UGARankings = {
  heading: "Why Choose?",
  items: [
    {
      title: "Innovation and Research Partnerships",
      count: 1, // Extracted from "#1 in France"
    },
    {
      title: "Computer Science",
      count: 100, // Extracted from "#100 in the World"
    },
    {
      title: "Physics",
      count: 150, // Extracted from "#150 in the World"
    },
    {
      title: "Internationalization",
      count: 5, // Extracted from "#5 in France"
    },
    {
      title: "Engineering & Technology",
      count: 200, // Extracted from "#200 in the World"
    },
  ],
};


const UDSRankings = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Life Sciences & Medicine",
      count: 1, // Extracted from "#1 in France"
    },
    {
      title: "Chemistry",
      count: 100, // Extracted from "Top 100 globally"
    },
    {
      title: "Law and Political Science",
      count: 1, // Extracted from "#1 in Europe"
    },
    {
      title: "Internationalization",
      count: 6, // Extracted from "#6 in France"
    },
    {
      title: "Physics",
      count: 150, // Extracted from "Top 150 globally"
    },
  ],
};


const UDMRankings = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "QS World University Rankings (Global Ranking)",
      count: 200, // Extracted from "Top 200"
    },
    {
      title: "QS World University Rankings by Subject (Environmental Science)",
      count: 100, // Extracted from "Top 100"
    },
    {
      title: "Life Sciences National Ranking in France",
      count: 1, // Extracted from "#1"
    },
    {
      title: "Global Employability Ranking",
      count: 150, // Extracted from "Top 150"
    },
    {
      title: "Research Projects Funded Annually",
      count: 300, // Extracted from "300+"
    },
  ],
};


const UDLRankings = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "QS World University Rankings (Global Ranking)",
      count: 200, // Extracted from "Top 200"
    },
    {
      title: "QS World University Rankings by Subject (Engineering & Technology)",
      count: 150, // Extracted from "Top 150"
    },
    {
      title: "Times Higher Education (THE) Global Ranking (Health & Clinical Sciences)",
      count: 100, // Extracted from "Top 100"
    },
    {
      title: "QS World University Rankings (Social Sciences)",
      count: 250, // Extracted from "Top 250"
    },
    {
      title: "National Ranking in France",
      count: "1", // Retained as a detail
    },
  ],
};


const UDNRankings = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "QS World University Rankings (Global Ranking)",
      count: 500, // Extracted from "Top 500"
    },
    {
      title: "QS World University Rankings by Subject (Environmental Sciences)",
      count: 150, // Extracted from "Top 150"
    },
    {
      title: "Times Higher Education (THE) Global Ranking (Engineering and Technology)",
      count: 200, // Extracted from "Top 200"
    },
    {
      title: "National Ranking in France",
      count: "1", // Retained as a detail
    },
    {
      title: "QS World University Rankings (Social Sciences)",
      count: 300, // Extracted from "Top 300"
    },
  ],
};


const UDBRankings = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "QS World University Rankings (Global Ranking)",
      count: 400, // Extracted from "Top 400"
    },
    {
      title: "QS World University Rankings by Subject (Environmental Science)",
      count: 100, // Extracted from "Top 100"
    },
    {
      title: "QS World University Rankings by Subject (Engineering and Technology)",
      count: 150, // Extracted from "Top 150"
    },
    {
      title: "Times Higher Education (THE) Global Ranking (Clinical, Pre-clinical & Health)",
      count: 200, // Extracted from "Top 200"
    },
    {
      title: "National Ranking in France",
      count: "1", // Retained as a detail
    },
  ],
};


const UPPSRankings = {
  heading: "Why Choose?",
  items: [
    {
      title: "QS World University Rankings (Global Ranking)",
      count: "100",
    },
    {
      title: "QS World University Rankings by Subject (Law)",
      count: "50",
    },
    {
      title:
        "QS World University Rankings by Subject (Political Science & International Studies)",
      count: "150",
    },
    {
      title:
        "Times Higher Education (THE) Global Ranking (Arts and Humanities)",
      count: "200",
    },
    {
      title: "National Ranking in France",
      count: "1",
    },
  ],
};

//Italy

const PDMilanoChooseUs = {
  heading: "Why Choose?",
  items: [
    {
      title: "#1 in Italy for Engineering and Technology",
      count: 1,
    },
    {
      title: "#2 in Europe for Architecture",
      count: 2,
    },
    {
      title: "Top 20 in the World for Art and Design",
      count: 20,
    },
    {
      title: "#1 in Italy for Graduate Employability",
      count: 1,
    },
    {
      title: "Top 50 in Europe for Research Output",
      count: 50,
    },
  ],
};

const UOMilanChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "#1 in Italy for Life Sciences",
      count: 1,
    },
    {
      title: "#2 in Italy for Humanities",
      count: 2,
    },
    {
      title: "Top 100 in Europe for Research Output",
      count: 100,
    },
    {
      title: "#1 in Italy for Internationalization",
      count: 1,
    },
    {
      title: "#10 in Italy for Graduate Employability",
      count: 10,
    },
  ],
};

const UOBolognaChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "#1 in Italy for Humanities",
      count: 1,
    },
    {
      title: "#3 in Italy for Research Output",
      count: 3,
    },
    {
      title: "Top 100 in QS World University Rankings",
      count: 100,
    },
    {
      title: "#1 in Italy for Internationalization",
      count: 1,
    },
    {
      title: "#5 in Italy for Graduate Employability",
      count: 5,
    },
  ],
};


const UOFlorenceChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Global Ranking",
      count: 250,
    },
    {
      title: "Research Output Ranking",
      count: 1,
    },
    {
      title: "International Students",
      count: 12000,
    },
    {
      title: "Student Satisfaction Rate",
      count: 85,
    },
    {
      title: "Graduate Employment Rate",
      count: 80,
    },
  ],
};

const UOPaduaChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Global Ranking (QS World University Rankings)",
      count: 200,
    },
    {
      title: "Research Output",
      count: 1,
    },
    {
      title: "International Students",
      count: 12000,
    },
    {
      title: "Student Satisfaction Rate",
      count: 85,
    },
    {
      title: "Graduate Employment Rate",
      count: 83,
    },
  ],
};

const UOTurinChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Global Ranking (QS World University Rankings)",
      count: 250,
    },
    {
      title: "Research Output",
      count: 1,
    },
    {
      title: "International Students",
      count: 15000,
    },
    {
      title: "Student Satisfaction Rate",
      count: 82,
    },
    {
      title: "Graduate Employment Rate",
      count: 80,
    },
  ],
};

const RLSChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Global Ranking (QS World University Rankings)",
      count: 200,
    },
    {
      title: "Research Output (CWTS Leiden Ranking)",
      count: 1,
    },
    {
      title: "International Students",
      count: 10000,
    },
    {
      title: "Programs Offered",
      count: 300,
    },
    {
      title: "Research Projects Funded Annually",
      count: 1500,
    },
  ],
};

const UONaplesChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Global Ranking (QS World University Rankings)",
      count: 300,
    },
    {
      title: "Research Output in Engineering and Technology (CWTS Leiden Ranking)",
      count: 1,
    },
    {
      title: "International Students",
      count: 10000,
    },
    {
      title: "Programs Offered",
      count: 200,
    },
    {
      title: "Employment Rate for Graduates Within One Year",
      count: 90,
    },
  ],
};


const UOPisaChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Global Ranking (QS World University Rankings)",
      count: 300,
    },
    {
      title: "Research Output in Engineering and Technology (CWTS Leiden Ranking)",
      count: 1,
    },
    {
      title: "International Students",
      count: 10000,
    },
    {
      title: "Programs Offered",
      count: 200,
    },
    {
      title: "Employment Rate for Graduates Within One Year",
      count: 90,
    },
  ],
};

const IEDChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Global Ranking (QS World University Rankings)",
      count: 300,
    },
    {
      title: "Research Excellence in Physics and Engineering (CWTS Leiden Ranking)",
      count: 1,
    },
    {
      title: "International Students",
      count: 5000,
    },
    {
      title: "Programs Offered",
      count: 200,
    },
    {
      title: "Employment Rate for Graduates Within One Year",
      count: 80,
    },
  ],
};


//Ireland

const TCDublinChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Top 1% Globally (QS World University Rankings 2024)",
      count: 1, // Representing 1% as the top percentile
    },
    {
      title: "Top 100 for Arts & Humanities (QS World University Rankings by Subject 2024)",
      count: 100,
    },
    {
      title: "Top 150 for Employability (QS Graduate Employability Rankings 2023)",
      count: 150,
    },
    {
      title: "Top 50 in Europe for Research (Times Higher Education 2023)",
      count: 50,
    },
    {
      title: "Top 100 for Medicine (QS World University Rankings by Subject 2024)",
      count: 100,
    },
  ],
};

const UCDublinChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Overall Ranking (QS World University Rankings 2024)",
      count: 173,
    },
    {
      title: "Business & Economics (QS World University Rankings by Subject 2024)",
      count: 200,
    },
    {
      title: "Engineering & Technology (QS World University Rankings by Subject 2024)",
      count: 150,
    },
    {
      title: "Agriculture & Forestry (QS World University Rankings by Subject 2024)",
      count: 100,
    },
    {
      title: "Graduate Employability (QS Graduate Employability Rankings 2023)",
      count: 100,
    },
  ],
};

const UOCorkChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "5th in Ireland for Research Quality",
      count: 5,
    },
    {
      title: "4th in Ireland for Graduate Employability (QS Graduate Employability Rankings 2024)",
      count: 4,
    },
    {
      title: "5th in the World for Sustainability (Times Higher Education Impact Rankings 2023)",
      count: 5,
    },
    {
      title: "5th in Ireland for International Student Experience",
      count: 5,
    },
    {
      title: "5th in Ireland for Teaching and Learning (QS World University Rankings by Subject 2024)",
      count: 5,
    },
  ],
};

const UOGalwayChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Top 1% globally for research and academic performance",
      count: 1, // Representing top 1% globally
    },
    {
      title: "Top 200 in QS World University Rankings",
      count: 200,
    },
    {
      title: "Top 5 in Ireland for Graduate Employability",
      count: 5,
    },
    {
      title: "#1 in Ireland for Social Impact (THE Impact Rankings)",
      count: 1,
    },
    {
      title: "Top 100 in the world for Environmental Impact (UI GreenMetric Rankings)",
      count: 100,
    },
  ],
};

const DCUChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Top 5 in Ireland for Employability (QS Graduate Employability Rankings)",
      count: 5,
    },
    {
      title: "Top 100 Globally for Business and Management (QS World University Rankings)",
      count: 100,
    },
    {
      title: "Top 100 in the World for Young Universities (QS World University Rankings - Top 50 Under 50)",
      count: 100,
    },
    {
      title: "Top 10 in Ireland for Research Output (Nature Index)",
      count: 10,
    },
    {
      title: "#1 in Ireland for Student Satisfaction (National Student Survey, Ireland)",
      count: 1,
    },
  ],
};

const UOLimerickChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Top 1% Globally for Research in the Field of Engineering (QS World University Rankings)",
      count: 1, // Representing top 1% globally
    },
    {
      title: "Top 10 in Ireland for Graduate Employability (QS Graduate Employability Rankings)",
      count: 10,
    },
    {
      title: "Top 100 Worldwide for Business and Management Studies (QS World University Rankings)",
      count: 100,
    },
    {
      title: "Top 200 Globally for Arts and Humanities (QS World University Rankings)",
      count: 200,
    },
    {
      title: "#1 in Ireland for Student Satisfaction (National Student Survey, Ireland)",
      count: 1,
    },
  ],
};

const MUChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Top 50 in the World for Education (QS World University Rankings by Subject)",
      count: 50,
    },
    {
      title: "Top 5 in Ireland for Student Satisfaction (National Student Survey, Ireland)",
      count: 5,
    },
    {
      title: "Top 200 Globally for Social Sciences (QS World University Rankings by Subject)",
      count: 200,
    },
    {
      title: "Top 100 in the World for Politics and International Studies (QS World University Rankings by Subject)",
      count: 100,
    },
    {
      title: "Top 1% Globally for Research (QS World University Rankings by Research)",
      count: 1, // Representing top 1% globally
    },
  ],
};

const TUDublinChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Top 10 in Ireland for Graduate Employability (QS Graduate Employability Rankings)",
      count: 10,
    },
    {
      title: "Top 200 in the World for Engineering (QS World University Rankings by Subject)",
      count: 200,
    },
    {
      title: "Top 100 Globally for Hospitality and Leisure Management (QS World University Rankings by Subject)",
      count: 100,
    },
    {
      title: "Top 10 in Ireland for Student Satisfaction (National Student Survey, Ireland)",
      count: 10,
    },
    {
      title: "Top 100 in Europe for Innovation and Industry Collaboration (Times Higher Education Rankings)",
      count: 100,
    },
  ],
};

const DBSChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "Largest Independent College in Ireland",
      count: 1,
    },
    {
      title: "Popular Among International Students in Ireland",
      count: 1,
    },
    {
      title: "Recognized for Flexible Learning Options",
      count: 1,
    },
    {
      title: "Accredited by QQI for Quality Programs",
      count: 1,
    },
    {
      title: "Top 5 in Industry-Aligned Business Education in Dublin",
      count: 5,
    },
  ],
};

const RCSChooseUs = {
  heading: "Why Choose Us?",
  items: [
    {
      title: "#1 in Ireland for Medical Education",
      count: 1,
    },
    {
      title: "Top 100 Worldwide for Clinical Medicine (QS World University Rankings)",
      count: 100,
    },
    {
      title: "#1 in Ireland for Health and Medical Sciences",
      count: 1,
    },
    {
      title: "Top 10 Globally for Surgery and Medical Research",
      count: 10,
    },
    {
      title: "#1 in Ireland for Postgraduate Medical Education",
      count: 1,
    },
  ],
};



const UniversityAucklandRankings = {
heading: "Why Choose?",
items: [
  {
    title: "QS World University Rankings 2024",
    count: "85",
  },
  {
    title: "Times Higher Education (THE) World University Rankings 2024",
    count: "201-250",
  },
  {
    title: "Academic Ranking of World Universities (ARWU) 2024",
    count: "151-200",
  },
  {
    title: "QS World University Rankings by Subject",
    description: "100",
  },
  {
    title: "U.S. News Best Global Universities Rankings 2024",
    count: "120",
  },
],
};

const universityMasseyRankings = {
heading: "Why Choose This University?",
items: [
  {
    title: "QS World University Rankings 2024",
    count: "239",
  },
  {
    title: "Times Higher Education (THE) World University Rankings 2024",
    count: "501-600",
  },
  {
    title: "Academic Ranking of World Universities (ARWU) 2024",
    count: "601-700",
  },
  {
    title: "QS Agriculture & Forestry Ranking",
    count: "50",
  },
  {
    title: "QS Veterinary Science Ranking",
    count: "30",
  },
],
};

const WaikatouniversityRankings = {
heading: "Why Choose This University?",
items: [
  {
    title: "QS World University Rankings",
    count: "375",
  },
  {
    title: "Times Higher Education (THE) World Rankings",
    count: "401-500",
  },
  {
    title: "QS Subject Rankings (Education)",
    count: " 200",
  },
  {
    title: "Academic Ranking of World Universities (ARWU)",
    count: "601-700",
  },
  {
    title: "Asia-Pacific",
    count: " 100",
  },
],
};

const VictoriauniversityRankings = {
heading: "Why Choose This University?",
items: [
  {
    title: "QS World University Rankings 2024",
    count: "215",
  },
  {
    title: "Times Higher Education (THE) Rankings 2024",
    count: "501-600",
  },
  {
    title: "QS World Rankings by Subject",
    count: "100",
  },
  {
    title: "ShanghaiRanking (ARWU) 2023",
    count: "301-400",
  },
  {
    title: "Asia-Pacific Rankings",
    count: "100",
  },
],
};

const CanterburyuniversityRankings = {
heading: "Why Choose This University?",
items: [
  {
    title: "QS World University Rankings 2024",
    count: "301-350",
  },
  {
    title: "Times Higher Education (THE) Rankings 2024",
    count: "401-500",
  },
  {
    title: "QS World Rankings by Subject",
    count: "200",
  },
  {
    title: "ShanghaiRanking (ARWU) 2023",
    count: "501-600",
  },
  {
    title: "Asia-Pacific Rankings",
    count: "100",
  },
],
};

const OtagouniversityRankings = {
heading: "Why Choose This University?",
items: [
  {
    title: "QS World University Rankings 2024",
    count: "175",
  },
  {
    title: "Times Higher Education (THE) Rankings 2024",
    count: "201-250",
  },
  {
    title: "QS World Rankings by Subject",
    count: "100",
  },
  {
    title: "ShanghaiRanking (ARWU) 2023",
    count: "301-400",
  },
  {
    title: "Asia-Pacific Rankings",
    count: "50",
  },
],
};

const AucklanduniversityRankings = {
heading: "Why Choose This University?",
items: [
  {
    title: "QS World University Rankings 2024",
    count: "301-350",
  },
  {
    title: "Times Higher Education (THE) Rankings 2024",
    count: "401-500",
  },
  {
    title: "QS World Rankings by Subject",
    count: "200",
  },
  {
    title: "ShanghaiRanking (ARWU) 2023",
    count: "501-600",
  },
  {
    title: "Asia-Pacific Rankings",
    count: "100",
  },
],
};
const  LincolnuniversityRankings = {
heading: "Why Choose This University?",
items: [
  {
    title: "QS World University Rankings 2024",
    count: "501-550",
  },
  {
    title: "Times Higher Education (THE) Rankings 2024",
    count: "801-1000",
  },
  {
    title: "QS World Rankings by Subject",
    count: "100",
  },
  {
    title: "ShanghaiRanking (ARWU) 2023",
    count: "500-600",
  },
  {
    title: "Asia-Pacific Rankings",
    count: "100",
  },
],
};

const EasternpolytechnicRankings = {
heading: "Why Choose This Polytechnic?",
items: [
  {
    title: "Top Polytechnics in New Zealand (Regional Focus)",
    count: "3",
  },
  {
    title: "Student Satisfaction in New Zealand",
    count: "6",
  },
  {
    title: "Programs in Creative Arts and Health",
    count: "5",
  },
  {
    title: "Vocational Training in the North Island",
    count: "4",
  },
  {
    title: "Business and Management Programs in New Zealand",
    count: "10",
  },
],
};
const UCICRankings = {
heading: "Why Choose This Pathway Provider?",
items: [
  {
    title: "Top Pathway Providers in New Zealand for International Students",
    count: "10",
  },
  {
    title: "High-Quality Preparatory Programs for University Degrees in Christchurch",
    count: "2",
  },
  {
    title: "Student Satisfaction among New Zealand's Pathway Colleges",
    count: "5",
  },
  {
    title: "Business and Engineering Foundation Programs in New Zealand",
    count: "3",
  },
  {
    title: "Overall International Student Success Rates in New Zealand (Progression to University Programs)",
    count: "15",
  },
],
};








export {
  CaliforniaChoose,
  WisconsinChoose,
  YeshivaChoose,
  UtahChoose,
  ColoradoChoose,
  IllinoisChoose,
  StanfordChoose,
  PennsylvaniaChoose,
  ChicagoChoose,
  MITChoose,
  BristolChoose,
  LeedsRankings,
  ImperialRankings,
  QueenMaryRankings,
  LiverpoolRankings,
  EdinburghRankings,
  SouthamptonRankings,
  DurhamRankings,
  BirminghamRankings,
  GlasgowRankings,
  UNSWRankings,
  MonashRankings,
  MelbourneRankings,
  QueenslandRankings,
  AdelaideRankings,
  MacquarieRankings,
  trentRankings,
  TrinityRankings,
  RRURankings,
  WaterlooRankings,
  CalgeryRankings,
  DalhousieRankings,
  BrockRankings,
  WindsorRankings,
  VCCProfile,
  TorontoRankings,
  ANURankings,
  WesternAustraliaRankings,
  UTSRankings,
  SydneyRankings,

  //Germany
  LMURankings,
  TUMRankings,
  HeidelbergUniversityRankings,
  HumboldtUniversityRankings,
  FUBRankings,
  RWTHRankings,
  UOFRankings,
  UOTRankings,
  UOMRankings,
  UOGRankings,

   //France
   UPSLRankings,
   UPSRankings,
   SURankings,
   UGARankings,
   UDSRankings,
   UDMRankings,
   UDLRankings,
   UDNRankings,
   UDBRankings,
   UPPSRankings,

   //Italy

   PDMilanoChooseUs,
   UOMilanChooseUs,
   UOBolognaChooseUs,
   UOFlorenceChooseUs,
   UOPaduaChooseUs,
   UOTurinChooseUs,
   RLSChooseUs,
   UONaplesChooseUs,
   UOPisaChooseUs,
   IEDChooseUs,


   //Ireland

   TCDublinChooseUs,
   UCDublinChooseUs,
   UOCorkChooseUs,
   UOGalwayChooseUs,
   DCUChooseUs,
   UOLimerickChooseUs,
   MUChooseUs,
   TUDublinChooseUs,
   DBSChooseUs,
   RCSChooseUs,


//New-Zealand

UniversityAucklandRankings,
universityMasseyRankings,
WaikatouniversityRankings,
VictoriauniversityRankings,
CanterburyuniversityRankings,
OtagouniversityRankings,
AucklanduniversityRankings,
LincolnuniversityRankings,
EasternpolytechnicRankings,
UCICRankings,

  

};
