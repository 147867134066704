/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from "react";
import { Viewer, Worker, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "./test.css";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  LinearProgress,
  Grid,
  TextField,
  TextareaAutosize,
  Button,
  Modal,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import { useTimer } from "react-timer-hook";
import ButtonComponent from "src/Component/content-component/ButtonComponent";
import AudioPlayer from "./AudioPlayer";
import { useLocation, useNavigate, useParams } from "react-router";
import MainHeader from "src/Component/content-component/MainHeader";
import { Icon } from "@iconify/react";

function TestPage() {
  // const location = useLocation();
  const navigate = useNavigate();
  const { id, type } = useParams();
  const [loading, setLoading] = useState(true);
  const [numPages, setNumPages] = useState();
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [duration, setDuration] = useState(0);
  const [timerExpiry, setTimerExpiry] = useState(null);
  const [answers, setAnswers] = useState({});
  const [readingPassages, setReadingPassages] = useState([]);
  const [testType, setTestType] = useState(null);
  const [isTestStarted, setIsTestStarted] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [readingTab, setReadingTab] = useState(0);
  const [questionsType, setQuestionsType] = useState(null);
  const [writingAnswer, setWritingAnswer] = useState([]); // if using array
  const [wordCount, setWordCount] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isTestSubmitted, setIsTestSubmitted] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Submit answers when the dialog confirms submission
  const handleConfirmSubmit = () => {
    handleCloseDialog();
    submitAnswers(); // Call your submit function here
  };
  // for word counts

  useEffect(() => {
    fetchTestDetails(id, type);
  }, [id, type]);

  //api for test data by id
  const fetchTestDetails = async (testId, type) => {
    setLoading(true);
    const token = sessionStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}test/testById`,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            testId: testId,
            type: type
          })
        }
      );

      const data = await response.json();
      const fetchedTotalQuestions =
        data.data.type === "reading"
          ? data.data.reading.individualQuestions
          : data.data.type === "writing"
          ? data.data.writing.individualQuestions
          : data.data.type === "listening"
          ? data.data.listening.individualQuestions
          : data.data.type === "aptitude"
          ? data.data.aptitude.individualQuestions
          : data.data.speaking.individualQuestions;
      const fetchedDuration =
        data.data.type === "reading"
          ? data.data.reading.duration
          : data.data.type === "writing"
          ? data.data.writing.duration
          : data.data.type === "listening"
          ? data.data.listening.duration
          : data.data.type === "aptitude"
          ? data.data.aptitude.duration
          : data.data.speaking.duration;
      const fetchedReadingPassages =
        data.data.type === "reading"
          ? data.data.reading.data
          : data.data.type === "writing"
          ? data.data.writing.data
          : data.data.type === "listening"
          ? data.data.listening.data
          : data.data.type === "aptitude"
          ? data.data.aptitude.data
          : data.data.speaking;
      const fetchQuestionsType =
        data.data.type === "reading"
          ? data.data.reading.question
          : data.data.type === "listening"
          ? data.data.listening.question
          : data.data.type === "aptitude"
          ? data.data.aptitude.question
          : "";
      const fetchTestType = data.data.type;

      setTotalQuestions(fetchedTotalQuestions);
      setDuration(fetchedDuration);
      setReadingPassages(fetchedReadingPassages);
      setTestType(fetchTestType);
      setQuestionsType(fetchQuestionsType);

      setAnswers({
        passage: Array(fetchedTotalQuestions).fill("")
      });
    } catch (error) {
      console.error("Error fetching test details:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  //duration get
  const handleInputChange = (event) => {
    setWritingAnswer(event.target.value);
  };
  const durationInSeconds = duration * 60;

  const { seconds, minutes, hours, restart } = useTimer({
    expiryTimestamp: timerExpiry,
    onExpire: () => console.warn("Time is up!"),
    autoStart: false
  });

  // Start Test
  const startTest = () => {
    setIsTestStarted(true);
    const expiryTime = new Date();
    expiryTime.setSeconds(expiryTime.getSeconds() + durationInSeconds);
    setTimerExpiry(expiryTime);
    restart(expiryTime);
    setShowModal(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleWritingAnswer = (e, taskIndex) => {
    const inputText = e.target.value;
    const words = inputText.trim().split(/\s+/); // Split text by spaces to get word count

    if (words.length <= readingPassages[taskIndex].numberOfWord) {
      // Update state if within word limit
      const newAnswers = [...writingAnswer];
      newAnswers[taskIndex] = inputText;
      setWritingAnswer(newAnswers);

      const newWordCount = [...wordCount];
      newWordCount[taskIndex] = words.length;
      setWordCount(newWordCount);
    }
  };

  // Handle answer change for single choice (MCQ or text input)
  const handleAnswerChange = (questionIndex, value) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers };
      newAnswers.passage[questionIndex] = value;
      return newAnswers;
    });
  };

  // Handle multiple choice (MSQ)
  const handleMSQChange = (questionIndex, option) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers };
      const selectedOptions = newAnswers.passage[questionIndex] || [];

      if (selectedOptions.includes(option)) {
        newAnswers.passage[questionIndex] = selectedOptions.filter(
          (o) => o !== option
        ); // Deselect option
      } else {
        newAnswers.passage[questionIndex] = [...selectedOptions, option]; // Select option
      }

      return newAnswers;
    });
  };

  const prepareAnswerData = () => {
    if (testType === "writing") {
      return readingPassages?.map((task, index) => ({
        passageNumber: (index + 1).toString(), // Passage number as a string
        answer: writingAnswer[index] || "" // Get the answer from the writingAnswer state
      }));
    } else {
      return questionsType?.map((question, index) => ({
        questionNumber: question.questionNumber,
        questionType: question.type,
        answer:
          question.type === "msq"
            ? answers.passage[index] || []
            : [answers.passage[index] || ""]
      }));
    }
  };
  useEffect(() => {
    if (isTestStarted && hours === 0 && minutes === 0 && seconds === 0) {
      submitAnswers(); // Auto-submit when timer reaches zero
    }
  }, [hours, minutes, seconds]);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // If the test is not submitted, warn the user
      if (!isTestSubmitted) {
        event.preventDefault();
        event.returnValue = ""; // Chrome requires returnValue to be set
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  // Submit answers to the API
  const submitAnswers = async () => {
    const token = sessionStorage.getItem("token");
    const answerData = prepareAnswerData();
    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}test/submitAnswer`,
        {
          method: "POST",
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            testId: id,
            data: answerData,
            type: testType
          })
        }
      );

      const result = await response.json();
      if (response.ok) {
        // Handle success (e.g., navigate to a success page or show a message)
        setIsTestSubmitted(true); // Set test as submitted

        console.log("Answers submitted successfully:", result);
        alert("Test submitted successfully!");
        setLoading(false);
        navigate("/dashboard/mocktest");
        // Redirect to success page
      } else {
        // Handle errors
        console.error("Error submitting answers:", result.message);
        alert(`Submission failed: ${result.message}`);
      }
    } catch (error) {
      console.error("Error submitting answers:", error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  // Called when document is loaded
  const onDocumentLoadSuccess = ({ numPages: loadedNumPages }) => {
    setNumPages(loadedNumPages);
  };

  const pdfRef = useRef();
  // pdf viewer custom css
  const toolbarPluginInstance = toolbarPlugin({
    layout: (Toolbar) => (
      <div style={{ padding: "0 2px" }}>
        <Toolbar>
          {({ CurrentPageInput, GoToNextPage, GoToPreviousPage }) => (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <GoToPreviousPage>
                  {(props) => (
                    <button onClick={props.onClick} disabled={props.isDisabled}>
                      Previous
                    </button>
                  )}
                </GoToPreviousPage>

                <div style={{ margin: "0 20px" }}>
                  <CurrentPageInput /> / {numPages}
                </div>

                <GoToNextPage>
                  {(props) => (
                    <button onClick={props.onClick} disabled={props.isDisabled}>
                      Next
                    </button>
                  )}
                </GoToNextPage>
              </div>
            </>
          )}
        </Toolbar>
      </div>
    )
  });

  const { Toolbar } = toolbarPluginInstance;

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          display: showModal ? "block" : "none",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backdropFilter: showModal ? "blur(8px)" : "none", // Apply blur when modal is open
          zIndex: showModal ? 10 : 0, // Ensure blur effect is on top
          transition: "backdrop-filter 0.3s ease-in-out" // Smooth transition
        }}
      ></Box>
      <Modal open={showModal}>
        <Box sx={{ bgcolor: "white", padding: "20px", textAlign: "center" }}>
          <Typography variant="h5">Are you ready to start the test?</Typography>

          <Button
            onClick={startTest}
            disabled={loading}
            variant="contained"
            sx={{
              m: 2,
              textTransform: "capitalize",
              bgcolor: "#00999E",
              "&:hover": { bgcolor: "#00999E" }
            }}
          >
            Start Test
          </Button>
          <Button
            disabled={loading}
            onClick={handleCancel}
            variant="contained"
            color="error"
            sx={{ m: 2, textTransform: "capitalize" }}
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: "30px",
          alignItems: "center",
          backdropFilter: showModal ? "blur(8px)" : "none" // Apply blur when modal is open
        }}
      >
        <Icon
          icon="heroicons:light-bulb"
          style={{ color: "#00999E", fontSize: "28px" }}
        />
        <Box sx={{ pl: "20px" }}>
          <MainHeader
            content={"Mock Test"}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>
      <Box p={3}>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <Box>
            <Box
              display="flex"
              flexDirection={"column"}
              sx={{ width: "100%" }}
              mb={2}
            >
              <Box sx={{ bgcolor: "#effdff" }}>
                <Typography
                  variant="body1"
                  sx={{ m: "10px", color: "#00999E", fontWeight: "500" }}
                >
                  Time Left: {hours}:{minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={
                  ((durationInSeconds -
                    (hours * 3600 + minutes * 60 + seconds)) /
                    durationInSeconds) *
                  100
                }
                sx={{
                  width: "100%",
                  ".css-5xe99f-MuiLinearProgress-bar1": {
                    bgcolor: "#00999E",
                    borderRadius: 5
                  }
                }}
              />
            </Box>

            <Box position="static" sx={{ m: "20px 0" }}>
              <Tabs
                value={readingTab}
                onChange={(event, newValue) => setReadingTab(newValue)}
                variant="fullWidth"
                textColor="inherit"
                sx={{
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#00999E"
                  }
                }}
              >
                {readingPassages?.map((reading, index) => (
                  <Tab key={index} label={reading?.name} />
                ))}
              </Tabs>
            </Box>

            {readingPassages[readingTab]?.audio && (
              <AudioPlayer src={readingPassages[readingTab].audio} />
            )}

            <Grid container spacing={2}>
              <Grid item md={7} xs={12}>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ m: "10px", color: "#606060", fontWeight: "500" }}
                  >
                    {testType === "reading"
                      ? "Reading Test"
                      : testType === "writing"
                      ? "Writing Test"
                      : testType === "listening"
                      ? "Listening Test"
                      : testType === "aptitude"
                      ? "Aptitude Test"
                      : "speaking"}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    bgcolor: "#effdff",
                    p: "5px 0px",
                    border: "2px solid #9095a7"
                  }}
                >
                  <Toolbar />
                </Box>
                <Box
                  sx={{
                    height: "750px",
                    width: "100%",
                    padding: "",
                    border: "2px solid #9095a7"
                  }}
                >
                  <Worker
                    ref={pdfRef}
                    workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
                  >
                    {readingPassages?.length > 0 && (
                      <Viewer
                        fileUrl={readingPassages[readingTab]?.pdf}
                        plugins={[toolbarPluginInstance]}
                        defaultScale={SpecialZoomLevel.ActualSize}
                        onDocumentLoad={onDocumentLoadSuccess}
                      />
                    )}
                  </Worker>
                </Box>
              </Grid>
              <Grid item md={5} xs={12}>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ m: "10px", color: "#606060", fontWeight: "500" }}
                  >
                    Module Taken :{" "}
                    {testType === "reading"
                      ? "Reading"
                      : testType === "writing"
                      ? "Writing"
                      : testType === "listening"
                      ? "Listening"
                      : "Aptitude"}
                  </Typography>
                </Box>
                {testType === "writing" && (
                  <Box
                    sx={{
                      width: "100%",
                      height: "auto",
                      p: 3,
                      border: "2px solid #9095a7",
                      bgcolor: "#fff"
                    }}
                  >
                    {readingPassages[readingTab] && (
                      <Box sx={{ mb: 3 }}>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Writing Task {readingTab + 1}: Not more than{" "}
                          {readingPassages[readingTab].numberOfWord} words
                        </Typography>
                        <TextareaAutosize
                          minRows={10}
                          style={{
                            width: "100%",
                            border: "none",
                            outline: "none",
                            resize: "none",
                            fontFamily: "monospace",
                            lineHeight: "2rem",
                            overflowY: "auto",
                            background: `repeating-linear-gradient(white, white 30px, #ccc 31px, white 32px)`
                          }}
                          placeholder={`Start typing here... (Max ${readingPassages[readingTab].numberOfWord} words)`}
                          value={writingAnswer[readingTab] || ""} // Show current answer for tab
                          onChange={(e) => handleWritingAnswer(e, readingTab)} // Update for current tab
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 1,
                            color:
                              wordCount[readingTab] >
                              readingPassages[readingTab].numberOfWord
                                ? "red"
                                : "green"
                          }}
                        >
                          Word count: {wordCount[readingTab] || 0} /{" "}
                          {readingPassages[readingTab].numberOfWord}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}

                {testType !== "writing" && (
                  <Box
                    sx={{
                      maxheight: "750px",
                      overflowY: "auto",
                      border: "1px solid grey",
                      bgcolor: "#effdff"
                    }}
                  >
                    {questionsType?.map((question, questionIndex) => (
                      <Box
                        key={questionIndex}
                        sx={{
                          padding: "15px 10px",
                          border: "1px solid grey",
                          bgcolor:
                            (questionIndex + 1) % 2 === 0
                              ? "#effdff"
                              : "#ffffff"
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{
                            m: "10px 0",
                            color: "#c1c1c1",
                            fontWeight: "400"
                          }}
                        >
                          Answer: {question.questionNumber} (
                          {question.type === "mcq"
                            ? "MCQ"
                            : question.type === "msq"
                            ? "MSQ"
                            : "TEXT"}
                          )
                        </Typography>

                        {/* Define array for A to F options */}
                        {question.type === "mcq" && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { sm: "row", xs: "column" }
                            }}
                          >
                            {/* Use static array of options */}
                            {["A", "B", "C", "D", "E", "F"].map((option) => (
                              <Box
                                key={option}
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <Checkbox
                                  checked={
                                    answers.passage[questionIndex] === option
                                  }
                                  onChange={() =>
                                    handleAnswerChange(questionIndex, option)
                                  }
                                  sx={{ color: "#00999E" }}
                                  checkedIcon={
                                    <Icon
                                      icon="material-symbols:check-box"
                                      style={{
                                        color: "#00999E",
                                        fontSize: "24px"
                                      }}
                                    />
                                  }
                                />
                                <Typography variant="body2">{`${option}`}</Typography>
                              </Box>
                            ))}
                          </Box>
                        )}

                        {/* For MSQ type questions */}
                        {question.type === "msq" && (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: { sm: "row", xs: "column" }
                            }}
                          >
                            {/* Use static array of options */}
                            {["A", "B", "C", "D", "E", "F"].map((option) => (
                              <Box
                                key={option}
                                sx={{
                                  display: "flex",
                                  alignItems: "center"
                                  // pr: "10px"
                                }}
                              >
                                <Checkbox
                                  checked={answers.passage[
                                    questionIndex
                                  ]?.includes(option)}
                                  onChange={() =>
                                    handleMSQChange(questionIndex, option)
                                  }
                                  sx={{ color: "#00999E" }}
                                  checkedIcon={
                                    <Icon
                                      icon="material-symbols:check-box"
                                      style={{
                                        color: "#00999E",
                                        fontSize: "24px"
                                      }}
                                    />
                                  }
                                />
                                <Typography variant="body2">{`${option}`}</Typography>
                              </Box>
                            ))}
                          </Box>
                        )}
                        {question.type !== "mcq" && question.type !== "msq" && (
                          // Render TextField for other types of questions (text input)
                          <TextField
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={2}
                            value={answers.passage[questionIndex]}
                            onChange={(e) =>
                              handleAnswerChange(questionIndex, e.target.value)
                            }
                            placeholder="Enter your answer here"
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
                <Box sx={{ m: "10px 0" }}>
                  <ButtonComponent
                    width={"100%"}
                    onClick={() => handleOpenDialog()}
                    text={"Submit"}
                    height={"50px"}
                    fontWeight={"600"}
                    fontSize={"24px"}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Submit Test</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to submit your answers?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            variant="contained"
            sx={{
              m: 2,
              textTransform: "capitalize",
              bgcolor: "red",
              "&:hover": { bgcolor: "red" }
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmSubmit}
            variant="contained"
            sx={{
              m: 2,
              textTransform: "capitalize",
              bgcolor: "#00999E",
              "&:hover": { bgcolor: "#00999E" }
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default TestPage;
