import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Footer from "src/Component/Footer";
import Navbar from "src/Component/Navbar";
import Universitybanner from "./University/Universitybanner";
import {
  AdelaideBanner,
  AnuBanner,
  AUOTBanner,
  BirminghamBanner,
  BristolBanner,
  BrockBanner,
  CalgeryBanner,
  CaliforniaBanner,
  ChicagoBanner,
  ColoradoBanner,
  DalhousieBanner,
  DBSBanner,
  DCUBanner,
  DurhamBanner,
  EdinburghBanner,
  EIOTBanner,
  FUBBanner,
  GlasgowBanner,
  HeidelbergUniversityBanner,
  HumboldtUniversityBanner,
  IEDBanner,
  IllinoisBanner,
  ImperialBanner,
  LeedsBanner,
  LiverpoolBanner,
  LMUBanner,
  LUniversityBanner,
  MacquarieBanner,
  MelbourneBanner,
  MITBanner,
  MonashBanner,
  MUBanner,
  MUniversityBanner,
  PDMilanoBanner,
  PennsylvaniaBanner,
  QueenMaryBanner,
  QueenslandBanner,
  RCSIBanner,
  RLSBanner,
  RRUBanner,
  RWTHBanner,
  SouthamptonBanner,
  StanfordBanner,
  SUBanner,
  SydneyBanner,
  TCDublinBanner,
  TDublinBanner,
  TechnologySydneyBanner,
  TMUBanner,
  TorontoBanner,
  TrentBanner,
  TrinityBanner,
  TUDublinBanner,
  UCanterburyBanner,
  UCDublinBanner,
  UCICBanner,
  UDBBanner,
  UDLBanner,
  UDMBanner,
  UDNBanner,
  UDSBanner,
  UGABanner,
  UNSWBanner,
  UOABanner,
  UOBolognaBanner,
  UOCorkBanner,
  UOFBanner,
  UOFlorenceBanner,
  UOGalwayBanner,
  UOGBanner,
  UOLimerickBanner,
  UOMBanner,
  UOMilanBanner,
  UONaplesBanner,
  UOPaduaBanner,
  UOPisaBanner,
  UOtagoBanner,
  UOTBanner,
  UOTurinBanner,
  UOWBanner,
  UPPSBanner,
  UPSBanner,
  UPSLBanner,
  UtahBanner,
  VCCBanner,
  VUOWBanner,
  WaterlooBanner,
  WesternaustraliaBanner,
  WindsorBanner,
  WisconsinBanner,
  YeshivaBanner
} from "./UniversityJson/UniversityBanner";
import LetsGetStarted from "src/Component/LetsGetStarted";
import UniversityInfo from "./University/UniversityInfo";
import {
  AdelaideInfo,
  ANUInfo,
  AUTInfo,
  BirminghamInfo,
  BristolInfo,
  BrockInfo,
  CalgaryInfo,
  CaliforniaInfo,
  CanterburyInfo,
  ChicagoInfo,
  ColoradoInfo,
  DalhousieInfo,
  DBSInfo,
  DCUInfo,
  DurhamInfo,
  EdinburghInfo,
  EITInfo,
  FUBInfo,
  GlasgowInfo,
  HeidelbergUniversityInfo,
  HumboldtUniversityInfo,
  IEDInfo,
  IllinoisInfo,
  ImperialInfo,
  LeedsInfo,
  LincolnInfo,
  LiverpoolInfo,
  LMUInfo,
  MacquarieInfo,
  MasseyInfo,
  MelbourneInfo,
  MITInfo,
  MonashInfo,
  MUInfo,
  OtagoInfo,
  PDMilanoiInfo,
  PennsylvaniaInfo,
  QueenMaryInfo,
  QueenslandInfo,
  RCSInfo,
  RLSInfo,
  RRUInfo,
  RWTHInfo,
  SouthamptonInfo,
  StanfordInfo,
  SUInfo,
  SydneyInfo,
  TCDublinInfo,
  TorontoInfo,
  TrentInfo,
  TrinityInfo,
  TUDublinInfo,
  TUMInfo,
  UCDublinInfo,
  UCICInfo,
  UDBInfo,
  UDLInfo,
  UDMInfo,
  UDNInfo,
  UDSInfo,
  UGAInfo,
  UniboInfo,
  UNSWInfo,
  UoAInfo,
  UOCorkInfo,
  UOFInfo,
  UOFlorenceInfo,
  UOGalwayInfo,
  UOGInfo,
  UOLimerickInfo,
  UOMilanInfo,
  UOMInfo,
  UONaplesInfo,
  UOPaduaInfo,
  UOPisaInfo,
  UOTInfo,
  UOTurinInfo,
  UPPSInfo,
  UPSInfo,
  UPSLInfo,
  UtahInfo,
  UTSInfo,
  UWAInfo,
  VCCInfo,
  VUWInfo,
  WaikatoInfo,
  WaterlooInfo,
  WindsorInfo,
  WisconsinInfo,
  YeshivaInfo
} from "./UniversityJson/UniversityInfoData";
import WhyChooseUniversity from "./University/WhyChooseUniversity";
import {
  AdelaideRankings,
  ANURankings,
  AucklanduniversityRankings,
  BirminghamRankings,
  BristolChoose,
  BrockRankings,
  CalgeryRankings,
  CaliforniaChoose,
  CanterburyuniversityRankings,
  ChicagoChoose,
  ColoradoChoose,
  DalhousieRankings,
  DBSChooseUs,
  DCUChooseUs,
  DurhamRankings,
  EasternpolytechnicRankings,
  EdinburghRankings,
  FUBRankings,
  GlasgowRankings,
  HeidelbergUniversityRankings,
  HumboldtUniversityRankings,
  IEDChooseUs,
  IllinoisChoose,
  ImperialRankings,
  InternationalProviderRankings,
  LeedsRankings,
  LincolnuniversityRankings,
  LiverpoolRankings,
  LMURankings,
  MacquarieRankings,
  MelbourneRankings,
  MITChoose,
  MonashRankings,
  MUChooseUs,
  OtagouniversityRankings,
  PDMilanoChooseUs,
  PennsylvaniaChoose,
  QueenMaryRankings,
  QueenslandRankings,
  RCSChooseUs,
  RLSChooseUs,
  RRURankings,
  RWTHRankings,
  SouthamptonRankings,
  StanfordChoose,
  SURankings,
  SydneyRankings,
  TCDublinChooseUs,
  TDublinChooseUs,
  TorontoRankings,
  trentRankings,
  TrinityRankings,
  TUDublinChooseUs,
  TUMRankings,
  UCDublinChooseUs,
  UCICRankings,
  UDBRankings,
  UDLRankings,
  UDMRankings,
  UDNRankings,
  UDSRankings,
  UGARankings,
  UniversityAucklandRankings,
  universityMasseyRankings,
  UNSWRankings,
  UOBolognaChooseUs,
  UOCorkChooseUs,
  UOFlorenceChooseUs,
  UOFRankings,
  UOGalwayChooseUs,
  UOGRankings,
  UOLimerickChooseUs,
  UOMilanChooseUs,
  UOMRankings,
  UONaplesChooseUs,
  UOPaduaChooseUs,
  UOPisaChooseUs,
  UOTRankings,
  UOTurinChooseUs,
  UPPSRankings,
  UPSLRankings,
  UPSRankings,
  UtahChoose,
  UTSRankings,
  VCCProfile,
  VictoriauniversityRankings,
  WaikatouniversityRankings,
  WaterlooRankings,
  WesternAustraliaRankings,
  WindsorRankings,
  WisconsinChoose,
  YeshivaChoose
} from "./UniversityJson/UniversityChoose";
import UniversityFeeStructure from "./University/UniversityFeeStructure";
import {
  AdelaideFeeStructure,
  ANUFeeStructure,
  AUOTFeeStructure,
  BirminghamFeeStructure,
  BristolFeeStructure,
  BrockFeeStructure,
  CalgeryFeeStructure,
  CaliforniaFeeStructure,
  ChicagoFeeStructure,
  ColoradoFeeStructure,
  DalhousieFeeStructure,
  DBSFeeStructure,
  DCUFeeStructure,
  DurhamFeeStructure,
  EdinburghFeeStructure,
  EIOTFeeStructure,
  FUBFeeStructure,
  GlasgowFeeStructure,
  HeidelbergUniversityFeeStructure,
  HumboldtUniversityFeeStructure,
  IEDFeeStructure,
  IllinoisFeeStructure,
  ImperialFeeStructure,
  LeedsFeeStructure,
  LiverpoolFeeStructure,
  LMUFeeStructure,
  LUniversityFeeStructure,
  MacquarieFeeStructure,
  MelbourneFeeStructure,
  MITFeeStructure,
  MonashFeeStructure,
  MUFeeStructure,
  MUniversityFeeStructure,
  PDMilanoFeeStructure,
  PennsylvaniaFeeStructure,
  QueenMaryFeeStructure,
  RCSFeeStructure,
  RLSFeeStructure,
  RRUFeeStructure,
  RWTHFeeStructure,
  SouthamptonFeeStructure,
  StanfordFeeStructure,
  SUFeeStructure,
  SydneyFeeStructure,
  TCDublinFeeStructure,
  TorontoFeeStructure,
  TrentFeeStructure,
  TrinityFeeStructure,
  TUDublinFeeStructure,
  TUMFeeStructure,
  UCanterburyFeeStructure,
  UCCorkFeeStructure,
  UCDublinFeeStructure,
  UCICFeeStructure,
  UDBFeeStructure,
  UDLFeeStructure,
  UDMFeeStructure,
  UDNFeeStructure,
  UDSFeeStructure,
  UGAFeeStructure,
  UNSWFeeStructure,
  UOAFeeStructure,
  UOBolognaFeeStructure,
  UOFFeeStructure,
  UOFlorenceFeeStructure,
  UOGalwayFeeStructure,
  UOGFeeStructure,
  UOLimerickFeeStructure,
  UOMFeeStructure,
  UOMilanFeeStructure,
  UONaplesFeeStructure,
  UOPaduaFeeStructure,
  UOPisaFeeStructure,
  UOtagoFeeStructure,
  UOTFeeStructure,
  UOTurinFeeStructure,
  UOWFeeStructure,
  UPPSFeeStructure,
  UPSFeeStructure,
  UPSLFeeStructure,
  UQFeeStructure,
  UtahFeeStructure,
  UTSFeeStructure,
  VCCFeeStructure,
  VUOWFeeStructure,
  WaterlooFeeStructure,
  WesternAustraliaFeeStructure,
  WindsorFeeStructure,
  WisconsinFeeStructure,
  YeshivaFeeStructure
} from "./UniversityJson/UniversityFeeStructure";
import UniversityScholarship from "./University/UniversityScholarship";
import {
  AdelaideScholarships,
  ANUScholarships,
  BirminghamScholarships,
  BristolScholarships,
  CaliforniaScholarships,
  ColoradoScholarships,
  GlasgowScholarships,
  ImperialScholarships,
  LeedsScholarships,
  MacquarieScholarships,
  MelbourneScholarships,
  MonashScholarships,
  QueenslandScholarships,
  SydneyScholarships,
  UNSWScholarships,
  UtahScholarships,
  UTSScholarships,
  UWAScholarships,
  WisconsinScholarships,
  YeshivaScholarships,
  TrentScholarships,
  TrinityScholarships,
  RoyalScholarships,
  WaterlooScholarships,
  CalgeryScholarships,
  DalhousieScholarships,
  BrockScholarships,
  WindsorScholarships,
  VCCScholarships,
  TorontoScholarships,
  LiverpoolScholarships,
  EdinburghScholarships,
  SouthamptonScholarships,
  DurhamScholarships,
  QueenMaryScholarships,
  IllinoisScholarships,
  StanfordScholarships,
  PennsylvaniaScholarships,
  ChicagoScholarships,
  MITScholarships,
  LMUScholarships,
  TUMScholarships,
  HeidelbergUniversityScholarships,
  HumboldtUniversityScholarships,
  FUBScholarships,
  RWTHScholarships,
  UOFScholarships,
  UOTScholarships,
  UOMScholarships,
  UOGScholarships,
  UPSLScholarships,
  UPSScholarships,
  SUScholarships,
  UGAScholarship,
  UDSScholarships,
  UDMScholarships,
  UDLScholarships,
  UDNScholarships,
  UDBScholarships,
  UPPSScholarships,
  PDMilanoScholarships,
  UOMilanScholarships,
  UOBolognaScholarships,
  UOFlorenceScholarships,
  UOTurinScholarships,
  UOPaduaScholarships,
  RLSScholarships,
  UONaplesScholarships,
  UOPisaScholarships,
  IEDScholarships,
  TCDublinScholarships,
  UCDublinScholarships,
  UOCorkScholarships,
  UOGalwayScholarships,
  DCUScholarships,
  UOLimerickScholarships,
  MUScholarships,
  TUDublinScholarships,
  DBSScholarships,
  RCSScholarships,
  AucklandScholarships,
  MasseyScholarships,
  WaikatoScholarships,
  VictoriaScholarships,
  CanterburyScholarships,
  OtagoScholarships,
  AUTScholarships,
  LincolnScholarships,
  EITScholarships,
  UCScholarships
} from "./UniversityJson/UniversityScholarship";
import UniversityKnowMore from "./University/UniversityKnowMore";
import {
  AdelaideKnowMore,
  ANUKnowMore,
  AucklandKnowMore,
  AUTKnowMore,
  BirminghamKnowMore,
  BristolKnowMore,
  BrockKnowMore,
  CalgaryKnowMore,
  CaliforniaKnowMore,
  CanterburyKnowMore,
  ChicagoKnowMore,
  ColoradoKnowMore,
  DalhousieKnowMore,
  DBSKnowMore,
  DCUKnowMore,
  DurhamKnowMore,
  EdinburghKnowMore,
  EITKnowMore,
  FUBerlinKnowMore,
  GlasgowKnowMore,
  HeidelbergUniversityKnowMore,
  HumboldtUniversityKnowMore,
  IEDKnowMore,
  IllinoisKnowMore,
  ImperialKnowMore,
  LeedsKnowMore,
  LincolnKnowMore,
  LiverpoolKnowMore,
  LMUKnowMore,
  MacquarieKnowMore,
  MasseyKnowMore,
  MelbourneKnowMore,
  MITKnowMore,
  MonashKnowMore,
  MUKnowMore,
  OtagoKnowMore,
  PDMilanoKnowMore,
  PennsylvaniaKnowMore,
  QueenMaryKnowMore,
  QueenslandKnowMore,
  RCSIKnowMore,
  RLSKnowMore,
  RoyalRoadsKnowMore,
  RWTHAachenKnowMore,
  SouthamptonKnowMore,
  StanfordKnowMore,
  SUKnowMore,
  SydneyKnowMore,
  TCDublinKnowMore,
  TorontoKnowMore,
  TrentKnowMore,
  TrinityKnowMore,
  TUDublinKnowMore,
  TUMKnowMore,
  UCDublinKnowMore,
  UCICKnowMore,
  UDBKnowMore,
  UDLKnowMore,
  UDMKnowMore,
  UDNKnowMore,
  UDSKnowMore,
  UGAKnowMore,
  UNSWKnowMore,
  UOBolognaKnowMore,
  UOCorkKnowMore,
  UOFKnowMore,
  UOFlorenceKnowMore,
  UOGalwayKnowMore,
  UOGKnowMore,
  UOLimerickKnowMore,
  UOMilanKnowMore,
  UOMKnowMore,
  UONaplesKnowMore,
  UOPaduaKnowMore,
  UOPisaKnowMore,
  UOTKnowMore,
  UOTurinKnowMore,
  UPPSKnowMore,
  UPSKnowMore,
  UPSLKnowMore,
  UtahKnowMore,
  UTSKnowMore,
  VCCKnowMore,
  VictoriaKnowMore,
  WaikatoKnowMore,
  WaterlooKnowMore,
  WesternAustraliaKnowMore,
  WindsorKnowMore,
  WisconsinKnowMore,
  YeshivaKnowMore
} from "./UniversityJson/UniversityKnowMore";
import UniversityRecruiters from "./University/UniversityRecruiters";
import {
  AUTRecruiter,
  AdelaideRecruiter,
  AnuRecruiter,
  AucklandRecruiter,
  BirminghamRecruiter,
  BristolRecruiter,
  BrockRecruiter,
  CalgeryRecruiter,
  CaliforniaRecruiter,
  CanterburyRecruiter,
  ChicagoRecruiter,
  ColoradoRecruiter,
  DBSRecruiter,
  DCURecruiter,
  DalhousieRecruiter,
  DurhamRecruiter,
  EITRecruiter,
  EdinburghRecruiter,
  FUBRecruiter,
  GlasgowRecruiter,
  HeidelbergUniversityRecruiter,
  HumboldtUniversityRecruiter,
  IEDRecruiter,
  IllinoisRecruiter,
  LMURecruiter,
  LeedsRecruiter,
  LincolnRecruiter,
  LiverpoolRecruiter,
  MITRecruiter,
  MURecruiter,
  MacquarieRecruiter,
  MasseyRecruiter,
  MelbourneRecruiter,
  MonashRecruiter,
  OtagoRecruiter,
  PDMilanoRecruiter,
  PennsylvaniaRecruiter,
  QueenMaryRecruiter,
  QueenslandRecruiter,
  RCSIRecruiter,
  RCSRecruiter,
  RLSRecruiter,
  RRURecruiter,
  RWTHRecruiter,
  SURecruiter,
  SouthamptonRecruiter,
  StanfordRecruiter,
  SydneyRecruiter,
  TCDublinRecruiter,
  TUDublinRecruiter,
  TUMRecruiter,
  TorontoRecruiter,
  TrentRecruiter,
  TrinityRecruiter,
  UCDublinRecruiter,
  UCRecruiters,
  UDBRecruiter,
  UDLRecruiter,
  UDMRecruiter,
  UDNRecruiter,
  UDSRecruiter,
  UGARecruiter,
  UNSWRecruiter,
  UOBolognaRecruiter,
  UOCorkRecruiter,
  UOFRecruiter,
  UOFlorenceRecruiter,
  UOGRecruiter,
  UOGalwayRecruiter,
  UOLimerickRecruiter,
  UOMRecruiter,
  UOMilanRecruiter,
  UONaplesRecruiter,
  UOPaduaRecruiter,
  UOPisaRecruiter,
  UOTRecruiter,
  UOTurinRecruiter,
  UPPSRecruiter,
  UPSLRecruiter,
  UPSRecruiter,
  UtahRecruiter,
  UtsRecruiter,
  VCCRecruiter,
  VictoriaRecruiter,
  WaikatoRecruiter,
  WaterlooRecruiter,
  WesternAustraliaRecruiter,
  WindsorRecruiter,
  YeshivaRecruiter,
  imperialRecruiter,
  wisconsinRecruiter
} from "./UniversityJson/UniversityRecruiters";
import BackRouteContainer from "src/Component/grid-component/BackButtonComponent";
import logo from "../asset/backuniversity.png";

const jsonData = [
  {
    pathname: "california",
    banner: CaliforniaBanner,
    info: CaliforniaInfo,
    choose: CaliforniaChoose,
    fee: CaliforniaFeeStructure,
    scholarship: CaliforniaScholarships,
    know: CaliforniaKnowMore,
    recruiters: CaliforniaRecruiter
  },
  {
    pathname: "wisconsin",
    banner: WisconsinBanner,
    info: WisconsinInfo,
    choose: WisconsinChoose,
    fee: WisconsinFeeStructure,
    scholarship: WisconsinScholarships,
    know: WisconsinKnowMore,
    recruiters: wisconsinRecruiter
  },
  {
    pathname: "yeshiva",
    banner: YeshivaBanner,
    info: YeshivaInfo,
    choose: YeshivaChoose,
    fee: YeshivaFeeStructure,
    scholarship: YeshivaScholarships,
    know: YeshivaKnowMore,
    recruiters: YeshivaRecruiter
  },
  {
    pathname: "utah",
    banner: UtahBanner,
    info: UtahInfo,
    choose: UtahChoose,
    fee: UtahFeeStructure,
    scholarship: UtahScholarships,
    know: UtahKnowMore,
    recruiters: UtahRecruiter
  },

  {
    pathname: "colorado",
    banner: ColoradoBanner,
    info: ColoradoInfo,
    choose: ColoradoChoose,
    fee: ColoradoFeeStructure,
    scholarship: ColoradoScholarships,
    Know: ColoradoKnowMore,
    recruiters: ColoradoRecruiter
  },

  {
    pathname: "illinois",
    banner: IllinoisBanner,
    info: IllinoisInfo,
    choose: IllinoisChoose,
    fee: IllinoisFeeStructure,
    scholarship: IllinoisScholarships,
    know: IllinoisKnowMore,
    recruiters: IllinoisRecruiter
  },
  {
    pathname: "stanford",
    banner: StanfordBanner,
    info: StanfordInfo,
    choose: StanfordChoose,
    fee: StanfordFeeStructure,
    scholarship: StanfordScholarships,
    know: StanfordKnowMore,
    recruiters: StanfordRecruiter
  },
  {
    pathname: "pennsylvania",
    banner: PennsylvaniaBanner,
    info: PennsylvaniaInfo,
    choose: PennsylvaniaChoose,
    fee: PennsylvaniaFeeStructure,
    scholarship: PennsylvaniaScholarships,
    know: PennsylvaniaKnowMore,
    recruiters: PennsylvaniaRecruiter
  },
  {
    pathname: "chicago",
    banner: ChicagoBanner,
    info: ChicagoInfo,
    choose: ChicagoChoose,
    fee: ChicagoFeeStructure,
    scholarship: ChicagoScholarships,
    know: ChicagoKnowMore,
    recruiters: ChicagoRecruiter
  },
  {
    pathname: "mit",
    banner: MITBanner,
    info: MITInfo,
    choose: MITChoose,
    fee: MITFeeStructure,
    scholarship: MITScholarships,
    know: MITKnowMore,
    recruiters: MITRecruiter
  },

  //UK

  {
    pathname: "bristol",
    banner: BristolBanner,
    info: BristolInfo,
    choose: BristolChoose,
    fee: BristolFeeStructure,
    scholarship: BristolScholarships,
    know: BristolKnowMore,
    recruiters: BristolRecruiter
  },
  {
    pathname: "leeds",
    banner: LeedsBanner,
    info: LeedsInfo,
    choose: LeedsRankings,
    fee: LeedsFeeStructure,
    scholarship: LeedsScholarships,
    know: LeedsKnowMore,
    recruiters: LeedsRecruiter
  },
  {
    pathname: "glasgow",
    banner: GlasgowBanner,
    info: GlasgowInfo,
    choose: GlasgowRankings,
    fee: GlasgowFeeStructure,
    scholarship: GlasgowScholarships,
    know: GlasgowKnowMore,
    recruiters: GlasgowRecruiter
  },
  {
    pathname: "birmingham",
    banner: BirminghamBanner,
    info: BirminghamInfo,
    choose: BirminghamRankings,
    fee: BirminghamFeeStructure,
    scholarship: BirminghamScholarships,
    know: BirminghamKnowMore,
    recruiters: BirminghamRecruiter
  },
  {
    pathname: "imperial",
    banner: ImperialBanner,
    info: ImperialInfo,
    choose: ImperialRankings,
    fee: ImperialFeeStructure,
    scholarship: ImperialScholarships,
    know: ImperialKnowMore,
    recruiters: imperialRecruiter
  },
  {
    pathname: "queenmary",
    banner: QueenMaryBanner,
    info: QueenMaryInfo,
    choose: QueenMaryRankings,
    fee: QueenMaryFeeStructure,
    scholarship: QueenMaryScholarships,
    know: QueenMaryKnowMore,
    recruiters: QueenMaryRecruiter
  },
  {
    pathname: "liverpool",
    banner: LiverpoolBanner,
    info: LiverpoolInfo,
    choose: LiverpoolRankings,
    fee: LiverpoolFeeStructure,
    scholarship: LiverpoolScholarships,
    know: LiverpoolKnowMore,
    recruiters: LiverpoolRecruiter
  },
  {
    pathname: "edinburgh",
    banner: EdinburghBanner,
    info: EdinburghInfo,
    choose: EdinburghRankings,
    fee: EdinburghFeeStructure,
    scholarship: EdinburghScholarships,
    know: EdinburghKnowMore,
    recruiters: EdinburghRecruiter
  },
  {
    pathname: "southampton",
    banner: SouthamptonBanner,
    info: SouthamptonInfo,
    choose: SouthamptonRankings,
    fee: SouthamptonFeeStructure,
    scholarship: SouthamptonScholarships,
    know: SouthamptonKnowMore,
    recruiters: SouthamptonRecruiter
  },
  {
    pathname: "durham",
    banner: DurhamBanner,
    info: DurhamInfo,
    choose: DurhamRankings,
    fee: DurhamFeeStructure,
    scholarship: DurhamScholarships,
    know: DurhamKnowMore,
    recruiters: DurhamRecruiter
  },

  // Australia Universities
  {
    pathname: "unsw",
    banner: UNSWBanner,
    recruiters: UNSWRecruiter,
    know: UNSWKnowMore,
    info: UNSWInfo,
    fee: UNSWFeeStructure,
    scholarship: UNSWScholarships,
    choose: UNSWRankings
  },
  {
    pathname: "monash",
    banner: MonashBanner,
    recruiters: MonashRecruiter,
    know: MonashKnowMore,
    info: MonashInfo,
    fee: MonashFeeStructure,
    scholarship: MonashScholarships,
    choose: MonashRankings
  },
  {
    pathname: "uq",
    banner: QueenslandBanner,
    recruiters: QueenslandRecruiter,
    know: QueenslandKnowMore,
    info: QueenslandInfo,
    fee: UQFeeStructure,
    choose: QueenslandRankings,
    scholarship: QueenslandScholarships
  },
  {
    pathname: "uwa",
    banner: WesternaustraliaBanner,
    recruiters: WesternAustraliaRecruiter,
    know: WesternAustraliaKnowMore,
    info: UWAInfo,
    fee: WesternAustraliaFeeStructure,
    choose: WesternAustraliaRankings,
    scholarship: UWAScholarships
  },
  {
    pathname: "adelaide",
    banner: AdelaideBanner,
    recruiters: AdelaideRecruiter,
    know: AdelaideKnowMore,
    info: AdelaideInfo,
    fee: AdelaideFeeStructure,
    choose: AdelaideRankings,
    scholarship: AdelaideScholarships
  },
  {
    pathname: "melbourne",
    banner: MelbourneBanner,
    recruiters: MelbourneRecruiter,
    know: MelbourneKnowMore,
    info: MelbourneInfo,
    fee: MelbourneFeeStructure,
    choose: MelbourneRankings,
    scholarship: MelbourneScholarships
  },
  {
    pathname: "sydeny",
    banner: SydneyBanner,
    recruiters: SydneyRecruiter,
    know: SydneyKnowMore,
    info: SydneyInfo,
    fee: SydneyFeeStructure,
    choose: SydneyRankings,
    scholarship: SydneyScholarships
  },
  {
    pathname: "anu",
    banner: AnuBanner,
    recruiters: AnuRecruiter,
    know: ANUKnowMore,
    info: ANUInfo,
    fee: ANUFeeStructure,
    choose: ANURankings,
    scholarship: ANUScholarships
  },
  {
    pathname: "uts",
    banner: TechnologySydneyBanner,
    recruiters: UtsRecruiter,
    know: UTSKnowMore,
    info: UTSInfo,
    fee: UTSFeeStructure,
    choose: UTSRankings,
    scholarship: UTSScholarships
  },
  {
    pathname: "macquarie",
    banner: MacquarieBanner,
    recruiters: MacquarieRecruiter,
    know: MacquarieKnowMore,
    info: MacquarieInfo,
    fee: MacquarieFeeStructure,
    choose: MacquarieRankings,
    scholarship: MacquarieScholarships
  },
  //Canada University
  {
    pathname: "trent",
    banner: TrentBanner,
    recruiters: TrentRecruiter,
    know: TrentKnowMore,
    info: TrentInfo,
    fee: TrentFeeStructure,
    choose: trentRankings,
    scholarship: TrentScholarships
  },

  {
    pathname: "trinity",
    banner: TrinityBanner,
    recruiters: TrinityRecruiter,
    know: TrinityKnowMore,
    info: TrinityInfo,
    fee: TrinityFeeStructure,
    choose: TrinityRankings,
    scholarship: TrinityScholarships
  },
  {
    pathname: "royal",
    banner: RRUBanner,
    recruiters: RRURecruiter,
    know: RoyalRoadsKnowMore,
    info: RRUInfo,
    fee: RRUFeeStructure,
    choose: RRURankings,
    scholarship: RoyalScholarships
  },
  {
    pathname: "waterloo",
    banner: WaterlooBanner,
    recruiters: WaterlooRecruiter,
    know: WaterlooKnowMore,
    info: WaterlooInfo,
    fee: WaterlooFeeStructure,
    choose: WaterlooRankings,
    scholarship: WaterlooScholarships
  },
  {
    pathname: "calgary",
    banner: CalgeryBanner,
    recruiters: CalgeryRecruiter,
    know: CalgaryKnowMore,
    info: CalgaryInfo,
    fee: CalgeryFeeStructure,
    choose: CalgeryRankings,
    scholarship: CalgeryScholarships
  },
  {
    pathname: "dalhousie",
    banner: DalhousieBanner,
    recruiters: DalhousieRecruiter,
    know: DalhousieKnowMore,
    info: DalhousieInfo,
    fee: DalhousieFeeStructure,
    choose: DalhousieRankings,
    scholarship: DalhousieScholarships
  },
  {
    pathname: "brock",
    banner: BrockBanner,
    recruiters: BrockRecruiter,
    know: BrockKnowMore,
    info: BrockInfo,
    fee: BrockFeeStructure,
    choose: BrockRankings,
    scholarship: BrockScholarships
  },
  {
    pathname: "windsor",
    banner: WindsorBanner,
    recruiters: WindsorRecruiter,
    know: WindsorKnowMore,
    info: WindsorInfo,
    fee: WindsorFeeStructure,
    choose: WindsorRankings,
    scholarship: WindsorScholarships
  },
  {
    pathname: "vcc",
    banner: VCCBanner,
    recruiters: VCCRecruiter,
    know: VCCKnowMore,
    info: VCCInfo,
    fee: VCCFeeStructure,
    choose: VCCProfile,
    scholarship: VCCScholarships
  },
  {
    pathname: "toronto",
    banner: TorontoBanner,
    recruiters: TorontoRecruiter,
    know: TorontoKnowMore,
    info: TorontoInfo,
    fee: TorontoFeeStructure,
    choose: TorontoRankings,
    scholarship: TorontoScholarships
  },

  // Germany

  {
    pathname: "lmum",
    banner: LMUBanner,
    recruiters: LMURecruiter,
    know: LMUKnowMore,
    info: LMUInfo,
    fee: LMUFeeStructure,
    choose: LMURankings,
    scholarship: LMUScholarships
  },

  {
    pathname: "tum",
    banner: TMUBanner,
    recruiters: TUMRecruiter,
    know: TUMKnowMore,
    info: TUMInfo,
    fee: TUMFeeStructure,
    choose: TUMRankings,
    scholarship: TUMScholarships
  },

  {
    pathname: "heidelberguniversity",
    banner: HeidelbergUniversityBanner,
    recruiters: HeidelbergUniversityRecruiter,
    know: HeidelbergUniversityKnowMore,
    info: HeidelbergUniversityInfo,
    fee: HeidelbergUniversityFeeStructure,
    choose: HeidelbergUniversityRankings,
    scholarship: HeidelbergUniversityScholarships
  },

  {
    pathname: "humboldtuniversity",
    banner: HumboldtUniversityBanner,
    recruiters: HumboldtUniversityRecruiter,
    know: HumboldtUniversityKnowMore,
    info: HumboldtUniversityInfo,
    fee: HumboldtUniversityFeeStructure,
    choose: HumboldtUniversityRankings,
    scholarship: HumboldtUniversityScholarships
  },

  {
    pathname: "freieuniversitätberlin",
    banner: FUBBanner,
    recruiters: FUBRecruiter,
    know: FUBerlinKnowMore,
    info: FUBInfo,
    fee: FUBFeeStructure,
    choose: FUBRankings,
    scholarship: FUBScholarships
  },

  {
    pathname: "rwth",
    banner: RWTHBanner,
    recruiters: RWTHRecruiter,
    know: RWTHAachenKnowMore,
    info: RWTHInfo,
    fee: RWTHFeeStructure,
    choose: RWTHRankings,
    scholarship: RWTHScholarships
  },

  {
    pathname: "universityoffreiburg",
    banner: UOFBanner,
    recruiters: UOFRecruiter,
    know: UOFKnowMore,
    info: UOFInfo,
    fee: UOFFeeStructure,
    choose: UOFRankings,
    scholarship: UOFScholarships
  },
  {
    pathname: "universityoftübingen",
    banner: UOTBanner,
    recruiters: UOTRecruiter,
    know: UOTKnowMore,
    info: UOTInfo,
    fee: UOTFeeStructure,
    choose: UOTRankings,
    scholarship: UOTScholarships
  },
  {
    pathname: "universityofmannheim",
    banner: UOMBanner,
    recruiters: UOMRecruiter,
    know: UOMKnowMore,
    info: UOMInfo,
    fee: UOMFeeStructure,
    choose: UOMRankings,
    scholarship: UOMScholarships
  },
  {
    pathname: "universityofgöttingen",
    banner: UOGBanner,
    recruiters: UOGRecruiter,
    know: UOGKnowMore,
    info: UOGInfo,
    fee: UOGFeeStructure,
    choose: UOGRankings,
    scholarship: UOGScholarships
  },

  //France

  {
    pathname: "upsl",
    banner: UPSLBanner,
    recruiters: UPSLRecruiter,
    know: UPSLKnowMore,
    info: UPSLInfo,
    fee: UPSLFeeStructure,
    choose: UPSLRankings,
    scholarship: UPSLScholarships
  },
  {
    pathname: "paris-saclay",
    banner: UPSBanner,
    recruiters: UPSRecruiter,
    know: UPSKnowMore,
    info: UPSInfo,
    fee: UPSFeeStructure,
    choose: UPSRankings,
    scholarship: UPSScholarships
  },

  {
    pathname: "sorbonne",
    banner: SUBanner,
    recruiters: SURecruiter,
    know: SUKnowMore,
    info: SUInfo,
    fee: SUFeeStructure,
    choose: SURankings,
    scholarship: SUScholarships
  },

  {
    pathname: "uga",
    banner: UGABanner,
    recruiters: UGARecruiter,
    know: UGAKnowMore,
    info: UGAInfo,
    fee: UGAFeeStructure,
    choose: UGARankings,
    scholarship: UGAScholarship
  },
  {
    pathname: "strasboury",
    banner: UDSBanner,
    recruiters: UDSRecruiter,
    know: UDSKnowMore,
    info: UDSInfo,
    fee: UDSFeeStructure,
    choose: UDSRankings,
    scholarship: UDSScholarships
  },
  
  {
    pathname: "udm",
    banner: UDMBanner,
    recruiters: UDMRecruiter,
    know: UDMKnowMore,
    info: UDMInfo,
    fee: UDMFeeStructure,
    choose: UDMRankings,
    scholarship: UDMScholarships
  },
  {
    pathname: "udl",
    banner: UDLBanner,
    recruiters: UDLRecruiter,
    know: UDLKnowMore,
    info: UDLInfo,
    fee: UDLFeeStructure,
    choose: UDLRankings,
    scholarship: UDLScholarships
  },
  {
    pathname: "udn",
    banner: UDNBanner,
    recruiters: UDNRecruiter,
    know: UDNKnowMore,
    info: UDNInfo,
    fee: UDNFeeStructure,
    choose: UDNRankings,
    scholarship: UDNScholarships
  },
  {
    pathname: "udb",
    banner: UDBBanner,
    recruiters: UDBRecruiter,
    know: UDBKnowMore,
    info: UDBInfo,
    fee: UDBFeeStructure,
    choose: UDBRankings,
    scholarship: UDBScholarships
  },
  {
    pathname: "upps",
    banner: UPPSBanner,
    recruiters: UPPSRecruiter,
    know: UPPSKnowMore,
    info: UPPSInfo,
    fee: UPPSFeeStructure,
    choose: UPPSRankings,
    scholarship: UPPSScholarships
  },

  //Italy

  {
    pathname: "pdmilano",
    banner: PDMilanoBanner,
    recruiters: PDMilanoRecruiter,
    know: PDMilanoKnowMore,
    info: PDMilanoiInfo,
    fee: PDMilanoFeeStructure,
    choose: PDMilanoChooseUs,
    scholarship: PDMilanoScholarships
  },
  {
    pathname: "uomilano",
    banner: UOMilanBanner,
    recruiters: UOMilanRecruiter,
    know: UOMilanKnowMore,
    info: UOMilanInfo,
    fee: UOMilanFeeStructure,
    choose: UOMilanChooseUs,
    scholarship: UOMilanScholarships
  },
  {
    pathname: "uobologna",
    banner: UOBolognaBanner,
    recruiters: UOBolognaRecruiter,
    know: UOBolognaKnowMore,
    info: UniboInfo,
    fee: UOBolognaFeeStructure,
    choose: UOBolognaChooseUs,
    scholarship: UOBolognaScholarships
  },
  {
    pathname: "uoflorence",
    banner: UOFlorenceBanner,
    recruiters: UOFlorenceRecruiter,
    know: UOFlorenceKnowMore,
    info: UOFlorenceInfo,
    fee: UOFlorenceFeeStructure,
    choose: UOFlorenceChooseUs,
    scholarship: UOFlorenceScholarships
  },
  {
    pathname: "uopadua",
    banner: UOPaduaBanner,
    recruiters: UOPaduaRecruiter,
    know: UOPaduaKnowMore,
    info: UOPaduaInfo,
    fee: UOPaduaFeeStructure,
    choose: UOPaduaChooseUs,
    scholarship: UOPaduaScholarships 

  },
  {
    pathname: "uoturin",
    banner: UOTurinBanner,
    recruiters: UOTurinRecruiter,
    know: UOTurinKnowMore,
    info: UOTurinInfo,
    fee: UOTurinFeeStructure,
    choose: UOTurinChooseUs,
    scholarship: UOTurinScholarships
  },
  {
    pathname: "rome-la-sapienza",
    banner: RLSBanner,
    recruiters: RLSRecruiter,
    know: RLSKnowMore,
    info: RLSInfo,
    fee: RLSFeeStructure,
    choose: RLSChooseUs,
    scholarship: RLSScholarships
  },
  {
    pathname: "uonaples",
    banner: UONaplesBanner,
    recruiters: UONaplesRecruiter,
    know: UONaplesKnowMore,
    info: UONaplesInfo,
    fee: UONaplesFeeStructure,
    choose: UONaplesChooseUs,
    scholarship: UONaplesScholarships
  },
  {
    pathname: "uopisa",
    banner: UOPisaBanner,
    recruiters: UOPisaRecruiter,
    know: UOPisaKnowMore,
    info: UOPisaInfo,
    fee: UOPisaFeeStructure,
    choose: UOPisaChooseUs,
    scholarship: UOPisaScholarships
  },
  {
    pathname: "ied",
    banner: IEDBanner,
    recruiters: IEDRecruiter,
    know: IEDKnowMore,
    info: IEDInfo,
    fee: IEDFeeStructure,
    choose: IEDChooseUs,
    scholarship: IEDScholarships
  },
  
  //Ireland

  {
    pathname: "tdublin",
    banner: TCDublinBanner,
    recruiters: TCDublinRecruiter,
    know: TCDublinKnowMore,
    info: TCDublinInfo,
    fee: TCDublinFeeStructure,
    choose: TCDublinChooseUs,
    scholarship: TCDublinScholarships
  },
  {
    pathname: "ucdublin",
    banner: UCDublinBanner,
    recruiters: UCDublinRecruiter,
    know: UCDublinKnowMore,
    info: UCDublinInfo,
    fee: UCDublinFeeStructure,
    choose: UCDublinChooseUs,
    scholarship: UCDublinScholarships
  },
  {
    pathname: "uocork",
    banner: UOCorkBanner,
    recruiters: UOCorkRecruiter,
    know: UOCorkKnowMore,
    info: UOCorkInfo,
    fee: UCCorkFeeStructure,
    choose: UOCorkChooseUs,
    scholarship: UOCorkScholarships
  },
  {
    pathname: "uogalway",
    banner: UOGalwayBanner,
    recruiters: UOGalwayRecruiter,
    know: UOGalwayKnowMore,
    info: UOGalwayInfo,
    fee: UOGalwayFeeStructure,
    choose: UOGalwayChooseUs,
    scholarship: UOGalwayScholarships
  },
  {
    pathname: "dcu",
    banner: DCUBanner,
    recruiters: DCURecruiter,
    know: DCUKnowMore,
    info: DCUInfo,
    fee: DCUFeeStructure,
    choose: DCUChooseUs,
    scholarship: DCUScholarships
  },
  {
    pathname: "uolimerick",
    banner: UOLimerickBanner,
    recruiters: UOLimerickRecruiter,
    know: UOLimerickKnowMore,
    info: UOLimerickInfo,
    fee: UOLimerickFeeStructure,
    choose: UOLimerickChooseUs,
    scholarship: UOLimerickScholarships
  },
  {
    pathname: "mu",
    banner: MUBanner,
    recruiters: MURecruiter,
    know: MUKnowMore,
    info: MUInfo,
    fee: MUFeeStructure,
    choose: MUChooseUs,
    scholarship: MUScholarships
  },
  {
    pathname: "tudublin",
    banner: TUDublinBanner,
    recruiters: TUDublinRecruiter,
    know: TUDublinKnowMore,
    info: TUDublinInfo,
    fee: TUDublinFeeStructure,
    choose: TUDublinChooseUs,
    scholarship: TUDublinScholarships
  },
  {
    pathname: "dbs",
    banner: DBSBanner,
    recruiters: DBSRecruiter,
    know: DBSKnowMore,
    info: DBSInfo,
    fee: DBSFeeStructure,
    choose: DBSChooseUs,
    scholarship: DBSScholarships
  },
  {
    pathname: "rcsi",
    banner: RCSIBanner,
    recruiters: RCSIRecruiter,
    know: RCSIKnowMore,
    info: RCSInfo,
    fee: RCSFeeStructure,
    choose: RCSChooseUs,
    scholarship: RCSScholarships
  },

  //New-Zealand

  {
    pathname: "uoa",
    banner: UOABanner,
    recruiters: AucklandRecruiter,
    know: AucklandKnowMore,
    info: UoAInfo,
    fee: UOAFeeStructure,
    choose: UniversityAucklandRankings,
    scholarship: AucklandScholarships
  },
  {
    pathname: "muniversity",
    banner: MUniversityBanner,
    recruiters: MasseyRecruiter,
    know: MasseyKnowMore,
    info: MasseyInfo,
    fee: MUniversityFeeStructure,
    choose: universityMasseyRankings,
    scholarship: MasseyScholarships
  },
  {
    pathname: "uwaikato",
    banner: UOWBanner,
    recruiters: WaikatoRecruiter,
    know: WaikatoKnowMore,
    info: WaikatoInfo,
    fee: UOWFeeStructure,
    choose: WaikatouniversityRankings,
    scholarship: WaikatoScholarships
  },
  {
    pathname: "vuow",
    banner: VUOWBanner,
    recruiters: VictoriaRecruiter,
    know: VictoriaKnowMore,
    info: VUWInfo,
    fee: VUOWFeeStructure,
    choose: VictoriauniversityRankings,
    scholarship: VictoriaScholarships
  },
  {
    pathname: "uoc",
    banner: UCanterburyBanner,
    recruiters: CanterburyRecruiter,
    know: CanterburyKnowMore,
    info: CanterburyInfo,
    fee: UCanterburyFeeStructure,
    choose: CanterburyuniversityRankings,
    scholarship: CanterburyScholarships
  },
  {
    pathname: "udm",
    banner: UOtagoBanner,
    recruiters: OtagoRecruiter,
    know: OtagoKnowMore,
    info: OtagoInfo,
    fee: UOtagoFeeStructure,
    choose: OtagouniversityRankings,
    scholarship: OtagoScholarships
  },
  {
    pathname: "auot",
    banner: AUOTBanner,
    recruiters: AUTRecruiter,
    know: AUTKnowMore,
    info: AUTInfo,
    fee: AUOTFeeStructure,
    choose: AucklanduniversityRankings,
    scholarship: AUTScholarships
  },
  {
    pathname: "luniversity",
    banner: LUniversityBanner,
    recruiters: LincolnRecruiter,
    know: LincolnKnowMore,
    info: LincolnInfo,
    fee: LUniversityFeeStructure,
    choose: LincolnuniversityRankings,
    scholarship: LincolnScholarships
  },
  {
    pathname: "eiot",
    banner: EIOTBanner,
    recruiters: EITRecruiter,
    know: EITKnowMore,
    info: EITInfo,
    fee: EIOTFeeStructure,
    choose: EasternpolytechnicRankings,
    scholarship: EITScholarships
  },
  {
    pathname: "ucic",
    banner: UCICBanner,
    recruiters: UCRecruiters,
    know: UCICKnowMore,
    info: UCICInfo,
    fee: UCICFeeStructure,
    choose: UCICRankings,
    scholarship: UCScholarships
  },


];
const UniversityPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { pageName } = useParams();
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    const currentPageData = jsonData.find(
      (data) => data?.pathname === pageName
    );
    setPageData(currentPageData);
    console.log(currentPageData);
  }, [pageName]);
  return (
    <>
      <Navbar />
      {pageData?.banner && <Universitybanner data={pageData.banner} />}
      <BackRouteContainer
        logo={logo}
        path={-1}
        title={"Select University Page"}
      />
      {pageData?.info && <UniversityInfo data={pageData.info} />}
      {pageData?.choose && <WhyChooseUniversity data={pageData.choose} />}
      {pageData?.fee && <UniversityFeeStructure data={pageData.fee} />}
      {pageData?.scholarship && (
        <UniversityScholarship data={pageData.scholarship} />
      )}
      {pageData?.recruiters && (
        <UniversityRecruiters data={pageData.recruiters} />
      )}
      {pageData?.know && <UniversityKnowMore data={pageData.know} />}
      <BackRouteContainer
        logo={logo}
        path={-1}
        title={"Select University Page"}
      />
      <LetsGetStarted />
      <Footer />
    </>
  );
};

export default UniversityPage;
