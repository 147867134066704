/* eslint-disable react-hooks/exhaustive-deps */
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Container,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import Footer from "src/Component/Footer";
import Navbar from "src/Component/Navbar";
import { createTheme, useMediaQuery } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { useNavigate } from "react-router";
import axios from "axios";
import cart from "../asset/emptycart.svg";
import logo from "../asset/TIE_LOGO.png";
import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";

const AddCart = ({ setItemUpdate, itemupdate }) => {
  console.log("itemcount :>> ", itemupdate);
  const [cartList, setCartList] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [loading, setLoading] = React.useState(false);
  const [fetchLoading, setFetchLoading] = useState(true); // Added state for fetching data

  const navigate = useNavigate();
  useEffect(() => {
    const fetchCartData = async () => {
      const token = sessionStorage.getItem("token");
      if (token) {
        setFetchLoading(true); // Show loader
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}order/get-cart`,
            {
              headers: {
                Authorization: token
              }
            }
          );
          const data = response.data;
          if (data.success) {
            const apiCartList = data.data.flatMap((order) =>
              order.items.map((item) => ({
                id: item._id,
                title: item.planName,
                packagePrice: item.amount,
                planId: item.planId
              }))
            );
            setCartList(apiCartList);
            setItemUpdate(!itemupdate);
          }
        } catch (error) {
          console.error("Error fetching cart data:", error);
        } finally {
          setFetchLoading(false); // Hide loader
        }
      } else {
        const localCart = JSON.parse(localStorage.getItem("cart")) || [];
        setCartList(localCart);
        setItemUpdate(!itemupdate);
        setFetchLoading(false); // Hide loader if no token
      }
    };

    fetchCartData();
  }, []);

  useEffect(() => {
    localStorage.setItem("itemsCount", cartList.length);
  }, [cartList]);

  console.log("cartList", cartList, cartList?.length);

  const handleDelete = async (index) => {
    setDeleteIndex(index);
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    const token = sessionStorage.getItem("token");
    const item = cartList[deleteIndex];

    if (token) {
      try {
        const response = await axios.delete(
          `${process.env.REACT_APP_BASE_URL}order/delete-cart-item/${item.id}`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        const data = response.data;
        if (data.success) {
          const updatedCartList = cartList.filter((_, i) => i !== deleteIndex);
          setCartList(updatedCartList);
          setItemUpdate(!itemupdate);
        } else {
          console.error("Error deleting cart item:", data.message);
        }
      } catch (error) {
        console.error("Error deleting cart item:", error);
      }
    } else {
      const updatedCartList = cartList.filter((_, i) => i !== deleteIndex);
      setCartList(updatedCartList);
      localStorage.setItem("cart", JSON.stringify(updatedCartList));
      setItemUpdate(!itemupdate);
    }

    setOpenDialog(false);
  };

  const handleCheckout = async () => {
    setLoading(true);

    const token = sessionStorage.getItem("token");

    if (!token) {
      navigate("/login", {
        state: {
          path: "/addCart"
        }
      });
      return;
    }

    try {
      const orderData = {
        items: cartList.map((item) => ({
          planName: item.title,
          amount: item.packagePrice,
          planId: item.planId
        }))
      };

      const orderResponse = await axios.post(
        `${process.env.REACT_APP_BASE_URL}order/add-order`,
        orderData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: token
          }
        }
      );

      const orderDataResponse = orderResponse.data;
      console.log("orderDataResponse :>> ", orderDataResponse);
      setLoading(false);
      if (orderDataResponse.success) {
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY,
          amount: totalPrice * 100,
          currency: "INR",
          name: "TIE",
          image: logo,
          description: "Test Transaction",
          order_id: orderDataResponse.data.razorpayOrderId,
          async handler(response) {
            console.log(
              "response",
              orderDataResponse.razorpayOrderId,
              response
            );
            try {
              const paymentVerificationData = {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature
              };
              console.log("objective", paymentVerificationData);

              const paymentVerificationResponse = await axios.post(
                `${process.env.REACT_APP_BASE_URL}payment/verify-payment`,
                paymentVerificationData,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token
                  }
                }
              );

              const paymentVerificationResult =
                paymentVerificationResponse.data;

              if (paymentVerificationResult.success) {
                console.log("Payment verified successfully");
                navigate("/order-success");
              } else {
                console.error(
                  "Payment verification failed:",
                  paymentVerificationResult.message
                );
              }
            } catch (error) {
              console.error("Error verifying payment:", error);
            }
          },
          notes: {
            address: "Razorpay Corporate Office"
          },
          theme: {
            color: "#00999E"
          }
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error adding order:", orderDataResponse.message);
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  const largeScreen = createTheme({
    breakpoints: {
      values: {
        lg: 1024,
        xxl: 1920
      }
    }
  });
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const mediumLarge = useMediaQuery(largeScreen.breakpoints.up("lg"));
  const totalPrice = cartList.reduce(
    (total, pkg) => total + parseInt(pkg.packagePrice),
    0
  );

  return (
    <div>
      <Navbar setItemUpdate={setItemUpdate} itemupdate={itemupdate} />
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            alignItems: "center",
            pr: "20px"
          }}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <Box onClick={() => setOpenDialog(false)}>
            <Icon
              icon="icon-park-outline:close-small"
              style={{ height: "30px", width: "30px" }}
            />
          </Box>
        </Box>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item from the cart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpenDialog(false)}
            color="primary"
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {fetchLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh"
          }}
        >
          <CircularProgress color="success" />
        </Box>
      ) : cartList.length === 0 ? (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "80px 0",
            margin: "auto",
            flexDirection: "column"
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: { md: "800px", sm: "500px", xs: "300px" },
              padding: "60px 0",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              border: "4px solid #c1c1c1",
              borderRadius: "10px"
            }}
          >
            <Box sx={{ width: { md: "250px", xs: "100px" } }}>
              <img src={cart} width="100%" alt="empty cart" />
            </Box>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "550",
                fontSize: { xs: "24px", md: "40px" },
                color: "#949494"
              }}
            >
              Your Cart is empty
            </Typography>
          </Box>

          <Button
            variant="contained"
            fullWidth
            sx={{
              mt: "40px",
              bgcolor: "#00999E",
              "&:hover": {
                bgcolor: "#00999E"
              },
              maxWidth: "300px"
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            Back To Home
          </Button>
        </Container>
      ) : (
        <Container>
          <Grid
            container
            spacing={isSmScreen ? 4 : isMediumScreen ? 4 : mediumLarge ? 4 : 8}
            display="flex"
            justifyContent="space-between"
            style={{
              height: "100%",
              padding: "50px 0"
            }}
          >
            <Grid item xs={12} sm={6} md={7} lg={7}>
              <Card style={{ maxWidth: 600, margin: "auto", padding: "20px" }}>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    Cart ({cartList.length} item{" "}
                    {cartList.length > 1 ? "s" : ""})
                  </Typography>
                  <Grid container spacing={2} alignItems="center">
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 100, elevation: 0 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>Course</TableCell>
                            <TableCell align="right">Price</TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {cartList.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell component="th" scope="row">
                                <Typography>{row.title}</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography>₹ {row.packagePrice}</Typography>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  color="error"
                                  onClick={() => handleDelete(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} sm={6} md={5} lg={5}>
              <Card style={{ margin: "auto", padding: "20px" }}>
                <CardContent>
                  <Typography variant="h6" component="div" gutterBottom>
                    Order Summary
                  </Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={"space-between"}
                  >
                    <Typography>Total Item:</Typography>
                    <Typography>{cartList.length}</Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent={"space-between"}
                    sx={{ pt: "20px" }}
                  >
                    <Typography>Total Amount:</Typography>
                    <Typography>₹ {totalPrice}</Typography>
                  </Box>
                </CardContent>
              </Card>

              <Box sx={{ pt: "20px", width: "100%" }}>
                <LoadingButton
                  onClick={handleCheckout}
                  loading={loading}
                  sx={{
                    color: "#ffffff",
                    backgroundColor: "#00999E",
                    fontSize: "20px",
                    padding: "10px 30px",
                    fontWeight: "550",
                    textTransform: "capitalize",
                    textAlign: "center",
                    width: "100%",
                    height: "50px",
                    borderRadius: "8px",
                    "&:hover": {
                      backgroundColor: "#00999E",
                      color: "#ffffff"
                    },
                    "& .MuiLoadingButton-loadingIndicator": {
                      color: "#ffffff"
                    }
                  }}
                >
                  {!loading && "Check Out"}
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      <Footer />
    </div>
  );
};

export default AddCart;
