import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  TextField,
  Button,
  MenuItem,
  Box,
  Container,
  Typography,
  CircularProgress,
  Snackbar,
  Alert
} from "@mui/material";
import axios from "axios";

// Validation schema using Yup
const schema = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  scheduleDate: yup.date().required("Schedule Date is required"),
  facultyId: yup.string().required("Faculty is required"),
  queries: yup.string().optional()
});

const ScheduleForm = () => {
  const [facultyList, setFacultyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });
  const token = sessionStorage.getItem("token");


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });

  useEffect(() => {
    // Fetch faculty data from the API
    const fetchFacultyList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}staff/faculty-list`,
          {
            headers: {
              Authorization: token
            }
          }
        );
        setFacultyList(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching faculty list:", error);
        setLoading(false);
      }
    };

    fetchFacultyList();
  }, [token]);

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}student/schedule`,
        {
          fullName: data.fullName,
          scheduleDate: data.scheduleDate,
          facultyId: data.facultyId,
          queries: data.queries
        },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          }
        }
      );
      console.log("Schedule created:", response.data);
      setSnackbar({
        open: true,
        message: "Schedule created successfully!",
        severity: "success"
      });
      reset();
    } catch (error) {
      console.error("Error creating schedule:", error);
      setSnackbar({
        open: true,
        message: "Failed to create schedule.",
        severity: "error"
      });
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Container>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "100%",
            margin: "10px",
            padding: "20px"
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Full Name */}
          <Typography sx={{ m: "10px 0" }}>{"Full Name"}</Typography>
          <TextField
            label="Full Name"
            placeholder="Name"
            variant="outlined"
            fullWidth
            {...register("fullName")}
            error={!!errors.fullName}
            helperText={errors.fullName ? errors.fullName.message : ""}
            InputProps={{
              style: {
                backgroundColor: "#DEF2F3"
              }
            }}
          />

          {/* Schedule Date */}
          <Typography sx={{ m: "10px 0" }}>{"Schedule Date"}</Typography>
          <TextField
            label="Schedule Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            fullWidth
            {...register("scheduleDate")}
            error={!!errors.scheduleDate}
            helperText={errors.scheduleDate ? errors.scheduleDate.message : ""}
            InputProps={{
              style: {
                backgroundColor: "#DEF2F3"
              }
            }}
          />

          {/* Faculty */}
          <Typography sx={{ m: "10px 0" }}>{"Faculty"}</Typography>
          {loading ? (
            <CircularProgress />
          ) : (
            <TextField
              label="Faculty"
              select
              variant="outlined"
              fullWidth
              {...register("facultyId")}
              error={!!errors.facultyId}
              helperText={errors.facultyId ? errors.facultyId.message : ""}
              InputProps={{
                style: {
                  backgroundColor: "#DEF2F3"
                }
              }}
            >
              {facultyList.map((faculty) => (
                <MenuItem key={faculty._id} value={faculty._id}>
                  {faculty.name}
                </MenuItem>
              ))}
            </TextField>
          )}

          {/* Write Queries */}
          <Typography sx={{ m: "10px 0" }}>{"Write Queries"}</Typography>
          <TextField
            label="Write Queries"
            placeholder="Provide supporting details or context"
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            {...register("queries")}
            InputProps={{
              style: {
                backgroundColor: "#DEF2F3"
              }
            }}
          />

          {/* Submit Button */}
          <Box
            sx={{ justifyContent: "center", display: "flex", margin: "10px 0" }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "250px",
                justifyContent: "center",
                display: "flex",
                backgroundColor: "#00999E",
                color: "white",
                padding: "10px 0",
                "&:hover": {
                  backgroundColor: "#00999f"
                }
              }}
            >
              SUBMIT
            </Button>
          </Box>
        </Box>
      </Container>

      {/* Snackbar Notification */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }} // Position at top right
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ScheduleForm;
