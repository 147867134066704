import React, { useEffect } from "react";
import Footer from "src/Component/Footer";
import Navbar from "src/Component/Navbar";
import { Box } from "@mui/material";
import StudyAbroadInfo from "./StudyAbroadMainComponents/StudyAbroadInfo";
import Milestones from "src/Component/mildstones/Milestones";
// import WorldClassEducation from './StudyAbroadMainComponents/worldclasseducation'
import StudyAbroadGlobalCombination from "./StudyAbroadMainComponents/StudyAbroadGlobalCombination";
import StudyAbroadCountry from "./StudyAbroadMainComponents/StudyAbroadCountry";
import Testimonial from "src/Component/Testimonial";
import LetsGetStarted from "src/Component/LetsGetStarted";
import Roadmap from "src/Component/services/Roadmap";
import { Helmet } from "react-helmet";
import Abroad from "src/Component/services/Abroad";

const StudyAbroadMainPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>Taksheela Institute: Your Guide to Studying Abroad</title>
        <meta
          name="description"
          content="Explore the best study abroad programs with Taksheela Institute. Benefit from expert counseling, comprehensive information, and dedicated admission support."
        />
        <meta
          name="keywords"
          content="study abroad opportunities in russia, student abroad programs, international study abroad, universities overseas"
        />
      </Helmet>
      <Navbar />
      <Box sx={{ paddingBottom: "40px" }}>
        <StudyAbroadCountry />
        {/* <StudyAbroadBanner /> */}
        <StudyAbroadInfo />

        <Milestones />
        {/* <WorldClassEducation /> */}
        <Abroad/>
        <Roadmap />
        <StudyAbroadGlobalCombination />
        <Box sx={{ margin: "50px 0" }}>
          <Testimonial />
        </Box>
        <LetsGetStarted />
      </Box>

      <Footer />
    </>
  );
};

export default StudyAbroadMainPage;
