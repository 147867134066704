import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import Header from "src/Component/content-component/Header";
import CustomBarChart from "./Components/progressChart";
import ProfileInfo from "./Components/TestPrepHome/ProfileInfo";
import Schedule from "./Components/TestPrepHome/Schedule";
import FacultyConnectBanner from "./Components/TestPrepHome/FacultyConnectBanner";

const TestPrepHome = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const token = sessionStorage.getItem("token");
  const planId = sessionStorage.getItem("courseId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://tied-api-hmjn.onrender.com/api/v1/test/web-dashboard",
          {
            headers: {
              Authorization: token
            },
            params: {
              planId: planId // Passing planId in the query parameters
            }
          }
        );
        if (response.data.success) {
          setDashboardData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [planId, token]);

  if (!dashboardData) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ margin: "0px 20px", display: "flex", flexDirection: "column" }}>
      {/* Pass Student, classes, and latestMockTest data to ProfileInfo */}
      <ProfileInfo
        student={dashboardData.Student}
        classes={dashboardData.classes}
        latestMockTest={dashboardData.latestMockTest}
      />

      {/* Pass UpcomingClasses and upcomingMockTestData to Schedule */}
      <Schedule
        upcomingClasses={dashboardData.UpcomingClasses}
        upcomingMockTestData={dashboardData.upcomingMockTestData}
      />

      <FacultyConnectBanner />

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center"
        }}
      >
        <Header content={"Milestones"} />
        <Box
          sx={{ margin: "20px 0", padding: "20px 30px", bgcolor: "#DDFEFF" }}
        >
          <Header content={"My Progress"} fontSize={"24px"} />

          {/* Pass myProgress data to CustomBarChart */}
          <Box sx={{ padding: "20px 0px 20px 20px" }}>
            <CustomBarChart progressData={dashboardData.myProgress} />
          </Box>

          <Typography
            variant="h6"
            component="div"
            gutterBottom
            sx={{ color: "#00999E" }}
          >
            No. of classes / Overall mock test
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TestPrepHome;
