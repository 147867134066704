import img from '../asset/event1.png';
import img2 from '../asset/event2.jpg';
import img3 from '../asset/homeedge.jpg';
import img4 from '../asset/blog4.jpg';
import img5 from '../asset/blog5.jpg';
import img6 from '../asset/img6.jpg';

const newsData = [

  {
    "image": img,
    "date": "Apr 13, 2024",
    "title": "Taksheela and Pearson Join Forces to Transform Education",
    "content": "In a groundbreaking collaboration, Taksheela and Pearson have merged their expertise to revolutionize education. By combining resources and knowledge, they aim to empower learners worldwide, fostering innovation and excellence in education. Together, they envision a future where every student can achieve their fullest potential."
  },
  {
    "image": img2,
    "date": "Apr 20, 2024",
    "title": "Taksheela Appointed as TOEFL iBT Market Ambassador",
    "content": "Taksheela, a leading platform for English language learning, has appointed a TOEFL iBT Market Ambassador to enhance its support for students. Excited about this collaboration, Taksheela aims to further empower students on their English language journey. The partnership promises to bring valuable resources and guidance to TOEFL iBT aspirants."
  },
  {
    "image": img3,
    "date": "Apr 28, 2024",
    "title": "Bengal Business Award 2024",
    "url": "https://www.hindustantimes.com/brand-stories/top-mbbs-admission-consultants-for-indian-students-in-delhi-india-study-abroad-101716288472997.html"

  },
  {
    "image": img4,
    "date": "May 8, 2024",
    "title": "Taksheela Shines: Best Study Abroad Consultant in Delhi, India for 2024",
    "url": "https://www.outlookindia.com/hub4business/top-study-abroad-consultant-near-me-in-delhi-india-2024-best-overseas-consultant"

  },
  {
    "image": img5,
    "date": "May 27, 2024",
    "title": "Hindustan Times Highlights Taksheela as Top Immigration Consultant in Delhi, India",
    "url": "https://www.hindustantimes.com/brand-stories/top-immigration-consultant-in-delhi-india-nationwide-immigration-services-101716808260812.html"
  },

  {
    "image": img6,
    "date": "Nov 19, 2024",
    "title": "Hindustan Times Highlights Taksheela as Top Immigration Consultant in Delhi, India",

    "description": [
      <p >Picture this: a classroom where students don’t just study equations but use them to build robots. A place where kids aren’t memorizing history—they’re making it by filing patents for their own inventions. That’s the kind of magic happening under the School Innovation Council (SIC).</p>,

      <p style={{ paddingTop: 6 }}>In a world buzzing with possibilities, SIC has emerged as the ultimate platform for students to explore their creativity, develop problem-solving skills, and prepare for the future. It’s where bright ideas meet hands-on action, turning school halls into hubs of innovation and entrepreneurship.</p>,

      <p style={{ paddingTop: 6 }}>Let’s dive into this transformative program, its exciting initiatives, and the incredible ways it’s impacting students and teachers alike.</p>,

      <h3 style={{ paddingTop: 6 }}>What’s the Buzz About SIC ?</h3>,

      <p style={{ paddingTop: 6 }}>The School Innovation Council (SIC), launched by the Ministry of Education's Innovation Cell (MIC), is India’s answer to building an ecosystem where ideas thrive and dreams take flight.</p>,

      <p style={{ paddingTop: 6 }}>It’s more than a school club; SIC is a structured framework that blends creativity, critical thinking, and entrepreneurship into everyday education. Through innovative workshops, bootcamps, and competitions, students get to experiment, fail, learn, and ultimately create solutions that could change the world.</p>,

      <p style={{ paddingTop: 6 }}>Aligned with the vision of the National Education Policy (NEP) 2020, SIC doesn’t just teach students; it empowers them to lead.</p>,


      <h3 style={{ paddingTop: 6 }}>The SIC Toolkit: Programs That Spark Innovation</h3>,

      <p style={{ paddingTop: 6 }}>SIC isn’t just about talk; it’s about action-packed programs that turn students into innovators and teachers into mentors. Here’s what’s making waves:</p>,

      <h3 style={{ paddingTop: 6 }}>1. School Innovation Ambassador Training Program (SIATP)</h3>,

      <p style={{ paddingTop: 6 }}>Teachers are the superheroes of this story, and the School Innovation Ambassador Training Program (SIATP) gives them the powers they need to inspire young minds.</p>,

      <ul>
        <li style={{ paddingTop: 6 }}>SIATP trains teachers in design thinking, entrepreneurship, intellectual property rights (IPR), and project management.</li>
        <li style={{ paddingTop: 6 }}>'Over 10,000 teachers have already donned the mantle of "Innovation Ambassadors," ready to guide students toward greatness.',</li>
      </ul>,



      <h3 style={{ paddingTop: 6 }}>2. Design Thinking: From Ideas to Impact</h3>,

      <p style={{ paddingTop: 6 }}>Ever wondered how great inventions start? With design thinking. This SIC program teaches students to empathize, ideate, and prototype their way to groundbreaking solutions.</p>,

      <ul>
        <li>Workshops have reached 5,000+ schools, touching the lives of more than 1 million students.</li>
      </ul>,


      <h3 style={{ paddingTop: 6 }}>3. KAPILA: The Patent Pathway</h3>,

      <p style={{ paddingTop: 6 }}>The Kalam Program for Intellectual Property Literacy and Awareness (KAPILA) is like a crash course on owning your genius.</p>,

      <ul>
        <li style={{ paddingTop: 6 }}>Students learn how to protect their inventions through patents, copyrights, and trademarks.</li>
        <li style={{ paddingTop: 6 }}>Fun fact: Under this program, students have already filed 25,000+ patents—a number that’s as impressive as their creativity!
        </li>
      </ul>,

      <h3 style={{ paddingTop: 6 }}>4. Bootcamps: Innovation in Action</h3>,

      <p style={{ paddingTop: 6 }}>SIC bootcamps are hands-on, high-energy, and a whole lot of fun.</p>,

      <ul>
        <li style={{ paddingTop: 6 }}>From building prototypes to pitching ideas Shark Tank-style, these camps teach students real-world skills.Think of it as a startup accelerator, but for kids!</li>
        <li style={{ paddingTop: 6 }}>Think of it as a startup accelerator, but for kids!
        </li>
      </ul>,


      <h3 style={{ paddingTop: 6 }}>5. ATL Tinkering Labs & Hackathons</h3>,

      <p style={{ paddingTop: 6 }}>Atal Tinkering Labs (ATLs) are where ideas come to life. These creative spaces are stocked with 3D printers, coding kits, and robotics tools, giving students everything they need to tinker and create.</p>,

      <ul>
        <li style={{ paddingTop: 6 }}>Meanwhile, SIC hackathons challenge students to solve global problems, whether it’s building a smart energy solution or designing a device for rural healthcare.</li>
      </ul>,




      <h4 style={{ paddingTop: 6 }}>The SIC Effect: Numbers That Inspire</h4>,

      <p style={{ paddingTop: 6 }}>SIC isn’t just creating a buzz—it’s delivering results:</p>,

      <ul>
        <li style={{fontWeight:'600',paddingTop: 6}}>Participation on a Massive Scale</li>
        <ul>
          <li style={{fontWeight:'600',paddingTop: 6}}>Over 50,000 schools are part of SIC, impacting 25 million students across India.
          </li>
        </ul>
        <li style={{fontWeight:'600',paddingTop: 6}}>Patent Power</li>
        <ul>
          <li style={{paddingTop: 6}}>Thanks to KAPILA, school students filed 2,000+ patents in 2023 alone. Imagine the ideas that will shape our future!
          </li>
        </ul>
        <li style={{fontWeight:'600',paddingTop: 6}}>Empowered Educators</li>
        <ul>
          <li style={{paddingTop: 6}}>Surveys show that 80% of teachers who underwent SIATP training feel confident leading innovation programs in their schools.
          </li>
        </ul>
        <li style={{fontWeight:'600',paddingTop: 6}}>Bridging Gaps</li>
        <ul>
          <li style={{paddingTop: 6}}>Rural schools are thriving under SIC, with students from small towns winning national innovation challenges.
          </li>
        </ul>
        <li style={{fontWeight:'600',paddingTop: 6}}>Girls in the Spotlight</li>
        <ul>
          <li style={{paddingTop: 6}}>Nearly 45% of participants in SIC programs are girls, proving that innovation is for everyone.
          </li>
        </ul>
      </ul>,
    
    <h4 style={{ paddingTop: 6 }}>The Future Is Bright</h4>,

    <p style={{ paddingTop: 6 }}>The School Innovation Council is shaping a generation that doesn’t just adapt to the future—it defines it. By fostering creativity, collaboration, and entrepreneurship, SIC is ensuring that every student, regardless of background, has the chance to shine.</p>,

    <p style={{ paddingTop: 6 }}>So, the next time you hear about a student filing a patent or designing a game-changing app, remember: it might just be the magic of SIC at work. The future is bright, and it’s starting in our schools today.</p>



    ]
  },


]

export default newsData;
