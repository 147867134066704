import React from "react";
import { Box } from "@mui/material";
import NavBar from "src/Component/dashboard/navbar/NavBar";
import { Outlet } from "react-router-dom";
import TopBar from "src/Component/dashboard/TopBar";

const DashboardLayout = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex"
      }}
    >
      {/* Left Sidebar */}
      <Box
        sx={{
          overflowY: "auto",
          height: "100%",
          boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)" // Right side shadow
        }}
      >
        <NavBar />
      </Box>
      {/* Right Content */}
      <Box
        sx={{
          overflowY: "auto",
          height: "100%",
          width: { md: `calc(100% - 200px)`, xs: "100%" }
        }}
      >
        {/* TopBar with fixed position */}
        <Box
          sx={{
            position: "fixed",
            width: { md: `calc(100% - 200px)`, xs: "100%" }, // Ensure proper width
            zIndex: 1000, // To ensure it's above other content
            top: 0 // Fix at the top of the page
          }}
        >
          <TopBar />
        </Box>

        <Box sx={{ paddingTop: "70px" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
