import React from "react";
import { Card, Typography, Grid, Chip } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";

const StudyAbroadCard = ({ data }) => {
  return (
    <Card
      sx={{
        padding: 3,
        borderRadius: 3,
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        maxWidth: 400,
        margin: "auto"
      }}
    >
      <Typography variant="body" fontWeight="medium">
        {data?.title}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 2 }}>
        {data?.subtitle}
      </Typography>

      <Grid container spacing={2}>
        {data?.statuses?.map((item, index) => (
          <>
            <Grid item xs={6} key={index}>
              <Typography variant="subtitle2" fontWeight="normal">
                {item.label}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Chip
                label={item.status}
                icon={
                  <CircleIcon
                    style={{ color: item.color, fontSize: "small" }}
                  />
                }
                sx={{
                  width: "120px",
                  backgroundColor: "#effdff",
                  color: "black",
                  fontWeight: "medium",
                  borderRadius: "30px"
                }}
              />
            </Grid>
          </>
        ))}
      </Grid>
    </Card>
  );
};

export default StudyAbroadCard;
