
const USAWhyStudy = {
    title: "Why Study in USA",
    point1: "Top-Ranked Universities",
    point2: "Quality of Education & Life",
    point3: "Employability",
    point6: "Wide range of programs",
    point5: "Opportunities for research and innovation",
    point4: "Cultural diversity"
};
const UKWhyStudy = {
    title: "Why Study in United Kingdom",
    point1: "Academic Excellence",
    point2: "Cultural Diversity",
    point3: "Research Opportunities",
    point4: "Globally Recognized Degrees",
    point5: "Career Advancement",
    point6: "Quality of Life"
};
const IrelandWhyStudy = {
    title: "Why Study in Ireland",
    point1: "Vibrant Campus Life",
    point2: "Innovation Ecosystem",
    point3: "Friendly Environment",
    point4: "Accessible Education",
    point5: "Strong Industry Connections",
    point6: "Beautiful Landscapes"
};
const CanadaWhyStudy = {
    title: "Why Study in Canada",
    point1: "Quality Education",
    point2: "Welcoming Environment",
    point3: "Multicultural Society",
    point4: "Career Opportunities",
    point5: "High Living Standards",
    point6: "Cultural Diversity"
};
const GermanyWhyStudy = {
    title: "Why Study in Germany",
    point1: "Renowned Universities",
    point2: "Affordable Education",
    point3: "Cutting-Edge Research",
    point4: "Strong Economy",
    point5: "Cultural Diversity",
    point6: "Work Opportunities"
};
const AustraliaWhyStudy = {
    title: "Why Study in Australia",
    point1: "World-Class Education",
    point2: "Stunning Landscapes",
    point3: "Innovative Research",
    point4: "Diverse Culture",
    point5: "Employment Prospects",
    point6: "High Quality of Life"
};
const SingaporeWhyStudy = {
    title: "Why Study in Singapore",
    point1: "Academic Excellence",
    point2: "Global Connectivity",
    point3: "Safe Environment",
    point4: "Cultural Fusion",
    point5: "Career Opportunities",
    point6: "Strategic Location"
};
const ItalyWhyStudy = {
    title: "Why Study in Italy",
    point1: "Rich Cultural Heritage",
    point2: "Historical Significance",
    point3: "Academic Excellence",
    point4: "Culinary Delights",
    point5: "Artistic Inspiration",
    point6: "Vibrant Lifestyle"
};
const FranceWhyStudy = {
    title: "Why Study in France",
    point1: "Prestigious Education",
    point2: "Artistic Heritage",
    point3: "Multicultural Environment",
    point4: "Gastronomic Delights",
    point5: "Fashion Capital",
    point6: "Work-Life Balance"
};
const DubaiWhyStudy = {
    title: "Why Study in Dubai",
    point1: "Dynamic Economy",
    point2: "Cultural Diversity",
    point3: "Modern Infrastructure",
    point4: "Global Hub",
    point5: "Business Opportunities",
    point6: "Quality Education"
};
const NewZealandWhyStudy = {
    title: "Why Study in New-Zealand",
    point1: "Dynamic Economy",
    point2: "Cultural Diversity",
    point3: "Modern Infrastructure",
    point4: "Global Hub",
    point5: "Business Opportunities",
    point6: "Quality Education"
};

export {
    USAWhyStudy,
    UKWhyStudy,
    IrelandWhyStudy,
    CanadaWhyStudy,
    GermanyWhyStudy,
    AustraliaWhyStudy,
    SingaporeWhyStudy,
    ItalyWhyStudy,
    FranceWhyStudy,
    DubaiWhyStudy,
    NewZealandWhyStudy
};
