// import React from "react";
// import { Box, TextField } from "@material-ui/core";
// import { useMediaQuery } from "@mui/material";
// import { useNavigate } from "react-router-dom";
// import emailjs from "@emailjs/browser";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { LoadingButton } from "@mui/lab";

// // Validation schema
// const validationSchema = Yup.object().shape({
//   name: Yup.string().required("Name is required"),
//   phone: Yup.string()
//     .matches(/[0-9]{10}$/, "Phone number must be exactly 10 digits ")
//     .required("Phone number is required"),
//   email: Yup.string()
//     .email("Invalid email address")
//     .required("Email is required")
// });

// function FormComponent({ handleCloseDialog, setOpenDialog, buttonText = "Schedule A Meeting" ,filters, setFilters  }) {
//   const [loading, setLoading] = React.useState(false);

//   const navigate = useNavigate();
//   const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

 
//   return (
//     <Box sx={{ position: "relative" }}>
//       <Formik
//         initialValues={{ name: "", email: "", phone: "" }}
//         validationSchema={validationSchema}
       
//       >
//         {({ isSubmitting }) => (
//           <Form
//             style={{
//               padding: "20px",
//               display: "grid",
//               alignContent: "space-between"
//             }}
//           >
//             <Field
//               as={TextField}
//               id="outlined-basic"
//               label="Name"
//               variant="outlined"
//               color="#eeeeee"
//               name="name"
//               size={isSmallScreen ? "small" : "medium"}
//               required
//               margin="normal"
//               helperText={
//                 <ErrorMessage
//                   name="name"
//                   component="div"
//                   style={{ color: "red" }}
//                 />
//               }
//             />
//             <Field
//               as={TextField}
//               id="outlined-basic"
//               label="Phone"
//               type="text"
//               variant="outlined"
//               color="#eeeeee"
//               name="phone"
//               size={isSmallScreen ? "small" : "medium"}
//               required
//               margin="normal"
//               helperText={
//                 <ErrorMessage
//                   name="phone"
//                   component="div"
//                   style={{ color: "red" }}
//                 />
//               }
//             />
//             <Field
//               as={TextField}
//               id="outlined-basic"
//               label="Email"
//               variant="outlined"
//               color="#eeeeee"
//               type="email"
//               name="email"
//               size={isSmallScreen ? "small" : "medium"}
//               required
//               margin="normal"
//               helperText={
//                 <ErrorMessage
//                   name="email"
//                   component="div"
//                   style={{ color: "red" }}
//                 />
//               }
//             />
           
//           </Form>
//         )}
//       </Formik>
//       <ToastContainer />
//     </Box>
//   );
// }

// export default FormComponent;


import React from "react";
import { Box, TextField } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";

// Validation schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  phone: Yup.string()
    .matches(/[0-9]{10}$/, "Phone number must be exactly 10 digits")
    .required("Phone number is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

function FormComponent({
  handleCloseDialog,
  setOpenDialog,
  buttonText = "Schedule A Meeting",
  filters,
  setFilters,
}) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // Handle form submission
  const handleSubmit = (values) => {
    setFilters(values); // Update the parent state with form values
    toast.success("Form submitted successfully!");
    if (handleCloseDialog) handleCloseDialog(); // Close dialog if needed
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Formik
        initialValues={{
          name: filters.name || "",
          phone: filters.phone || "",
          email: filters.email || "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleChange, handleBlur }) => (
          <Form
            style={{
              padding: "20px",
              display: "grid",
              alignContent: "space-between",
            }}
          >
            <Field
              as={TextField}
              id="name"
              label="Name"
              variant="outlined"
              color="#eeeeee"
              name="name"
              size={isSmallScreen ? "small" : "medium"}
              required
              margin="normal"
              onChange={(e) => {
                handleChange(e); // Formik's change handler
                setFilters((prev) => ({
                  ...prev,
                  name: e.target.value,
                })); // Update filters
              }}
              onBlur={handleBlur}
              helperText={
                <ErrorMessage
                  name="name"
                  component="div"
                  style={{ color: "red" }}
                />
              }
            />
            <Field
              as={TextField}
              id="phone"
              label="Phone"
              type="text"
              variant="outlined"
              color="#eeeeee"
              name="phone"
              size={isSmallScreen ? "small" : "medium"}
              required
              margin="normal"
              onChange={(e) => {
                handleChange(e); // Formik's change handler
                setFilters((prev) => ({
                  ...prev,
                  phone: e.target.value,
                })); // Update filters
              }}
              onBlur={handleBlur}
              helperText={
                <ErrorMessage
                  name="phone"
                  component="div"
                  style={{ color: "red" }}
                />
              }
            />
            <Field
              as={TextField}
              id="email"
              label="Email"
              variant="outlined"
              color="#eeeeee"
              type="email"
              name="email"
              size={isSmallScreen ? "small" : "medium"}
              required
              margin="normal"
              onChange={(e) => {
                handleChange(e); // Formik's change handler
                setFilters((prev) => ({
                  ...prev,
                  email: e.target.value,
                })); // Update filters
              }}
              onBlur={handleBlur}
              helperText={
                <ErrorMessage
                  name="email"
                  component="div"
                  style={{ color: "red" }}
                />
              }
            />
            {/* <Box sx={{ textAlign: "center", marginTop: "20px" }}>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={isSubmitting}
                color="primary"
              >
                {buttonText}
              </LoadingButton>
            </Box> */}
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </Box>
  );
}

export default FormComponent;
