import { Box } from "@mui/material";
import React from "react";
import MainHeader from "src/Component/content-component/MainHeader";

import Post from "./component/comunity_post";
import ButtonComponent from "src/Component/content-component/ButtonComponent";

const JoinCommunity = () => {
  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          mb: "30px"
        }}
      >
        <Box sx={{ pl: "20px" }}>
          <MainHeader
            content={"~Welcome to Taksheela Community~"}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          paddingRight: "20px",
          mb: "20px"
        }}
      >
        <ButtonComponent text={"Create Post"} href={"createcommunitypost"} />
      </Box>
      <Post />
    </Box>
  );
};

export default JoinCommunity;
