/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import MainHeader from "src/Component/content-component/MainHeader";
import CalendarPage from "./components/CalendarComponents";
import PlannerTimeline from "./components/Timeline";
import PlannerHistory from "./components/PlannerHistory";
import axios from "axios";

const Planner = () => {
  const [events, setEvents] = useState([]);
  const [plannerDate, setPlannerDate] = useState();
  const [plannerDetails, setPlannerDetails] = useState({
    classes: [],
    mockTests: [],
    practiceAssignment: []
  });
  const [loading, setLoading] = useState(true); // Loading state
  const [noDataMessage, setNoDataMessage] = useState(""); // No data message
  const token = sessionStorage.getItem("token");

  const handleDateClick = (info) => {
    fetchPlannerDetails(info.dateStr);
  };

  const handleEventClick = (info) => {
    const clickedDate = new Date(info.event.start);
    const year = clickedDate.getFullYear();
    const month = String(clickedDate.getMonth() + 1).padStart(2, "0");
    const day = String(clickedDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    fetchPlannerDetails(formattedDate);
  };
  const planId = sessionStorage.getItem("courseId");

  const fetchData = async (year, month) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}planner/student-planner`,
        { year, month, planId: planId },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          }
        }
      );

      const eventData = response.data.data.flatMap((item) => {
        const events = [];
        if (item.mockTest) {
          events.push({ title: "Mock Test", date: item.date, type: "mock" });
        }
        if (item.class) {
          events.push({ title: "Class", date: item.date, type: "class" });
        }
        if (item.attendedClass) {
          events.push({
            title: "Attended Class",
            date: item.date,
            type: "attended"
          });
        }
        if (item.notAttendedClass) {
          events.push({
            title: "Not Attended",
            date: item.date,
            type: "notAttended"
          });
        }
        return events;
      });

      setEvents(eventData);
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    }
  };

  const fetchPlannerDetails = async (date) => {
    setLoading(true); // Set loading to true
    setNoDataMessage(""); // Clear any previous message
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}planner/planner-detail`,
        { date, planId: planId },
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json"
          }
        }
      );

      const {
        class: classes,
        mocktest,
        practiceAssignment
      } = response.data.data;

      setPlannerDate(response.data.data.Date);
      setPlannerDetails({
        classes: classes,
        mockTests: mocktest,
        practiceAssignment: practiceAssignment
      });

      // Check if all data arrays are empty
      if (
        classes.length === 0 &&
        mocktest.length === 0 &&
        practiceAssignment.length === 0
      ) {
        setNoDataMessage("There are no classes or mock tests for today.");
      }
    } catch (error) {
      console.error("Error fetching planner details:", error);
    } finally {
      setLoading(false); // Set loading to false when done
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;

    fetchData(year, month);
    fetchPlannerDetails(currentDate.toISOString().split("T")[0]); // Fetch details for today
  }, []);

  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          mb: "30px",
          alignItems: "center"
        }}
      >
        <Icon
          icon="streamline:class-lesson"
          style={{ color: "#00999E", fontSize: "28px" }}
        />
        <Box sx={{ pl: "20px" }}>
          <MainHeader
            content={"Planner"}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>
      <Box>
        <CalendarPage
          events={events}
          fetchData={fetchData}
          onDateClick={handleDateClick}
          onEventClick={handleEventClick}
        />
      </Box>

      <Box sx={{ mt: "20px", textAlign: "center" }}>
        {/* Display loader over entire date, timeline, and history section */}
        {loading ? (
          <CircularProgress sx={{}} />
        ) : (
          <Typography
            sx={{ fontSize: "28px", fontWeight: "550", color: "#00999E" }}
          >
            {new Date(plannerDate).toLocaleDateString("en-GB", {
              day: "numeric",
              month: "long",
              year: "numeric"
            })}
          </Typography>
        )}
      </Box>
      <Box sx={{ m: "10px 0" }}>
        {!loading && (
          <PlannerTimeline
            mockTests={plannerDetails?.mockTests}
            practiceAssignments={plannerDetails?.practiceAssignment}
          />
        )}
      </Box>
      <Box sx={{ m: "20px 0" }}>
        {!loading ? (
          noDataMessage ? (
            <Typography variant="body1" color="textSecondary">
              {noDataMessage}
            </Typography>
          ) : (
            <PlannerHistory
              classes={plannerDetails?.classes}
              practiceAssignments={plannerDetails?.practiceAssignment}
            />
          )
        ) : null}
      </Box>
    </Box>
  );
};

export default Planner;
