import { Box, Typography, TextField } from "@mui/material";
import React from "react";
import MainHeader from "src/Component/content-component/MainHeader";
import ImageUpload from "./component/ImageUploadSection";

const CreateCommunityPost = () => {
  return (
    <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          mb: "30px",
        }}
      >
        <Box sx={{ pl: "20px", ustifyContent: "flex-start" }}>
          <MainHeader
            content={"Create a new post"}
            fontWeight={"550"}
            fontSize={"28px"}
          />
        </Box>
      </Box>

      <Typography
        className="flip-header"
        variant="h4"
        sx={{
          fontSize: { xs: "14px", sm: "10px", md: "18px", lg: "15px" },
          fontFamily: "Poppins",
          color: "#000000",
          paddingLeft: "20px",
          fontWeight: "500",
          textAlign: "left",
        }}
      >
        Fields marked with an asterisk(*) are required
      </Typography>

      <Box sx={{ padding: "20px", borderRadius: "5px" }}>
        <Typography
          variant="body1"
          sx={{ fontWeight: "bold", marginBottom: "5px" }}
        >
          Title<span style={{ color: "red" }}>*</span>
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Write a descriptive title"
          InputProps={{
            style: { backgroundColor: "white" },
          }}
        />
      </Box>

      <Box sx={{  padding: '20px', borderRadius: '5px' }}>
      <Typography variant="body1" sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
        Message/Queries<span style={{ color: 'red' }}>*</span>
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Provide supporting details or context"
        multiline
        rows={4} // Number of rows for the textarea
        InputProps={{
          style: { backgroundColor: 'white' },
        }}
      />
    </Box>
    <ImageUpload />
    </Box>
  );
};

export default CreateCommunityPost;
