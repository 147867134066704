import React from "react";
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from "recharts";
import { Box } from "@mui/material";
import Header from "src/Component/content-component/Header";

// Sample data for the chart
const data = [
  { name: "0%", progress: 0 },
  { name: "10%", progress: 10 },
  { name: "20%", progress: 20 },
  { name: "30%", progress: 30 },
  { name: "40%", progress: 40 },
  { name: "50%", progress: 50 },
  { name: "60%", progress: 60 },
  { name: "70%", progress: 70 },
  { name: "80%", progress: 80 },
  { name: "90%", progress: 90 },
  { name: "Final Goal", progress: 100 }
];

// Custom component to add small bars at each milestone
const CustomBars = ({ x, y, width, height }) => {
  const barHeight = height * 1; // Adjust bar height as needed
  const barYPosition = y + height - barHeight;

  return (
    <rect
      x={x - width / 4} // Center the bar under the dot
      y={barYPosition}
      width={width / 2} // Width of the bar
      height={barHeight}
      fill="#00999E"
      opacity={0.3}
      stroke="#ffffff"
      strokeWidth={3}
    />
  );
};

function ProgressChart() {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#E0F7FA",
        padding: "20px",
        borderRadius: "10px",
        textAlign: "center"
      }}
    >
      {" "}
      <Box sx={{ m: "10px 0px" }}>
        <Header content={"My Progress"} />
      </Box>
      <Box sx={{ bgcolor: "#ffffff" }}>
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart
            data={data}
            margin={{ top: 20, right: 30, left: 30, bottom: 20 }}
          >
            {/* Gradient definition for the area fill */}
            <defs>
              <linearGradient id="colorProgress" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#00999E" />
                <stop offset="100%" stopColor="#ffffff" />
              </linearGradient>
            </defs>

            <XAxis dataKey="name" tick={{ fill: "#00999E" }} />
            <Tooltip />

            {/* Area chart for progress line with gradient */}
            <Area
              type="monotone"
              dataKey="progress"
              stroke="#00999E"
              fill="url(#colorProgress)"
              strokeWidth={4}
              dot={({ cx, cy, index }) => (
                <g>
                  {/* Render the custom bars first */}
                  <CustomBars
                    x={cx}
                    y={cy}
                    width={50}
                    height={250 - cy}
                    index={index}
                  />
                  {/* Render the circles on top of the bars */}
                  <circle
                    cx={cx}
                    cy={cy}
                    r={10}
                    fill="#00999E"
                    stroke="#FFFFFF"
                    strokeWidth={2}
                  />
                </g>
              )}
            />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={{ m: "10px 0px" }}>
        <Header content={"Track Your Study Abroad Journey"} fontSize={"18px"} />
      </Box>
    </Box>
  );
}

export default ProgressChart;
