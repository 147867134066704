import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import AboutMain from "../Component/about/AboutMain";
import MissionVision from "./about/MissionVision";
import OurValue from "./about/Value";

import OurTeam from "./about/OurTeam";
import { useEffect } from "react";
import { Box } from "@mui/material";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {" "}
      <Helmet>
        <title>
          Best Study Abroad & Test Prep Institute | Taksheela - Delhi, Kolkata,
          Jaipur
        </title>
        <meta
          name="description"
          content="Taksheela Institute offers top-notch study abroad, immigration, MBBS abroad, and test prep services. Based in Delhi, Kolkata, and Jaipur, we are the best institute for your global education needs. Discover our expert guidance and comprehensive support today!"
        />
        <meta
          name="keywords"
          content="best institute for your global education, Best study abroad institute in kolkata, study abroad in Delhi,"
        />
      </Helmet>
      <Navbar />
      <AboutMain />
      <MissionVision />
      <OurValue />
      {/* <Founder /> */}
      <OurTeam />
      {/* <Mentors /> */}
      <Box paddingTop={"30px"}>
        <Footer />
      </Box>
    </>
  );
};

export default AboutUs;
