//californiaImages
import c1 from "../../asset/california/1.png";
import c2 from "../../asset/california/2.png";
import c3 from "../../asset/california/3.png";
import c4 from "../../asset/california/4.png";
import c5 from "../../asset/california/5.png";
import c6 from "../../asset/california/6.png";
import c7 from "../../asset/california/7.png";
import c8 from "../../asset/california/8.png";
import c9 from "../../asset/california/9.png";
import c10 from "../../asset/california/10.png";
//wisconsin
import w1 from "../../asset/wisconsin/1.png";
import w2 from "../../asset/wisconsin/2.png";
import w3 from "../../asset/wisconsin/3.png";
import w4 from "../../asset/wisconsin/4.png";
import w5 from "../../asset/wisconsin/5.png";
import w6 from "../../asset/wisconsin/6.png";
import w7 from "../../asset/wisconsin/7.png";

import w11 from "../../asset/wisconsin/11.png";
import w12 from "../../asset/wisconsin/12.png";
import w13 from "../../asset/wisconsin/13.png";

//yeshivaimages
import y1 from "../../asset/yeshiva/1.png";
import y2 from "../../asset/yeshiva/2.png";
import y3 from "../../asset/yeshiva/3.png";
import y4 from "../../asset/yeshiva/4.png";
import y5 from "../../asset/yeshiva/5.png";
import y6 from "../../asset/yeshiva/6.png";
import y7 from "../../asset/yeshiva/7.png";
import y8 from "../../asset/yeshiva/8.png";
import y9 from "../../asset/yeshiva/9.png";
import y10 from "../../asset/yeshiva/10.png";

//UtahImages
import u1 from "../../asset/utah/1.png";
import u2 from "../../asset/utah/2.png";
import u3 from "../../asset/utah/3.png";
import u4 from "../../asset/utah/4.png";
import u5 from "../../asset/utah/5.png";
import u6 from "../../asset/utah/6.png";
import u7 from "../../asset/utah/7.png";
import u8 from "../../asset/utah/8.png";
import u9 from "../../asset/utah/9.png";
import u10 from "../../asset/utah/10.png";
//ColoradoImages
import co1 from "../../asset/colorado/1.png";
import co2 from "../../asset/colorado/2.png";
import co3 from "../../asset/colorado/3.png";
import co4 from "../../asset/colorado/4.png";
import co5 from "../../asset/colorado/5.png";
import co6 from "../../asset/colorado/6.png";
import co7 from "../../asset/colorado/7.png";
import co8 from "../../asset/colorado/8.png";
import co9 from "../../asset/colorado/9.png";
import co10 from "../../asset/colorado/10.png";
//recruiters
import accenture from "../../asset/recruiters/accenture.png";
import amazon from "../../asset/recruiters/amazon.png";
import apple from "../../asset/recruiters/apple.png";
import bbc from "../../asset/recruiters/bbc.png";
import britishairways from "../../asset/recruiters/britishairways.png";
import deloitte from "../../asset/recruiters/deloitte.png";
import ey from "../../asset/recruiters/ey.png";
import goldmansachs from "../../asset/recruiters/goldmansachs.png";
import google from "../../asset/recruiters/google.png";
import gsk from "../../asset/recruiters/gsk.png";
import hsbc from "../../asset/recruiters/hsbc.png";
import ibm from "../../asset/recruiters/ibm.png";
import jaguar from "../../asset/recruiters/jaguar.png";
import jpmorgan from "../../asset/recruiters/jpmorgan.png";
import kpmg from "../../asset/recruiters/kpmg.png";
import lloyds from "../../asset/recruiters/lloyds.png";
import mckinsey from "../../asset/recruiters/mckinsey.png";
import microsoft from "../../asset/recruiters/microsoft.png";
import pwc from "../../asset/recruiters/pwc.png";
import rollsroyce from "../../asset/recruiters/rollsroyce.png";
import unilever from "../../asset/recruiters/unilever.png";
import abc from "../../asset/recruiters/abc.png";
import anz from "../../asset/recruiters/anz.png";
import bhpbilliton from "../../asset/recruiters/bhpbilliton.png";
import civica from "../../asset/recruiters/civica.png";
import commonwealthbank from "../../asset/recruiters/commonwealthBank.png";
import csl from "../../asset/recruiters/csl.png";
import flinders from "../../asset/recruiters/flinders.png";
import hp from "../../asset/recruiters/hp.png";
import macquarie from "../../asset/recruiters/macquarie.png";
import nationalaustraliabank from "../../asset/recruiters/nationalausstraliabank.png";
import queenslandgovernment from "../../asset/recruiters/queenslandgovernment.png";
import riotinto from "../../asset/recruiters/riotinto.png";
import santos from "../../asset/recruiters/santos.png";
import telstra from "../../asset/recruiters/telstra.png";
import westpac from "../../asset/recruiters/westpac.png";
import woodside from "../../asset/recruiters/woodside.png";
import Dominion from "../../asset/recruiters/dominion.png";
import RBC from "../../asset/recruiters/RBC.png";
import siemens from "../../asset/recruiters/siemens.png";
import CIBC from "../../asset/recruiters/CIBC.png";
import GM from "../../asset/recruiters/GM.png";
import Hatch from "../../asset/recruiters/Hatch.png";
import Telus from "../../asset/recruiters/Telus.png";
import Fraser from "../../asset/recruiters/Fraser.png";
import Vancouver from "../../asset/recruiters/Vancouver.png";
import CityofVancouver from "../../asset/recruiters/CityofVancouver.png";
import Suncor from "../../asset/recruiters/Suncor.png";
import World from "../../asset/recruiters/World.png";
import Island from "../../asset/recruiters/Island.png";
import Victoria from "../../asset/recruiters/Victoria.png";
import Environmental from "../../asset/recruiters/Environmental.png";
import WWF from "../../asset/recruiters/WWF.png";
import sap from "../../asset/recruiters/sap.png";
import Tesla from "../../asset/recruiters/Tesla.png";
import Shopify from "../../asset/recruiters/Shopify.png";
import Calgary from "../../asset/recruiters/Calgary.png";
import enbridge from "../../asset/recruiters/enbridge.png";
import atco from "../../asset/recruiters/atco.png";
import canadian from "../../asset/recruiters/canadian.png";
import pcl from "../../asset/recruiters/pcl.png";
import husky from "../../asset/recruiters/husky.png";
import scotiabank from "../../asset/recruiters/scotiabank.png";
import bell from "../../asset/recruiters/bell.png";
import snc from "../../asset/recruiters/snc.png";
import nova from "../../asset/recruiters/nova.png";
import cbc from "../../asset/recruiters/cbc.png";
import mnp from "../../asset/recruiters/mnp.png";
import Imperial from "../../asset/recruiters/Imperial.png";
import ford from "../../asset/recruiters/ford.png";
import magna from "../../asset/recruiters/magna.png";
import walmart from "../../asset/recruiters/walmart.png";
import ctc from "../../asset/recruiters/ctc.png";
import nh from "../../asset/recruiters/nh.png";
import eu from "../../asset/recruiters/eu.png";
import wrh from "../../asset/recruiters/wrh.png";
import bchydro from "../../asset/recruiters/bchydro.png";
import ld from "../../asset/recruiters/ld.png";
import loblaws from "../../asset/recruiters/loblaws.png";
import bt from "../../asset/recruiters/bt.png";
import bos from "../../asset/recruiters/bos.png";
import sg from "../../asset/recruiters/sg.png";
import ms from "../../asset/recruiters/ms.png";
import shell from "../../asset/recruiters/shell.png";
import nestlé from "../../asset/recruiters/nestlé.png";
import intel from "../../asset/recruiters/intel.png";
import boeing from "../../asset/recruiters/boeing.png";
import caterpillar from "../../asset/recruiters/caterpillar.png";
import facebook from "../../asset/recruiters/facebook.png";
import salesforce from "../../asset/recruiters/salesforce.png";
import bcg from "../../asset/recruiters/bcg.png";
import boa from "../../asset/recruiters/boa.png";
import bmw from "../../asset/recruiters/bmw.png";
import allianz from "../../asset/recruiters/allianz.png";
import bayer from "../../asset/recruiters/bayer.png";
import deutscheBank from "../../asset/recruiters/deutscheBank.png";
import bosch from "../../asset/recruiters/bosch.png";
import daimler from "../../asset/recruiters/daimler.png";
import heidelbergcement from "../../asset/recruiters/heidelbergcement.png";
import roche from "../../asset/recruiters/roche.png";
import volkswagen from "../../asset/recruiters/volkswagen.png";
import basf from "../../asset/recruiters/basf.png";
import deutscheTelekom from "../../asset/recruiters/deutscheTelekom.png";
import loreal from "../../asset/recruiters/loreal.png";
import bnpParibas from "../../asset/recruiters/bnpParibas.png";
import capgemini  from "../../asset/recruiters/capgemini.png";
import airbus  from "../../asset/recruiters/airbus.png";
import edf  from "../../asset/recruiters/edf.png";
import renault  from "../../asset/recruiters/renault.png";
import orange  from "../../asset/recruiters/orange.png";
import thales  from "../../asset/recruiters/thales.png";
import dassaultSystemes  from "../../asset/recruiters/dassaultSystemes.png";
import safran  from "../../asset/recruiters/safran.png";
import schneiderElectric  from "../../asset/recruiters/schneiderElectric.png";
import stMicroelectronics  from "../../asset/recruiters/stMicroelectronics.png";
import valeo  from "../../asset/recruiters/valeo.png";
import sanofi  from "../../asset/recruiters/sanofi.png";
import societeGenerale  from "../../asset/recruiters/societeGenerale.png";
import axa  from "../../asset/recruiters/axa.png";
import airFranceKLM  from "../../asset/recruiters/airFranceKLM.png";
import fiatChrysler  from "../../asset/recruiters/fiatChrysler.png";
import pirelli  from "../../asset/recruiters/pirelli.png";
import bulgari  from "../../asset/recruiters/bulgari.png";
import tcs  from "../../asset/recruiters/tcs.png";
import infosys  from "../../asset/recruiters/infosys.png";
import wipro  from "../../asset/recruiters/wipro.png";
import cognizant  from "../../asset/recruiters/cognizant.png";
import hcl  from "../../asset/recruiters/hcl.png";
import luxottica  from "../../asset/recruiters/luxottica.png";
import eni  from "../../asset/recruiters/eni.png";
import unicredit  from "../../asset/recruiters/unicredit.png";
import ferragamo  from "../../asset/recruiters/ferragamo.png";
import prada  from "../../asset/recruiters/prada.png";
import intesaSanpaolo  from "../../asset/recruiters/intesaSanpaolo.png";
import ferrari  from "../../asset/recruiters/ferrari.jpg";
import lavazza  from "../../asset/recruiters/lavazza.png";
import leonardo  from "../../asset/recruiters/leonardo.png";
import gucci  from "../../asset/recruiters/gucci.png";
import fendi  from "../../asset/recruiters/fendi.png";
import diesel  from "../../asset/recruiters/diesel.png";
import ikea  from "../../asset/recruiters/ikea.png";
import stJamesHospital  from "../../asset/recruiters/stJamesHospital.png";
import materMisericordiae  from "../../asset/recruiters/materMisericordiae.png";
import beaumontHospital  from "../../asset/recruiters/beaumontHospital.png";
import corkUniversityHospital  from "../../asset/recruiters/corkUniversityHospital.png";
import hse  from "../../asset/recruiters/hse.png";
import abbVie  from "../../asset/recruiters/abbVie.png";
import medtronic  from "../../asset/recruiters/medtronic.png";
import bostonScientific  from "../../asset/recruiters/bostonScientific.png";
import pfizer  from "../../asset/recruiters/pfizer.png";
import linkedin  from "../../asset/recruiters/linkedin.png";
import meta  from "../../asset/recruiters/meta.png";
import fonterra  from "../../asset/recruiters/fonterra.png";
import adhb  from "../../asset/recruiters/adhb.png";
import airNewZealand  from "../../asset/recruiters/airNewZealand.png";
import xero  from "../../asset/recruiters/xero.png";
import anzBank  from "../../asset/recruiters/anzBank.png";
import fisherPaykel  from "../../asset/recruiters/fisherPaykel.png";
import genesisEnergy  from "../../asset/recruiters/genesisEnergy.png";
import sparkNewZealand  from "../../asset/recruiters/sparkNewZealand.png";
import taitCommunications  from "../../asset/recruiters/taitCommunications.png";
import christchurchCityCouncil  from "../../asset/recruiters/christchurchCityCouncil.png";
import doc  from "../../asset/recruiters/doc.png";
import fisherPaykelHealthcare  from "../../asset/recruiters/fisherPaykelHealthcare.jpg";
import newZealandPolice  from "../../asset/recruiters/newZealandPolice.jpg";
import agResearch  from "../../asset/recruiters/agResearch.png";
import landcorpFarming  from "../../asset/recruiters/landcorpFarming.png";
import aftPharmaceuticals  from "../../asset/recruiters/aftPharmaceuticals.png";
import tgGlobal  from "../../asset/recruiters/tgGlobal.png";
import horticultureNewZealand  from "../../asset/recruiters/horticultureNewZealand.png";
import lic  from "../../asset/recruiters/lic.png";
import nzTransportAgency  from "../../asset/recruiters/nzTransportAgency.png";
import fletcherBuilding  from "../../asset/recruiters/fletcherBuilding.jpg";



const CaliforniaRecruiter = [
  {
    id: 1,
    Image: c1,
    title: ["Google"]
  },
  {
    id: 2,
    Image: c2,
    title: ["Apple"]
  },
  {
    id: 3,
    Image: c3,
    title: ["Microsoft"]
  },
  {
    id: 4,
    Image: c4,
    title: ["Amazon"]
  },
  {
    id: 5,
    Image: c5,
    title: ["Facebook (Meta)"]
  },
  {
    id: 6,
    Image: c6,
    title: ["Tesla"]
  },
  {
    id: 7,
    Image: c7,
    title: ["Deloitte"]
  },
  {
    id: 8,
    Image: c8,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 9,
    Image: c9,
    title: ["Intel"]
  },
  {
    id: 10,
    Image: c10,
    title: ["LinkedIn"]
  }
];
const wisconsinRecruiter = [
  {
    id: 1,
    Image: w1,
    title: ["Google"]
  },
  {
    id: 2,
    Image: w2,
    title: ["Amazon"]
  },
  {
    id: 3,
    Image: w3,
    title: ["Microsoft"]
  },
  {
    id: 4,
    Image: w4,
    title: ["Apple"]
  },
  {
    id: 5,
    Image: w5,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: w6,
    title: ["Goldman Sachs"]
  },
  {
    id: 7,
    Image: w7,
    title: ["Deloitte"]
  },

  {
    id: 8,
    Image: w11,
    title: ["Facebook (Meta)"]
  },
  {
    id: 9,
    Image: w12,
    title: ["Oracle"]
  },
  {
    id: 10,
    Image: w13,
    title: ["Johnson & Johnson"]
  }
];
const YeshivaRecruiter = [
  {
    id: 1,
    Image: y1,
    title: ["Google"]
  },
  {
    id: 2,
    Image: y2,
    title: ["Amazon"]
  },
  {
    id: 3,
    Image: y3,
    title: ["Goldman Sachs"]
  },
  {
    id: 4,
    Image: y4,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: y5,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 6,
    Image: y6,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 7,
    Image: y7,
    title: ["KPMG"]
  },
  {
    id: 8,
    Image: y8,
    title: ["IBM"]
  },
  {
    id: 9,
    Image: y9,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: y10,
    title: ["JPMorgan Chase"]
  }
];
const UtahRecruiter = [
  {
    id: 1,
    Image: u1,
    title: ["Intermountain Healthcare"]
  },
  {
    id: 2,
    Image: u2,
    title: ["SkyWest Airlines"]
  },
  {
    id: 3,
    Image: u3,
    title: ["Utah State Government"]
  },
  {
    id: 4,
    Image: u4,
    title: ["Zions Bank"]
  },
  {
    id: 5,
    Image: u5,
    title: ["Southern Utah University"]
  },
  {
    id: 6,
    Image: u6,
    title: ["Wells Fargo"]
  },
  {
    id: 7,
    Image: u7,
    title: ["Adobe"]
  },
  {
    id: 8,
    Image: u8,
    title: ["Utah Shakespeare Festival"]
  },
  {
    id: 9,
    Image: u9,
    title: ["Southwest Technical College"]
  },
  {
    id: 10,
    Image: u10,
    title: ["Iron County School District"]
  }
];
const ColoradoRecruiter = [
  {
    id: 1,
    Image: co1,
    title: ["Lockheed Martin"]
  },
  {
    id: 2,
    Image: co2,
    title: ["Intel"]
  },
  {
    id: 3,
    Image: co3,
    title: ["IBM"]
  },
  {
    id: 4,
    Image: co4,
    title: ["Hewlett-Packard (HP)"]
  },
  {
    id: 5,
    Image: co5,
    title: ["Agilent Technologies"]
  },
  {
    id: 6,
    Image: co6,
    title: ["Ball Aerospace & Technologies Corp."]
  },
  {
    id: 7,
    Image: co7,
    title: ["Arrow Electronics"]
  },
  {
    id: 8,
    Image: co8,
    title: ["Woodward, Inc."]
  },
  {
    id: 9,
    Image: co9,
    title: ["Charles Schwab"]
  },
  {
    id: 10,
    Image: co10,
    title: ["Kaiser Permanente"]
  }
];

const IllinoisRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 4,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: intel,
    title: ["Intel"]
  },
  {
    id: 7,
    Image: caterpillar,
    title: ["Caterpillar"]
  },
  {
    id: 8,
    Image: boeing,
    title: ["Boeing"]
  },
  {
    id: 9,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  },
];

const StanfordRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 3,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 4,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 5,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 6,
    Image: Tesla,
    title: ["Tesla"]
  },
  
  {
    id: 7,
    Image: intel,
    title: ["Intel"]
  },
  
  {
    id: 8,
    Image: salesforce,
    title: ["Salesforce"]
  },
  
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
];

const PennsylvaniaRecruiter = [
  
  {
    id: 1,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 2,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: mckinsey,
    title: ["McKinsey & Company"]
  },
  {
    id: 5,
    Image: apple,
    title: ["Apple"]
  },

  {
    id: 6,
    Image: google,
    title: ["Google"]
  },
  
  {
    id: 7,
    Image: facebook,
    title: ["Facebook (Meta)"]
  }, 

  {
    id: 8,
    Image: bcg,
    title: ["Boston Consulting Group (BCG)IBM "]
  },

  {
    id: 9,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 10,
    Image: Tesla,
    title: ["Tesla"]
  },
  
];

const ChicagoRecruiter = [

  {
    id: 1,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 2,
    Image: mckinsey,
    title: ["McKinsey & Company"]
  },
  {
    id: 3,
    Image: bcg,
    title: ["Boston Consulting Group (BCG)IBM "]
  },
  {
    id: 4,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  
  {
    id: 7,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 8,
    Image: google,
    title: ["Google"]
  },  

  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 10,
    Image: boa,
    title: ["Bank of America"]
  },

];

const MITRecruiter = [

  {
    id: 1,
    Image: google,
    title: ["Google"]
  },

  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },

  {
    id: 3,
    Image: apple,
    title: ["Apple"]
  },

  {
    id: 4,
    Image: amazon,
    title: ["Amazon"]
  },

  {
    id: 5,
    Image: Tesla,
    title: ["Tesla"]
  },

  {
    id: 6,
    Image: bcg,
    title: ["Boston Consulting Group (BCG)IBM "]
  },
  {
    id: 7,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  
  {
    id: 8,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },

];


const BristolRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: google,
    title: ["Google"]
  },
  {
    id: 7,
    Image: hsbc,
    title: ["HSBC"]
  },
  {
    id: 8,
    Image: lloyds,
    title: ["LLOYDS Banking Group"]
  },
  {
    id: 9,
    Image: bbc,
    title: ["BBC"]
  },
  {
    id: 10,
    Image: rollsroyce,
    title: ["Rolls- Royce"]
  }
];
const LeedsRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 7,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: gsk,
    title: ["GSK"]
  },
  {
    id: 10,
    Image: britishairways,
    title: ["British Airways"]
  }
];
const GlasgowRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 3,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 4,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 5,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 7,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 8,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 9,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  }
];
const BirminghamRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 5,
    Image: hsbc,
    title: ["HSBC"]
  },
  {
    id: 6,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: rollsroyce,
    title: ["Rolls- Royce"]
  },
  {
    id: 9,
    Image: jaguar,
    title: ["JAGUAR"]
  },
  {
    id: 10,
    Image: unilever,
    title: ["Unilever"]
  }
];
const imperialRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 4,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 5,
    Image: mckinsey,
    title: ["McKinsey & Company"]
  },
  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 7,
    Image: pwc,
    title: ["PwC (Pricewater houseCoopers)"]
  },
  {
    id: 8,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 9,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 10,
    Image: hsbc,
    title: ["HSBC"]
  }
];

const QueenMaryRecruiter = [
  {
    id: 1,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },
  {
    id: 2,
    Image: hsbc,
    title: ["HSBC"]
  },
  {
    id: 3,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 4,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 7,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 8,
    Image: google,
    title: ["Google"]
  },
  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  },

];

const LiverpoolRecruiter = [

  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: hsbc,
    title: ["HSBC"]
  },

 
  {
    id: 7,
    Image: bt,
    title: ["BT Group"]
  },
  
  
  
  {
    id: 8,
    Image: unilever,
    title: ["Unilever"]
  },
  {
    id: 9,
    Image: bbc,
    title: ["BBC"]
  },
  {
    id: 10,
    Image: lloyds,
    title: ["Lloyds Banking Group"]
  },

];

const EdinburghRecruiter = [

  {
    id: 1,
    Image: bos,
    title: ["Bank of Scotland"]
  },

  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 5,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  
  
  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  


  {
    id: 7,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },



  {
    id: 8,
    Image: sg,
    title: ["Scottish Government"]
  },
  {
    id: 9,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  },

];

const SouthamptonRecruiter = [

 
  {
    id: 1,
    Image: jpmorgan,
    title: ["J.P. Morgan"]
  },

  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 4,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  
  
  {
    id: 4,
    Image: ms,
    title: ["Morgan Stanley"]
  },
  
  {
    id: 5,
    Image: google,
    title: ["Google"]
  },


  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 7,
    Image: bt,
    title: ["BT Group"]
  },
  {
    id: 9,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 10,
    Image: shell,
    title: ["Shell"]
  },

];

const DurhamRecruiter = [


  

  {
    id: 1,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  
  {
    id: 3,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },

  {
    id: 1,
    Image: kpmg,
    title: ["KPMG"]
  },


  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 5,
    Image: jpmorgan,
    title: ["JP Morgan"]
  },


  {
    id: 6,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },

  {
    id: 7,
    Image: bbc,
    title: ["BBC"]
  },
  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 10,
    Image: nestlé,
    title: ["Nestlé"]
  },

];

const UNSWRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 9,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 10,
    Image: telstra,
    title: ["Telstra"]
  }
];
const MonashRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: anz,
    title: ["ANZ"]
  },
  {
    id: 7,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 8,
    Image: westpac,
    title: ["Westpac"]
  },
  {
    id: 9,
    Image: telstra,
    title: ["Telstra"]
  },
  {
    id: 10,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  }
];
const QueenslandRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: abc,
    title: ["ABC"]
  },
  {
    id: 7,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 8,
    Image: riotinto,
    title: ["Rio Tinto"]
  },
  {
    id: 9,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 10,
    Image: queenslandgovernment,
    title: ["Queensland Government"]
  }
];
const WesternAustraliaRecruiter = [
  {
    id: 1,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 2,
    Image: riotinto,
    title: ["Rio Tinto"]
  },
  {
    id: 3,
    Image: woodside,
    title: ["Woodside Petroleum"]
  },
  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: abc,
    title: ["ABC"]
  },
  {
    id: 9,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 10,
    Image: civica,
    title: ["Civica"]
  }
];
const AdelaideRecruiter = [
  {
    id: 1,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 2,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 7,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 8,
    Image: santos,
    title: ["Santos"]
  },
  {
    id: 9,
    Image: hp,
    title: ["HP"]
  },
  {
    id: 10,
    Image: flinders,
    title: ["Flinders"]
  }
];
const MelbourneRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: anz,
    title: ["ANZ"]
  },
  {
    id: 4,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: macquarie,
    title: ["Macquarie"]
  },
  {
    id: 7,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 8,
    Image: nationalaustraliabank,
    title: ["NAB"]
  },
  {
    id: 9,
    Image: csl,
    title: ["CSL"]
  },
  {
    id: 10,
    Image: telstra,
    title: ["Telstra"]
  }
];
const SydneyRecruiter = [
  {
    id: 1,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: anz,
    title: ["ANZ"]
  },
  {
    id: 4,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: macquarie,
    title: ["Macquarie"]
  },
  {
    id: 8,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 9,
    Image: google,
    title: ["Google"]
  },
  {
    id: 10,
    Image: telstra,
    title: ["Telstra"]
  }
];
const AnuRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: westpac,
    title: ["Westpac"]
  },
  {
    id: 6,
    Image: anz,
    title: ["ANZ Bank"]
  },
  {
    id: 7,
    Image: bhpbilliton,
    title: ["BHP Billiton"]
  },
  {
    id: 8,
    Image: telstra,
    title: ["Telstra"]
  },
  {
    id: 9,
    Image: nationalaustraliabank,
    title: ["NAB"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  }
];
const UtsRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 7,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 8,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 9,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 10,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  }
];
const MacquarieRecruiter = [
  {
    id: 1,
    Image: macquarie,
    title: ["Macquarie"]
  },
  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PWC"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 7,
    Image: westpac,
    title: ["Westpac"]
  },
  {
    id: 8,
    Image: commonwealthbank,
    title: ["CBA"]
  },
  {
    id: 9,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  }
];


const TrentRecruiter = [
  {
    id: 1,
    Image: Dominion,
    title: ["Toronto Dominion Bank (TD)"]
  },
  {
    id: 2,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: siemens,
    title: ["Siemens"]
  },
  {
    id: 7,
    Image: CIBC,
    title: ["CIBC (Canadian Imperial Bank of Commerce)"]
  },
  {
    id: 8,
    Image: unilever ,
    title: ["Unilever"]
  },
  {
    id: 9,
    Image: GM,
    title: ["General Motors"]
  },
  {
    id: 10,
    Image: Hatch,
    title: ["Hatch Ltd"]
  }
];

const TrinityRecruiter = [

  {
    id: 1,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },
 
  {
    id: 2,
    Image: Telus,
    title: ["Telus"]
  },
  
  {
    id: 3,
    Image: Fraser,
    title: ["Fraser Health Authority"]
  },
  {
    id: 4,
    Image: Vancouver,
    title: ["Vancouver Coastal Health"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  
 
 
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },

 
  {
    id: 8,
    Image: CityofVancouver,
    title: ["City of Vancouver"]
  },
  {
    id: 9,
    Image: World,
    title: ["World Vision Canada"]
  },
  {
    id: 10,
    Image: Suncor,
    title: ["Suncor Energy"]
  }
];

const RRURecruiter = [

  {
    id: 1,
    Image: World,
    title: ["BC Public Service"]
  },

  {
    id: 2,
    Image: Telus,
    title: ["Telus"]
  },

  {
    id: 3,
    Image: Island,
    title: ["Vancouver   Health Authority"]
  },
  {
    id: 4,
    Image: Victoria,
    title: ["City of Victoria"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },



  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },


  {
    id: 8,
    Image: Environmental,
    title: ["Environmental Protection Agency (EPA)"]
  },

  {
    id: 9,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },
  
  {
    id: 10,
    Image: WWF,
    title: ["World Wildlife Fund (WWF)"]
  }
];

const WaterlooRecruiter = [

  {
    id: 1,
    Image: google,
    title: ["Google"]
  },

  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },

  {
    id: 3,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 4,
    Image: sap,
    title: ["SAP"]
  },
  {
    id: 5,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },



  

  {
    id: 7,
    Image: ibm,
    title: ["IBM"]
  },

  {
    id: 8,
    Image: Tesla,
    title: ["Tesla"]
  },

  

  {
    id: 9,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 10,
    Image: Shopify,
    title: ["Shopify"]
  }
];

const CalgeryRecruiter = [

  {
    id: 1,
    Image: Calgary,
    title: ["Calgary Health Region"]
  },

  {
    id: 2,
    Image: Suncor,
    title: ["Suncor Energy"]
  },

  {
    id: 3,
    Image: enbridge,
    title: ["Enbridge"]
  },
  {
    id: 4,
    Image: atco,
    title: ["ATCO"]
  },
  { 
    id: 5,
    Image: canadian,
    title: ["Canadian Natural Resources Limited (CNRL)"]
  },
  {
    id: 6,
    Image: Telus,
    title: ["Telus"]
  },

  {
    id: 7,
    Image: pcl,
    title: ["PCL Construction"]
  },

  {
    id: 8,
    Image: husky,
    title: ["Husky Energy"]
  },



  {
    id: 9,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 10,
    Image: CIBC,
    title: ["CIBC"]
  }
];

const DalhousieRecruiter = [

  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 2,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 3,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 4,
    Image: scotiabank,
    title: ["Scotiabank"]
  },
  {
    id: 5,
    Image: bell,
    title: ["Bell Canada)"]
  },
  {
    id: 6,
    Image: snc,
    title: ["SNC-Lavalin"]
  },

  {
    id: 7,
    Image: nova,
    title: ["Nova Scotia Health Authority"]
  },

  {
    id: 8,
    Image: cbc  ,
    title: ["CBC (Canadian Broadcasting Corporation)"]
  },



  {
    id: 9,
    Image: mnp,
    title: ["MNP LLP"]
  },

  {
    id: 10,
    Image: Imperial,
    title: ["Imperial Oil"]
  }
];

const BrockRecruiter = [

  {
    id: 1,
    Image: ford,
    title: ["Ford Motor Company"]
  },

  {
    id: 2,
    Image: GM,
    title: ["General Motors"]
  },

  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 5,
    Image: magna,
    title: ["Magna International"]
  },
  
  {
    id: 6,
    Image: walmart,
    title: ["Walmart Canada"]
  },

  
  {
    id: 7,
    Image: bell,
    title: ["Bell Canada)"]
  },

  {
    id: 8,
    Image: ctc,
    title: ["Canadian Tire Corporation"]
  },



  {
    id: 9,
    Image: nh,
    title: ["Niagara Health"]
  },

  {
    id: 10,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  }
];

const WindsorRecruiter = [

  {
    id: 1,
    Image: ford,
    title: ["Ford Motor Company"]
  },

  {
    id: 2,
    Image: GM,
    title: ["General Motors"]
  },

  {
    id: 3,
    Image: magna,
    title: ["Magna International"]
  },

  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },

 

  {
    id: 6,
    Image: wrh,
    title: ["Windsor Regional Hospital"]
  },


  {
    id: 7,
    Image: eu,
    title: ["Enwin Utilities"]
  },
  {
    id: 8,
    Image: bell,
    title: ["Bell Canada)"]
  },




  {
    id: 9,
    Image: snc,
    title: ["SNC-Lavalin"]
  },

  {
    id: 10,
    Image: hp,
    title: ["Hewlett-Packard (HP)"]
  }
];

const VCCRecruiter = [

  {
    id: 1,
    Image: Telus,
    title: ["Telus"]
  },

  {
    id: 2,
    Image: bchydro,
    title: ["BC Hydro"]
  },

  {
    id: 3,
    Image: ld,
    title: ["London Drugs"]
  },

  {
    id: 4,
    Image: Vancouver,
    title: ["Vancouver Coastal Health"]
  },
  {
    id: 5,
    Image: loblaws,
    title: ["Loblaws"]
  },



  {
    id: 6,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },


  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: kpmg,
    title: ["KPMG"]
  },




  {
    id: 9,
    Image: amazon,
    title: ["Amazon"]
  },

  {
    id: 10,
    Image: sap,
    title: ["SAP"]
  }
];

const TorontoRecruiter = [

  {
    id: 1,
    Image: google,
    title: ["Google"]
  },

  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },

  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 4,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 5,
    Image: RBC,
    title: ["RBC (Royal Bank of Canada)"]
  },



  {
    id: 6,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },


  {
    id: 7,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },




  {
    id: 9,
    Image: Dominion,
    title: ["Toronto-Dominion Bank (TD)"]
  },

  {
    id: 10,
    Image: Tesla,
    title: ["Tesla"]
  }
];

//Germany

const LMURecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 3,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 4,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 9,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 10,
    Image: roche,
    title: ["Roche"]
  }
];

const TUMRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 3,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 4,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 5,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 6,
    Image: microsoft,
    title: ["Microsoft"]
  },

  {
    id: 7,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 9,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 10,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  }
];

const HeidelbergUniversityRecruiter = [
  {
    id: 1,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 4,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 5,
    Image: heidelbergcement,
    title: ["HeidelbergCement"]
  },

  {
    id: 6,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 7,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 8,
    Image: roche,
    title: ["Roche"]
  },

  {
    id: 9,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 10,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  }
];


const HumboldtUniversityRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  },

  {
    id: 3,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 4,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 5,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 6,
    Image: basf,
    title: ["BASF"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 9,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  }
];

const RWTHRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 4,
    Image: ford,
    title: ["Ford"]
  },

  {
    id: 5,
    Image: deutscheTelekom,
    title: ["Deutsche Telekom"]
  },

  {
    id: 6,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 8,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 9,
    Image: basf,
    title: ["BASF"]
  },

  {
    id: 10,
    Image: volkswagen,
    title: ["Volkswagen"]
  }
];

const FUBRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  },

  {
    id: 4,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 5,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 6,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 7,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 8,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 9,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 10,
    Image: accenture,
    title: ["Accenture"]
  }
];

const UOFRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 4,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 5,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 6,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 9,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 10,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  }
];

const UOTRecruiter = [
  {
    id: 1,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 3,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 4,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 5,
    Image: allianz,
    title: ["Allianz"]
  },

  {
    id: 6,
    Image: volkswagen,
    title: ["Volkswagen"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 9,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 10,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  }
];

const UOMRecruiter = [
  {
    id: 1,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 2,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 3,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 4,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 5,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 6,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  },

  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 8,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 9,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },

  {
    id: 10,
    Image: allianz,
    title: ["Allianz"]
  }
];

const UOGRecruiter = [
  {
    id: 1,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 2,
    Image: bmw,
    title: ["BMW"]
  },

  {
    id: 3,
    Image: sap,
    title: ["SAP"]
  },

  {
    id: 4,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 5,
    Image: deutscheBank,
    title: ["Deutsche Bank"]
  },

  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 7,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 9,
    Image: bayer,
    title: ["Bayer"]
  },

  {
    id: 10,
    Image: siemens,
    title: ["Siemens"]
  }
];


//France

const UPSLRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 2,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 3,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 5,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 6,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 7,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 8,
    Image: renault,
    title: ["Renault"]
  },

  {
    id: 9,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 10,
    Image: orange,
    title: ["Orange"]
  }
];


const UPSRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 2,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 3,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 4,
    Image: thales,
    title: ["Thales"]
  },

  {
    id: 5,
    Image: dassaultSystemes,
    title: ["Dassault Systèmes"]
  },

  {
    id: 6,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 7,
    Image: safran,
    title: ["Safran"]
  },

  {
    id: 8,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 9,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 10,
    Image: renault,
    title: ["Renault"]
  }
];

const SURecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 2,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 3,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 4,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 6,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 7,
    Image: dassaultSystemes,
    title: ["Dassault Systèmes"]
  },

  {
    id: 8,
    Image: orange,
    title: ["Orange"]
  },

  {
    id: 9,
    Image: safran,
    title: ["Safran"]
  },

  {
    id: 10,
    Image: renault,
    title: ["Renault"]
  }
];

const UGARecruiter = [
  {
    id: 1,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 2,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 3,
    Image: stMicroelectronics,
    title: ["STMicroelectronics"]
  },

  {
    id: 4,
    Image: valeo,
    title: ["Valeo"]
  },

  {
    id: 5,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 6,
    Image: renault,
    title: ["Renault"]
  },

  {
    id: 7,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 8,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },

  {
    id: 10,
    Image: dassaultSystemes,
    title: ["Dassault Systèmes"]
  }
];

const UDSRecruiter = [
  {
    id: 1,
    Image: bosch,
    title: ["Bosch"]
  },

  {
    id: 2,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 3,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 4,
    Image: daimler,
    title: ["Daimler"]
  },

  {
    id: 5,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 6,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 7,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 8,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 9,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 10,
    Image: siemens,
    title: ["Siemens"]
  }
];

const UDMRecruiter = [
  {
    id: 1,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 2,
    Image: sanofi,
    title: ["Sanofi"]
  },

  {
    id: 3,
    Image: edf,
    title: ["EDF (Électricité de France)"]
  },

  {
    id: 4,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 6,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 7,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },

  {
    id: 9,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 10,
    Image: boeing,
    title: ["Boeing"]
  }
];


const UDLRecruiter = [
  {
    id: 1,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 2,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 3,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 5,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 6,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 7,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  }
];


const UDNRecruiter = [
  {
    id: 1,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 2,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 4,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 6,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 8,
    Image: orange,
    title: ["Orange"]
  },

  {
    id: 9,
    Image: thales,
    title: ["Thales"]
  },

  {
    id: 10,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  }
];

const UDBRecruiter = [
  {
    id: 1,
    Image: airbus,
    title: ["Airbus"]
  },

  {
    id: 2,
    Image: capgemini,
    title: ["Capgemini"]
  },

  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 5,
    Image: schneiderElectric,
    title: ["Schneider Electric"]
  },

  {
    id: 6,
    Image: siemens,
    title: ["Siemens"]
  },

  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 8,
    Image: orange,
    title: ["Orange"]
  },

  {
    id: 9,
    Image: thales,
    title: ["Thales"]
  },

  {
    id: 10,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  }
];

const UPPSRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },

  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },

  {
    id: 3,
    Image: accenture,
    title: ["Accenture"]
  },

  {
    id: 4,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },

  {
    id: 5,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },

  {
    id: 6,
    Image: bnpParibas,
    title: ["BNP Paribas"]
  },

  {
    id: 7,
    Image: societeGenerale,
    title: ["Société Générale"]
  },

  {
    id: 8,
    Image: loreal,
    title: ["L'Oréal"]
  },

  {
    id: 9,
    Image: axa,
    title: ["AXA"]
  },

  {
    id: 10,
    Image: airFranceKLM,
    title: ["Air France-KLM"]
  }
];

//Italy

const PDMilanoRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: bosch,
    title: ["Bosch"]
  },
  {
    id: 3,
    Image: siemens,
    title: ["Siemens"]
  },
  {
    id: 4,
    Image: google,
    title: ["Google"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles (Stellantis)"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: pirelli,
    title: ["Pirelli"]
  },
  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  }
];

const UOMilanRecruiter = [
  {
    id: 1,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: bosch,
    title: ["Bosch"]
  },
  {
    id: 5,
    Image: siemens,
    title: ["Siemens"]
  },
  {
    id: 6,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles (Stellantis)"]
  },
  {
    id: 9,
    Image: bulgari,
    title: ["Bulgari"]
  },
  {
    id: 10,
    Image: pirelli,
    title: ["Pirelli"]
  }
];

const UOBolognaRecruiter = [
  {
    id: 1,
    Image: tcs,
    title: ["Tata Consultancy Services (TCS)"]
  },
  {
    id: 2,
    Image: infosys,
    title: ["Infosys"]
  },
  {
    id: 3,
    Image: wipro,
    title: ["Wipro"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: cognizant,
    title: ["Cognizant"]
  },
  {
    id: 7,
    Image: capgemini,
    title: ["Capgemini"]
  },
  {
    id: 8,
    Image: hcl,
    title: ["HCL Technologies"]
  },
  {
    id: 9,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 10,
    Image: amazon,
    title: ["Amazon"]
  }
];

const UOFlorenceRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: luxottica,
    title: ["Luxottica"]
  },
  {
    id: 5,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 6,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles"]
  },
  {
    id: 7,
    Image: unicredit,
    title: ["Unicredit"]
  },
  {
    id: 8,
    Image: ferragamo,
    title: ["Ferragamo"]
  },
  {
    id: 9,
    Image: pirelli,
    title: ["Pirelli"]
  },
  {
    id: 10,
    Image: prada,
    title: ["Prada"]
  }
];

const UOPaduaRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: luxottica,
    title: ["Luxottica"]
  },
  {
    id: 5,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 6,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles"]
  },
  {
    id: 7,
    Image: unicredit,
    title: ["Unicredit"]
  },
  {
    id: 8,
    Image: prada,
    title: ["Prada"]
  },
  {
    id: 9,
    Image: pirelli,
    title: ["Pirelli"]
  },
  {
    id: 10,
    Image: ferragamo,
    title: ["Ferragamo"]
  }
];

const UOTurinRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 5,
    Image: unicredit,
    title: ["Unicredit"]
  },
  {
    id: 6,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 7,
    Image: intesaSanpaolo,
    title: ["Intesa Sanpaolo"]
  },
  {
    id: 8,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 9,
    Image: ferrari,
    title: ["Ferrari"]
  },
  {
    id: 10,
    Image: lavazza,
    title: ["Lavazza"]
  }
];

const RLSRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 5,
    Image: unicredit,
    title: ["UniCredit"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: ferrari,
    title: ["Ferrari"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: intesaSanpaolo,
    title: ["Intesa Sanpaolo"]
  }
];

const UONaplesRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: unicredit,
    title: ["UniCredit"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 9,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles"]
  },
  {
    id: 10,
    Image: leonardo,
    title: ["Leonardo S.p.A."]
  }
];

const UOPisaRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: eni,
    title: ["Eni"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: unicredit,
    title: ["UniCredit"]
  },
  {
    id: 7,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 9,
    Image: fiatChrysler,
    title: ["Fiat Chrysler Automobiles"]
  },
  {
    id: 10,
    Image: leonardo,
    title: ["Leonardo S.p.A."]
  }
];

const IEDRecruiter = [
  {
    id: 1,
    Image: gucci,
    title: ["Gucci"]
  },
  {
    id: 2,
    Image: prada,
    title: ["Prada"]
  },
  {
    id: 3,
    Image: fendi,
    title: ["Fendi"]
  },
  {
    id: 4,
    Image: loreal,
    title: ["L'Oréal"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 7,
    Image: ferragamo,
    title: ["Ferragamo"]
  },
  {
    id: 8,
    Image: diesel,
    title: ["Diesel"]
  },
  {
    id: 9,
    Image: pirelli,
    title: ["Pirelli"]
  },
  {
    id: 10,
    Image: ikea,
    title: ["Ikea"]
  }
];

//Ireland

const TCDublinRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 5,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 6,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 7,
    Image: goldmansachs,
    title: ["Goldman Sachs"]
  },
  {
    id: 8,
    Image: ey,
    title: ["EY"]
  },
  {
    id: 9,
    Image: linkedin,
    title: ["LinkedIn"]
  },
  {
    id: 10,
    Image: amazon,
    title: ["Amazon"]
  }
];

const UCDublinRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 6,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 7,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 8,
    Image: meta,
    title: ["Meta (formerly Facebook)"]
  },
  {
    id: 9,
    Image: intel,
    title: ["Intel"]
  },
  {
    id: 10,
    Image: amazon,
    title: ["Amazon"]
  }
];

const UOCorkRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: microsoft,
    title: ["Microsoft"]
  }
];

const UOGalwayRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: microsoft,
    title: ["Microsoft"]
  }
];

const DCURecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: microsoft,
    title: ["Microsoft"]
  }
];

const UOLimerickRecruiter = [
  {
    id: 1,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 2,
    Image: google,
    title: ["Google"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 7,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 8,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 9,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 10,
    Image: apple,
    title: ["Apple"]
  }
];

const MURecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 7,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 8,
    Image: apple,
    title: ["Apple"]
  },
  {
    id: 9,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 10,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  }
];


const TUDublinRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 6,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 7,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 8,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 9,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  }
];

const DBSRecruiter = [
  {
    id: 1,
    Image: google,
    title: ["Google"]
  },
  {
    id: 2,
    Image: microsoft,
    title: ["Microsoft"]
  },
  {
    id: 3,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 8,
    Image: amazon,
    title: ["Amazon"]
  },
  {
    id: 9,
    Image: facebook,
    title: ["Facebook (Meta)"]
  },
  {
    id: 10,
    Image: ibm,
    title: ["IBM"]
  }
];

const RCSIRecruiter = [
  {
    id: 1,
    Image: stJamesHospital,
    title: ["St. James’s Hospital"]
  },
  {
    id: 2,
    Image: materMisericordiae,
    title: ["Mater Misericordiae University Hospital"]
  },
  {
    id: 3,
    Image: beaumontHospital,
    title: ["Beaumont Hospital"]
  },
  {
    id: 4,
    Image: corkUniversityHospital,
    title: ["Cork University Hospital"]
  },
  {
    id: 5,
    Image: hse,
    title: ["Health Service Executive (HSE)"]
  },
  {
    id: 6,
    Image: abbVie,
    title: ["AbbVie"]
  },
  {
    id: 7,
    Image: medtronic,
    title: ["Medtronic"]
  },
  {
    id: 8,
    Image: bostonScientific,
    title: ["Boston Scientific"]
  },
  {
    id: 9,
    Image: pfizer,
    title: ["Pfizer"]
  },
  {
    id: 10,
    Image: gsk,
    title: ["GlaxoSmithKline (GSK)"]
  }
];


 



// New Zealand
const AucklandRecruiter = [
  {
    id: 1,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 2,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 3,
    Image: pwc,
    title: ["PwC (PricewaterhouseCoopers)"]
  },
  {
    id: 4,
    Image: ey,
    title: ["EY (Ernst & Young)"]
  },
  {
    id: 5,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 6,
    Image: fonterra,
    title: ["Fonterra"]
  },
  {
    id: 7,
    Image: adhb,
    title: ["Auckland District Health Board (ADHB)"]
  },
  {
    id: 8,
    Image: anz,
    title: ["ANZ"]
  },
  {
    id: 9,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 10,
    Image: xero,
    title: ["Xero"]
  }
];

const MasseyRecruiter = [
  {
    id: 1,
    Image: fonterra,
    title: ["Fonterra"]
  },
  {
    id: 2,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 3,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 7,
    Image: ey,
    title: ["Ernst & Young (EY)"]
  },
  {
    id: 8,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 9,
    Image: genesisEnergy,
    title: ["Genesis Energy"]
  },
  {
    id: 10,
    Image: sparkNewZealand,
    title: ["Spark New Zealand"]
  }
];

const WaikatoRecruiter = [
  {
    id: 1,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 5,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 6,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 9,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 10,
    Image: taitCommunications,
    title: ["Tait Communications"]
  }
];

const VictoriaRecruiter = [
  {
    id: 1,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 7,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 8,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 9,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 10,
    Image: sparkNewZealand,
    title: ["Spark New Zealand"]
  }
];

const CanterburyRecruiter = [
  {
    id: 1,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 7,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 8,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 9,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 10,
    Image: christchurchCityCouncil,
    title: ["Christchurch City Council"]
  }
]

const OtagoRecruiter = [
  {
    id: 1,
    Image: fisherPaykel,
    title: ["Fisher & Paykel"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: doc,
    title: ["Department of Conservation"]
  }
];

const AUTRecruiter = [
  {
    id: 1,
    Image: fisherPaykelHealthcare,
    title: ["Fisher & Paykel Healthcare"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: kpmg,
    title: ["KPMG"]
  },
  {
    id: 4,
    Image: pwc,
    title: ["PwC"]
  },
  {
    id: 5,
    Image: deloitte,
    title: ["Deloitte"]
  },
  {
    id: 6,
    Image: anzBank,
    title: ["ANZ Bank"]
  },
  {
    id: 7,
    Image: accenture,
    title: ["Accenture"]
  },
  {
    id: 8,
    Image: airNewZealand,
    title: ["Air New Zealand"]
  },
  {
    id: 9,
    Image: ibm,
    title: ["IBM"]
  },
  {
    id: 10,
    Image: newZealandPolice,
    title: ["New Zealand Police"]
  }
];

const LincolnRecruiter = [
  {
    id: 1,
    Image: fisherPaykelHealthcare,
    title: ["Fisher & Paykel Healthcare"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: agResearch,
    title: ["AgResearch"]
  },
  {
    id: 4,
    Image: landcorpFarming,
    title: ["Landcorp Farming"]
  },
  {
    id: 5,
    Image: aftPharmaceuticals,
    title: ["AFT Pharmaceuticals"]
  },
  {
    id: 6,
    Image: tgGlobal,
    title: ["T&G Global"]
  },
  {
    id: 7,
    Image: horticultureNewZealand,
    title: ["Horticulture New Zealand"]
  },
  {
    id: 8,
    Image: lic,
    title: ["LIC (Livestock Improvement Corporation)"]
  },
  {
    id: 9,
    Image: doc,
    title: ["Department of Conservation (DOC)"]
  },
  {
    id: 10,
    Image: nzTransportAgency,
    title: ["NZ Transport Agency (NZTA)"]
  }
];

const EITRecruiter = [
  {
    id: 1,
    Image: fisherPaykelHealthcare,
    title: ["Fisher & Paykel Healthcare"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: agResearch,
    title: ["AgResearch"]
  },
  {
    id: 4,
    Image: landcorpFarming,
    title: ["Landcorp Farming"]
  },
  {
    id: 5,
    Image: aftPharmaceuticals,
    title: ["AFT Pharmaceuticals"]
  },
  {
    id: 6,
    Image: tgGlobal,
    title: ["T&G Global"]
  },
  {
    id: 7,
    Image: horticultureNewZealand,
    title: ["Horticulture New Zealand"]
  },
  {
    id: 8,
    Image: lic,
    title: ["LIC (Livestock Improvement Corporation)"]
  },
  {
    id: 9,
    Image: doc,
    title: ["Department of Conservation (DOC)"]
  },
  {
    id: 10,
    Image: nzTransportAgency,
    title: ["NZ Transport Agency (NZTA)"]
  },
  {
    id: 11,
    Image: fletcherBuilding,
    title: ["Fletcher Building"]
  }
];

const UCRecruiters = [
  {
    id: 1,
    Image: fisherPaykelHealthcare,
    title: ["Fisher & Paykel Healthcare"]
  },
  {
    id: 2,
    Image: xero,
    title: ["Xero"]
  },
  {
    id: 3,
    Image: agResearch,
    title: ["AgResearch"]
  },
  {
    id: 4,
    Image: landcorpFarming,
    title: ["Landcorp Farming"]
  },
  {
    id: 5,
    Image: tgGlobal,
    title: ["T&G Global"]
  },
  {
    id: 6,
    Image: horticultureNewZealand,
    title: ["Horticulture New Zealand"]
  },
  {
    id: 7,
    Image: lic,
    title: ["LIC (Livestock Improvement Corporation)"]
  },
  {
    id: 8,
    Image: doc,
    title: ["Department of Conservation (DOC)"]
  },
  {
    id: 9,
    Image: nzTransportAgency,
    title: ["NZ Transport Agency (NZTA)"]
  },
  {
    id: 10,
    Image: fletcherBuilding,
    title: ["Fletcher Building"]
  }
];









export {
  CaliforniaRecruiter,
  wisconsinRecruiter,
  YeshivaRecruiter,
  UtahRecruiter,
  ColoradoRecruiter,
  IllinoisRecruiter,
  StanfordRecruiter,
  PennsylvaniaRecruiter,
  ChicagoRecruiter,
  MITRecruiter,
  BristolRecruiter,
  LeedsRecruiter,
  GlasgowRecruiter,
  imperialRecruiter,
  QueenMaryRecruiter,
  LiverpoolRecruiter,
  EdinburghRecruiter,
  SouthamptonRecruiter,
  DurhamRecruiter,
  BirminghamRecruiter,
  UNSWRecruiter,
  MonashRecruiter,
  QueenslandRecruiter,
  WesternAustraliaRecruiter,
  AdelaideRecruiter,
  MelbourneRecruiter,
  SydneyRecruiter,
  AnuRecruiter,
  UtsRecruiter,
  MacquarieRecruiter,
  TrentRecruiter,
  TrinityRecruiter,
  RRURecruiter,
  WaterlooRecruiter,
  CalgeryRecruiter,
  DalhousieRecruiter,
  BrockRecruiter,
  WindsorRecruiter,
  VCCRecruiter,
  TorontoRecruiter,

  //Germany

  LMURecruiter,
  TUMRecruiter,
  HeidelbergUniversityRecruiter,
  HumboldtUniversityRecruiter,
  FUBRecruiter,
  RWTHRecruiter,
  UOFRecruiter,
  UOTRecruiter,
  UOMRecruiter,
  UOGRecruiter,

  //France

  UPSLRecruiter,
  UPSRecruiter,
  SURecruiter,
  UGARecruiter,
  UDSRecruiter,
  UDMRecruiter,
  UDLRecruiter,
  UDNRecruiter,
  UDBRecruiter,
  UPPSRecruiter,

  //Italy

  PDMilanoRecruiter,
  UOMilanRecruiter,
  UOBolognaRecruiter,
  UOFlorenceRecruiter,
  UOPaduaRecruiter,
  UOTurinRecruiter,
  RLSRecruiter,
  UONaplesRecruiter,
  UOPisaRecruiter,
  IEDRecruiter,

  //Ireland

  TCDublinRecruiter,
  UCDublinRecruiter,
  UOCorkRecruiter,
  UOGalwayRecruiter,
  DCURecruiter,
  UOLimerickRecruiter,
  MURecruiter,
  TUDublinRecruiter,
  DBSRecruiter,
  RCSIRecruiter,

   //NewZealand

   AucklandRecruiter,
   MasseyRecruiter,
   WaikatoRecruiter,
   VictoriaRecruiter,
   CanterburyRecruiter,
   OtagoRecruiter,
   AUTRecruiter,
   LincolnRecruiter,
   EITRecruiter,
   UCRecruiters,
 
 

};
