/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Grid,
  TextField,
  Button,
  Avatar,
  Typography,
  Link,
  Box
} from "@mui/material";
import { Icon } from "@iconify/react";
import axios from "axios";

// Define validation schema using Yup
const schema = yup.object().shape({
  fullName: yup.string().required("Full Name is required"),
  phoneNumber: yup.string().required("Phone Number is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
  location: yup.string().required("Location is required")
});

const ProfileForm = () => {
  const [profileImage, setProfileImage] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [initialDocuments, setInitialDocuments] = useState([]);

  const {
    control,
    handleSubmit,
    setValue, // To set form values
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  });
  const token = sessionStorage.getItem("token");
  const userid = sessionStorage.getItem("userId");

  // Handle profile image change
  const handleImageChange = (event) => {
    setProfileImage(URL.createObjectURL(event.target.files[0]));
  };

  // Handle document upload
  const handleFileChange = (event) => {
    setUploadedFiles(Array.from(event.target.files));
  };

  // Fetch and populate user data using API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://tied-api-hmjn.onrender.com/api/v1/student/get-student/${userid}`,
          {
            headers: {
              Authorization: token
            }
          }
        );

        const { username, email, mobile, location, image, documents } =
          response.data.data;

        setValue("fullName", username);
        setValue("phoneNumber", mobile || "");
        setValue("email", email);
        setValue("location", location || "");

        // Set profile image URL
        if (image) setProfileImage(image);

        // Set initial documents
        if (documents.length > 0) setInitialDocuments(documents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [setValue]);

  // Form submission handler
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("username", data.fullName);
    formData.append("email", data.email);
    formData.append("mobile", data.phoneNumber);
    formData.append("location", data.location);

    // Handle profile image upload if changed
    if (profileImage && typeof profileImage !== "string") {
      formData.append("image", profileImage);
    }

    // Handle documents upload
    uploadedFiles.forEach((file) => formData.append("documents", file));

    try {
      const response = await axios.put(
        "https://tied-api-hmjn.onrender.com/api/v1/student/update-profile",
        formData,
        {
          headers: {
            Authorization: token
          }
        }
      );
      console.log("Update response:", response.data);
      
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };
  const inputStyles = {
    backgroundColor: "#DEF2F3"
  };

  return (
    <Box sx={{ padding: "20px", margin: "20px 0" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justifyContent="center">
          {/* Profile Image */}
          <Grid item xs={12} md={4} container justifyContent="center">
            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  bgcolor: "#00999E",
                  p: "10px",
                  borderRadius: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 2
                }}
              >
                <Avatar
                  src={profileImage}
                  sx={{ width: "200px", height: "200px" }}
                />
              </Box>
              <Button
                variant="contained"
                component="label"
                sx={{
                  bgcolor: "#D2FEFF",
                  color: "#000",
                  "&:hover": { bgcolor: "#D2FEFF" }
                }}
              >
                Update Profile
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageChange}
                />
              </Button>
            </Box>
          </Grid>

          {/* Form Fields */}
          <Grid item xs={12} md={8}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {["fullName", "phoneNumber", "email", "location"].map(
                (field, idx) => (
                  <Controller
                    key={field}
                    name={field}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Box key={field} mb={3}>
                        <Typography>
                          {field
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder={field
                            .replace(/([A-Z])/g, " $1")
                            .replace(/^./, (str) => str.toUpperCase())}
                          value={value}
                          onChange={onChange}
                          error={!!errors[field]}
                          helperText={errors[field]?.message}
                          InputProps={{ style: inputStyles }}
                        />
                      </Box>
                    )}
                  />
                )
              )}

              {/* Document Upload */}
              <Typography>{"Upload Document"}</Typography>
              <Box
                sx={{
                  border: "1px dashed grey",
                  borderRadius: "5px",
                  bgcolor: "#DEF2F3",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  p: 2,
                  mt: 2
                }}
              >
                <Icon
                  icon="flowbite:upload-solid"
                  style={{ color: "#80869A", fontSize: "30px" }}
                />
                <Typography variant="h6">
                  Drag and Drop or Select to Upload Documents
                </Typography>
                <Typography variant="caption">
                  JPG, PNG, or PDF, file size no more than 10MB
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  sx={{
                    bgcolor: "#fff",
                    width: "150px",
                    border: "1px solid #00999E",
                    color: "#00999E",
                    mt: 2
                  }}
                >
                  Select File
                  <input
                    type="file"
                    hidden
                    multiple
                    accept=".pdf,.doc,.docx,.png,.jpg"
                    onChange={handleFileChange}
                  />
                </Button>
              </Box>

              {/* Display uploaded file names */}
              {uploadedFiles.length > 0 && (
                <Grid container spacing={1} mt={2}>
                  {uploadedFiles.map((file, index) => (
                    <Grid item xs={12} key={index}>
                      <Link
                        href={URL.createObjectURL(file)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.name}
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              )}

              {/* Display initial document URLs */}

              {/* Display initial document URLs */}
              {initialDocuments.length > 0 && (
                <Grid container spacing={1} mt={2}>
                  {initialDocuments.map((doc, index) => (
                    <Grid item xs={12} key={index}>
                      <Link
                        href={doc} // Assuming doc is a URL
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {`Document ${index + 1}`}{" "}
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              )}

              {/* Submit Button */}
              <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    width: "200px",
                    bgcolor: "#00999E",
                    "&:hover": { bgcolor: "#00999E" }
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default ProfileForm;
