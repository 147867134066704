import React from 'react';
import { Box, Typography, Button } from "@mui/material";
import Build from "../../../../asset/builder_abroad.svg"

const Builder = () => {
  return (

    <>
<Box
  sx={{
    width: { xs: "100%", sm: "200px" },
    height: "128px",
    opacity: "0.9",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "12px",
    marginTop: { xs: "0", sm: "157.5px" },
    marginLeft: { xs: "0", sm: "50px" },
    bgcolor: "#00999E",
    borderRadius: "20px",
  }}
>
  <Typography color="white">PROFILE BUILDING</Typography>
</Box>

<Box
  sx={{
    width: { xs: "100%", sm: "662px" },
    height: { xs: "auto", sm: "439px" },
    padding: "32px 32.04px 13px 33.96px",
    borderRadius: "10px",
    backgroundColor: "#E2FEFF",
    margin: "12px",
    marginLeft: { sm: "34px" },
    border: "1px solid #ccc",
  }}
>
  <Typography
    variant="h4"
    align="center"
    gutterBottom
    sx={{ fontSize: { xs: "24px", sm: "32px" } }}
  >
    Profile Building
  </Typography>
  <Typography
    variant="subtitle2"
    align="center"
    gutterBottom
    sx={{ fontSize: { xs: "14px", sm: "16px" } }}
  >
    Lorem ipsum dolor sit amet consectetur Lorem ipsum dolor sit amet consectetur.
  </Typography>

  <Box
    sx={{
      width: { xs: "100%", sm: "570px" },
      height: "265px",
      padding: "16px",
      borderRadius: "20px",
      border: "1px solid #ccc",
      backgroundColor: "#FFF",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      gap: "8px",
      marginLeft: { xs: "0", sm: "7px" },
    }}
  >
    <img src={Build} alt="wrong" />

    <Box>
      <Typography
        variant="h6"
        color="#606060"
        sx={{
          fontSize: "25px",
          fontWeight: 600,
          fontFamily: "Poppins",
        }}
      >
        Download your updated Profile Document
      </Typography>
      <Typography
        variant="body1"
        color="#9BA1B2"
        sx={{
          marginTop: -0.5,
          opacity: 0.8,
        }}
      >
        Your Profile building Document
      </Typography>
    </Box>

    <Button
      variant="contained"
      sx={{
        backgroundColor: "#00999E",
        mt: 2,
        "&:hover": {
          backgroundColor: "#007f80",
          color: "#fff",
        },
      }}
    >
      View Profile Building
    </Button>
  </Box>
</Box>

        </>
  )
}

export default Builder